import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'

import { SDKContext } from './Context'
import { isDevelopment } from '../../constants'

const withSDK = (WrappedComponent) => {
  class WithSDK extends React.PureComponent {
    static contextType = SDKContext

    render = () => (
      <WrappedComponent {...this.props} sdk={this.context}/>
    )
  }

  if (isDevelopment) {
    WithSDK.displayName = `WithSDK(${WrappedComponent.displayName || WrappedComponent.name}`
  }

  return hoistNonReactStatics(WithSDK, WrappedComponent)
}

export default withSDK
