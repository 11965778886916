import clsx from 'clsx'
import React from 'react'
import styles from './styles.module.scss'


export interface IToggleProps {
    isActive: boolean
    toggleSelected: () => void
    labelActive?: React.ReactNode
    labelInactive?: React.ReactNode
}

export const Toggle = ({
    isActive,
    toggleSelected,
    labelActive,
    labelInactive
}: IToggleProps) => {
    return (
        <div className={clsx(styles.toggleContainer)} onClick={toggleSelected}>
        <span className={clsx(styles.trackText, isActive ? styles.start : styles.end)}>{isActive ? labelActive : labelInactive}</span>
         <div className={isActive ? styles.thumbActive : clsx(styles.thumbInactive, styles.thumbActive)}>
            <span className={clsx(styles.thumbText, isActive && styles.powered)}>{isActive ? labelInactive : labelActive}</span>
         </div>
       </div>
    )
}
 

export default Toggle