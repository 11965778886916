import React, { useCallback, useState } from 'react'
import SimpleModalBox from 'components/common/SimpleModalBox'
import { IAddDriverModalProps } from '../AddUserModal'
import styles from './styles.module.scss'
import Box from '@material-ui/core/Box'
import FlatButton from 'material-ui/FlatButton'
import {translate as t} from "../../../utils";
import addModalStyles from '../AddUserModal/styles.module.scss'
import { useWithLongClick } from 'hooks/useWithLongClick'
import clsx from 'clsx'
import { setArchiveState } from 'actions/profile'
import {useDispatch} from "react-redux";

export const DeleteUserModal = ({
    onSucceed, user, deleteFilter, ...props
}: IAddDriverModalProps & { deleteFilter?: boolean }) => {
    const dispatch = useDispatch()

    const handleLongPressClick = useCallback(async() => {
        setShowLoadingLabel(true)
        if(deleteFilter) {
            await dispatch(setArchiveState(user, false))
        }else {
            await dispatch(setArchiveState(user, true))
        }
        setShowLoadingLabel(false)
        return onSucceed()
    }, [user])


    const [showLoadingLabel, setShowLoadingLabel] = useState(false)
    const { starting, onMouseLeave, ...buttonProps } = useWithLongClick(handleLongPressClick)


    const handleMouseLeave = useCallback(() => {
        if(!showLoadingLabel && onMouseLeave) {
            setShowLoadingLabel(false)
            onMouseLeave()
        }
    }, [onMouseLeave, showLoadingLabel])

    return (
        <SimpleModalBox {...props}>
            <Box className={styles.container}>
                <Box className={styles.body}>
                    <div className={styles.title}>
                       {t('DELETE_USER_TITLE')}
                    </div>
                    <div className={styles.subtitle}>
                       {t('DELETE_USER_SUBTEXT')}
                    </div>

                    <div className={clsx(addModalStyles["footer"], styles.marginTop)}>
                        <FlatButton
                            className={addModalStyles["cancel-button"]}
                            label={t('ADD_USER_CANCEL_BUTTON')}
                            onClick={props.onClose}
                        />
                        <FlatButton
                            {...buttonProps}
                            disableTouchRipple
                            onMouseLeave={handleMouseLeave}
                            className={clsx(styles.loadingButton, (starting && !showLoadingLabel) && styles.loading)}
                            icon={showLoadingLabel && <span className={addModalStyles.loading} />}
                            label={showLoadingLabel ? t('LONGPRESS_DONE') : t('LONGPRESS_DELETE_USER')}
                        />
                    </div>
                </Box>
            </Box>
        </SimpleModalBox>
    )
}


export default DeleteUserModal
