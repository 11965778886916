import get from 'lodash/get'
import  initialState, {IPlannerState} from "./state";
import {ACTION_TYPES} from "./actionTypes";

export default (state: IPlannerState = initialState, action: {type: ACTION_TYPES, payload: any}) => {
  switch (action.type) {
    case ACTION_TYPES.TOGGLE_TOUR_VISIBILITY: {
      return {
        ...state,
        tourVisibility: {
          ...state.tourVisibility,
          [action.payload] : !get(state.tourVisibility, action.payload, true)
        }
      }
    }
    //add here
    default:
      return state
  }
}
