import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'

import TextField from 'material-ui/TextField'

import Translate from '../../Translate'

import TimeRemaining from './TimeRemaining'
import DoneCounter from './DoneCounter'

import classes from './TodoHeader.module.scss'

const ScanField = ({ field }) => (
  <TextField autoFocus
    fullWidth
    underlineShow={false}
    hintText={<Translate>BARCODE_TODO</Translate>}
    {...field}
  />
)

const formInitial = { barcode: '' }

const TodoHeader = ({
  shipmentsDone, shipmentsTotal, onScan,
}) => {
  const handleSubmit = React.useCallback(({ barcode }, { resetForm }) => {
    resetForm()
    onScan(barcode)
  }, [onScan])

  return (
    <div className={classes.container}>
      <div className={classes.bar}>
        <Formik onSubmit={handleSubmit} initialValues={formInitial}>
          <Form>
            <Field name="barcode" component={ScanField} />
          </Form>
        </Formik>
      </div>

      <div className={classes.details}>
        <DoneCounter done={shipmentsDone} total={shipmentsTotal} />
        <TimeRemaining />
      </div>
    </div>
  )
}

TodoHeader.propTypes = {
  onScan: PropTypes.func,
  shipmentsDone: PropTypes.number,
  shipmentsTotal: PropTypes.number,
}

TodoHeader.defaultProps = {
  onScan: () => null,
  shipmentsDone: 0,
  shipmentsTotal: 0,
}

export default TodoHeader
