import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import get from 'lodash/get'
import { browserHistory } from '../../browserHistory'
import { Card } from 'material-ui'
import { faUserCircle, faEnvelope, faPhone, faHome } from '@fortawesome/free-solid-svg-icons'

import * as fromProfile from '../../selectors/profile'

import { InfoButton } from '../common/Button/Button'
import { BasicMap } from '../planner/Map/GoogleMap'
import Translate from '../Translate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { translate } from '../../utils/translate'

const cardStyle = {
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.12)',
  width: 872,
  borderRadius: 5,
  marginTop: 28,
}

class Profile extends Component {
  componentDidMount() {
    const { routeParams } = this.props

    this.checkForToast(get(routeParams, 'status'))
  }

  checkForToast = (status) => {
    if (status === 'saved') {
      const [title, message] = translate(['PROFILE_EDITED', 'PROFILE_EDITED_MSG'])
      toastr.success(title, message)
    }
    if (status === 'pass') {
      const [title, message] = translate(['PASSWORD_CHANGED', 'PASSWORD_CHANGED_MSG'])
      toastr.success(title, message)
    }
  }

  render() {
    const { subco, location } = this.props
    const tdStyle = { verticalAlign: 'top' }
    return !!subco && (
      <div className="profile--wrapper">
        <h1 className="profile--title">
          {translate('SUBCO_PROFILE')} - {subco.tag}
        </h1>
        <Card className="profile--form" style={cardStyle}>
          <div className="profile-info--wrapper">
            <div className="profile-info">
              <span className="label name">
                {subco.name}
              </span>
              <span className="label location">
                <FontAwesomeIcon icon={faHome} />
                <span>{location.address}</span>
                <span>{location.postalCode}</span>
                <span>{location.city} {location.country}</span>
                <span>{location.latitude} - {location.longitude}</span>
              </span>
            </div>
            <div className="profile-info-location">
              <BasicMap
                lat={location.latitude}
                lng={location.longitude}
                zoom={13}
                loadingElement={<div style={{ height: '276px' }} />}
                containerElement={<div style={{ height: '276px', borderRadius: '6px' }} />}
                mapElement={<div style={{ height: '276px', borderRadius: '6px' }} />}
              />
            </div>
          </div>
          <div className="profile-actions--container">
            <InfoButton
              label={<Translate capitalize={true}>EDIT_PROFILE</Translate>}
              onClick={() => browserHistory.push('/edit-profile')}
            />
          </div>
        </Card>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  subco: fromProfile.getSubco(state),
  location: state.profile.location,
})

export default connect(mapStateToProps, null)(Profile)
