export enum FILTERS {
  ALL_EMPLOYEES,
  PLANNER,
  INACTIVE,
  DELETED
}

export enum FORM_ACTION {
  CREATE,
  EDIT,
  DELETE
}

export interface IUserProfile {
  id: number
  name: string
  phoneNumber: string
  email: string
  active: boolean
  isPlanner: boolean
  isArchived: boolean
  picture: {
    location: string
    base64string: string
  }
  userPreferences?: {
    locale: string
  }
}
