import React, {memo, useCallback, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment'
import get from 'lodash/get'
import clsx from "clsx";

import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {FlatButton} from "material-ui";
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from "material-ui/Table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as fromCombined from '../../../../../selectors/combined'
import * as fromDrivers from '../../../../../selectors/driver'

import {setDriver, setVehicleType} from '../../../../../actions/tours'

import {ITour} from "../../@types";
import {getDepartureTime, getLastStopTime, getTotalShipment} from "../../helpers";

import TourStopsModal from "../TourStopsModal";

import fuelTypes from '../../resource'

import {translate as t} from "../../../../../utils";

import styles from './styles.module.scss'
import SimpleAutoComplete from "../../../../common/SimpleAutoComplete";

const ToursTable = () => {
  const [isTourStopsModalVisible, setIsTourStopsModalVisible] = useState(false)
  const [selectedTour, setSelectedTour] = useState<ITour>()

  const dispatch = useDispatch()

  const tours = useSelector(fromCombined.getShipmentTours)
  const drivers = useSelector(fromDrivers.getDrivers)
  const availableDrivers = useSelector(fromDrivers.getAvailableDrivers).map(({id, name}) => ({value: id, text: name}))
  const areToursLocked = useSelector(fromCombined.areToursLocked)

  const handleDriverChange = (tourId: string) => (ev: React.ChangeEvent<{}>, option: {value: string}) => {
    const driverId = get(option, 'value')
    dispatch(setDriver(tourId, driverId))
  }

  const handleVehicleChange = (tourId: string, vehicleType: string) => () => {
    dispatch(setVehicleType(tourId, vehicleType.toLowerCase()))
  }

  const openTourStopsModal = (tour: ITour) => () => {
    setSelectedTour(tour)
    setIsTourStopsModalVisible(true)
  }

  const closeTourStopsModal = useCallback(() => setIsTourStopsModalVisible(false), [])

  return (
    <div className={styles["table-container"]}>
      <Table
        selectable={false}
        className={clsx('table', styles.table)}
      >
        <TableHeader
          adjustForCheckbox={false}
          displaySelectAll={false}
          className="table-header"
        >
          <TableRow className="table-header-row">
            <TableHeaderColumn className="table-header-column">
              {t('LITE_TOURS_TABLE_TOUR')}
            </TableHeaderColumn>
            <TableHeaderColumn className="table-header-column">
              {t('LITE_TOURS_TABLE_DEPARTURE')}
            </TableHeaderColumn>
            <TableHeaderColumn className="table-header-column">
              {t('LITE_TOURS_TABLE_LAST_STOP')}
            </TableHeaderColumn>
            <TableHeaderColumn className="table-header-column">
              {t('LITE_TOURS_TABLE_SHIPMENTS')}
            </TableHeaderColumn>
            <TableHeaderColumn className="table-header-column">
              {t('LITE_TOURS_TABLE_DRIVER')}
            </TableHeaderColumn>
            <TableHeaderColumn className="table-header-column">
              {t('LITE_TOURS_TABLE_VEHICLE_TYPE')}
            </TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false} showRowHover={false} className="table-body">
          {useMemo(() => tours.map((tour: ITour) => {
            const {id, number, collations, driverId, vehicleType = 'fossil'} = tour
            const departureTime = getDepartureTime(collations)
            const lastStopTime = getLastStopTime(collations)
            const shipmentCount = getTotalShipment(collations)
            const driverName = get(drivers.find(({id}) => id === driverId), 'name', '')
            const isNotDoneAtOptimizedTime = lastStopTime && moment(lastStopTime.format('HH:mm'), 'HH:mm').isAfter(moment({'h': 22}))

            return (
              <TableRow key={id} className="table-body-row">
                <TableRowColumn className="table-body-column">
                  {number}
                </TableRowColumn>
                <TableRowColumn className="table-body-column">
                  {departureTime ? moment(departureTime).format('HH:mm') : '--:--'}
                </TableRowColumn>
                <TableRowColumn className={clsx('table-body-column', isNotDoneAtOptimizedTime && styles.danger)}>
                  {lastStopTime ? moment(lastStopTime).format('HH:mm') : '--:--'}
                </TableRowColumn>
                <TableRowColumn className="table-body-column">
                  {shipmentCount}
                </TableRowColumn>
                <TableRowColumn className="table-body-column">
                  <SimpleAutoComplete
                    value={driverId}
                    textValue={driverName}
                    onChange={handleDriverChange(id)}
                    options={availableDrivers}
                    placeholder={t('LITE_SELECT_DRIVER')}
                    noOptionsText={t('LITE_AUTO_COMPLETE_DRIVER_NOT_FOUND')}
                    disabled={areToursLocked}
                  />
                </TableRowColumn>
                <TableRowColumn className="table-body-column">
                  <div className={styles["vehicle-type-container"]}>
                  {
                    fuelTypes.map(({name, icon}) => (
                      <Tooltip classes={{tooltip: styles.tooltip, arrow: styles["tooltip-arrow"]}} title={t(name)} placement="top" arrow>
                        <FlatButton
                          className={clsx(name === vehicleType.toUpperCase() && styles.active)}
                          disableTouchRipple={true}
                          icon={<FontAwesomeIcon icon={icon}/>}
                          onClick={handleVehicleChange(id, name)}
                        />
                      </Tooltip>
                    ))
                  }
                  </div>
                  <div>
                    <FlatButton
                      label={t('LITE_SHOW_TOUR')}
                      className={styles["show-tour-button"]}
                      disableTouchRipple={true}
                      onClick={openTourStopsModal(tour)}
                    />
                  </div>
                </TableRowColumn>
              </TableRow>
            )
          }), [tours, drivers, fuelTypes])}
        </TableBody>
      </Table>
      <TourStopsModal tour={selectedTour} visible={isTourStopsModalVisible} onClose={closeTourStopsModal} />
    </div>
  )
}

export default memo(ToursTable)
