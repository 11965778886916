import React from 'react'
import PropTypes from 'prop-types'

import MenuItem from 'material-ui/MenuItem'

import Checked from 'material-ui/svg-icons/toggle/check-box'
import Unchecked from 'material-ui/svg-icons/toggle/check-box-outline-blank'

import Translate from '../../Translate'
import { color } from '../../../utils/getColor'

const TourList = ({
  tour, index, isShown, onToggleShown,
}) => !!tour.collations.length && (
  <MenuItem
    key={tour.id}
    primaryText={
      <span>
        <Translate capitalize>TOUR</Translate>&nbsp;{`#${tour.number}`}
      </span>
    }
    rightIcon={(
      isShown
        ? <Checked color={tour.color} />
        : <Unchecked color={tour.color} />
    )}
    value={tour.number}
    style={{
      color: tour.color || color(index),
    }}
    onClick={() => onToggleShown(tour.id)}
  />
) || <></>

TourList.propTypes = {
  tour: PropTypes.object,
  index: PropTypes.number,
  isShown: PropTypes.bool,
  onToggleShown: PropTypes.func,
}

export default TourList
