import { Component } from 'react'
import { connect } from 'react-redux'
import IconButton from 'material-ui/IconButton'
import PrintIcon from 'material-ui/svg-icons/action/print'
import TableData from './TableData'
import React from 'react'
import * as fromCombined from '../../selectors/combined'


class Export extends Component {

  doubleNumber(num) {
    return (String(num).length < 2) ? `0${String(num)}` : num
  }
  minToTime(mins) {
    const h = mins / 60 | 0,
      m = mins % 60 | 0
    const hours = h >= 10 ? h : '0' + h
    const min = m >= 10 ? m : '0' + m

    return `${hours}:${min}`
  }
  render() {

    const styles = {
      main: {
        position: 'relative',
      },
      printBtnStyle: {
        position: 'absolute',
        top: -25,
        right: 0,
        height: 72,
        width: 72,
        padding: 12,
      },
      printIconStyle: {
        width: 36,
        height: 36,
      },
    }

    const { tours, drivers } = this.props

    return (
      <div style={styles.main}>
        <IconButton
          className="no-print"
          onClick={() => window.print()}
          iconStyle={styles.printIconStyle}
          style={styles.printBtnStyle} touch={true}>
          <PrintIcon color="#CCC"/>
        </IconButton>
        {
          !!tours
        && tours.map((tour) => {
          return !!tour.collations.length && (
            <TableData
              spanStyle={{
                display: 'inline-block',
                width: 100,
              }}
              tour={tour}
              key={tour.id}
              driver={drivers[tour.driverId]}
            />
          )
          // if(route.Collations.length > 0 && route.DriverId ){
          //   let duration = 0;
          //   let totalDuration = 0;
          //   route.Collations.map((id, index) => {
          //     totalDuration += (this.props.shipments[id].Duration + 180);
          //   })
          //   let totalDurationRoute = this.minToTime(route.Duration);
          //   let startTime = getStart(this.props.routes[route.Id].Collations, this.props.shipments);
          //   startTime = this.doubleNumber(startTime.getHours())+":"+this.doubleNumber(startTime.getMinutes());
          //   let spanStyle = {display: 'inline-block',width: 100};
          //   return (
          //   <TableData spanStyle={spanStyle}
          //              route={route}
          //              driverName={this.props.drivers[route.DriverId].Name}
          //              key={route.id}
          //              shipments={this.props.shipments}
          //   />
          // )}
        })
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  tours: fromCombined.getShipmentTours(state),
  drivers: state.drivers,
})

export default connect(mapStateToProps, null)(Export)
