import map from 'lodash/map'
import * as moment from 'moment'

export const validateTours = ({ tours }) => {
  const hasSetAllDrivers = tours.every(tour => tour.driverId)
  if (!hasSetAllDrivers) {
    return { valid: false }
  }

  const hasUniqueDrivers = new Set(map(tours, 'driverId')).size
    === tours.length
  if (!hasUniqueDrivers) {
    return { valid: false }
  }

  return null
}

export const getDriversWUsage = (drivers, tours) => {
  const usageMap = tours.filter(tour => !!tour.driverId)
    .reduce((map, tour) => Object.assign(map, {
      [tour.driverId]: true,
    }), {})

  return drivers.map(driver => ({
    ...driver,
    inUse: !!usageMap[driver.id],
  }))
}

export const isBeforeToday = (date) => {
  const dateValue = moment(date, 'YYYY-MM-DD')
  return dateValue.isBefore(moment().startOf('day'))
}
