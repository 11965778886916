import React, {HTMLAttributes, memo} from 'react';
import FormControl from "@material-ui/core/FormControl/FormControl";
import {Input} from "../../../common/reactHookComponents";
import {faBarcode} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from './styles.module.scss'

interface IScanBarProps extends HTMLAttributes<HTMLInputElement> {
  register?: any;
  name: string;
  error?: string;
}

const ScanBar = ({...props} : IScanBarProps) => {
  return (
    <FormControl fullWidth={true}>
      <div className={styles.bar}>
        <FontAwesomeIcon icon={faBarcode} />
        <Input {...props}/>
      </div>
    </FormControl>
  )
}

export default memo(ScanBar)
