import React from 'react'
import PropTypes from 'prop-types'

import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { List, ListItem } from 'material-ui/List'
import Progress from '@material-ui/core/CircularProgress'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Tooltip from '@material-ui/core/Tooltip'

import Toolbar from '../../toolbar/Toolbar'
import Translate from '../../Translate'
import Badge from '../../badge/Badge'

import styles from './OrderList.module.scss'
import get from 'lodash/get'
import { translate } from '../../../utils/translate'

export const OrderList = ({
  color,
  tour,
  driver,
  onModalOpen,
  onHandOver,
}) => {
  const [isPressed, setIsPressed] = React.useState(false)
  const totalDistanceInKM = React.useMemo(() => (get(tour, 'totalDistance') / 1000).toFixed(1).concat('KM'), [tour])

  React.useEffect(() => {
    const isTKeyPressed = (event) => {
      setIsPressed(event.keyCode === 84)
    }
    const releaseT = (event) => { setIsPressed(false) }
    document.addEventListener('keydown', isTKeyPressed, false)
    document.addEventListener('keyup', releaseT, false)
    return () => {
      document.removeEventListener('keydown', isTKeyPressed, false)
      document.removeEventListener('keyup', releaseT, false)
    }
  }, [])

  const handoverShipment = (shipmentId) => () => {
    if (isPressed) {
      onHandOver(shipmentId)
    } else {
      alert('Scan het pakket om het te sorteren')
    }
  }
  return (<div className={styles.container}>
    <Toolbar
      color={color}
      style={{
        borderRadius: '5px 5px 0 0',
        marginBottom: 0,
        width: 320,
      }}
    >
      <div className={styles.toolbarContainer}>
        <span>
          <Translate>CART</Translate> {tour.number} - {driver.name}
        </span>
        <span>
          <FontAwesomeIcon icon={faExchangeAlt} className={styles.changeDriverIcon} onClick={onModalOpen} />
          <Badge label={tour.collations.length} />
        </span>
      </div>
    </Toolbar>
    <List
      style={{
        paddingTop: 0,
        boxShadow: '0 2px 4px rgba(0,0,0,.08)',
        borderRadius: '0 0 5px 5px',
        background: '#fff',
      }}
    >
      <div className={styles.totalDistance}><Translate>TOTAL_DISTANCE</Translate>: {totalDistanceInKM}</div>
      {!tour.collations.length && (
        <ListItem
          style={{
            background: '#fff',
            fontSize: 14,
          }}
          primaryText={
            <span className={styles.header}>
              <Translate>ALL_SHIPMENTS_HANDOVERED</Translate>
            </span>
          }
          disabled={true}
        />
      )}
      {tour.collations && tour.collations.map((collation, index) => (
        // TODO: add multi colli identification
        <ListItem
          key={collation.id}
          style={{
            background: '#fff',
            borderBottom: (index < tour.collations.length - 1) ? '1px solid rgba(0,0,0,.12)' : 'none',
            fontSize: 14,
          }}
          primaryText={
            <span className={styles.header}>
              {collation.recipient.location.address}
            </span>
          }
          secondaryText={
            <span className={styles.subHeader}>
              {collation.recipient.location.postalCode} {collation.recipient.location.city}
            </span>
          }
          onClick={handoverShipment(collation.id)}
        >
          {collation.isWorking && (
            <ListItemSecondaryAction>
              <Tooltip
                arrow
                placement="right"
                title={translate('PROCESSING')}
                classes={{ tooltip: styles.tooltip }}
              >
                <Progress size={24} />
              </Tooltip>
            </ListItemSecondaryAction>
          )
          }
        </ListItem>
      ))}
    </List>
  </div>)
}

OrderList.propTypes = {
  color: PropTypes.string,
  tour: PropTypes.any,
  driverId: PropTypes.any,
  onHandOver: PropTypes.func,
}

OrderList.defaultProps = {
  onHandOver: () => null,
}

export default OrderList
