import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

const Box = (props) => {
  const { index, classes, primaryIndex, secondaryIndex, setFilter } = props
  const handleSetFilter = useCallback(() => setFilter(primaryIndex, secondaryIndex), [ index ])
  return (
    <a
      key={index}
      className={classes}
      onClick={handleSetFilter}
    />
  )
}

Box.propTypes = {
  index: PropTypes.number,
  classes: PropTypes.string,
  primaryIndex: PropTypes.number,
  secondaryIndex: PropTypes.number,
  setFilter: PropTypes.func,
}

Box.defaultProps = {
  index: 0,
  classes: '',
  primaryIndex: 0,
  secondaryIndex: 0,
  setFilter: () => null,
}

export default Box
