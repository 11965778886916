import moment from 'moment'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'

import { createFeatureSelector } from './core'

import * as fromProfile from '../reducers/profile'
import { CNT_1, CNT_2 } from '../constants/regions'

const selectFeature = createFeatureSelector('profile')

export const getIsSubco = createSelector(
  selectFeature,
  fromProfile.selectIsSubco,
)

export const getIsPlanner = createSelector(
  selectFeature,
  fromProfile.selectIsPlanner,
)

export const getSdkSubco = createSelector(
  selectFeature,
  fromProfile.selectSubcoInstance,
)

export const getSubco = createSelector(
  selectFeature,
  fromProfile.selectSubcoProfile,
)

export const getIsCenter = createSelector(
  getSubco,
  (subCo) => subCo && (subCo.tag === CNT_1 || subCo.tag === CNT_2),
)

export const getSubcos = createSelector(
  selectFeature,
  fromProfile.selectSubcoProfiles,
)

export const getInvoiceSubcos = createSelector(
  selectFeature,
  fromProfile.selectInvoiceSubcos,
)

export const getSubcoRegion = createSelector(
  selectFeature,
  fromProfile.selectSubcoRegion,
)

export const isProfileReady = createSelector(
  selectFeature,
  fromProfile.selectIsReady,
)

export const getDrivers = createSelector(
  selectFeature,
  fromProfile.selectDrivers,
)

export const getPlanners = createSelector(
  getDrivers,
  drivers => drivers ? drivers.filter(driver => driver.isPlanner && !driver.isArchived && driver.active) : [],
)

export const getInactiveEmployees = createSelector(
  getDrivers,
  drivers => drivers ? drivers.filter(driver => !driver.active && !driver.isArchived) : [],
)

export const getDeletedEmployees = createSelector(
  getDrivers,
  drivers => drivers ? drivers.filter(driver => driver.isArchived) : [],
)

export const getOrderedSubcos = createSelector(
  getSubcos,
  subcos => orderBy(subcos, ['tag']),
)

export const getSubcoIndex = createSelector(
  getSubcos,
  subcos => subcos && subcos.reduce((acc, { tag }, index) => {
    return {
      ...acc,
      [tag]: index,
    }
  }, {}) || {},
)

export const getSubcosById = createSelector(
  getSubcos,
  subcos => subcos && subcos.reduce((acc, { tag, id }) => {
    return {
      ...acc,
      [id]: tag,
    }
  }, {}) || {},
)

export const getCurrentSubcoId = createSelector(
  getSubco,
  subco => get(subco, 'id'),
)

export const getDriverProfiles = createSelector(
  getDrivers,
  drivers => drivers ? drivers.filter(driver => !driver.isArchived && driver.active) : [],
)

export const getDepartureTime = createSelector(
  getSubco,
  (subco) => {
    let departureTime = moment()
    const useSubcoStartTime = subco
      && moment(subco.defaultStartTime).hour() > moment().hour()
    if (useSubcoStartTime) {
      departureTime = moment()
        .hour(moment(subco.defaultStartTime).hour())
        .minute(moment(subco.defaultStartTime).minute())
    }
    return departureTime.toISOString()
  },
)

export const getDepotCoordinates = createSelector(
  selectFeature,
  fromProfile.selectDepotCoords,
)

export const getCurrentLocale = createSelector(
  selectFeature,
  fromProfile.selectCurrentLocale,
)
