import React from 'react'
import { translate } from '../../../utils/translate'
import ModalBox from '../../common/ModalBox'
import icon from './caution-icon.svg'
import Translate from '../../Translate'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import orderBy from 'lodash/orderBy'

import styles from './ChangeDriverModal.module.scss'
import FlatButton from 'material-ui/FlatButton'

export const ChangeDriverModal = ({ show, onClose, drivers, tour, selectedDriver, onDriverChanged, onSaveDriverChange }) => {
  const renderDrivers = React.useCallback((drivers) =>
    drivers.filter(driver => driver.active).map(driver => (
      <MenuItem
        key={driver.id}
        value={driver.id}
        primaryText={driver.name}
      />
    ))
  , [drivers])

  const handleChange = (ev, target, value) => {
    onDriverChanged(value)
  }

  return (
    <ModalBox hidden={!show} onClose={onClose}>
      <div className="content">
        <div className={styles.wrapper}>
          <div className={styles.details}>
            <div className={styles.icon}>
              <img src={icon} />
              <span className={styles.text}>{translate('CAUTION')}</span>
            </div>
            <p className={styles.subDetails}>
              {translate('CAUTION_CHANGE_DRIVER')}
            </p>
          </div>
          <div className={styles.inputContainer}>
            <SelectField
              fullWidth
              className="dropdown-driver"
              value={selectedDriver}
              onChange={handleChange}
              hintText={<Translate capitalize>SELECT_DRIVER</Translate>}
            >
              {renderDrivers(orderBy(drivers, 'name', 'asc'))}
            </SelectField>
            <div className={styles.buttons}>
              <FlatButton
                className={styles.cancelButton}
                label={<Translate>CANCEL</Translate>}
                onClick={onClose}
              />
              <FlatButton
                className={styles.changeDriverButton}
                label={<Translate>CHANGE_DRIVER</Translate>}
                onClick={onSaveDriverChange}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalBox>
  )
}
