import get from 'lodash/get'

export const isFeatureEnabled = (key: string) =>
  /true/i.test(
    get(process.env, `REACT_APP_FEATURE_${key.toUpperCase()}`, get(featureDefaults, key, true).toString())
  )

const featureDefaults = {
  'COLLECTIONS' : false,
  'FREEZER_MANAGEMENT' : false,
  'TODO_LIST' : false
} as {[key:string] : boolean}
