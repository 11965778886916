import React from 'react'
import PropTypes from 'prop-types'
import flow from 'lodash/flow'

import connect from './connect'
import withSDK from '../SDK/withSDK'

class DataGate extends React.PureComponent {
  isLoading = false

  componentDidMount() {
    this.checkLoadState()
  }

  componentDidUpdate() {
    this.checkLoadState()
  }

  checkLoadState = () => {
    const { setLoading, hasShipments } = this.props

    if (!hasShipments && !this.isLoading) {
      setLoading(true)
      this.isLoading = true

      this.findSubco()
        .then(this.loadShipments)
        .finally(setLoading(false))
    }
  }

  findSubco = async() => {
    let { sdk, subco, setSubco } = this.props

    if (!subco) {
      subco = await sdk.Auth().fetchSubcoForUser()
      setSubco(subco)
    }

    return subco
  }

  loadShipments = async(subco) => {
    if(!subco) {
      this.isLoading = false
      return
    }
    if (Array.isArray(subco)) {
      subco = subco[0]
    }
    const { setShipments, setDrivers } = this.props
    const { shipments, subcontractor: { drivers } } = await subco.fetchDailyShipmentOverview()
    setDrivers(drivers)
    setShipments(shipments)
    this.isLoading = false
  }

  isReady = () => {
    const { hasShipments, isLoggedIn } = this.props

    return !isLoggedIn || hasShipments
  }

  render() {
    const {
      props: { children },
      isReady,
    } = this

    return isReady() && children
  }
}

DataGate.propTypes = {
  setShipments: PropTypes.func.isRequired,
  setDrivers: PropTypes.func.isRequired,
  setSubco: PropTypes.func.isRequired,
  sdk: PropTypes.object.isRequired,
  subco: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  hasShipments: PropTypes.bool,
  children: PropTypes.node,
}

DataGate.defaultProps = {
  subco: null,
  hasShipments: false,
  isLoggedIn: false,
  children: null,
}

export default flow([
  connect,
  withSDK,
])(DataGate)
