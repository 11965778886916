import React from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import { connect } from 'react-redux'
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps'

import { Markers, Directions } from './GoogleMap'
import { mapStyles } from './mapStyles'

import * as uiActions from '../../../actions/ui'
import MapActions from './MapActions'

import * as fromUI from '../../../selectors/ui'

const Actions = ({
  tours, selectedMarkers, clearSelectedMarkers, activeTour,
  moveSelectedCollations, addToNewTour,
}) => {

  const handleDeselect = () => {
    clearSelectedMarkers()
  }

  return (
    <MapActions moveSelectedCollations={moveSelectedCollations}
      activeTour={activeTour} tours={tours} deselect={handleDeselect} addToNewTour={addToNewTour} selectedMarkers={selectedMarkers}/>
  )
}

Actions.propTypes = {
  tour: PropTypes.any,
  deselect: PropTypes.any,
  shown: PropTypes.any,
  selectedMarkers: PropTypes.any,
  clearSelectedMarkers: PropTypes.any,
  addToNewTour: PropTypes.any,
}

const mapDispatchToProps = {
  clearSelectedMarkers: uiActions.clearSelected,
}

export const ActionsComponent = connect(null, mapDispatchToProps)(Actions)

class MapView extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showActionContainer: false,
      unSelectAll: false,
      highlightedTour: null,
    }
  }


  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      const { selected } = this.props

      if(selected.length !== 0) {
        this.setState({ showActionContainer: true })
        return
      }
      this.setState({ showActionContainer: false })
    }
  }

    renderTourMarkers = memoize((tours, shown, highlightedTour) =>
      tours.map((tour) => shown.includes(tour.id) && (
        <React.Fragment key={tour.id}>
          <Markers
            tour={tour}
            key={tour.id}
            tours={tours}
            move={this.props.move}
            highlightedTour={highlightedTour}
            setHighlightedTour={this.setHighlightedTour}
          />
          <Directions tour={tour} />
        </React.Fragment>
      )),
    )

    setHighlightedTour = (tourId) => this.setState({ highlightedTour: tourId })

    render() {
      const {
        onGoogleMapLoad, depotCoordinates, tours,
        shown, zoom, enableScroll, moveMultipleCollations,
      } = this.props

      return (
        <div>
          <GoogleMap
            ref={onGoogleMapLoad}
            zoom={zoom}
            defaultCenter={{ lat: 51.990276, lng: 5.103033 }}
            options={{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              scrollwheel: enableScroll,
              styles: mapStyles,
              disableDoubleClickZoom: true
            }}
          >
            <Marker
              position={depotCoordinates}
              defaultAnimation={0}
              icon="https://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=home|BDBDBD|ffffff"
            >
              {this.renderTourMarkers(tours, shown, this.state.highlightedTour)}
            </Marker>
          </GoogleMap>
          {this.state.showActionContainer && <ActionsComponent activeTour={shown}
            moveSelectedCollations={moveMultipleCollations}
            tours={tours} selectedMarkers={this.props.selected} addToNewTour={this.props.addToNewTour}/>}
        </div>
      )
    }
}

const mapStateToProps = state => ({
  selected: fromUI.getSelectedCollations(state),
})


const GoogleMapView = connect(mapStateToProps, null)(MapView)

export default withGoogleMap(GoogleMapView)
