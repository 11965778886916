import get from 'lodash/get'
import { IHubInventory } from '../reducers/hublogs'
import isEqual from 'lodash/isEqual'

export const setShipmentNextStep = (state: any, action: any) => {
  const updatedList = state.inventory.reduce((acc: any, hublog: IHubInventory) => {
    const isTrunkrsNrEqual = isEqual(get(hublog, 'trunkrsNr'), get(action, 'payload.trunkrsNr'))
    const isBarcodeEqual = isEqual(get(hublog, 'barcode'), get(action, 'payload.barcode'))

    if(isTrunkrsNrEqual && isBarcodeEqual) {
      hublog.type = action.payload.type
    }
    return [
      ...acc,
      hublog,
    ]
  }, [])
  return updatedList
}

export default setShipmentNextStep