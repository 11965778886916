import moment from 'moment'
import { PROCESSED_SHIPMENT_STATUS } from 'Trunkrs-SDK/dist/models/delivery/Shipment'
import * as Sentry from '@sentry/browser'
import get from 'lodash/get'

import * as actionTypes from '../constants/ActionTypes'
import * as fromProfile from '../selectors/profile'
import * as fromTodos from '../selectors/todoList'
import { setLoading } from './ui'

import { trunkrs } from '../'
import { translate } from '../utils/translate'

export const setShipmentLoading = (shipmentId, loading) => ({
  type: actionTypes.SET_SHIPMENT_LOADING,
  payload: { shipmentId, loading },
})

const markShipmentProcessed = (shipmentId, choice) =>
  async(dispatch, getState) => {
    const shipment = fromTodos.getNoEndStateData(getState())[shipmentId]
      || fromTodos.getNotDeliveredData(getState())[shipmentId] || fromTodos.getReturnsData(getState())[shipmentId]

    if (!get(shipment, 'isLoading')) {
      dispatch(setShipmentLoading(shipmentId, true))
    }

    try {
      await trunkrs.Shipment().markTodoShipmentProcessed(shipmentId, choice)
      dispatch({
        type: actionTypes.MARK_SHIPMENT_PROCESSED,
        payload: { shipmentId, choice },
      })
    } catch (error) {
      Sentry.captureException(error)
      dispatch(setShipmentLoading(shipmentId, false))
    }
  }

export const loadShipments = () =>
  async(dispatch, getState) => {
    dispatch(setLoading(true, translate('LOAD_TODO')))
    dispatch({ type: actionTypes.LOAD_TODO })

    try {
      const currentSubcodId = fromProfile.getCurrentSubcoId(getState())
      const shipments = await trunkrs.Shipment().getTodoListShipments(currentSubcodId)
      dispatch({
        type: actionTypes.LOAD_TODO_SUCCESS,
        payload: shipments,
      })
    } catch (error) {
      Sentry.captureException(error)
    }

    dispatch(setLoading(false))
  }

export const markPostponed = (shipmentId) =>
  async(dispatch) => {
    dispatch(setShipmentLoading(shipmentId, true))

    try {
      const Shipment = trunkrs.Shipment()
      const shipmentInstance = new Shipment(shipmentId)
      await shipmentInstance.postPoneOnDepot()

      dispatch(markShipmentProcessed(
        shipmentId,
        PROCESSED_SHIPMENT_STATUS.SHIPMENT_BACK_IN_PLANNING,
      ))
    } catch (error) {
      Sentry.captureException(error)
      dispatch(setShipmentLoading(shipmentId, false))
    }
  }

export const markLost = (shipmentId, commentText) =>
  async(dispatch) => {
    dispatch(setShipmentLoading(shipmentId, true))

    try {
      const userSub = await trunkrs.Auth().getUserSub()

      const Comment = trunkrs.ShipmentComment()
      const comment = new Comment({
        shipmentId,
        userSub,
        message: commentText,
        timestamp: moment(),
      })

      await comment.save()

      dispatch(markShipmentProcessed(
        shipmentId,
        PROCESSED_SHIPMENT_STATUS.SHIPMENT_NOT_AT_DEPOT,
      ))
    } catch (error) {
      Sentry.captureException(error)
      dispatch(setShipmentLoading(shipmentId, false))
    }
  }

export const markReturned = (shipmentId) =>
  markShipmentProcessed(
    shipmentId,
    PROCESSED_SHIPMENT_STATUS.PARCEL_RETURNED_TO_TRUNKRS,
  )
