import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react'

import {useDispatch, useSelector} from "react-redux";
import {UseFormMethods} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup'
import {SimpleForm, SimpleModalBox, SimpleSwitch, SimpleTextbox, SimpleDropdownMenu} from "../../common";
import {getCurrentSubcoId} from "../../../selectors/profile";
import {createUser, editUser, ICreateUserParams, reloadUsers, updateDriverSuccess} from "../../../actions/user";
import {getDriverLocale} from '../../../actions/profile'
import {FORM_ACTION, IUserProfile} from "../UsersList/@types";
import {translate as t} from "../../../utils";

import Alert, {ALERT_TYPES} from 'components/Alert/Alert';
import languages, {EditModel, UserModel} from './resource'
import validationSchema, {defaultValues} from './schema'

import FlatButton from "material-ui/FlatButton";
import Grid from "@material-ui/core/Grid/Grid";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import get from "lodash/get";
import isEmpty from 'lodash/isEmpty'
import styles from './styles.module.scss'
import pick from 'lodash/pick'
import clsx from "clsx";
import Collapse from '@material-ui/core/Collapse';
import isEqual from 'lodash/isEqual';


export interface IAddDriverModalProps {
  visible: boolean
  onSucceed: () => void
  onClose: () => void
  user?: IUserProfile
}

const AddUserModal = ({onSucceed, user, ...props}: IAddDriverModalProps) => {
  const mode = !!user ? FORM_ACTION.EDIT : FORM_ACTION.CREATE
  const [loading, setLoading] = useState(false)
  const [isPhoneNumberChanged, setIsPhoneNumberChanged] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const currentSubcoId = useSelector(getCurrentSubcoId)
  const dispatch = useDispatch()

  const formMethods = useRef<
    UseFormMethods<ICreateUserParams> & {
    formRef?: React.Ref<HTMLFormElement>
  }>()

  const handleOnLoad = useCallback(
    (methods: UseFormMethods<ICreateUserParams>) => {
      formMethods.current = methods
    },
    []
  )

  const resetState = useCallback(() => {
    setLoading(false)
    setIsPhoneNumberChanged(false)
    setDisabled(false)
    setShowAlert(false)
    setErrorMsg('')
  }, [])

  const resetForm = useCallback((values = defaultValues) => {
    if (formMethods.current) {
      const { reset } = formMethods.current
      reset(values)
    }
    setErrorMsg('')
  }, [formMethods.current])

  const handleSubmit = useCallback(async (values: ICreateUserParams) => {
    let params:ICreateUserParams = {
      ...values,
      subcontractorId: currentSubcoId
    }

    if(mode === FORM_ACTION.EDIT && !isPhoneNumberChanged) {
      params = pick(params, EditModel) as ICreateUserParams
    }

    setLoading(true)
    const response = await (mode === FORM_ACTION.CREATE ? createUser(params) : editUser(user!.id, params))
    setLoading(false)

    if(get(response, 'isError')) {
      return setErrorMsg(get(response, 'message'))
    }

    if(mode === FORM_ACTION.CREATE){
      dispatch(reloadUsers({
        ...response,
        isPlanner: values.isPlanner
      }))
    }
    else {
      dispatch(updateDriverSuccess({...params, id: user!.id, active: user!.active}))
    }
    onSucceed()

  }, [currentSubcoId, mode, user, isPhoneNumberChanged])



  useEffect( () => {
    resetState()
    if(user){
      const {id} = user
      let locale = get(user, 'userPreferences.locale')
      setDisabled(true)
      if(!locale) {
        (async() => {
          const payload = await dispatch(getDriverLocale(id))
          locale = get(payload, 'value', 'nl')
          user.userPreferences = { locale }
          resetForm(user)
        })()
      }
      else {
        resetForm(user)
      }
    }
    else {
      setDisabled(false)
      resetForm(defaultValues)
    }
  }, [user, props.visible])

  const onFocus = useCallback(() => {
    const defaultValues = pick(formMethods.current!.getValues(), EditModel)
    setShowAlert(true)
    resetForm(defaultValues)
  }, [mode, formMethods.current, user])

  const onBlur = useCallback(() => {
    const formValues = formMethods.current!.getValues()
    setShowAlert(false)
    resetForm({...formValues, phoneNumber: user?.phoneNumber})
  }, [mode, formMethods.current, user])

  const onChange = useCallback(() => {
    const formValues = formMethods.current!.getValues()
    if(isEqual(pick(user, UserModel), formValues)) {
      setDisabled(true)
    }else {
      setDisabled(false)
      if(!!formValues.phoneNumber && !isEqual(formValues.phoneNumber, user?.phoneNumber)) {
        setIsPhoneNumberChanged(true)
      }
    }
  }, [disabled, formMethods.current, user])

  return (
    <SimpleModalBox {...props}>
      <SimpleForm<ICreateUserParams>
        onSubmit={handleSubmit}
        onLoad={handleOnLoad}
        onClick={mode === FORM_ACTION.EDIT ? onChange : undefined}
        onChange={mode === FORM_ACTION.EDIT ? onChange : undefined}
        resolver={yupResolver(validationSchema)}
        defaultValues={defaultValues}
      >
        <div className={styles.wrapper}>
          <div className={styles["header-container"]}>
            <span className={styles.header}>{t(mode === FORM_ACTION.CREATE ? 'ADD_USER_TITLE' : 'EDIT_USER_TITLE')}</span>
          </div>
          {
            !isEmpty(errorMsg) && (
              <div className={styles.error}>
                {errorMsg}
              </div>
            )
          }
          <div className={styles["main-content"]}>
              <Grid container>
                <Grid item xs={12}>
                  <label htmlFor="full-name">{t('ADD_USER_FULL_NAME')}</label>
                  <SimpleTextbox id="full-name" name="name" placeholder={t('ADD_USER_FULL_NAME_PLACEHOLDER')} />
                </Grid>
                <Grid item xs={12}>
                  <div className={styles["planner-permission-container"]}>
                    <div>
                      <span>{t('PLANNER_PERMISSION')}</span>
                      <span>{t('PLANNER_PERMISSION_SUBTEXT')}</span>
                    </div>
                    <SimpleSwitch name="isPlanner"/>
                  </div>
                </Grid>
                <Grid item xs={12} className={styles["dropdown-menu-container"]}>
                  <label htmlFor="preferred-language">{t('ADD_USER_PREFERRED_LANGUAGE')}</label>
                  <SimpleDropdownMenu id="preferred-language" name="userPreferences.locale">
                    <MenuItem value="" hidden><span className={styles.placeholder}>{t('ADD_USER_PREFERRED_LANGUAGE_PLACEHOLDER')}</span></MenuItem>
                    {
                      useMemo(() => languages.map(({text, value}) => <MenuItem value={value}>{text}</MenuItem>), [languages, mode])
                    }
                  </SimpleDropdownMenu>
                </Grid>
                <Grid item xs={12}>
                  <label htmlFor="phone-number">{t('ADD_USER_PREFERRED_PHONE_NUMBER')}</label>
                  <SimpleTextbox
                    onBlur={mode === FORM_ACTION.EDIT && !isPhoneNumberChanged ? onBlur : undefined}
                    onFocus={mode === FORM_ACTION.EDIT && !isPhoneNumberChanged ? onFocus : undefined}
                    id="phone-number"
                    name="phoneNumber"
                    autoComplete='off'
                    placeholder={t('ADD_USER_PREFERRED_PHONE_NUMBER_PLACEHOLDER')}
                    />
                </Grid>
                    {mode === FORM_ACTION.EDIT &&
                      <Collapse in={showAlert} className={styles.fade}>
                        <Alert
                          type={ALERT_TYPES.WARNING}
                          className={styles.alertContainer}
                        >
                          <div className={styles.title}>{t('WARNING')}</div>
                          <div className={styles.message}>
                            {t('EDIT_PHONE_NUMBER_WARNING')}
                          </div>
                        </Alert>
                      </Collapse>
                    }
              </Grid>
          </div>
          <div className={styles["footer"]}>
            <FlatButton
              className={styles["cancel-button"]}
              label={t('ADD_USER_CANCEL_BUTTON')}
              onClick={props.onClose}
            />
            <FlatButton
              type="submit"
              disabled={disabled}
              className={clsx(styles["submit-button"], loading && styles["submit-loading"])}
              icon={loading && <span className={styles.loading} />}
              label={t(mode === FORM_ACTION.CREATE ? loading ? 'ADD_USER_CREATING_BUTTON' : 'ADD_USER_CREATE_BUTTON' : loading ? 'SAVING_USER_BUTTON' : 'SAVE_USER_BUTTON')}
            />
          </div>
        </div>
      </SimpleForm>
    </SimpleModalBox>
  )
}

export default memo(AddUserModal)
