import get from 'lodash/get'
import defaultTo from 'lodash/defaultTo'
import {
  LOGIN,
  LOGOFF,
  EDIT_LOCATION,
  NEW_DRIVER,
  DISABLE_MARK_TOUR_CONFIRMATION,
  SET_SUBCO,
  CHANGE_SUBCO,
  CHANGE_DRIVERS,
  CHANGE_LOCATION,
  REMOVE_DRIVER,
  SET_INVOICE_SUBCO,
  ROLE_IS_SUBCO,
  ROLE_IS_NOT_SUBCO,
  ROLE_IS_PLANNER,
  ROLE_IS_NOT_PLANNER, SET_CURRENT_LOCALE,
  UPDATE_DRIVERS,
  UPDATE_USER_SUCCESS,
  TOGGLE_USER_ACTIVE,
  SET_USER_LOCALE,
  SET_USER_ARCHIVED,
} from '../constants/ActionTypes'

const initialState = {
  isReady: false,
  id: null,
  accesstoken: null,
  subcos: null,
  selectedSubco: 0,
  location: null,
  drivers: null,
  subcoInstance: null,
  disableMarkTourConfirmation: false,
  invoiceSubcos: [],
  isSubco: false,
  isPlanner: false,
  currentLocale: 'nl',
}

export default function profile(state = initialState, action) {
  switch (action.type) {
  case LOGIN:
    return {
      ...state,
      ...action.payload,
      isReady: true,
    }

  case UPDATE_DRIVERS:
    return {
      ...state,
      drivers: [...state.drivers, action.payload],
    }

  case UPDATE_USER_SUCCESS:
    const updatedList = state.drivers.reduce((acc, driver, idx) => {
      let newDriver = driver
      if(driver.id === action.payload.id) {
        newDriver = { ...driver, ...action.payload }
        state.drivers.splice(idx, 1)
      }
      return [
        ...acc,
        newDriver,
      ]
    }, [])
    return {
      ...state,
      drivers: [...updatedList ],
    }

  case SET_USER_ARCHIVED:
    const userToArchive = state.drivers.find(user => user.id === action.payload.id)
    userToArchive.isArchived = action.payload.isArchived
    return {
      ...state,
      drivers: [...state.drivers],
    }

  case TOGGLE_USER_ACTIVE:
    const userToUpdate = state.drivers.find(user => user.id === action.payload.id)
    userToUpdate.active = action.payload.active
    return {
      ...state,
      drivers: [...state.drivers],
    }

  case SET_USER_LOCALE:
    const userToSetLocale = state.drivers.find(user => user.id === action.payload.id)
    userToSetLocale.userPreferences = { locale: action.payload.locale }
    return {
      ...state,
      drivers: [...state.drivers],
    }

  case LOGOFF:
    return Object.assign({}, state, initialState)

  case EDIT_LOCATION:
    return Object.assign({}, state, {
      location: {
        phoneNumber: action.payload.phone,
        location: {
          ...state.subco.location,
          address: action.payload.address,
          number: action.payload.number,
          postalCode: action.payload.postalCode,
          city: action.payload.city,
          country: action.payload.country,
        },
      },
    })

  case NEW_DRIVER:
    return Object.assign({}, state, {
      drivers: [
        ...state.subco.drivers,
        {
          driverId: action.payload.driverId,
          locationId: action.payload.locationId,
          driverType: action.payload.driverType,
          default: action.payload.Edefault,
          defaultLinehaul: action.payload.defaultLinehaul,
          name: action.payload.name,
          email: action.payload.email,
          phoneNumber: action.payload.phoneNumber,
          remarks: action.payload.remarks,
          created_at: action.payload.created_at,
          updated_at: action.payload.updated_at,
        },
      ],
    })

  case REMOVE_DRIVER:
    return {
      ...state,
      drivers: state.drivers.filter(driver => driver.id !== action.payload),
    }

  case CHANGE_LOCATION:
    return {
      ...state,
      location: action.payload,
    }

  case SET_SUBCO:
    return {
      ...state,
      subcoInstance: action.payload,
    }

  case CHANGE_SUBCO:
    return {
      ...state,
      selectedSubco: Number(action.payload),
    }

  case CHANGE_DRIVERS:
    return {
      ...state,
      drivers: action.payload,
    }

  case DISABLE_MARK_TOUR_CONFIRMATION:
    return Object.assign({}, state, { disableMarkTourConfirmation: true })

  case SET_INVOICE_SUBCO:
    return {
      ...state,
      invoiceSubcos: action.payload,
    }

  case ROLE_IS_SUBCO:
    return {
      ...state,
      isSubco: true,
    }

  case ROLE_IS_NOT_SUBCO:
    return {
      ...state,
      isSubco: false,
    }
  case ROLE_IS_PLANNER:
    return {
      ...state,
      isPlanner: true,
    }
  case ROLE_IS_NOT_PLANNER:
    return {
      ...state,
      isPlanner: false,
    }

  case SET_CURRENT_LOCALE:
    return {
      ...state,
      currentLocale: action.payload,
    }

  default:
    return state
  }
}

export const selectSubcoInstance = state =>
  get(state, `subcoInstance[${state.selectedSubco}]`)
export const selectSubcoProfile = state => get(state, `subcos[${state.selectedSubco}]`)
export const selectSubcoProfiles = state => get(state, 'subcos')
export const selectIsReady = state => get(state, 'isReady')
export const selectDrivers = state => get(state, 'drivers')
export const selectSubcoRegion = state => defaultTo(get(state, `subcos[${state.selectedSubco}].tag`), null)
export const selectInvoiceSubcos = state => get(state, 'invoiceSubcos')
export const selectIsSubco = state => get(state, 'isSubco')
export const selectIsPlanner = state => get(state, 'isPlanner')
export const selectDepotCoords = state => ({
  lat: get(state, 'location.latitude'),
  lng: get(state, 'location.longitude'),
})
export const selectCurrentLocale = state => get(state, 'currentLocale')
