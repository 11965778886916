import React from 'react'
import PropTypes from 'prop-types'
import LineGraph from './components/LineGraph'
import classes from './ShipmentOverview.module.scss'

export const ShipmentOverview = (props) => {
  const { setFilter, dataSets } = props
  return (
    <div className={classes.overview}>
      {dataSets.map(({ first, second, title, isRatio, total }, index) => (
        <LineGraph
          key={index}
          first={first}
          second={second}
          title={title}
          isRatio={isRatio}
          setFilter={setFilter}
          index={index}
          total={total}
        />
      ))}
    </div>
  )
}

ShipmentOverview.propTypes = {
  setFilter: PropTypes.func,
  dataSets: PropTypes.array,
}

ShipmentOverview.defaultProps = {
  setFilter: () => null,
  dataSets: [],
}

export default ShipmentOverview
