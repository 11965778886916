import React, { PropTypes, Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

class Modal extends Component {
  render() {
    if (this.props.isOpen) {
      return (
        <ReactCSSTransitionGroup transitionName={this.props.transitionName} transitionEnterTimeout={300} transitionLeaveTimeout={300}>
          <div className="modal">
            {this.props.children}
          </div>
        </ReactCSSTransitionGroup>
      )
    }
    return <ReactCSSTransitionGroup transitionName={this.props.transitionName} transitionEnterTimeout={300} transitionLeaveTimeout={300} />

  }
}

export default Modal
