import * as React from 'react'
import { translate } from '../../../utils/translate'
import clsx from 'clsx'
import { isCancelled, isDataProcessed } from '../../../utils/state-checker/index'
import Proptypes from 'prop-types'
import styles from './ShipmentStateLabel.module.scss'

const { useCallback } = React

const getShipmentLabel = (state, hasTour) => {
  switch (true) {
  case isCancelled(state):
    return {
      label: translate('STATE_CANCELED'),
      activeClass: styles.canceled,
    }
  case isDataProcessed(state):
    return {
      label: translate('STATE_EXPECTED'),
      activeClass: styles.expected,
    }
  case hasTour:
    return {
      label: translate('STATE_HAS_TOUR'),
      activeClass: styles.assigned,
    }
  default:
    return {
      label: translate('STATE_NO_TOUR'),
      activeClass: styles['not-assigned'],
    }
  }
}

const ShipmentStateLabel = (props) => {
  const { shipment: { State, HasTourAssigned } } = props
  const { label, activeClass } = useCallback(getShipmentLabel(State, HasTourAssigned), [State, HasTourAssigned])

  return <span className={clsx(styles['shipment-state-label'], activeClass)}>{label}</span>
}

ShipmentStateLabel.propTypes = {
  shipment: Proptypes.object,
}

export default ShipmentStateLabel
