import React, {memo, useEffect, useState} from 'react'
import clsx from "clsx";

import styles from './styles.module.scss'
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import TrunkrsIcon from '../../../images/trunkrs-loader-icon.svg'
import Translate from 'components/Translate';
import moment from 'moment';
import { useCallback } from 'react';
import { useInterval } from 'hooks/useInterval';
import { translate } from 'utils/translate';
import { useSelector } from 'react-redux';
import { getCurrentPath } from 'selectors/routing';
import classes from '../../toursOverview/PageHeader/PageHeader.module.scss'
import Spinner from "material-ui/CircularProgress"

export interface IOptimizeLoaderProps {
  visible: boolean
  showLoader?: boolean
  message?: string
}

const OptimizeLoader = ({visible, showLoader = false, message } : IOptimizeLoaderProps) => {


  const [time, setTime] = useState(moment().format('HH:mm:ss'))

  const updateTime = useCallback(() => setTime(moment().format('HH:mm:ss')), [time])

  const [startPolling, stopPolling] = useInterval(updateTime, 2500)

  const currentPath = useSelector(getCurrentPath)

  useEffect(() => {
    if(showLoader && visible) {
      startPolling()
    } else {
      stopPolling()
    }
    return () => stopPolling()
  }, [visible, showLoader])

  return (
    <Backdrop className={clsx(styles.backdrop, currentPath === '/login' ? styles.login : styles.inside)} open={visible}>
      <Box className={styles.container}>
        <Box className={clsx(styles.box, styles.firstBox)}>
          <img src={TrunkrsIcon}/>
          <span className={styles.spinner}>
            <Spinner size={18} thickness={2} style={{ paddingBottom: 3, paddingRight: 12 }} /> {message ? message : <Translate>LOADING</Translate>}
          </span>
        </Box>
        {showLoader &&
          <Box className={clsx(styles.box, styles.secondBox)}>
            <div className={styles.updatesBox}>
              <div className={styles.update}>
                <span>{translate('LAST_UPDATE')}</span>
                <span>{time.toString()}</span>
              </div>
              <div className={styles.updates}>
                <span>{translate('CREATING_TOURS')}</span>
                <span className={styles.sub}>{translate('DO_NOT_LEAVE_PAGE')}</span>
              </div>
            </div>
            <div className={styles.loading}>
              <div className={clsx(visible && styles.loadingContainer)}/>
            </div>
          </Box>
        }
      </Box>
    </Backdrop>
  )
}

export default memo(OptimizeLoader)
