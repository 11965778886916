/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState} from 'react'
import { connect } from 'react-redux'

import { GoogleMap, InfoWindow, Marker, Polyline, withGoogleMap } from 'react-google-maps'
import { decode } from 'google-polyline'

import * as uiActions from '../../../actions/ui'
import * as fromUI from '../../../selectors/ui'
import * as fromCombined from '../../../selectors/combined'

import { isValidCollation } from '../../../actions/tours/helpers'
import { mapStyles } from './mapStyles'

import PropTypes from 'prop-types'
import Translate from '../../Translate'
import { COLORS } from '../../../constants'
import {MarkerIconSVG} from "./MarkerIconSVG";

const MarkerIcon = React.memo(({
  tourColor,
  stop,
  move,
  tours,
  tour,
  isSelected,
  onSelectMarker,
  locked,
  highlightedTour,
}) => {
  const { recipient: { location }, position } = stop
  const [showMarker, setShowMarker] = useState(false)
  const [icon, setIcon] = useState()

  const handleToggleMarker = () => {
    !locked && setShowMarker(!showMarker)
  }

  const handleRightClick = (stopId) => {
    !locked && onSelectMarker(stopId)
  }

  const opacity = highlightedTour
    ? highlightedTour === tour.id
      ? 1
      : 0.3
    : 1

  useEffect(() => {
    const markerColor = isSelected
      ? COLORS.GREEN_1 // selected color
      : tourColor ? tourColor : 'cdcdcd'
    setIcon(MarkerIconSVG(position,markerColor, stop.isMultiColli))
  }, [isSelected,stop.isMultiColli,tourColor,position])

  return (
    <Marker
      key={stop.id}
      position={{
        lat: location.latitude,
        lng: location.longitude,
      }}
      defaultAnimation={0}
      title={`${location.address} ${location.postalCode} ${location.city}`}
      icon={icon}
      onClick={handleToggleMarker}
      onRightClick={() => handleRightClick(stop.id)}
      opacity={opacity}
    >
      {showMarker && (
        <InfoWindow
          onCloseClick={handleToggleMarker}
          options={{
            disableAutoPan: true,
          }}
        >
          <div style={{ color: tourColor }}>
            <strong>{`${location.address}, ${location.postalCode}, ${location.city}`}</strong>
            <br />
            <p><Translate>MOVE_TO_TOUR</Translate>:</p>
            <div style={{ display: 'flex', width: '100%' }}>
              {tours.map((newTour) => tour.id !== newTour.id && (
                <img
                  style={{ cursor: 'pointer' }}
                  key={newTour.id}
                  src={`https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${newTour.number}|${newTour.color.substring(1)}|ffffff`}
                  onClick={() => move(tour, newTour, stop)}
                />
              ))}
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  )
})

const MarkersImpl = ({
  tour, tours, move, selected, selectMarker, clearSelectedMarkers, areToursLocked, highlightedTour, setHighlightedTour,
}) => {
  // -1 & -2 can never be  a multi-colli
  // thus -> first id > 0
  const stops = useMemo(
    () => tour.collations.filter(isValidCollation),
    [tour],
  )

  return stops.map(stop => (
    <MarkerIcon
      key={stop.id}
      tour={tour}
      tours={tours}
      tourColor={tour.color}
      stop={stop}
      move={move}
      isSelected={selected.includes(stop.id)}
      onSelectMarker={selectMarker}
      onClearAll={clearSelectedMarkers}
      locked={areToursLocked}
      highlightedTour={highlightedTour}
      setHighlightedTour={setHighlightedTour}
    />
  ))
}

MarkersImpl.propTypes = {
  tour: PropTypes.any,
  index: PropTypes.any,
  tours: PropTypes.any,
  move: PropTypes.any,
  selected: PropTypes.any,
  selectMarker: PropTypes.any,
  clearSelectedMarkers: PropTypes.any,
}

const mapStateToProps = state => ({
  selected: fromUI.getSelectedCollations(state),
  areToursLocked: fromCombined.areToursLocked(state),
})

const mapDispatchToProps = {
  selectMarker: uiActions.toggleSelected,
  clearSelectedMarkers: uiActions.clearSelected,
}

export const Markers = connect(mapStateToProps, mapDispatchToProps)(MarkersImpl)


const mapToLatLngLiteral = (item) => item && ({
  lat: item[0],
  lng: item[1],
})

export const Directions = React.memo(({
  tour: { color, collations },
}) => collations.map(collation => !!collation.polyline && (
  <Polyline
    key={collation.id}
    path={decode(collation.polyline).map(mapToLatLngLiteral)}
    options={{
      strokeColor: color,
      strokeOpacity: 0.5,
    }}
  />
)))

export const BasicMap = withGoogleMap((props) => {
  const defaults = {
    lat: 51.990276, zoom: 8, lng: 5.103033, isMarkerShown: true,
  }
  const { lat, lng, zoom, isMarkerShown } = { ...defaults, ...props }

  return (
    <GoogleMap
      defaultZoom={zoom}
      defaultCenter={{ lat, lng }}
      options={{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        scrollwheel: false,
        styles: mapStyles,
      }}
    >
      {isMarkerShown && <Marker position={{ lat, lng }} />}
    </GoogleMap>
  )
})

