import React from 'react'
import PropTypes from 'prop-types'

import TranslatedButton from '../../TranslatedButton'

const NotDeliveredActions = ({
  onPostpone, onReturn,
}) => (
  <>
    <TranslatedButton onClick={onPostpone} variant="blue">
      TODO_NOT_RETURNED
    </TranslatedButton>
    <TranslatedButton onClick={onReturn} variant="green">
      TODO_RETURNED
    </TranslatedButton>
  </>
)

NotDeliveredActions.propTypes = {
  onPostpone: PropTypes.func,
  onReturn: PropTypes.func,
}

NotDeliveredActions.defaultProps = {
  onPostpone: () => null,
  onReturn: () => null,
}

export default NotDeliveredActions
