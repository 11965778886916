export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const TOGGLE_LOADING = '[UI] TOGGLE LOADING'
export const SET_LOADING = '[UI] SET LOADING'
export const RESET_LOADING = 'RESET_LOADING'
export const TOGGLE_SELECTED_COLLATION = '[UI] TOGGLE SELECTION'
export const SELECT_MULTI_COLLATIONS = '[UI] SELECT_MULTI_COLLATIONS'
export const CLEAR_SELECTED_COLLATIONS = '[UI] CLEAR SELECTED'
export const SET_LOGIN_ERROR = '[UI] SET LOGIN ERROR'
export const SET_SIDEBAR_COLLAPSED = '[UI] SET SIDEBAR COLLAPSED'
export const SET_HAS_OPTIMIZATION_ERROR = '[UI] SET HAS OPTIMIZATION ERROR'
export const SET_POWER_MODE = '[UI] SET POWER MODE'
export const SET_OPTIMIZE_DONE = '[UI] SET_OPTIMIZE_DONE'

export const EDIT_SHIPMENT = 'EDIT_SHIPMENT'
export const DELETE_SHIPMENT = 'DELETE_SHIPMENT'
export const UPDATE_DRIVERS = 'UPDATE_DRIVERS'

export const SET_OPTIMIZING = 'SET_OPTIMIZING'
export const ADD_TIME = 'ADD_TIME'
export const ADD_ARRIVAL = 'ADD_ARRIVAL'
export const SET_SETTING = 'SET_SETTING'
export const ADD_ROUTE = 'ADD_ROUTE'
export const DELETE_ROUTE = 'DELETE_ROUTE'
export const CLEAR_ROUTES = 'CLEAR_ROUTES'
export const UPDATE_ROUTES = 'UPDATE_ROUTES'
export const ADD_DRIVER = 'ADD_DRIVER'
export const ADD_DRIVERS = '[DRIVERS] ADD'
export const CLEAR_DRIVERS = 'CLEAR_DRIVERS'
export const EDIT_LOCATION = 'EDIT_LOCATION'
export const NEW_DRIVER = 'NEW_DRIVER'
export const DEACTIVATE_DRIVER = 'DEACTIVATE_DRIVER'
export const ACTIVATE_DRIVER = 'ACTIVATE_DRIVER'
export const TOUR_PLANNED = 'TOUR_PLANNED'
export const DISABLE_MARK_TOUR_CONFIRMATION = 'DISABLE_MARK_TOUR_CONFIRMATION'
export const REMOVE_DRIVER = 'REMOVE_DRIVER'
export const TRANSFER_DRIVER = 'TRANSFER_DRIVER'

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const TOGGLE_USER_ACTIVE = 'TOGGLE_USER_ACTIVE'
export const SET_USER_LOCALE = 'SET_USER_LOCALE'
export const SET_USER_ARCHIVED = 'SET_USER_ARCHIVED'

//PROFILE
export const SET_CURRENT_LOCALE = '[PROFILE] SET CURRENT PROFILE'
export const LOGIN = '[PROFILE] LOGIN'
export const LOGOFF = '[PROFILE] LOGOFF'
export const SET_SUBCO = '[PROFILE] SET SUBCO'
export const CHANGE_SUBCO = '[PROFILE] CHANGE SUBCO'
export const CHANGE_DRIVERS = '[PROFILE] CHANGE DRIVERS'
export const CHANGE_LOCATION = '[PROFILE] CHANGE LOCATION'
export const SET_INVOICE_SUBCO = '[PROFILE] SET INVOICE SUBCO'

// TOURS
export const SET_TOURS = '[TOURS] SET TOURS'
export const SET_DRIVER = '[TOURS] SET DRIVER'
export const SAVE_DRIVER = '[TOURS] SAVE DRIVER'
export const TOGGLE_TOURS_WORKING = '[TOURS] TOGGLE WORKING'
export const UPDATE_TOURS = '[TOURS] UPDATE'
export const SET_EDIT_MODE = '[TOURS] SET EDIT MODE'
export const UPDATE_TOUR = '[TOURS] UPDATE SINGLE TOUR'
export const SET_REGION_PRODUCTIVITY_GOAL =
  '[TOURS] SET REGION PRODUCTIVITY GOAL'
export const SET_VEHICLE_TYPE = '[TOURS] SET VEHICLE TYPE'
export const SET_IS_TOURS_PLANNING_LOCKED =
  '[TOURS] SET IS TOURS PLANNING LOCKED'
export const SET_ARE_TOURS_SORTED = '[TOURS] SET_ARE_TOURS_SORTED'
export const PLANNING_SAVED = '[TOURS] PLANNING_SAVED'
export const SET_IS_PAGERELOADED = '[TOURS] SET PAGERELOADED'

// TOUR OVERVIEW
export const UPDATE_TOUR_OVERVIEW_LOADING = '[TOUR OVERVIEW] UPDATE LOADING'
export const UPDATE_TOUR_OVERVIEW_SUCCESS = '[TOUR OVERVIEW] UPDATE SUCCESS'

// SHIPMENTS
export const TOGGLE_SHIPMENT_LOADING = '[SHIPMENTS] TOGGLE LOADING'
export const ADD_SHIPMENT = '[SHIPMENTS] ADD'
export const CLEAR_SHIPMENTS = '[SHIPMENTS] CLEAR'
export const SHIPMENT_SORTED = '[SHIPMENT] SORTED'
export const SHIPMENT_IN_TRANSACTION = '[SHIPMENT] SHIPMENT IN TRANSACTION'

// TODO LIST
export const LOAD_TODO = '[TODO] LOAD'
export const LOAD_TODO_SUCCESS = '[TODO] LOAD SUCCESS'

export const MARK_SHIPMENT_PROCESSED = '[TODO] MARK PROCESSED'

export const SET_SHIPMENT_LOADING = '[TODO] SET LOADING'

// Collection Tours
export const LOAD_COLLECTION_TOURS = '[COLLECTIONS] LOAD TOURS'
export const LOAD_COLLECTION_TOURS_SUCCESS = '[COLLECTIONS] LOAD TOURS SUCCESS'
export const LOAD_COLLECTION_TOURS_FAILED = '[COLLECTIONS] LOAD TOURS FAILED'

export const SAVE_TOURS = '[COLLECTIONS] SAVE TOURS'
export const SAVE_TOURS_SUCCESS = '[COLLECTIONS] SAVE TOURS SUCCESS'
export const SAVE_TOURS_FAILED = '[COLLECTIONS] SAVE TOURS FAILED'

export const SELECT_COLLECTION_TOUR = '[COLLECTIONS] SET TOUR'

// Shipment and Driver Estimation
export const SHIPMENT_AND_DRIVER_ESTIMATION_LOADING =
  'SHIPMENT_AND_DRIVER_ESTIMATION_LOADING'
export const SHIPMENT_AND_DRIVER_ESTIMATION_SUCCESS =
  'SHIPMENT_AND_DRIVER_ESTIMATION_SUCCESS'
export const SHIPMENT_AND_DRIVER_ESTIMATION_FAIL =
  'SHIPMENT_AND_DRIVER_ESTIMATION_FAIL'
export const SHIPMENT_AND_DRIVER_ESTIMATION_COUNTDOWN =
  'SHIPMENT_AND_DRIVER_ESTIMATION_COUNTDOWN'

// User Roles
export const ROLE_IS_SUBCO = 'ROLE_IS_SUBCO'
export const ROLE_IS_NOT_SUBCO = 'ROLE_IS_NOT_SUBCO'
export const ROLE_IS_PLANNER = 'ROLE_IS_PLANNER'
export const ROLE_IS_NOT_PLANNER = 'ROLE_IS_NOT_PLANNER'

// Task Storage
export const QUEUE_TASK = '[TASKS] QUEUE TASK'
export const START_TASK = '[TASKS] START TASK'
export const FAIL_TASK = '[TASKS] FAIL TASK'
export const COMPLETE_TASK = '[TASKS] COMPLETE TASK'
export const TASK_TICKER_STARTED = '[TASKS] TICKER STARTED'

// Freezer Management
export const FREEZER_MANAGEMENT_LOADING = '[FREEZER MANAGEMENT] SET LOADING'
export const FREEZER_MANAGEMENT_SET_FREEZER_LOGS =
  '[FREEZER MANAGEMENT] SET FREEZER_LOGS'

// Warehouse
export const WAREHOUSE_GET_BUSY_DAY = '[WAREHOUSE] GET BUSY DAY'
export const WAREHOUSE_SET_BUSY_DAY = '[WAREHOUSE] SET BUSY_DAY'
export const WAREHOUSE_DELETE_BUSY_DAY = '[WAREHOUSE] DELETE BUSY DAY'
export const WAREHOUSE_GET_CENTRAL_SORTING = '[WAREHOUSE] GET CENTRAL SORTING'
export const WAREHOUSE_SET_WHAT_BANNER_SHOULD_DISPLAY = '[WAREHOUSE] SET WHAT BANNER SHOULD DISPLAY'
