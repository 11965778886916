import React from 'react'
import PropTypes from 'prop-types'
import { translate } from '../../utils/translate'
import classes from './ShipmentTableTitle.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const ShipmentTableTitle = (props) => {
  const { resetFilter, title, results, showClear } = props
  return (
    <div className={classes.container}>
      <span className={classes.title}>{title}</span>
      <span className={classes.results}>&nbsp;- {results} {translate('RESULTS')}</span>
      {showClear && (
        <button onClick={resetFilter} className={classes.clearFilter}>
          <FontAwesomeIcon icon={faTrash} className={classes.iconRed}/>
          <i className={classes.iconRed}/>
          clear filter
        </button>
      )}
    </div>
  )
}

ShipmentTableTitle.propTypes = {
  title: PropTypes.string,
  results: PropTypes.number,
  resetFilter: PropTypes.func,
  showClear: PropTypes.bool,
}

ShipmentTableTitle.defaultProps = {
  title: translate('NO_FILTERS_APPLIED'),
  result: 0,
  resetFilter: () => null,
  showClear: false,
}

export default ShipmentTableTitle
