import { combineReducers } from 'redux'

import { reducer as toastrReducer } from 'react-redux-toastr'
import { routerReducer } from 'react-router-redux'

import ui from './ui'
import shipments from './shipments'
import profile from './profile'
import times from './times'
import arrivals from './arrivals'
import routes from './routes'
import drivers from './drivers'
import tours from './tour'
import collations from './collations'
import tourOverviews from './tourOverview'
import todoList from './todoList'
import collectionTours from './collection/tours'
import shipmentAndDriverEstimation from './shipmentAndDriverEstimation'
import intervalTasks from './intervalTasks'
import freezerManagement from './freezerManagement/index'
import hubLogs from './hublogs'
import warehouse from './warehouse'

const rootReducer = combineReducers({
  collection: combineReducers({
    tour: collectionTours,
  }),
  ui,
  shipments,
  profile,
  times,
  arrivals,
  routes,
  drivers,
  tours,
  collations,
  toastr: toastrReducer,
  routing: routerReducer,
  tourOverviews,
  todoList,
  shipmentAndDriverEstimation,
  intervalTasks,
  freezerManagement,
  hubLogs,
  warehouse,
})

export default rootReducer
