import { ADD_TIME } from '../constants/ActionTypes'

const initialState = {}

export default function times(state = initialState, action) {
  switch (action.type) {
  case ADD_TIME:
    return {
      ...state,
      ...action.payload,
    }

  default:
    return state
  }
}
