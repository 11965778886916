import * as _omit from 'lodash/omit'
import { ADD_ROUTE, DELETE_ROUTE, CLEAR_ROUTES, UPDATE_ROUTES } from '../constants/ActionTypes'
import get from 'lodash/get'
const initialState = {}

export default function routes(state = initialState, action) {
  switch (action.type) {
  case ADD_ROUTE:
    return Object.assign({}, state, {
      [action.payload.Id]: {
        Id: action.payload.Id,
        Collations: action.payload.Collations || [],
        DriverId: action.payload.DriverId || null,
        Date: action.payload.Date,
        Show: (typeof action.payload.Show === 'boolean') ? action.payload.Show : true,
        Color: action.payload.Color || '#ffffff',
        Directions: action.payload.Directions || null,
        Start: action.payload.Start,
        Finish: action.payload.Finish,
        TimeTo: action.payload.TimeTo,
        TimeFrom: action.payload.TimeFrom,
        Duration: action.payload.Duration,
        Instance: action.payload.Instance,
        isPlanned: action.payload.isPlanned,
      },
    })

  case DELETE_ROUTE:
    if (!state[action.payload.id]) return state
    return _omit(state, action.payload.id)

  case CLEAR_ROUTES:
    return initialState

  case UPDATE_ROUTES:
    return {
      ...state,
      ...action.payload,
    }

  default:
    return state
  }
}

export const selectCurrentRoute = state => get(state, 'locationBeforeTransitions.pathname', '')
