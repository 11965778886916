import * as types from '../constants/ActionTypes'

export function add(payload) {
  return { type: types.ADD_ROUTE, payload }
}

export function del(payload) {
  return { type: types.DELETE_ROUTE, payload }
}

export function clear() {
  return { type: types.CLEAR_ROUTES }
}

export function update(payload) {
  return { type: types.UPDATE_ROUTES, payload }
}

export function isPlanned(payload) {
  return { type: types.TOUR_PLANNED, payload }
}
