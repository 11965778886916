import uuid from 'uuid'
import map from 'lodash/map'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'

const getLocationCompareValue = ({ recipient: { location }, id }) =>
  Math.pow(location.latitude ,2)+ Math.pow(location.longitude,2) + (id > 0 ? 0 : id)

export const createMultiColliStopMapping = (shipments) => {
  const locationLookup = {}
  const shipmentLookup = {}
  const stops = []

  shipments.forEach(shipment => {
    const locationValue = getLocationCompareValue(shipment)
    const { [locationValue]: mappingValue } = locationLookup
    const { [mappingValue]: mappedShipments } = shipmentLookup

    if (!mappingValue) {
      locationLookup[locationValue] = shipment.id
      shipmentLookup[shipment.id] = [shipment.id]
      stops.push(shipment)
    } else {
      mappedShipments.push(shipment.id)
    }
  })

  return [stops, shipmentLookup]
}

export const combineEstimations = (estimations, mapping) =>
  map(estimations, tourEstimations => map(
    tourEstimations,
    (estimation, position) => {
      const { [estimation.stop.id]: shipmentIds } = mapping

      return {
        ...estimation,
        collation: {
          id: uuid(),
          position,
          isMultiColli: get(shipmentIds, 'length', 0) > 1,
          shipmentIds: shipmentIds || [estimation.stop.id],
        },
      }
    }))

export const concatBermudaStops = (array) => {
  const { tourEstimations, total } = array.reduce((acc, r) => {
    if(r && !isEmpty(get(r, 'bermudaStops'))) {
      const validStops = get(r, 'result').filter(e => get(e, 'stop.id') > 0)
      map(r.bermudaStops, (stop) => {
        r.result.push({
          ...last(validStops),
          stop,
          isBermudaStop: true,
        })
      })
    }
    acc.tourEstimations.push(get(r, 'result', undefined))
    acc.total.push(get(r, 'total', undefined))
    return acc
  }, {
    tourEstimations: [],
    total: [],
  })

  return { tourEstimations, total }
}

export const setBermudaStop = (array, isBermudaStop = false) => array.reduce((acc, stop) => {
  acc.push({
    ...stop,
    result: get(stop, 'result', []).map(result => {
      return {
        ...result,
        isBermudaStop
      }
    })
  })
  return acc
}, [])
