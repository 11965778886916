import React, { memo } from 'react'
import clsx from 'clsx'
import closeIcon from '../../../images/close.svg'
import styles from './styles.module.scss'

export interface ISimpleModalBoxProps extends React.HTMLAttributes<any>{
  visible: boolean,
  onClose: () => void
}

const SimpleModalBox = ({ visible, onClose, children, ...props } : ISimpleModalBoxProps) => {
  return (
    <div className={clsx('modal-box', { hidden: !visible })} {...props}>
      <div className={clsx('modal-box-content', styles.modalBody)}>
        <span className="close-button" onClick={onClose}>
          <img src={closeIcon}/>
        </span>
        <div className="content">
          {
            children
          }
        </div>
      </div>
    </div>
  )
}

export default memo(SimpleModalBox)
