import React from 'react'

import { ITour } from '../../../@models'
import { useTranslate } from 'utils/translate'

import OptimizeIcon from 'material-ui/svg-icons/action/update'
import styles from './styles.module.scss'
import Tooltip from '../Tooltip'
import clsx from 'clsx'
import FlatButton from 'material-ui/FlatButton'
import AutoComplete from '../AutoComplete'
import SelectFuelType from '../SelectFuelType'
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faPalette } from '@fortawesome/free-solid-svg-icons'
import ClickAwayListener from 'material-ui/internal/ClickAwayListener'
import { CirclePicker } from 'react-color'
import Fade from '@material-ui/core/Fade'
import ReverseIcon from 'material-ui/svg-icons/action/compare-arrows'
import { colours } from '../../../../../utils/getColor'

interface IProps {
  tour: any
  drivers: any
  tours: ITour[]
  isVisible: boolean,
  showList: boolean
  tourColor: string
  locked: boolean
  displayColorPicker: boolean
  onChangeVehicleType: () => void
  toggleTour: () => void
  showColorPicker: () => void
  handleDriverChanged: () => void
  changeTourColor: () => void
  clearColorPicker: () => void
  handleListChange: (id: number, show: boolean, number: number) => void
  onReverseTour: (id: number) => void
  onOptimizeTour: (id: number) => void
}

export const BoardActions = ({
  tour,
  drivers,
  tours,
  isVisible,
  showList,
  onChangeVehicleType,
  locked,
  toggleTour,
  onReverseTour,
  showColorPicker,
  onOptimizeTour,
  changeTourColor,
  clearColorPicker,
  displayColorPicker,
  tourColor,
  handleListChange,
  handleDriverChanged
 }: IProps) => {
  const { t } = useTranslate()

  const handleReverseTour = useCallback(() => {
    if(!locked) {
      onReverseTour(tour.id)
    }
  }, [tour, locked])
  const handleOptimize = useCallback(() => {
    if(!locked && !tour.hasBermudaStop) {
      onOptimizeTour(tour.id)
    }
  }, [onOptimizeTour, locked, tour])

  const onShowList = useCallback(() => handleListChange(tour.id, !showList, tour.number), [tour, showList])
  return (
    <div className={styles.container}>
      <div className={clsx(styles.icon, tour.hasBermudaStop && styles.disabled)} onClick={handleOptimize}>
        <Tooltip content={t('OPTIMISE_TOUR')} position="right">
          <OptimizeIcon color="white" />
        </Tooltip>
      </div>
      <div className={clsx(styles.icon)} onClick={handleReverseTour}>
        <Tooltip content={t('REVERSE_TOUR')} position="right">
          <ReverseIcon color="white" onClick={handleReverseTour} />
        </Tooltip>
      </div>
      <div className={clsx(styles.icon)} style={{backgroundColor: tourColor }} onClick={locked ? null : showColorPicker}>
        <ClickAwayListener onClickAway={clearColorPicker}>
          <Tooltip content={t('COLOR_TOUR')} position="right">
            <div><FontAwesomeIcon color="white" icon={faPalette} /></div>
          </Tooltip>
      </ClickAwayListener>
      <Fade in={displayColorPicker}>
        <div className={styles.colorPicker}>
          <div>
            <span>{t('CHOOSE_TOUR_COLOUR')}</span>
            <span>{t('COLOUR_PICK_CANCEL')}</span>
          </div>
          <CirclePicker width="100%" circleSpacing={12} circleSize={32} colors={colours}
            onChangeComplete={changeTourColor} color={tour.color} />
        </div>
      </Fade>
      </div>
       <div className={clsx(styles.icon, showList && styles.hidden)}>
        <Tooltip content={t(isVisible ? 'HIDE_TOUR_TOOLTIP' : 'UNHIDE_TOUR_TOOLTIP')} position="right">
          <span className={styles.visibilityToggle} onClick={toggleTour}>
            <FontAwesomeIcon icon={isVisible ? faEye : faEyeSlash} />
          </span>
        </Tooltip>
        </div>
        

        <FlatButton
          disabled={!isVisible}
          className={clsx(styles.buttonColor)}
          label={showList ? t('HIDE_TOUR') : t('SHOW_FULL_TOUR') }
          onClick={onShowList} 
        />

      <div style={{ marginTop: 50 }}>
        <AutoComplete
          options={drivers}
          value={tour.driverId}
          id={tour.id.toString()}
          className={styles['driver-autocomplete']}
          assignedDriverIds={tours.map(tour => tour.driverId)}
          disabled={locked || tour.isWorking}
          onChange={handleDriverChanged}
          fuelType={tour.vehicleType}
        >
          <SelectFuelType
            className={styles.margin}
            locked={locked}
            vehicleType={tour.vehicleType}
            changeVehicleType={onChangeVehicleType}
            id={tour.id}
          />
        </AutoComplete>
      </div>
    </div>
  )
}