import React, { useState, useCallback, useEffect } from 'react'
import clsx from 'clsx'

import withStyles from '@material-ui/core/styles/withStyles'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import PerfectScrollbar from 'react-perfect-scrollbar'

import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import Box from '@material-ui/core/Box'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import DropdownItem from '../../planner/BoardList/components/Dropdown/item'
import styles from './styles.module.scss'
import InputBase from '@material-ui/core/InputBase'
import { useField } from 'formik'


interface IFormikDropdown {
    name: string
    hintText?: string
    open?: boolean
    color?: string
    className?: string
    containerRoot?: string
    onChange: (event: any, selectedItem: { value: string, label: string  }) => void
    disabled?: boolean
    placeholder: string
    rootClass: string
    button?: false
}

const FormikDropdown: React.FC<IFormikDropdown> = ({
    name,
    hintText,
    open,
    color = 'action',
    className,
    onChange,
    children,
    disabled,
    placeholder,
    rootClass,
    containerRoot,
    button,
    ...inputProps
}) => {
  const [expanded, setExpanded] = useState(open || false)
  const [selectedItem, setSelectedItem] = useState({
    value: '',
    label: '',
  })

  const [field, meta, helpers] = useField(name)
  const hasError = !!(meta.touched && meta.error)

  useEffect(()=> {
    if(meta.value) {
      const selected: any = React.Children.toArray(children).find((item: any) => item.props.value === meta.value)
      setSelectedItem(selected.props)
    }
  }, [])

  const handleToggleDropdown = useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded)
  }, [expanded])

  const handleClickAway = useCallback(() => {
    setExpanded(false)
  }, [])

  const handleClick = useCallback(
    (event) => {
      const selectedValue = event.currentTarget.dataset

      if(selectedValue.value.toString() === selectedItem.value.toString()) {
        return null
      }

      if (!open) {
        setExpanded(false)
      }
      setSelectedItem(selectedValue)

      return onChange ? onChange(event, selectedValue) : null
    },
    [selectedItem, onChange, open],
  )


    useEffect(() => {
        helpers.setValue(selectedItem.value)
    }, [selectedItem])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        className={clsx(styles.form, styles.dropDown, className)}
      >

        <InputBase
          classes={{ root: styles.input }}
          {...inputProps}
          {...field}
          id={name}
        />
        <List
          aria-label="dropdown"
          disablePadding
          className={clsx(styles[color])}
          classes={{
            root: disabled ? styles.disabled : clsx(hasError && styles.error, rootClass, styles.listRoot),
          }}
        >
          <ListItem
            button={button}
            disabled={disabled}
            onClick={handleToggleDropdown}
            classes={{
              gutters: clsx({
                [styles.gutters]: selectedItem.value !== '',
              }),
            }}
            className={clsx(styles.listItem, containerRoot)}
          >
            <ListItemText>{selectedItem.label || hintText}</ListItemText>
            {expanded ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) :
              <FontAwesomeIcon icon={faChevronDown} />

            }
          </ListItem>
          <Collapse
            in={expanded}
            unmountOnExit
            className={clsx(styles[color])}
            classes={{
              container: styles.container,
            }}
          >
            <PerfectScrollbar className={styles.collapseWrapper}>
              <Box>
                {React.Children.toArray(children).map((item: any, index: number) =>
                  React.cloneElement(<DropdownItem />, {
                    onClick: handleClick,
                    ...item.props,
                    selected: selectedItem.value.toString() === item.props.value.toString(),
                    key: index,
                    className: clsx(
                      item.props.className,
                    ),
                  }),
                )}
              </Box>
            </PerfectScrollbar>
          </Collapse>
        </List>
      </Box>
    </ClickAwayListener>
  )
}

export default FormikDropdown
