import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { COLORS } from '../../../../constants'
import { InfoWindow, Marker} from 'react-google-maps'
import { useDispatch, useSelector } from "react-redux";
import { moveSingle } from 'actions/tours'
import { clearSelected } from '../../../../actions/ui'
import { ITour } from '../../@models/tour'
import { MarkerIconSVG } from '../../Map/MarkerIconSVG'

import * as uiActions from '../../../../actions/ui'
import * as fromCombined from '../../../../selectors/combined'
import {usePlannerStore} from "../../../../stores/planner";

import Translate from 'components/Translate'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";

import {translate} from "../../../../utils";
import styles from './styles.module.scss'

export interface IGoogleMarkerProps {
    tourColor: string,
    stop: any,
    tour: ITour,
    isSelected: boolean,
    hilightedTour?: number
    isSingleTour?: boolean
}

const GoogleMarker = React.memo(({
  tourColor,
  stop,
  tour,
  isSelected,
  hilightedTour,
  isSingleTour
}: IGoogleMarkerProps) => {
  const dispatch = useDispatch()

  const [showMarker, setShowMarker] = useState(false)
  const [icon, setIcon] = useState<any>()
  const areToursLocked = useSelector(fromCombined.areToursLocked)
  const tours = useSelector(fromCombined.getShipmentTours)

  const handleMoveSingle = useCallback((oldTour, newTour, collation)  =>  () => {
    dispatch(moveSingle(oldTour.id, newTour.id, collation.id))
    dispatch(clearSelected())
    setShowMarker(false)
  }, [moveSingle])


  const handleRightClick = useCallback((stopId: number) => () => dispatch(uiActions.toggleSelected(stopId)), [])

  const handleToggleMarker = useCallback(() => !areToursLocked && setShowMarker(!showMarker), [areToursLocked, showMarker])

  const { recipient: { location }, position } = stop

  const opacity = useMemo(() =>
    hilightedTour
    ? hilightedTour === tour.id
      ? 1
      : 0.3
    : 1,
  [hilightedTour])

  useEffect(() => {
    const markerColor = isSelected
      ? COLORS.GREEN_1 // selected color
      : tourColor ? tourColor : 'cdcdcd'
    setIcon(MarkerIconSVG(position, markerColor, stop.isMultiColli))
  }, [isSelected, stop.isMultiColli, tourColor, position])

  const {toggleTourVisibility} = usePlannerStore()

  const toggleTour = useCallback(() => {
    toggleTourVisibility(tour.id)
  }, [tour.id])

  return (
    <Marker
      key={stop.id}
      position={{
        lat: location.latitude,
        lng: location.longitude,
      }}
      defaultAnimation={0}
      title={`${location.address} ${location.postalCode} ${location.city}`}
      icon={icon}
      onClick={handleToggleMarker}
      onRightClick={handleRightClick(stop.id)}
      opacity={opacity}
    >
      {useMemo(() =>
      showMarker && (
        <InfoWindow
          onCloseClick={handleToggleMarker}
          options={{
            disableAutoPan: true,
          }}
        >
          <div style={{ color: tourColor }}>
            <strong>{`${location.address}, ${location.postalCode}, ${location.city}`}</strong>
            <br />
            <p><Translate>MOVE_TO_TOUR</Translate>:</p>
            <div style={{ display: 'flex', width: '100%' }}>
              {tours.map((newTour: ITour) => tour.id !== newTour.id && (
                <img
                  style={{ cursor: 'pointer' }}
                  key={newTour.id}
                  src={`https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${newTour.number}|${newTour.color.substring(1)}|ffffff`}
                  onClick={handleMoveSingle(tour, newTour, stop)}
                />
              ))}
            </div>
            {
              !isSingleTour && (
                <div className={styles["hide-tour-button"]} onClick={toggleTour}><FontAwesomeIcon icon={faEye} /> {translate('HIDE_TOUR')}</div>
              )
            }
          </div>
        </InfoWindow>
      ),[handleMoveSingle, tour, stop, handleToggleMarker, tourColor, showMarker])}
    </Marker>
  )
})

export default GoogleMarker
