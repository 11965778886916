import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import get from 'lodash/get'

import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table'

import { translate } from '../../../utils/translate'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

import TextBlock from './TextBlock'
import TableActions from './TableActions'
import CommentModal from '../CommentModal'

import classes from './ShipmentTable.module.scss'

export const variantNames = {
  notDelivered: 'not-delivered',
  noEndState: 'no-end-state',
  returnShipments: 'returns',
  done: 'done',
}

const ShipmentTable = ({
  variant, shipments,
  onPostpone, onReturn, onComment,
}) => {
  const [openShipment, setOpenShipment] = React.useState(null)

  const handleShipmentDone = React.useCallback(
    (comment) => {
      onComment(openShipment.shipmentId, comment)
      setOpenShipment(null)
    },
    [openShipment, onComment],
  )

  const handleOnClose = React.useCallback(
    () => setOpenShipment(null),
    [],
  )

  return (
    <>
      <CommentModal
        open={!!openShipment}
        onClose={handleOnClose}
        onSave={handleShipmentDone}
      />

      <Table className={clsx('table', classes.table)} selectable={false}>
        <TableBody displayRowCheckbox={false} showRowHover={false} className={classes.body}>
          {React.useMemo(() => shipments.map(shipment => (
            <TableRow className={clsx('table-body-row', classes.row)} key={shipment.shipmentId}>
              <TableRowColumn className={clsx('table-body-column', classes.column, classes.address)}>
                <TextBlock
                  variant="address"
                  title={get(shipment, 'recipient.address')}
                  text={`${get(shipment, 'recipient.postalCode')} ${get(shipment, 'recipient.city')}`}
                />
              </TableRowColumn>
              <TableRowColumn className={clsx('table-body-column', classes.column, classes.driver)}>
                <TextBlock
                  title="DRIVER"
                  text={shipment.driverName || 'N/A'}
                />
              </TableRowColumn>
              <TableRowColumn className={clsx('table-body-column', classes.column, classes.tour)}>
                <TextBlock
                  title="TOUR"
                  text={shipment.tourId || 'N/A'}
                />
              </TableRowColumn>
              <TableRowColumn className={clsx('table-body-column', classes.column, classes.reason)}>
                {
                  shipment.stateName === SHIPMENT_STATES.SHIPMENT_ACCEPTED_BY_DRIVER
                    ? (
                      <TextBlock
                        title="LATEST_STATUS"
                        text={translate(shipment.stateName)}
                      />
                    ) : (
                      <TextBlock
                        title="NON_DELIVERY_REASON"
                        text={translate(shipment.reasonCode)}
                      />
                    )
                }
              </TableRowColumn>
              <TableRowColumn className={clsx('table-body-column', classes.column)}>
                <div className={classes.buttonContainer}>
                  <TableActions
                    variant={variant}
                    status={shipment.todoStatus}
                    isLoading={shipment.isLoading}
                    onComment={() => setOpenShipment(shipment)}
                    onPostpone={() => onPostpone(shipment.shipmentId)}
                    onReturn={() => onReturn(shipment.shipmentId)}
                  />
                </div>
              </TableRowColumn>
            </TableRow>
          )), [shipments, onPostpone, onReturn, variant])}
        </TableBody>
      </Table>
    </>
  )
}

ShipmentTable.propTypes = {
  variant: PropTypes.oneOf([
    'not-delivered',
    'no-end-state',
    'done',
  ]),
  shipments: PropTypes.array,
  onPostpone: PropTypes.func,
  onReturn: PropTypes.func,
  onComment: PropTypes.func,
}

ShipmentTable.defaultProps = {
  variant: variantNames.notDelivered,
  shipments: [],
  onPostpone: () => null,
  onReturn: () => null,
  onComment: () => null,
}

export default ShipmentTable


