import React from 'react'
import PropTypes from 'prop-types'

import { SDKContext } from './Context'

const SDKProvider = ({
  sdk, children,
}) => (
  <SDKContext.Provider value={sdk}>
    {children}
  </SDKContext.Provider>
)

SDKProvider.propTypes = {
  sdk: PropTypes.object,
  children: PropTypes.node,
}

export default SDKProvider
