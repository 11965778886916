import { ACTION_TYPES } from '../actions/hubLogs'
import defaultTo from 'lodash/defaultTo'
import setShipmentNextStep from '../utils/setShipmentNextStep'

export enum ITYPES {
  BACK_TO_TRUNKRS = 'BACK_TO_TRUNKRS',
  KEEP_IN_REGION = 'KEEP_IN_REGION',
  ERROR = 'ERROR'
}

export interface IHubInventory {
  trunkrsNr: string,
  address: string,
  houseNumber: string,
  postalCode: string,
  city: string,
  country: string,
  lastDriver: number,
  driverName: string,
  shipmentState: string,
  intendedDeliveryDate: Date,
  shipmentId: number,
  inSince: Date,
  barcode: string,
  type?: ITYPES
}

export interface IReturnedShipments {
  shipmentId: number,
  address: string,
  postalCode: string,
  type: ITYPES
}

interface IAction {
  type: ACTION_TYPES,
  payload: any
}

const initialState : {
  inventory: Array<IHubInventory>,
  returnedShipments: Array<IReturnedShipments>,
  isBatchHubOutDone: boolean,
} = {
  inventory: [],
  returnedShipments: [],
  isBatchHubOutDone: false,
}

export default function hubLogs(state = initialState, action: IAction) {
  switch (action.type) {
  case ACTION_TYPES.SET_HUB_INVENTORY:
    return {
      ...state,
      isBatchHubOutDone: false,
      inventory: defaultTo(action.payload, []),
    }
  case ACTION_TYPES.SET_RETURNED_SHIPMENTS:
    return {
      ...state,
      returnedShipments: defaultTo(action.payload, []),
    }
  case ACTION_TYPES.SET_SHIPMENT_NEXT_STEP:
    return {
      ...state,
      inventory: setShipmentNextStep(state, action),
    }
  case ACTION_TYPES.SET_BATCH_HUB_OUT_DONE:
    return {
      ...state,
      isBatchHubOutDone: true,
    }
  default:
    return state
  }
}
