import get from 'lodash/get'
import first from 'lodash/first'
import last from 'lodash/last'
import moment from 'moment'

import * as actionTypes from '../constants/ActionTypes'

import { setLoading } from './ui'
import { translate } from '../utils/translate'
import { getShipmentToursHandovered } from '../selectors/combined'
import { trunkrs } from '../'

import * as fromTourOverviews from '../selectors/tourOverview'

const getCleanCollations = (collations) =>
  collations.filter(collation => collation.shipment_id > 0)

export const mapToTourOverview = ({ id: tourId, collations, driver }) => {
  const latitude = get(driver, 'latitude', 0)
  const longitude = get(driver, 'longitude', 0)
  const latestPosition = `https://maps.google.com/?q=${latitude},${longitude}`
  const speed = Math.max(0, Math.round(get(driver, 'speed', 0) * 3.6))
  const startTime = moment(first(collations).eta).format('HH:mm')
  const endTime = moment(last(collations).eta).format('HH:mm')
  const cleanCollations = getCleanCollations(collations)

  return {
    id: tourId,
    tour: {
      id: tourId,
      startTime,
      endTime,
    },
    collations: cleanCollations,
    driver: {
      ...driver,
      latestPosition,
      speed,
    },
  }
}

export const startUpdateTourOverview = () => ({
  type: actionTypes.UPDATE_TOUR_OVERVIEW_LOADING,
})

export const updateTourOverview = tours => ({
  type: actionTypes.UPDATE_TOUR_OVERVIEW_SUCCESS,
  payload: tours,
})

export const fetchTourOverviews = () => async(dispatch, getState) => {
  dispatch(startUpdateTourOverview())

  const state = getState()
  const hasFetched = fromTourOverviews.hasFetched(state)

  if (!hasFetched) {
    dispatch(setLoading(true, translate('FETCH_TOUR_OVERVIEW')))
  }

  const tours = getShipmentToursHandovered(state)
  const tourIds = tours.map(tour => tour.id)
  const overviewTours = await Promise.all(tourIds.map(async tourId => {
    const overview = await trunkrs.Tour().fetchTourOverview(tourId)
    return { id: tourId, ...overview }
  }))

  if (!hasFetched) {
    dispatch(setLoading(false))
  }

  dispatch(updateTourOverview(overviewTours.map(mapToTourOverview)))
}
