import React, { Component } from 'react'
import { connect } from 'react-redux'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import clsx from 'clsx'

import { ToolbarGroup } from 'material-ui/Toolbar'
import MenuItem from 'material-ui/MenuItem'
import FontIcon from 'material-ui/FontIcon'
import IconMenu from 'material-ui/IconMenu'
import FlatButton from 'material-ui/FlatButton'
import Checked from 'material-ui/svg-icons/toggle/check-box'
import Unchecked from 'material-ui/svg-icons/toggle/check-box-outline-blank'
import EventListener from 'react-event-listener'

import * as uiAction from '../../actions/ui'
import * as tourActions from '../../actions/tours'

import * as fromProfile from '../../selectors/profile'
import * as fromUI from '../../selectors/ui'

import Toolbar from '../toolbar/Toolbar'
import Translate from '../Translate'
import TourList from './Map/TourList'
import FilterIcon from '../../images/filter.svg'
import MapView from './Map/MapView'
import { MultiMoveCollations } from './Map/MultiMoveCollations'

import styles from './Map.module.scss'

class Map extends Component {
  mapRef = React.createRef()

  state = {
    showMarker: false,
    zoom: 7,
    fullScreen: false,
    selected: [],
    initialized: false,
    shown: [],
    enableScroll: false,
    tourMarkerFlag: 0,
  };

  componentDidMount() {
    this.setState({ shown: map(this.props.tours, 'id') })
  }

  componentDidUpdate(prevProps) {
    const { tourShown, tours } = this.props
    if(!isEqual(this.props, prevProps)) {
      if(tourShown.length >= 1) {
        this.setState({ shown: tourShown })
        const activeTours = tours.filter(({ id }) => tourShown.includes(id))
        if (!prevProps.tourShown.length) {
          this.fitToActiveTours(activeTours)
        }
        return
      }
      this.setState({ shown: map(tours, 'id') })
      if (!this.props.selectedCollations.length) {
        this.fitToActiveTours(tours)
      }
    }
  }

  fitToActiveTours = (tours, mapRef) => {
    const { depotCoordinates } = this.props
    const map = mapRef ? mapRef : this.mapRef.current

    if(tours && map) {
      const bounds = new window.google.maps.LatLngBounds()

      if (depotCoordinates.lat && depotCoordinates.lng) {
        bounds.extend(new window.google.maps.LatLng(
          depotCoordinates.lat,
          depotCoordinates.lng,
        ))
      }

      tours.forEach(tour => {
        if(tour) {
          tour.collations.forEach(({ coord }) => {
            !!coord.lat && !!coord.lng && bounds.extend(
              new window.google.maps.LatLng(coord.lat, coord.lng))
          })
        }
      })

      map.fitBounds(bounds)
    }
  }


  toggleShown = (tourId) => {
    const { shown } = this.state

    if (shown.includes(tourId)) {
      this.setState({
        shown: shown.filter(id => id !== tourId),
      })
    } else {
      this.setState({
        shown: shown.concat([tourId]),
      })
    }
  }

  setZoom = (e) => {
    const { current: map } = this.mapRef
    const currentZoom = map ? map.getZoom() : 7

    let newZoom
    let hasChanged = false

    if(!e.ctrlKey) {
      if(e.keyCode === 187 && currentZoom <= 18) {
        newZoom = currentZoom + 1
        hasChanged = true
      }
      if(e.keyCode === 189 && currentZoom > 0) {
        newZoom = currentZoom - 1
        hasChanged = true
      }
      if(hasChanged) {
        this.setState({
          zoom: newZoom,
        })
        hasChanged = false
      }
    }
  }

  updateMap = () => {
    setTimeout(() => {
      const { current: map } = this.mapRef

      if (!map) return

      window.google.maps.event.trigger(
        map.context['__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED'],
        'resize',
      )

      this.fit()
    }, 100)
  }

  handleMapMounted = (map) => {
    if (!map) return

    const { editMode } = this.props

    this.mapRef.current = map
    this.setState({ initialized: true })

    if (editMode) {
      setTimeout(() => this.fitMap(map), 250)
    } else {
      this.fitMap(map)
    }
  }

  fitMap = (map) => {
    const { tours } = this.props
    this.fitToActiveTours(tours, map)
  }

  fit = () => {
    const { current: map } = this.mapRef

    if (map) {
      this.fitMap(map)
    }
  }

  toggleRoute = (routeId) => {
    this.props.toggleRoute(routeId)

    setTimeout(this.fit, 1000)
  }

  handleRouteChange(e, value) {
    this.toggleRoute(value)
  }

  moveTour = (oldTour, newTour, collation) => {
    const { moveCollation } = this.props

    moveCollation(oldTour.id, newTour.id, collation.id)
  }

  handleMoveMultiple = (newTour) => {
    const {
      moveMultiple, clearSelected, selectedCollations,
    } = this.props

    moveMultiple(newTour.id, selectedCollations)
    clearSelected()
  }

  handleNewTour = () => {
    const {
      createTour, clearSelected, selectedCollations,
    } = this.props

    createTour(selectedCollations)
    clearSelected()
  }

  handleToggleScrolling = () => {
    const { enableScroll } = this.state

    this.setState({ enableScroll: !enableScroll })
  }

  render() {
    const { tours, depotCoordinates, editMode, selectedCollations, tourShown } = this.props
    const { enableScroll } = this.state

    return (
      <>
        <EventListener
          target="window"
          onKeyDown={this.setZoom}
        />

        <MapView
          key={enableScroll}
          containerElement={(
            <div
              className={clsx(
                styles.mapContainer,
                editMode && styles.editMode,
              )}
            />
          )}
          moveMultipleCollations={this.handleMoveMultiple}
          depotCoordinates={depotCoordinates}
          mapElement={<div className={styles.map} />}
          zoom={this.state.zoom}
          onGoogleMapLoad={this.handleMapMounted}
          tours={this.props.tours}
          move={this.moveTour}
          editMode={editMode}
          shown={this.state.shown}
          enableScroll={enableScroll}
          tourShown={tourShown}
          addToNewTour={this.handleNewTour}
        />

        {this.props.showToolbar && (
          <Toolbar
            color='#201047'
            className={clsx(
              styles.actionBar,
              editMode && styles.disabled,
            )}
          >
            <ToolbarGroup firstChild className={styles.toolbar}>
              <IconMenu
                touchTapCloseDelay={0}
                iconButtonElement={
                  <FlatButton
                    label={(
                      <>
                        <img src={FilterIcon} className={styles.filterIcon}/>
                        <Translate capitalize={true}>VISIBLE_TOURS</Translate>
                      </>
                    )}
                    backgroundColor={'#ffffff'}
                    hoverColor={'#ededed'}
                    labelStyle={{
                      fontSize: 12,
                      textTransform: 'capitalize',
                    }}
                    className={styles.filterButton}
                  />
                }
                onChange={this.handleRouteChange}
                className={styles.filterMenu}
                menuStyle={{ width: 150 }}
              >
                {tours && tours.map((tour, index) => (
                  <TourList
                    key={tour.id}
                    tour={tour}
                    index={index}
                    isShown={this.state.shown.includes(tour.id)}
                    onToggleShown={this.toggleShown}
                  />
                ))}
              </IconMenu>

              {!!selectedCollations.length && (
                <MultiMoveCollations
                  selected={selectedCollations}
                  tours={tours}
                  onMoveCollations={this.handleMoveMultiple}
                  onNewTour={this.handleNewTour}
                />
              )}

              <IconMenu
                touchTapCloseDelay={0}
                iconButtonElement={
                  <FontIcon
                    className={clsx('material-icons', styles.settingsIcon)}
                  >
                    settings
                  </FontIcon>}
                onChange={this.handleToggleScrolling}
                className={styles.settingsMenu}
                menuStyle={{ width: 200 }}
              >
                <MenuItem
                  key={1}
                  primaryText={<Translate capitalize={true}>SETTING_ZOOM</Translate>}
                  rightIcon={enableScroll ? <Checked /> : <Unchecked />}
                  value="zoom"
                />
              </IconMenu>
              <FontIcon
                className={clsx('material-icons', styles.centerMapButton)}
                onClick={this.fit}
              >
                settings_overscan
              </FontIcon>
            </ToolbarGroup>
          </Toolbar>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  depotCoordinates: fromProfile.getDepotCoordinates(state),
  selectedCollations: fromUI.getSelectedCollations(state),
})

const mapDispatchToProps = {
  setLoading: uiAction.setLoading,
  moveCollation: tourActions.moveSingle,
  moveMultiple: tourActions.moveMultiple,
  createTour: tourActions.addTour,
  clearSelected: uiAction.clearSelected,
}

export default connect(mapStateToProps, mapDispatchToProps)(Map)
