import React from 'react'
import {ReactComponent as FlagIcon_EN} from '../../images/flags/en-flag-icon.svg';
import {ReactComponent as FlagIcon_NL} from '../../images/flags/nl-flag-icon.svg';
import {ReactComponent as FlagIcon_FR} from '../../images/flags/fr-flag-icon.svg';


export default [
  {
    text: 'English',
    value: 'en',
    icon: <FlagIcon_EN/>
  },
  {
    text: 'Dutch',
    value: 'nl',
    icon: <FlagIcon_NL/>
  },
  {
    text: 'French',
    value: 'fr',
    icon: <FlagIcon_FR/>
  },
] as Array<{text: string, value: string, icon: any}>

