import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { Card } from 'material-ui'

import { InputField } from '../InputField/InputField'
import { SuccessButton } from '../common/Button/Button'
import Translate from '../Translate'
import { translate } from '../../utils/translate'
import { getSubco } from '../../selectors/profile'

const cardStyle = {
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.12)',
  width: 650,
  borderRadius: 5,
  marginTop: 28,
  padding: 28,
  textAlign: 'center',
}

const VALIDATION_ERRORS = {
  FORM_INCOMPLETE: 'FORM_INCOMPLETE',
  PHONE_INCORRECT: 'PHONE_INCORRECT',
}

class EditProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      address: get(this.props.subco, 'location.address', ''),
      number: '',
      postalCode: get(this.props.subco, 'location.postalCode', ''),
      city: get(this.props.subco, 'location.city', ''),
      lat: get(this.props.subco, 'location.latitude', ''),
      lng: get(this.props.subco, 'location.longitude', ''),
      country: get(this.props.subco, 'location.country', ''),
      phone: '',
    }
  }

  // componentDidMount() {
  //   if (this.props.subco) {
  //     const {subco} = this.props;
  //     const location = get(subco, 'location');
  //     console.log('1',subco);
  //     this.setState({
  //       address: location.address,
  //       postalCode: location.postalCode,
  //       city: location.city,
  //       lat: location.latitude,
  //       lng: location.longitude,
  //       country: location.country
  //     });
  //   }
  // }
  //
  componentDidUpdate(prevProps) {
    if (!prevProps.subco && this.props.subco) {
      const { subco } = this.props
      const location = get(subco, 'location')
      this.setState({
        address: location.address,
        postalCode: location.postalCode,
        city: location.city,
        lat: location.latitude,
        lng: location.longitude,
        country: location.country,
      })
    }
  }

  handleChange = (name) => ({ target: { value } }) => {
    this.setState({ [name]: value })
  };

  showToast = (title, msg) => {
    const [transTitle, transMessage] = translate([title, msg])
    toastr.error(transTitle, transMessage)
  }

  verifyFieldValidity = () => {
    const {
      address,
      postalCode,
      city,
      lat,
      lng,
    } = this.state

    if (isEmpty(address) || isEmpty(postalCode) || isEmpty(city) || isEmpty(lat.toString()) || isEmpty(lng.toString())) {
      return VALIDATION_ERRORS.FORM_INCOMPLETE
    }

    return true
  };

  saveProfile = async() => {
    try {
      // API call to update record
      const location = get(this.props, 'subco.location')
      if (!location) {
        throw new Error('no location found')
      }
      location.setAddress = this.state.address
      location.setPostalCode = this.state.postalCode
      location.setCity = this.state.city
      location.latitude = this.state.lat
      location.longitude = this.state.lng
      await location.save()
      window.location.href = '/profile'
    } catch (error) {
      this.showToast('ERROR', error)
    }
  };

  saveForm = () => {
    const validity = this.verifyFieldValidity()
    if (validity !== true) {
      return this.showToast(validity, validity + '_MSG')
    }
    return this.saveProfile()
  };

  render() {
    return !!this.props.profile && (
      <div className="edit-profile--wrapper">
        <h1 className="edit-profile--title">
          <Translate capitalize>EDIT_PROFILE</Translate>
        </h1>
        <Card className="edit-profile--form" style={cardStyle}>
          <div className="input-field--row">
            <InputField
              label={<Translate capitalize={true}>STREET</Translate>}
              value={this.state.address}
              onChange={this.handleChange('address')}
              valid={this.state.address.length}
            />
          </div>
          <div className="input-field--row">
            <div className="input-field--col" style={{ width: '47%', marginRight: '3%' }}>
              <InputField
                label={<Translate capitalize={true}>POSTALCODE</Translate>}
                value={this.state.postalCode}
                onChange={this.handleChange('postalCode')}
                valid={this.state.postalCode.length}
              />
            </div>
            <div className="input-field--col" style={{ width: '50%' }}>
              <InputField
                label={<Translate capitalize={true}>CITY</Translate>}
                value={this.state.city}
                onChange={this.handleChange('city')}
                valid={this.state.city.length}
              />
            </div>
          </div>
          <div className="input-field--row">
            <div className="input-field--col" style={{ width: '47%', marginRight: '3%' }}>
              <InputField
                label={<Translate capitalize={true}>LAT</Translate>}
                value={this.state.lat}
                onChange={this.handleChange('lat')}
                valid={!!this.state.lat}
              />
            </div>
            <div className="input-field--col" style={{ width: '50%' }}>
              <InputField
                label={<Translate capitalize={true}>LNG</Translate>}
                value={this.state.lng}
                onChange={this.handleChange('lng')}
                valid={!!this.state.lng}
              />
            </div>
          </div>
        </Card>
        <div className="edit-profile-actions--container">
          <SuccessButton
            label={<Translate capitalize={true}>SAVE</Translate>}
            onClick={this.saveForm}
            disabled={this.verifyFieldValidity() !== true}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  subco: getSubco(state),
})

export default connect(mapStateToProps, null)(EditProfile)
