import React, {memo} from 'react'
import clsx from 'clsx'
import get from 'lodash/get'
import drop from 'lodash/drop'

import styles from './styles.module.scss'
import Icon from "../Icon";

enum CALLOUT_TYPES {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning'
}

const Callout = ({children, className, variant = CALLOUT_TYPES.DEFAULT, ...props}: React.DetailedHTMLProps<any, any> & {variant?: CALLOUT_TYPES|string}) => {
  const icons = {
    [CALLOUT_TYPES.PRIMARY] : <Icon name="info-circle" />,
    [CALLOUT_TYPES.SUCCESS] : <Icon name="check-circle" />,
    [CALLOUT_TYPES.DANGER] : <Icon name="danger" />,
    [CALLOUT_TYPES.WARNING] : <Icon name="warning" />
  }

  const icon = get(icons, variant);

  const contents = React.Children.toArray(children)

  return(
    <div {...props} className={clsx(styles.callout, get(styles, variant), className)}>
      <div>
        {icon}
      </div>
      <div>
        {
          contents.length > 1 ? (
            <>
              <div className={styles.title}>
                {get(contents, '[0]')}
              </div>
              {
                drop(contents)
              }
            </>
          ) : get(contents, '[0]')
        }
      </div>
    </div>
  )
}

export default memo(Callout)
