import React from 'react'
import PropTypes from 'prop-types'
import { PROCESSED_SHIPMENT_STATUS } from 'Trunkrs-SDK/dist/models/delivery/Shipment'

import classes from './TodoStatus.module.scss'
import Translate from '../../Translate'

const TodoStatus = ({
  status,
}) => {
  switch (status) {
  case PROCESSED_SHIPMENT_STATUS.SHIPMENT_BACK_IN_PLANNING:
    return <Translate component="p" className={classes.blue}>BACK_INTO_PLANNING</Translate>
  case PROCESSED_SHIPMENT_STATUS.PARCEL_RETURNED_TO_TRUNKRS:
    return <Translate component="p" className={classes.green}>RETURNED_TRUNKRS</Translate>
  default:
    return <Translate component="p" className={classes.red}>NOT_AT_DEPOT</Translate>
  }
}

TodoStatus.propTypes = {
  status: PropTypes.oneOf(
    Object.values(PROCESSED_SHIPMENT_STATUS),
  ),
}

TodoStatus.defaultProps = {
  status: PROCESSED_SHIPMENT_STATUS.SHIPMENT_BACK_IN_PLANNING,
}

export default TodoStatus
