import { connect } from 'react-redux'

import * as fromShipments from '../../selectors/shipments'
import * as fromProfile from '../../selectors/profile'

import * as uiActions from '../../actions/ui'
import * as shipmentActions from '../../actions/shipments'
import * as driverActions from '../../actions/drivers'
import * as profileActions from '../../actions/profile'

const mapStateToProps = state => {
  return {
    subco: fromProfile.getSdkSubco(state),
    hasShipments: fromShipments.hasFetched(state),
  }
}

const mapDispatchToProps = {
  setLoading: uiActions.setLoading,
  setShipments: shipmentActions.add,
  setDrivers: driverActions.addMultiple,
  setSubco: profileActions.setSubco,
}

export default connect(mapStateToProps, mapDispatchToProps)
