import React, {memo, useCallback, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {toggleDriver} from '../../../actions/profile'
import {FILTERS, FORM_ACTION, IUserProfile} from "./@types";

import {
  Avatar,
  Button, Callout, Chip,
  Icon, MaskedInput, ModalBox, Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
  Tooltip
} from "../../base";
import {NoResults} from "../../noResults/noResults";
import {useTranslate} from '../../../utils'

import * as fromProfile from "../../../selectors/profile";
import * as fromTours from '../../../selectors/tour'

import clsx from 'clsx';
import FlatButton from "material-ui/FlatButton";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import DriverIcon from '../../../images/driver-icon.png'
import styles from './styles.module.scss'
import Modal from '../Modal';
import Toast from "../../base/Toast";
import DriverImportModal from "../DriverImportModal";


const UsersList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDriverImportModalVisible, setIsDriverImportModalVisible] = useState(false)
  const [isSuccessNotificationVisible, setSuccessNotificationVisible] = useState(false)
  const [currentFilter, setCurrentFilter] = useState<FILTERS>(FILTERS.ALL_EMPLOYEES)
  const [selectedUser, setSelectedUser] = useState<IUserProfile>()
  const [formAction, setFormAction] = useState<FORM_ACTION>(FORM_ACTION.CREATE)
  const dispatch = useDispatch()
  const {t} = useTranslate('USERS')

  const assignedDriverIds = useSelector(fromTours.getAssignedDriverIds)

  const categorizedData = {
    [FILTERS.ALL_EMPLOYEES] : useSelector(fromProfile.getDriverProfiles),
    [FILTERS.PLANNER] : useSelector(fromProfile.getPlanners),
    [FILTERS.INACTIVE] : useSelector(fromProfile.getInactiveEmployees),
    [FILTERS.DELETED] : useSelector(fromProfile.getDeletedEmployees)
  } as {[key: string]: Array<IUserProfile>}

  const filteredUsers = categorizedData[currentFilter].sort((a, b) => a.name.localeCompare(b.name))

  const openModal = useCallback(() => setIsModalVisible(true), [])
  const closeModal = useCallback(() => setIsModalVisible(false), [])

  const openDriverImportModal = useCallback(() => setIsDriverImportModalVisible(true), [])
  const closeDriverImportModal = useCallback(() => setIsDriverImportModalVisible(false), [])

  const handleNotificationExit = useCallback(() => setSuccessNotificationVisible(false), [])

  const handleUserCreationSuccess = useCallback(() => {
    closeModal()
    setSuccessNotificationVisible(true)
  }, [])

  const handleUserCreation = useCallback(() => {
    setSelectedUser(undefined)
    setFormAction(FORM_ACTION.CREATE)
    openModal()
  }, [])

  const handleUserEdit = (user: IUserProfile) => async() => {
      setSelectedUser(user)
      setFormAction(FORM_ACTION.EDIT)
      openModal()
  }

  const handleUserArchive = (user: IUserProfile) => () => {
    setSelectedUser(user)
    setFormAction(FORM_ACTION.DELETE)
    openModal()
  }

  const toggleEmployee = (driverId: number) => (active: boolean) => {
    dispatch(toggleDriver(driverId, active))
  }

  const filterToAllEmployees = useCallback(() => setCurrentFilter(FILTERS.ALL_EMPLOYEES), [])
  const filterToPlanner = useCallback(() => setCurrentFilter(FILTERS.PLANNER), [])
  const filterToInactive = useCallback(() => setCurrentFilter(FILTERS.INACTIVE), [])
  const filterToDeleted = useCallback(() => setCurrentFilter(FILTERS.DELETED), [])

  const isFilteredToDeleted = currentFilter === FILTERS.DELETED

  return (
    <div>
      <div className={styles['top-button-container']}>
        <Button
          variant="success"
          onClick={openDriverImportModal}
        >
          <Icon name="swap"/>
          {t('BUTTON_IMPORT_DRIVER')}
        </Button>
        <Button
          onClick={handleUserCreation}
        >
          <Icon name="plus"/>
          {t('ADD_USER_BUTTON')}
        </Button>
      </div>
      <Modal
        visible={isModalVisible}
        mode={formAction}
        user={selectedUser}
        onClose={closeModal}
        onSucceed={handleUserCreationSuccess}
        deleteFilter={isFilteredToDeleted}
      />
      {
        isSuccessNotificationVisible && <Toast headerText={t('ADD_USER_SUCCESS_MSG')}
        subText={t(formAction === FORM_ACTION.DELETE ? t('DELETE_USER_SUCCESS_MSG_SUBTEXT').replace('{{EMP_NAME}}', selectedUser!.name) :
             formAction === FORM_ACTION.CREATE ? 'ADD_USER_SUCCESS_MSG_SUBTEXT' : 'EDIT_USER_SUCCESS_MSG_SUBTEXT')} onExit={handleNotificationExit} />
      }
      <h1 className={styles.header}>{t('USER_LIST_HEADER')}</h1>
      <div className={styles["tab-container"]}>
        <Tab active={currentFilter === FILTERS.ALL_EMPLOYEES} onClick={filterToAllEmployees}>
          {t('USER_LIST_TAB_ALL_EMPLOYEES')}
        </Tab>
        <Tab active={currentFilter === FILTERS.PLANNER} onClick={filterToPlanner}>
          {t('USER_LIST_TAB_PLANNER')}
        </Tab>
        <Tab active={currentFilter === FILTERS.INACTIVE} onClick={filterToInactive}>
          {t('USER_LIST_TAB_INACTIVE')}
        </Tab>
        <Tab active={currentFilter === FILTERS.DELETED} onClick={filterToDeleted}>
          {t('USER_LIST_TAB_DELETED')}
        </Tab>
      </div>
      <Table className={styles['custom-table-style']}>
        <TableRowHeader className="table-header-row">
          <TableHeader>
            {t('USER_LIST_TABLE_EMPLOYEE_NAME')}
          </TableHeader>
          <TableHeader>
            {t('USER_LIST_TABLE_ROLE')}
          </TableHeader>
          <TableHeader className={clsx(isFilteredToDeleted && styles.hidden)}>
            {t('USER_LIST_TABLE_PHONE_NUMBER')}
          </TableHeader>
          <TableHeader>
            {t('USER_LIST_TABLE_STATUS')}
          </TableHeader>
          <TableHeader>
            {t('USER_LIST_TABLE_MANAGE_EMPLOYEE')}
          </TableHeader>
        </TableRowHeader>
        <TableBody>
          {useMemo(() => filteredUsers.map((user: IUserProfile) => {
            const {id, name, phoneNumber, active, isPlanner} = user
            return (
              <TableRow key={id} className={clsx( isFilteredToDeleted && styles.disabled)}>
                <TableCell className={clsx( styles["avatar-container"])}>
                  <Avatar>{name}</Avatar>
                  {name}
                </TableCell>
                <TableCell><Chip>{t(isFilteredToDeleted ? 'USER_LIST_TABLE_ROLE_DELETED' : isPlanner ? 'USER_LIST_TABLE_ROLE_PLANNER' : 'USER_LIST_TABLE_ROLE_DRIVER')}</Chip></TableCell>
                <TableCell className={clsx(isFilteredToDeleted && styles.hidden)}>{phoneNumber}</TableCell>
                <TableCell className={clsx( styles["switch-container"])}>
                  <Switch onToggle={toggleEmployee(id)} active={active} />
                  <span>{t(active ? 'USER_LIST_TABLE_ACTIVE' : 'USER_LIST_TABLE_INACTIVE')}</span>
                </TableCell>
                <TableCell className={styles['menu-tooltip']}>
                  <Tooltip
                    text={t('EDIT_INFORMATION_TOOLTIP')}
                    placement='bottom'
                    eventTriggers={['hover']}>
                    <Icon name="edit" onClick={handleUserEdit(user)}/>
                  </Tooltip>
                  <Tooltip
                    text={t('DELETE_TOOLTIP')}
                    placement='bottom'
                    eventTriggers={['hover']}>
                    <Icon name="trash" onClick={handleUserArchive(user)}/>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )
          }), [filteredUsers])}
          {
            !filteredUsers.length && (
              <NoResults
                icon={DriverIcon}
                title={t('NO_USERS_FOUND')}
                content={t('NO_USERS_FOUND_MSG')}
                button={null}
              />
            )
          }
        </TableBody>
      </Table>
      <DriverImportModal visible={isDriverImportModalVisible} onClose={closeDriverImportModal} />
    </div>
  )
}

export default memo(UsersList)


