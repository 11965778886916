import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { browserHistory } from '../../browserHistory'
import {getCurrentLocale, getCurrentSubcoId, getOrderedSubcos, getSubco, getSubcoIndex} from '../../selectors/profile'
import { getNumberOfNonCancelledShipments } from '../../selectors/shipments'
import { getIdentifiers as getTourIdentifiers } from '../../selectors/tour'
import { changeSubco, fetchLocale, setLocale } from '../../actions/profile'
import { getCurrentPath } from '../../selectors/routing'
import { getSidebarCollapsed, getMode, getOptimizeResult } from '../../selectors/ui'
import { setPowerMode, set, setOptimizeResponse } from '../../../src/actions/ui'
import { translate, useTranslate } from '../../utils/translate'
import { environment } from '../../constants'

import * as fromCombined from '../../selectors/combined'

import Translate from '../Translate'
import styles from './Sidebar.module.scss'
import WithDelay from '../common/WithDelay'
import languages from './resource'
import Toggle from '../planner/BoardList/components/Toggle'
import clsx from 'clsx'
import moment from 'moment'
import SwitchModal from './components/SwitchModal'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import orderBy from 'lodash/orderBy'
import { shortenString } from '../../utils/shortenString'
import routes from './routes'
import OptimizationResultDialog from 'components/planner/Board/OptimizationResultDialog'
import {getDrivers} from "../../selectors/driver";
import {ACTION_TYPES, getHubInventoryList} from "../../actions/hubLogs";
import { getHubInventory, getIsBatchHubOutDone, isShipmentReturnedExistOnInventory } from 'selectors/hubLogs'
import DropdownLink from "../base/DropdownLink";
import DropdownMenu from "../base/DropdownMenu";
import Badge from '../base/Badge'
import SideMenuIcon from "../SideMenuIcon";
import SidebarListMenu from './components/SidebarListMenu'
import { ITYPES } from 'reducers/hublogs'
import isUndefined from 'lodash/isUndefined'

export interface ISideBarProps {
  logoff: () => void,
  children: any
}

export const SideBar = ({
  logoff,
  children
}: ISideBarProps) => {
  const dispatch = useDispatch()
  const endTime = moment().hour(13).startOf('hour')
  const shipmentCeiling = environment === 'staging' ? 50 : 350

  const shipmentCount = useSelector(getNumberOfNonCancelledShipments)
  const tourIdentifiers = useSelector(getTourIdentifiers)
  const subcontractor = useSelector(getSubco)
  const subcontractorIndexes = useSelector(getSubcoIndex)
  const subcontractors = useSelector(getOrderedSubcos)
  const currentPath = useSelector(getCurrentPath)
  const areToursLocked = useSelector(fromCombined.areToursLocked)
  const sidebarCollapsed = useSelector(getSidebarCollapsed)
  const currentLocale = useSelector(getCurrentLocale)
  const powerMode = useSelector(getMode)
  const optimizationResult = useSelector(getOptimizeResult)
  const currentSubcoId = useSelector(getCurrentSubcoId)
  const drivers = useSelector(getDrivers)
  const hubInventory = useSelector(getHubInventory)
  const isNotAcknowledgedYet = useSelector(isShipmentReturnedExistOnInventory)
  const isHubOutDone = useSelector(getIsBatchHubOutDone)

  const navigateTo = (path: string) => () => browserHistory.push(path)
  const isActiveClass = (part: string) => currentPath.search(part) > -1 ? 'active ' : ''
  const timeLeft = Math.max(endTime.diff(moment()), 0)

  const [isModalVisible, setModalVisible] = useState(false)

  const {t} = useTranslate()

  useEffect(() => {
    dispatch(fetchLocale())
  }, [])

  useEffect(() => {
    if (shipmentCount >= shipmentCeiling) {
      dispatch(setPowerMode(false))
    } else {
      dispatch(setPowerMode(true))
    }
  }, [shipmentCount, shipmentCeiling])

  useEffect(()=> {
    if(currentSubcoId && !isEmpty(drivers)) {
      dispatch(getHubInventoryList(currentSubcoId, drivers))
    }
    dispatch({ type: ACTION_TYPES.SET_HUB_INVENTORY, payload: [] })
    dispatch({ type: ACTION_TYPES.SET_RETURNED_SHIPMENTS, payload: [] })
  }, [currentSubcoId, drivers])

  const closeModal = useCallback(() => setModalVisible(false), [])
  const openModal = useCallback(() => setModalVisible(true), [])

  const handleSelectLanguage = useCallback((lang: string) => dispatch(setLocale(lang)), [])
  const onChangeSubco = useCallback((subcoId: number) => dispatch(changeSubco(subcoId)), [])

  const onToggle = useCallback(() => {
    if (shipmentCount >= shipmentCeiling && !powerMode) {
      openModal()
    } else {
      onSwitch()
    }
  }, [shipmentCount, powerMode, shipmentCeiling])

  const onSwitch = useCallback(() => {
    dispatch(setPowerMode(!powerMode))
    closeModal()
  }, [powerMode])

  const onReOptimize = useCallback(() => {
    // close optimiation result dialog
    dispatch(setOptimizeResponse(undefined))
    dispatch(set({ optimizeModal: true }))
  }, [])

  const hubInventoryCount = useMemo(() => !isNotAcknowledgedYet || isHubOutDone ? 
    hubInventory.filter((shipment) => shipment.type === ITYPES.KEEP_IN_REGION || !shipment.type).length 
    : hubInventory.length, [hubInventory, isNotAcknowledgedYet, isHubOutDone])

  const subcontractorsInitials = get(subcontractor, 'name', '').split(' ').map((str: string) => str.substr(0, 1)).join('').substr(0, 2)

  const subcontractorsList = useMemo(()=> reduce(subcontractors, (obj, prop) => {
    (obj as any)[subcontractorIndexes[prop.tag]] = shortenString(`${prop.tag} - ${prop.name}`, 20)
    return obj
  }, {}), [subcontractors])

  const languageList = useMemo(() => reduce(languages, (obj, prop) => {
    (obj as any)[prop.value] = (
      <li>
          <span className="sidebar--profile-language">
            {prop.icon}
            <span>{prop.text}</span>
          </span>
      </li>
    )
    return obj
  },{}), [languages])

  const onProfileClick = (ev: React.MouseEvent<any>) => {
    const targetElements = [
      document.querySelector('#sidebar-profile-name > div > div > div'),
      document.querySelector('#sidebar-profile-name > div > div > div > svg'),
      document.querySelector('#sidebar-profile-name > div > div > div > svg > path')
    ];

    if(!targetElements.find(element => element.isEqualNode(ev.target as any))){
      navigateTo('/profile')();
    }
  }

  return (
    <div className={clsx('sidebar--wrapper no-print', sidebarCollapsed && styles.collapsed, styles.scrollContainer)}>
      {children}
      <div className={clsx('sidebar--logo', styles.sidebarLogo, styles.fixed)}>
        <div/>
      </div>
      <div className={styles.scrollContent}>
        <ul className={clsx('sidebar--menu', styles.sidebarMenu)}>
          {
            useMemo(() => routes.filter(({ label, disabled }) => label !== 'EMPLOYEES' && !disabled).map((route, index) => {
              const { id, path, label, icon } = route
              return (
                <>
                  <SidebarListMenu
                    menuId={id}
                    className={clsx(
                      (id === 5 && !!timeLeft) && styles.warning,
                      isActiveClass(path)
                    )}
                    onClick = {navigateTo(path)}
                  >
                    {icon}
                    <span>
                      {t(label)}
                    </span>
                    {
                      id === 1 && <Badge>{shipmentCount}</Badge>
                    }
                    {
                      id === 2 && <Badge>{tourIdentifiers.length}</Badge>
                    }
                    {
                      id === 5 && (!!timeLeft && !sidebarCollapsed) && (
                        <WithDelay delay={400}><FontAwesomeIcon icon={faExclamationTriangle} className={styles.icon} /></WithDelay>
                      )
                    }
                    {
                      id === 9 && <Badge>{hubInventoryCount}</Badge>
                    }
                  </SidebarListMenu>
                  {
                    id == 2 && (
                      <div className={clsx(styles.rectangle, sidebarCollapsed && styles.collapsedDropdown)}>
                        <div className={styles.planMode}>{translate('PLAN_MODE')}</div>
                        <Toggle
                          labelActive={translate('LITE_MODE')}
                          labelInactive={translate('POWER_MODE')}
                          isActive={powerMode}
                          toggleSelected={onToggle} />
                        {
                          sidebarCollapsed && (
                            <div className={powerMode && styles.powerMode}>{translate(powerMode ? 'POWER_MODE' : 'LITE_MODE')}</div>
                          )
                        }
                      </div>
                    )
                  }
                </>
              )
            }), [t, routes, currentPath, sidebarCollapsed, shipmentCount, 
                  tourIdentifiers.length, shipmentCeiling, timeLeft, powerMode, hubInventory, hubInventoryCount ])
          }
        </ul>
        <div className="sidebar--space" />
        {subcontractor && (
          <>
            <div className={clsx('sidebar--profile', isActiveClass('/profile'))} onClick={onProfileClick}>
              <div className={clsx('sidebar--profile-picture', styles.sidebarProfilePicture)}>
                {/*<FontAwesomeIcon icon={faUserCircle} size="4x" title={capitalize(subcontractor.name)} />*/}
                {subcontractorsInitials}
              </div>
              <div className={clsx('sidebar--profile-text', styles.sidebarProfileText)}>
                <div id="sidebar-profile-name" className="sidebar--profile-name">
                  <div className={clsx(styles.dropdown, sidebarCollapsed && styles.hidden)}>
                    {
                      subcontractors.length > 1 ? (
                        <DropdownLink ordered={true} data={subcontractorsList} value={subcontractorIndexes[subcontractor.tag]} textValue={shortenString(subcontractor.name, 15)} onChange={onChangeSubco}/>
                      ) : <span className="sidebar--profile-text-header">{shortenString(subcontractor.name, 15)}</span>
                    }
                  </div>
                  <span className="sidebar--profile-text-sub-line">Subcontractor</span>
                </div>
              </div>
            </div>
            <div className={clsx("sidebar--profile-language-picker", styles.languagePicker)}>
              <DropdownMenu data={languageList} onChange={handleSelectLanguage} value={currentLocale} />
            </div>
          </>
        )}
        <ul className={clsx('sidebar--menu', styles.sidebarMenu, styles.userIcon)}>
          {
            useMemo(() => {
              const route = routes.find(({ label, disabled }) => label === 'EMPLOYEES' && !disabled)
              if (route) {
                const { path, label, icon } = route
                return (
                  <li onClick={navigateTo(path)} className={isActiveClass(path)}>
                    {icon}
                    <Translate capitalize={true}>{label}</Translate>
                  </li>
                )
              }
            }, [routes, currentPath])
          }
          <li className="sidebar--menu-alert" onClick={logoff}>
            <SideMenuIcon icon="log-off" />
            <Translate capitalize={true}>LOG_OFF</Translate>
          </li>
        </ul>
      </div>
      <SwitchModal visible={isModalVisible} onSwitch={onSwitch} onClose={closeModal} />
      <OptimizationResultDialog reOptimize={onReOptimize}
        visible={get(optimizationResult, 'visible', false)} optimizationResultData={optimizationResult || {}} />
    </div>
  )
}


export default memo(SideBar)
