import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import moment, { Moment } from "moment";
import clsx from "clsx";
import get from "lodash/get";
// any additional languages should be imported here, else the translated dates won't work
import "moment/locale/nl";
import "moment/locale/de";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { useInterval } from "../../hooks";
import { Button } from "../common/Button/Button";

import OptimizationModal from "./Board/OptimizationModal";
import noTourIcon from "../../images/planner-power-icon.svg";
import Map from "./GoogleMaps";
import TourList from "./TourList";
import OptimizeWarning from "../notification/OptimizeWarning";
import TourToolbar from "./Board/TourToolbar";
import EditModal from "./EditModal";

import { IDriver, ITour } from "models";

import * as shipmentsAction from "../../actions/shipments";
import * as uiAction from "../../actions/ui";
import * as tourActions from "../../actions/tours";
import * as warehouseActions from '../../actions/warehouse'

import * as fromShipments from "../../selectors/shipments";
import * as fromTours from "../../selectors/tour";
import * as fromProfile from "../../selectors/profile";
import * as fromDrivers from "../../selectors/driver";
import * as fromCombined from "../../selectors/combined";
import * as fromShipmentAndDriverEstimation from "../../selectors/shipmentAndDriverEstimation";
import * as fromUI from "../../selectors/ui";
import * as fromWarehouse from '../../selectors/warehouse'

import { PlannerStore } from "../../stores";

import { translate as t, getLocale } from "../../utils/translate";

import styles from "./Board.module.scss";

interface IBoardProps {
  addTour: () => void;
  areToursLocked: boolean;
  assignShipmentTours: () => void;
  drivers: IDriver[];
  editTour: () => void;
  estimatedAmountOfShipments: number;
  estimatedDriversNeeded: number;
  fetchToursStatus: () => void;
  getProductivityGoal: () => void;
  handoverStarted: boolean;
  hasTours: boolean;
  isPlanner: boolean;
  isPlanningLocked: boolean;
  isSubco: boolean;
  fetchTourSortingStatus: () => void;
  productivityGoal: number;
  profile: any;
  regionProductivity: number;
  remainingTimeForLockScreen: number;
  route: any;
  saveTours: (lock?: boolean) => Promise<void>;
  saveDrivers: () => void;
  selectedSubcoId: number;
  setDriver: () => void;
  setEditMode: (tourId: number | null) => void;
  shipmentIdsWithCollations: number[];
  toggleOptimizeModal: () => void;
  tours: ITour[];
  vrpShipments: any[];
  areToursSorted: boolean;
  tourPageState: { timeUserOnPage?: Moment };
  setTourPageState: (timeUserOnPage?: Moment) => void;
  hasOptimizationError: boolean;
  setHasOptimizationError: (payload: boolean) => void;
  whatBannerShouldDisplay: () => void;
  isBusyDay: boolean;
  getBusyDay: () => void;
  isCentralSortingDone: boolean;
  getCentralSortingStatus: () => void;
  reloadShipments: () => void;
}

interface IBoardState {
  directions: null;
  markers: any[];
  drivers: { [key: string]: IDriver };
  breakOff: number;
  changes: boolean;
  initial: boolean;
  unsaved: string;
  cutoffIntervalId: number;
  reload: boolean;
  modalText: string;
  showNext: boolean;
  showPrev: boolean;
  easyEdit: number;
  cutoffTime: number;
  cutoffTimePassed: boolean;
  newShipments: boolean;
  ready: boolean;
  showWarning: boolean;
  showResetHandOverConfirmation: boolean;
  showChangeDriverConfirmation: boolean;
  showMarkTourConfirmation: boolean;
  isDisableMarkTourConfirmationChecked: boolean;
  markedTours: ITour[];
  tourForConfirmation: ITour | null;
  routesForDriverChanging: any | null;
  overflowed: boolean;
  currentTour: any[];
  showTourActions: boolean;
  currentTourNo: number | undefined;
}

const Board: React.FunctionComponent<IBoardProps> = ({
  addTour,
  areToursLocked,
  assignShipmentTours,
  drivers,
  editTour,
  estimatedAmountOfShipments,
  estimatedDriversNeeded,
  fetchToursStatus,
  getProductivityGoal,
  handoverStarted,
  hasTours,
  isPlanner,
  isPlanningLocked,
  isSubco,
  productivityGoal,
  profile,
  regionProductivity,
  remainingTimeForLockScreen,
  route,
  saveTours,
  saveDrivers,
  selectedSubcoId,
  setDriver,
  setEditMode,
  shipmentIdsWithCollations,
  toggleOptimizeModal,
  tours,
  vrpShipments,
  areToursSorted,
  tourPageState,
  fetchTourSortingStatus,
  setTourPageState,
  hasOptimizationError,
  setHasOptimizationError,
  isBusyDay,
  getBusyDay,
  isCentralSortingDone,
  getCentralSortingStatus,
  whatBannerShouldDisplay,
  reloadShipments,
}) => {
  const [boardState, setBoardState] = useState<IBoardState>({
    directions: null,
    markers: [],
    drivers: {},
    breakOff: 20,
    changes: false,
    initial: !hasTours,
    unsaved: "",
    cutoffIntervalId: 0,
    reload: true,
    modalText: "",
    showNext: false,
    showPrev: false,
    easyEdit: -1,
    cutoffTime: 0,
    cutoffTimePassed: false,
    newShipments: false,
    ready: true,
    showWarning: false,
    showResetHandOverConfirmation: false,
    showChangeDriverConfirmation: false,
    showMarkTourConfirmation: false,
    isDisableMarkTourConfirmationChecked: false,
    markedTours: [] as ITour[],
    tourForConfirmation: null as ITour | null,
    routesForDriverChanging: null,
    overflowed: false,
    currentTour: [],
    showTourActions: false,
    currentTourNo: undefined,
  });

  const { markedTours } = boardState;

  const toursWindow = useRef<any>(null);
  const dispatch = useDispatch();
  const [startPolling, stopPolling] = useInterval(fetchToursStatus, 300000);

  const [startBusyDayPolling, stopBusyDayPolling] = useInterval(getBusyDay, 10800000)
  const [startCentralSortingPolling, stopCentralSortingPolling] = useInterval(getCentralSortingStatus, 300000)

  useEffect(() => {
    const { initial } = boardState;

    if (initial && !!tours.length) {
      openOptimizeModal();
    }

    if (initial) {
      setHasOptimizationError(false);
    }

    getProductivityGoal();

    // run once then poll
    getBusyDay();
    startBusyDayPolling();
  }, []);

  useEffect(() => {
    whatBannerShouldDisplay()
    if (isBusyDay) {
      stopBusyDayPolling()

      // run once then poll
      getCentralSortingStatus()
      startCentralSortingPolling()
    }

    if (isCentralSortingDone) {
      stopCentralSortingPolling()
      // get the latest shipment status
      reloadShipments()
    }
  }, [isBusyDay, isCentralSortingDone])

  useEffect(() => {
    startPolling();
    return () => stopPolling();
  }, [selectedSubcoId]);

  useEffect(() => {
    fetchToursStatus();
  }, [selectedSubcoId]);

  useEffect(() => {
    setBoardState({
      ...boardState,
      overflowed: hasOverflowed(),
    });
  }, [toursWindow]);

  useEffect(() => () => clearInterval(boardState.cutoffIntervalId), []);

  useEffect(() => {
    const container = document.getElementById("main-container");
    if (container) {
      container.style.padding = "0";
      return () => {
        container.style.padding = "40px";
      };
    }
  }, []);

  const hasOverflowed = () => {
    return (
      !!toursWindow.current &&
      toursWindow.current.scrollWidth > toursWindow.current.clientWidth
    );
  };

  const getScrollWidth = () => {
    return get(toursWindow.current, "scrollWidth", 0);
  };
  useEffect(() => {
    fetchTourSortingStatus();
  }, []);

  const onShowFullTour = (
    tourId: number,
    toggleState: boolean,
    tourNo: number
  ) => {
    if (!toggleState) {
      setBoardState((prevState) => ({
        ...prevState,
        currentTourNo: undefined,
        currentTour: prevState.currentTour.filter((id) => id !== tourId),
      }));
      return;
    }
    setBoardState((prevState) => ({
      ...prevState,
      currentTourNo: tourNo,
      currentTour: [...prevState.currentTour, tourId],
    }));
  };

  const calculateScrollPosition = (left: boolean) => {
    const room = getScrollWidth();
    const { current: elmScroll } = toursWindow;
    const { scrollLeft, offsetWidth } = elmScroll;
    const stepSize = 392;
    const max = room - offsetWidth;

    let sum;

    if (left) {
      if (scrollLeft > 0) {
        sum = scrollLeft - stepSize;
      } else {
        sum = 0;
      }
    }
    if (!left) {
      if (scrollLeft < max) {
        sum = scrollLeft + stepSize;
      } else {
        sum = max;
      }
    }

    elmScroll.scrollLeft = sum;
  };

  const handleClickLeft = () => {
    calculateScrollPosition(true);
  };

  const handleClickRight = () => {
    calculateScrollPosition(false);
  };

  const changeMade = () => setBoardState({ ...boardState, changes: true });

  const runOptimization = async () => {
    closeOptimizationModal();
    await assignShipmentTours();

    setBoardState({
      ...boardState,
      initial: false,
      reload: false,
      newShipments: false,
    });

    // check if the banner is still valid
    whatBannerShouldDisplay()
  };

  const storeChanges = async (lock?: boolean) => {
    await saveTours(lock);
    setBoardState((prevState) => ({
      ...prevState,
      currentTourNo: undefined,
      currentTour: [],
    }));

    // check if the banner is still valid
    whatBannerShouldDisplay()
  };

  const openOptimizeModal = () => showOptimizeModal();

  const closeWarning = () =>
    setBoardState({ ...boardState, showWarning: false });

  const showOptimizeModal = () => {
    setBoardState({ ...boardState, showWarning: false });
    dispatch(uiAction.set({ optimizeModal: true }));
  };

  const resetEditMode = () => {
    setEditMode(null);
  };

  const setTourEditMode = (tourId: number) => {
    setEditMode(tourId);
  };

  const toggleMarkTourConfirmation = () => {
    setBoardState({
      ...boardState,
      showMarkTourConfirmation: boardState.showMarkTourConfirmation,
    });
  };

  const markTourAsPlanned =
    (tour: ITour, toUndo = false) =>
    () => {
      const { markedTours, isDisableMarkTourConfirmationChecked } = boardState;
      let nextValues: ITour[] = [];

      if (toUndo) {
        nextValues = markedTours.filter(
          (markedTour: ITour) => markedTour.id !== tour.id
        );
      } else {
        if (
          !markedTours.some((markedTour: ITour) => markedTour.id === tour.id)
        ) {
          nextValues = [...markedTours, tour];
        }
      }
      if (isDisableMarkTourConfirmationChecked) {
        dontAskForMarkTourConfirmation();
      }

      setBoardState({
        ...boardState,
        markedTours: nextValues,
        showMarkTourConfirmation: false,
      });
    };

  const handleMarkTourAsPlanned =
    (tour: ITour, toUndo: boolean = false) =>
    () => {
      const { disableMarkTourConfirmation } = profile;
      if (disableMarkTourConfirmation || toUndo) {
        markTourAsPlanned(tour, toUndo)();
      } else {
        setTourForConfirmation(tour);
        toggleMarkTourConfirmation();
      }
    };

  const dontAskForMarkTourConfirmation = () => {
    const { disableMarkTourConfirmation } = route;
    disableMarkTourConfirmation();
  };

  const setTourForConfirmation = (tour: ITour) => {
    setBoardState({ ...boardState, tourForConfirmation: tour });
  };

  const closeOptimizationModal = () =>
    dispatch(uiAction.set({ optimizeModal: false }));

  const optimizeModalVisible = useSelector(fromUI.isOptimizeModalShown);
  const numberOfShipments = useSelector(
    fromShipments.getNumberOfNonCancelledShipments
  );

  const date = moment().locale(getLocale()).format("DD MMMM YYYY, dddd");

  if (editTour) {
    return (
      <EditModal
        tour={editTour}
        tours={tours}
        drivers={drivers}
        handoverStarted={handoverStarted}
        onSetDriver={setDriver}
        onNewRoute={addTour}
        onClose={resetEditMode}
      />
    );
  }

  return (
    <>
      {!!tours.length ? (
        <Box className={clsx(styles.grow, styles["h-100"])}>
          <Grid container className={styles["h-100"]}>
            <PlannerStore>
              <Grid
                className={styles["board-list-wrapper"]}
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                xl={5}
              >
                <div>
                  <TourToolbar
                    areAllShipmentsInTours={
                      vrpShipments.length === shipmentIdsWithCollations.length
                    }
                    isLocked={areToursLocked}
                    saveTours={storeChanges}
                    saveDrivers={saveDrivers}
                    optimizeTours={openOptimizeModal}
                    overflowed={boardState.overflowed}
                    handleClickRight={handleClickRight}
                    handleClickLeft={handleClickLeft}
                    isPlanningLocked={isPlanningLocked}
                    openOptimizeModal={openOptimizeModal}
                    areToursSorted={areToursSorted}
                    tourPageState={tourPageState}
                    setTourPageState={setTourPageState}
                  />
                  <div className={styles["notifications-wrapper"]}>
                    <OptimizeWarning
                      close={closeWarning}
                      continue={showOptimizeModal}
                      show={boardState.showWarning}
                    />
                  </div>
                </div>
                <Box className={styles.customBox}>
                  <Box
                    id="board-list-container"
                    className={styles.boardListContainer}
                  >
                    <TourList
                      areToursLocked={areToursLocked}
                      activeTour={boardState.currentTourNo}
                      tours={tours}
                      drivers={drivers}
                      addRoute={get(route, "handleNewTour")}
                      changeMade={changeMade}
                      setEditMode={setEditMode}
                      onShowFullTour={onShowFullTour}
                      markTourAsPlanned={handleMarkTourAsPlanned}
                      isPlanningLocked={isPlanningLocked}
                      markedTours={markedTours}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={12} xs={12} md={6} lg={6} xl={7}>
                <Map
                  editMode={false}
                  showToolbar={false}
                  tourShown={boardState.currentTour}
                />
              </Grid>
            </PlannerStore>
          </Grid>
        </Box>
      ) : (
        <div className={styles.noToursContent}>
          <img src={noTourIcon} />
          <h1>{t("NO_TOURS_POWER_TEXT")}</h1>
          <span className={styles.body}>{t("NO_TOURS_POWER_BODY")}</span>
          <Button
            className={styles.button}
            type="info"
            label={t("CREATE_TOURS")}
            onClick={openOptimizeModal}
          />
        </div>
      )}
      <OptimizationModal
        visible={optimizeModalVisible}
        onClose={closeOptimizationModal}
        onOptimize={runOptimization}
        shipmentCount={numberOfShipments}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  areToursSorted: fromTours.getAreToursSorted(state),
  vrpShipments: fromShipments.getVRPShipments(state),
  handoverStarted: fromShipments.hasHandoverStarted(state),
  tours: fromCombined.getShipmentTours(state),
  subcontractor: fromProfile.getSdkSubco(state),
  departureTime: fromProfile.getDepartureTime(state),
  hasTours: fromTours.hasTours(state),
  editTour: fromCombined.getEditTour(state),
  drivers: fromDrivers.getDrivers(state),
  areToursLocked: fromCombined.areToursLocked(state),
  shipmentIdsWithCollations: fromCombined.getShipmentIdsWithCollations(state),
  shipments: fromShipments.getIdentifiers(state),
  productivityGoal: fromTours.getProductivityGoal(state),
  regionProductivity: fromCombined.getRegionProductivity(state),
  estimatedAmountOfShipments:
    fromShipmentAndDriverEstimation.getShipments(state),
  estimatedDriversNeeded: fromShipmentAndDriverEstimation.getDrivers(state),
  isPlanningLocked: fromTours.getIsPlanningLocked(state),
  selectedSubcoId: get(fromProfile.getSubco(state), "id"),
  remainingTimeForLockScreen:
    fromShipmentAndDriverEstimation.getRemaining(state),
  isSubco: fromProfile.getIsSubco(state),
  isPlanner: fromProfile.getIsPlanner(state),
  tourPageState: fromTours.getTourPageState(state),
  hasOptimizationError: fromUI.getHasOptimizationError(state),
  isBusyDay: fromWarehouse.getIsBusyDay(state),
  isCentralSortingDone: fromWarehouse.getIsCentralSortingDone(state),
});

const mapDispatchToProps = {
  setDriver: tourActions.setDriver,
  setTourPageState: tourActions.setTourPageState,
  setTours: tourActions.setTours,
  setEditMode: tourActions.setEditMode,
  saveTours: tourActions.saveTours,
  saveDrivers: tourActions.saveDrivers,
  assignShipmentTours: shipmentsAction.assignToTours,
  toggleOptimizeModal: uiAction.toggleOptimizeModal,
  getProductivityGoal: tourActions.getProductivityGoal,
  fetchToursStatus: tourActions.fetchToursStatus,
  fetchTourSortingStatus: tourActions.fetchTourSortingStatus,
  setHasOptimizationError: uiAction.setHasOptimizationError,
  getBusyDay: warehouseActions.getBusyDay,
  getCentralSortingStatus: warehouseActions.getCentralSortingStatus,
  whatBannerShouldDisplay: warehouseActions.whatBannerShouldDisplay,
  reloadShipments: shipmentsAction.reloadShipments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Board);
