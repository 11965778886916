import {PDFDocument} from "pdf-lib";

export const mergePDFBinaryFiles = async (files: ArrayBuffer[]) => {
  const pdfDoc = await PDFDocument.create()

  for (const file of files){
    if(file){
      const doc = await PDFDocument.load(file)
      const pages = await pdfDoc.copyPages(doc, doc.getPageIndices())
      pages.forEach(page => pdfDoc.addPage(page))
    }
  }

  return await pdfDoc.save()
}
