import React from 'react'
import PropTypes from 'prop-types'

import { withFormik, Form, Field } from 'formik'

import styles from './VerifyCodeForm.module.scss'
import Translate from '../Translate'
import { Button } from '../common/Button/Button'
import DigitField from './DigitField'

const isFilledField = value =>
  (!value || !value.match(/[0-9]{6}/)) && 'not valid'

const FormikNumberField = ({
  field: { name, ...field },
  form: { setFieldValue, touched, errors },
  ...passed
}) => {
  const { [name]: isFieldTouched } = touched
  const { [name]: errorText } = errors

  return (
    <DigitField
      name={name}
      errorText={isFieldTouched && errorText}
      {...field}
      onChange={value => setFieldValue(name, value)}
      {...passed}
    />
  )
}

const VerifyCodeForm = ({
  disabled,
  isValid,
  values: { digits },
  submitForm,
}) => {
  React.useEffect(() => {
    if (isValid) submitForm()
  }, [digits, isValid])

  return (
    <Form
      autocomplete="off"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Field
        disabled={disabled}
        name="digits"
        component={FormikNumberField}
        placeholder="000000"
        validate={isFilledField}
      />

      <Button
        className={styles.button}
        label={
          <Translate capitalize style={{ color: 'white' }}>
            LOGIN
          </Translate>
        }
        disabled={!isValid || disabled}
      />
    </Form>
  )
}

VerifyCodeForm.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
}

VerifyCodeForm.defaultProps = {
  disabled: false,
  onSubmit: () => null,
}

export default withFormik({
  handleSubmit: ({ digits }, { props: { onSubmit } }) => onSubmit(digits),
})(VerifyCodeForm)
