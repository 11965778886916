import React, {memo,  useMemo} from 'react'
import moment from 'moment'
import get from 'lodash/get'
import {TableBody, TableHeader, TableRow} from "material-ui/Table";
import TableCell from "@material-ui/core/TableCell/TableCell"
import Table from "@material-ui/core/Table/Table";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import {setDriver, setVehicleType} from '../../../../../actions/tours'

import {ITour, ICollation} from "../../@types";

import {SimpleModalBox} from "../../../../common";

import {translate as t} from "../../../../../utils";

import styles from './styles.module.scss'

interface ITourStopsTableProps {
  visible: boolean
  onClose: () => void
  tour?: ITour
}

const TourStopsModal = ({tour, ...props}: ITourStopsTableProps) => {
  const tourNo = get(tour, 'number', '')
  const collations = (get(tour, 'collations', []) as Array<ICollation>).filter(({state}: ICollation) => !!state)

  return (
    <SimpleModalBox {...props}>
      <div className={styles.wrapper}>
        <div className={styles["header-container"]}>
          <span className={styles.header}>{`${t('LITE_TOURS_TABLE_TOUR')} ${tourNo}`}</span>
        </div>
        <div className={styles["main-content"]}>
          <OverlayScrollbarsComponent>
            <TableContainer className={styles["table-container"]}>
              <Table
                stickyHeader
              >
                <TableHeader
                  adjustForCheckbox={false}
                  displaySelectAll={false}
                >
                  <TableRow className={styles["table-header-row"]}>
                    <TableCell>
                      {t('LITE_TOUR_STOP_TABLE_STOP')}
                    </TableCell>
                    <TableCell>
                      {t('LITE_TOUR_STOP_TABLE_STREET')}
                    </TableCell>
                    <TableCell>
                      {t('LITE_TOUR_STOP_TABLE_POSTAL_CODE')}
                    </TableCell>
                    <TableCell >
                      {t('LITE_TOUR_STOP_TABLE_CITY')}
                    </TableCell>
                    <TableCell>
                      {t('LITE_TOUR_STOP_TABLE_COLLI_COUNT')}
                    </TableCell>
                    <TableCell>
                      {t('LITE_TOUR_STOP_TABLE_ETA')}
                    </TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false} showRowHover={false}>
                  {
                    useMemo(() => collations.map(({position, recipient: {location: {address, postalCode, city}}, shipmentIds: {length: colli}, estimation}) => {
                      return (
                        <TableRow className={styles["table-row"]}>
                          <TableCell>
                            {position}
                          </TableCell>
                          <TableCell>
                            {address}
                          </TableCell>
                          <TableCell>
                            {postalCode}
                          </TableCell>
                          <TableCell>
                            {city}
                          </TableCell>
                          <TableCell>
                            {colli}
                          </TableCell>
                          <TableCell>
                            {moment(estimation).format('HH:mm')}
                          </TableCell>
                        </TableRow>
                      )
                    }), [collations])
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </OverlayScrollbarsComponent>
        </div>
      </div>
    </SimpleModalBox>
  )
}

export default memo(TourStopsModal)
