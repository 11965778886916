import React, {memo} from 'react'
import {useSelector} from "react-redux";
import styles from './styles.module.scss'
import {PercentageCard} from "../../../../base";
import {useTranslate} from "../../../../../utils";
import {PERCENTAGE_CARD_TYPES} from "../../../../base/PercentageCard";
import * as fromCombined from "../../../../../selectors/combined";

const ShipmentStateDashboard = () => {
  const {t} = useTranslate('SHIPMENTS_OVERVIEW')

  const shipmentsSortedAtRegionalHub = useSelector(fromCombined.getShipmentsSortedAtRegionalHub)
  const shipmentsNotSortedAtRegionalHub = useSelector(fromCombined.getShipmentsNotSortedAtRegionalHub)
  const shipmentsAcceptedByDrivers = useSelector(fromCombined.getShipmentsAcceptedByDrivers)
  const shipmentsNotAcceptedByDrivers = useSelector(fromCombined.getShipmentsNotAcceptedByDrivers)
  const shipmentsWithEndState = useSelector(fromCombined.getShipmentsWithEndState)
  const shipmentsWithoutEndState = useSelector(fromCombined.getShipmentsWithoutEndState)
  const shipmentsDeliveredToNeighbour = useSelector(fromCombined.getShipmentsDeliveredToNeighbour)
  const shipmentsNotDelivered = useSelector(fromCombined.getShipmentsNotDelivered)

  return (
    <div className={styles.container}>
      <PercentageCard
        caption={t('SHIPMENTS_SORTED_AT_REGIONAL_HUB')}
        details={t('OUT_OF_MAXCOUNT', {count: shipmentsSortedAtRegionalHub.length.toString(), maxCount: (shipmentsSortedAtRegionalHub.length + shipmentsNotSortedAtRegionalHub.length).toString()})}
        percentage={(shipmentsSortedAtRegionalHub.length/(shipmentsSortedAtRegionalHub.length + shipmentsNotSortedAtRegionalHub.length))}
        variant={PERCENTAGE_CARD_TYPES.CENTRAL_SORTED}
      />
      <PercentageCard
        caption={t('SHIPMENTS_ACCEPTED_BY_DRIVERS')}
        details={t('OUT_OF_MAXCOUNT', {count: shipmentsAcceptedByDrivers.length.toString(), maxCount: (shipmentsAcceptedByDrivers.length + shipmentsNotAcceptedByDrivers.length).toString()})}
        percentage={(shipmentsAcceptedByDrivers.length/(shipmentsAcceptedByDrivers.length + shipmentsNotAcceptedByDrivers.length))}
        variant={PERCENTAGE_CARD_TYPES.ACCEPTED_BY_DRIVER}
      />
      <PercentageCard
        caption={t('SHIPMENTS_WITH_END_STATE')}
        details={t('OUT_OF_MAXCOUNT', {count: shipmentsWithEndState.length.toString(), maxCount: (shipmentsWithEndState.length + shipmentsWithoutEndState.length).toString()})}
        percentage={(shipmentsWithEndState.length/(shipmentsWithEndState.length + shipmentsWithoutEndState.length))}
        variant={PERCENTAGE_CARD_TYPES.WITH_END_STATE}
      />
      <PercentageCard
        caption={t('NEIGHBOUR_DELIVERY_VS_NON_DELIVERY')}
        details={`${shipmentsDeliveredToNeighbour.length} : ${shipmentsNotDelivered.length}`}
        percentage={(shipmentsDeliveredToNeighbour.length/(shipmentsDeliveredToNeighbour.length + shipmentsNotDelivered.length))}
        variant={PERCENTAGE_CARD_TYPES.NEIGHBOUR_VS_NON_DELIVERY}
      />
    </div>
  )
}

export default memo(ShipmentStateDashboard)
