import React from 'react'
import clsx from 'clsx'

import withStyles from '@material-ui/core/styles/withStyles'

import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import styles from './Dropdown.module.scss'


const DropdownItem = ({
  value,
  label,
  disabled,
  className,
  overrideSelectColor = false,
  selected,
  key,
  onClick,
}) => {
  return (
    <MenuItem
      disabled={disabled}
      onClick={onClick}
      className={clsx({
        [styles.selected]: selected && overrideSelectColor,
        [styles.menuHover]: !overrideSelectColor,
      }, className)}
      data-value={value}
      data-label={label}
      key={key}
      selected={selected}
    >
      {label}
    </MenuItem>
  )
}

DropdownItem.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  key: PropTypes.any,
  onClick: PropTypes.func,
}

DropdownItem.defaultProps = {
  value: null,
  label: null,
  key: null,
  className: undefined,
  onClick: null,
  disabled: false,
  selected: false,
}

export default withStyles(styles)(DropdownItem)
