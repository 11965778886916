import React, {memo, useCallback, useEffect, useRef, useState} from 'react'
import get from 'lodash/get'
import clsx from "clsx";

import styles from './styles.module.scss'
import Icon from "../Icon";
import drop from "lodash/drop";
import {Button} from "../index";

enum MINITOAST_TYPES {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning'
}

export interface IMiniToastProps extends React.DetailedHTMLProps<any, any>{
  variant?: MINITOAST_TYPES|string
  visible?: boolean
  onClose: () => void
}

const MiniToast = ({variant = MINITOAST_TYPES.DEFAULT, visible, onClose, className, children, ...props}: IMiniToastProps) => {
  const icons = {
    [MINITOAST_TYPES.PRIMARY] : <Icon name="info-circle" />,
    [MINITOAST_TYPES.SUCCESS] : <Icon name="check-circle" />,
    [MINITOAST_TYPES.DANGER] : <Icon name="danger" />,
    [MINITOAST_TYPES.WARNING] : <Icon name="warning" />
  }

  const icon = get(icons, variant);

  const [isExpanded, setIsExpanded] = useState(false)
  const handleToggleClick = () => setIsExpanded(!isExpanded)

  const contents = React.Children.toArray(children)

  return visible ? (
    <div {...props} className={clsx(styles.miniToast, get(styles, variant), className)}>
      <div>
        {icon}
      </div>
      <div>
        {
          contents.length > 1 ? (
            <div className={styles.detailsContainer}>
              <div>
                <div className={styles.headerContainer}>
                  <div className={styles.title}>
                    {get(contents, '[0]')}
                  </div>
                </div>
                <div className={clsx(styles.subDetails, isExpanded && styles.expanded)}>
                  {
                    drop(contents)
                  }
                </div>
              </div>
              <div>
                <div className={clsx(styles.toggleButton, isExpanded && styles.expanded)}>
                  <div onClick={handleToggleClick}>{isExpanded ? <Icon name="chevron-down"/> : 'More Info'}</div>
                </div>
                <span className={styles.closeButton} onClick={onClose} ><Icon name="close"/></span>
              </div>
            </div>
          ) : get(contents, '[0]')
        }
      </div>
    </div>
  ) : null
}

export default memo(MiniToast)
