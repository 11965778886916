import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as toursActions from '../../actions/tours'

import SortableListComponent from './BoardList/components/SortableListComponent'

class BoardList extends PureComponent {
  handleChangePosition = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return

    const { tour, changePosition } = this.props
    changePosition(tour.id, oldIndex, newIndex)
  }

  handleMoveCollations = (oldTour, collation, newTour) => {
    const { moveCollation } = this.props
    moveCollation(oldTour.id, newTour.id, collation.id)
  }

  handleNewTour = (collation) => {
    const { createTour } = this.props

    createTour([collation.id])
  }

  handleSetEditMode = (id) => {
    const { setEditMode, resetEditMode } = this.props

    if (this.props.editMode) {
      resetEditMode()
    } else {
      setEditMode(id)
    }
  }

  render() {
    const {
      drivers, tour, tours, isMarkAsPlanned, editMode, scroll, onShowFullTour, setDriver,
      reverseTour, optimizeTour, locked, saveTourColor, setVehicleType, isPlanningLocked, index,
    } = this.props


    return (
      <SortableListComponent
        locked={locked}
        ref={`list_${tour.id}`}
        index={index}
        items={tour.collations}
        drivers={drivers}
        tours={tours}
        tour={tour}
        editMode={editMode}
        isMarkAsPlanned={isMarkAsPlanned}
        isPlanningLocked={isPlanningLocked}
        container={scroll}
        onShowFullTour={onShowFullTour}
        onChangePosition={this.handleChangePosition}
        onMoveCollations={this.handleMoveCollations}
        onSetEditMode={this.handleSetEditMode}
        onNewTour={this.handleNewTour}
        onReverseTour={reverseTour}
        onOptimizeTour={optimizeTour}
        onChangeDriver={setDriver}
        onChangeVehicleType={setVehicleType}
        saveTourColor={saveTourColor}
      />
    )
  }
}

const mapDispatchToProps = {
  setDriver: toursActions.setDriver,
  setVehicleType: toursActions.setVehicleType,
  moveCollation: toursActions.moveSingle,
  createTour: toursActions.addTour,
  reverseTour: toursActions.reverseTour,
  optimizeTour: toursActions.optimizeTour,
  changePosition: toursActions.changeCollationPosition,
  saveTourColor: toursActions.saveTourColor,
}

BoardList.propTypes = {
  tour: PropTypes.object,
  index: PropTypes.number,
  color: PropTypes.string,
  changePosition: PropTypes.func,
  moveCollation: PropTypes.func,
  createTour: PropTypes.func,
  setEditMode: PropTypes.func,
  resetEditMode: PropTypes.func,
  editMode: PropTypes.string,
  drivers: PropTypes.array,
  tours: PropTypes.array,
  isMarkAsPlanned: PropTypes.bool,
  scroll: PropTypes.bool,
  reverseTour: PropTypes.func,
  optimizeTour: PropTypes.func,
  setDriver: PropTypes.func,
  locked: PropTypes.bool,
  saveTourColor: PropTypes.func,
  setVehicleType: PropTypes.func,
  onShowFullTour: PropTypes.func,
  isPlanningLocked: PropTypes.bool,
  addRoute: PropTypes.func,
  changeMade: PropTypes.func,
  markTourAsPlanned: PropTypes.func
}

BoardList.defaultProps = {
  tour: [],
  editMode: null,
  drivers: [],
  tours: [],
  isMarkAsPlanned: false,
  scroll: false,
  locked: false,
  isPlanningLocked: false,
}

export default connect(null, mapDispatchToProps)(BoardList)
