import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { browserHistory } from '../../browserHistory'
import FlatButton from 'material-ui/FlatButton'
import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton'
import FontIcon from 'material-ui/FontIcon'

import Translate from '../Translate'
import { translate } from '../../utils/translate'

class ChangePassword extends PureComponent {
  constructor(props) {
    super(props)
    const subco = this.props.profile.subco.location
    this.state = {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmed: '',
      active: false,
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  showToast(title, msg) {
    const [transTitle, transMessage] = translate([title, msg])
    toastr.error(transTitle, transMessage)
  }

  handleSubmit(e) {
    const { oldPassword, newPassword, newPasswordConfirmed } = this.state
    this.setState({ active: true })
    if(!oldPassword || newPassword.length < 8) {
      this.showToast('PASSWORD_TOO_SHORT', 'PASSWORD_TOO_SHORT_MSG')
      this.setState({ active: false })
      return false
    }
    if(newPassword !== newPasswordConfirmed) {
      this.showToast('PASSWORDS_DONT_MATCH', 'PASSWORDS_DONT_MATCH_MSG')
      this.setState({ active: false })
      return false
    }

    this.props.editPassword(oldPassword, newPassword)
      .then((result) => {
        browserHistory.push('/profile/pass')
      }, (err) => {
        this.showToast('WRONG_PASSWORD', 'WRONG_PASSWORD_MSG')
        this.setState({ active: false })
      })
  }

  render() {

    return (

      <div>
        <h1>
          <Translate capitalize={true}>CHANGE_PASSWORD</Translate>
        </h1>
        <div style={{ width: '100%', maxWidth: 450 }}>
          <TextField
            style={{ width: '100%' }}
            hintText={<Translate capitalize={true}>CURRENT_PASSWORD</Translate>}
            floatingLabelText={<Translate capitalize={true}>CURRENT_PASSWORD</Translate>}
            value={this.state.oldPassword}
            type="password"
            name="oldPassword"
            onChange={this.handleChange.bind(this)}
          />
          <TextField
            style={{ width: '47%', marginRight: '3%' }}
            hintText={<Translate capitalize={true}>NEW_PASSWORD</Translate>}
            floatingLabelText={<Translate capitalize={true}>NEW_PASSWORD</Translate>}
            value={this.state.newPassword}
            type="password"
            name="newPassword"
            onChange={this.handleChange.bind(this)}
          />
          <TextField
            style={{ width: '50%' }}
            hintText={<Translate capitalize={true}>CONFIRM_NEW_PASSWORD</Translate>}
            floatingLabelText={<Translate capitalize={true}>CONFIRM_NEW_PASSWORD</Translate>}
            value={this.state.newPasswordConfirmed}
            type="password"
            name="newPasswordConfirmed"
            onChange={this.handleChange.bind(this)}
          />
          <div style={{ height: 30 }}></div>

          <RaisedButton
            secondary={true}
            disabled={this.state.active}
            label={<Translate uppercase={true}>SAVE</Translate>}
            icon={<FontIcon className="material-icons">save</FontIcon>}
            onClick={this.handleSubmit.bind(this)}/>
          <FlatButton
            style={{ marginLeft: 12 }}
            label={<Translate uppercase={true}>CANCEL</Translate>}
            onClick={() => browserHistory.push('/edit-profile')}/>
          <div style={{ height: 60 }}></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
})

export default connect(mapStateToProps, null)(ChangePassword)
