import React, {memo} from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import {IconLibrary} from "./library";

const Icon = memo(({name, className, disabled, ...props}: {name: string} & React.DetailedHTMLProps<any, any>) => {
  const Icon = IconLibrary[name]
  return <Icon className={clsx(className, styles.icon, disabled && styles.disabled, props.onClick && styles.clickable)} {...props} />
})

Icon.displayName = 'Icon'

export default Icon
