import {createSelector} from "reselect";
import {IPlannerState} from "./state";
import get from 'lodash/get'

export const getTourVisibility = createSelector(
  (state: IPlannerState) => state.tourVisibility,
  tourVisibility => tourVisibility
)

export const getHiddenTours = createSelector(
  (state: IPlannerState) => state.tourVisibility,
  tourVisibility => Object.keys(tourVisibility).filter(key => !get(tourVisibility, key, true))
)
