import React, {memo} from 'react'
import clsx from 'clsx'
import get from 'lodash/get'

import styles from './styles.module.scss'

enum BADGE_TYPES {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning'
}

const Badge = ({children, className, variant = BADGE_TYPES.DANGER, ...props}: React.DetailedHTMLProps<any, any> & {variant?: BADGE_TYPES|string}) => {
  let badgeText = children

  if(typeof children == 'number') {
    badgeText = ("0" + children).substr((children.toString().length + 1) === 2 ? 0 : 1, children.toString().length + 1)
  }

  return(
    <div {...props} className={clsx(styles.badge, get(styles, variant), !parseInt(children) && styles.hidden)}>
      {badgeText}
    </div>
  )
}

export default memo(Badge)
