import React, {memo, ReactElement, useEffect} from "react";
import {useForm, FormProvider, UseFormOptions, UnpackNestedValue, UseFormMethods} from "react-hook-form";

interface ISimpleFormProps<T> extends UseFormOptions<T> {
  onSubmit: (formValues: UnpackNestedValue<T>) => void
  onLoad?: (formMethods: UseFormMethods<T>) => void
  onChange?: () => void
  onClick?: () => void
  children: ReactElement | ReactElement[]
  defaultValues: any
}

function SimpleForm<T>({ children, onSubmit, onLoad, ...props } : ISimpleFormProps<T>) {
  const methods = useForm<T>({...props, reValidateMode: 'onBlur' });

  useEffect(() => {
    onLoad && onLoad(methods)
  }, [onLoad, methods])

  return (
    <FormProvider {...methods}>
      <form {...props} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}

export default memo(SimpleForm) as typeof SimpleForm
