import { ADD_ARRIVAL } from '../constants/ActionTypes'

const initialState = {}

export default function times(state = initialState, action) {
  switch (action.type) {
  case ADD_ARRIVAL:
    return {
      ...state,
      [action.payload.Id]: action.payload.ETA,
    }

  default:
    return state
  }
}
