import React from 'react'
import PropTypes from 'prop-types'

import { Formik, Form, Field } from 'formik'

import PhoneNumber from './components/PhoneNumber'
import { Button } from '../common/Button/Button'

import { translate } from '../../utils/translate'
import { validatePhoneNumber } from '../../utils/validators'

import styles from './PhoneNumberPhone.module.scss'

const phoneNumberValidator = validatePhoneNumber()

const FormikPhoneField = ({ field, form, ...passed }) => (
  <PhoneNumber {...field} {...passed} form={form} />
)

const PhoneNumberForm = ({
  className,
  disabled,
  initialNumber,
  onSubmit,
  setErrorMessage,
}) => {
  const handleSubmit = ({ phoneNumber }) => onSubmit(phoneNumber)

  const handleValidation = value => {
    const error = validatePhoneNumber()(value.phoneNumber)
    if (!error) {
      setErrorMessage('')
    } else {
      setErrorMessage(error)
    }
  }

  return (
    <Formik
      initialValues={{ phoneNumber: initialNumber }}
      onSubmit={handleSubmit}
      validate={handleValidation}
    >
      {({ isValid, errors }) => (
        <Form className={className}>
          <Field
            name="phoneNumber"
            component={FormikPhoneField}
            validate={phoneNumberValidator}
            error={errors.phoneNumber}
            disabled={disabled}
          />

          <Button
            className={styles.button}
            label={translate('SEND_CODE')}
            disabled={!isValid || disabled}
          />
        </Form>
      )}
    </Formik>
  )
}

PhoneNumberForm.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  initialNumber: PropTypes.string,
  onSubmit: PropTypes.func,
}

PhoneNumberForm.defaultProps = {
  className: null,
  disabled: false,
  initialNumber: '',
  onSubmit: () => null,
  setErrorMessage: () => null,
}

export default PhoneNumberForm
