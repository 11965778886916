import React, { useCallback, useState, useMemo } from 'react'
import Polyline from 'react-google-maps/lib/components/Polyline'
import { decode } from 'google-polyline'
import { ITour } from '../../@models/tour'


export interface IDirectionsProps {
    tour: ITour
}

export const Directions = ({
    tour
}: IDirectionsProps) => {
    const { color, collations } = tour
    const mapToLatLngLiteral = (item: any) => item && ({
        lat: item[0],
        lng: item[1],
    })

    return (
        <>
            {
                useMemo(() => 
                collations.map(collation => !!collation.polyline && 
                    <Polyline
                        key={collation.id}
                        path={decode(collation.polyline).map(mapToLatLngLiteral)}
                        options={{
                        strokeColor: color,
                        strokeOpacity: 0.5,
                        }}
                    />
                )
                ,[tour, mapToLatLngLiteral])
            }
        </>
    )
}

export default Directions