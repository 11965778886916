import React, { PureComponent } from 'react'
import { TableRow, TableRowColumn } from 'material-ui'
import Translate from '../Translate'
import { isLaterThanProcessed, isCancelled, isDataReceived, isSorted } from '../../utils/state-checker'
import ShipmentStateLabel from './ShipmentStateLabel'

class ShipmentRow extends PureComponent {
  render() {
    const { shipment } = this.props
    let rowStyle = {}
    if(isLaterThanProcessed(shipment.State) && !isSorted(shipment.State)) {
      rowStyle = {
        ...rowStyle,
        opacity: !isCancelled(shipment.State) ? '.3' : '1',
        pointerEvents: 'none',
      }
    }
    if(isDataReceived(shipment.State)) {
      rowStyle = {
        ...rowStyle,
        backgroundColor: '#ffe494',
      }
    }
    return (
      <TableRow
        className="table-body-row"
        style={rowStyle}>
        <TableRowColumn className="table-body-column" style={{ width: 90, paddingRight: 5 }}>
          {shipment.TrunkrsNr}
        </TableRowColumn>
        <TableRowColumn className="table-body-column" style={{ width: 90, paddingRight: 5 }}>
          {shipment.Recipient.PostalCode}
        </TableRowColumn>
        <TableRowColumn className="table-body-column" style={{ width: 200 }}>
          {shipment.Recipient.Address} {shipment.Recipient.HouseNumber}
        </TableRowColumn>
        <TableRowColumn className="table-body-column" style={{ maxWidth: 150 }}>
          {shipment.Recipient.City}
        </TableRowColumn>
        <TableRowColumn className="table-body-column" style={{ maxWidth: 150 }}>
          {shipment.Route || <Translate>NOT_PLANNED_YET</Translate>}
        </TableRowColumn>
        <TableRowColumn className="table-body-column" style={{ width: 65 }}>
          <ShipmentStateLabel shipment={shipment} />
        </TableRowColumn>
      </TableRow>
    )
  }
}

export default ShipmentRow
