import { store, trunkrs } from '../'
import get from 'lodash/get'
import * as Sentry from "@sentry/browser";
import {Dispatch} from "redux";
import * as actionTypes from "../constants/ActionTypes";
import * as UIActions from '../actions/ui'
import * as fromProfile from '../reducers/profile'

export interface ICreateUserParams {
  name: string
  phoneNumber: string
  subcontractorId?: number
  picture?: string
  invoiceSubcontractorId?: number
  isPlanner?: boolean
  userPreferences? :{
    [key: string]: any
  }
}

export const createUser = async (payload: ICreateUserParams) => {
  try {
      return await trunkrs.Driver().createDriver(payload)
  }
  catch (err){
    console.error(err)
    Sentry.captureException(err)

    return {
      isError: true,
      message: err.message
    }
  }
}

export const reloadUsers = (response: any) => (dispatch: Dispatch) => {
  const driver = trunkrs.Driver()
  const newDriver = new driver(
    get(response, 'id'),
    get(response, 'name'),
    get(response, 'emailAddress'),
    get(response, 'phoneNumber'),
    undefined,
    get(response, 'subcontractorId'),
    true,
    undefined,
    response.isPlanner
  )

  dispatch({
    type: actionTypes.UPDATE_DRIVERS,
    payload: {
      Instance: newDriver,
      active: true,
      email: newDriver.getEmailAddress,
      id: newDriver.getId,
      name: newDriver.getName,
      phoneNumber: newDriver.getPhoneNumber,
      isPlanner: get(newDriver, 'isPlanner')
    }
  })
}



export const refetchDrivers = async(subcoId: number) => {
  const subcontractors: any = await trunkrs.Auth().fetchSubcoForUser()
  const currentSubco = subcontractors.find(s => s.id === subcoId)
  const rawDrivers = await Promise.all(await currentSubco.getDrivers)
  const drivers = rawDrivers.map((driver: any) => {
    return (
      {
        id: driver.id,
        name: driver.name,
        email: driver.getEmailAddress,
        phoneNumber: driver.getPhoneNumber,
        active: driver.active,
        picture: driver.picture,
        isPlanner: get(driver, 'isPlanner', false),
        isArchived: get(driver, 'isArchived', false),
        Instance: driver,
      }
    )
  })
  store.dispatch({ type: actionTypes.CHANGE_DRIVERS, payload: drivers })

}
  
    

export const editUser = async(driverId: number, payload: any) => {
  try {
    await trunkrs.Driver().editDriverInfo(driverId, payload)
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return {
      isError: true,
      message: error.message
    }
  }
}

export const moveDriverToDiffRegion = async(payload: {
  driverId: number, subcoId: number
}) => {
  store.dispatch(UIActions.setLoading(true));
  try {
    await trunkrs.Driver().moveDriver(payload)
    await refetchDrivers(payload.subcoId)
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return {
      isError: true
    }
  }
  finally {
    store.dispatch(UIActions.setLoading(false));
  }
}

export const searchDriverByNumber = async(phoneNumber: string) => {
  store.dispatch(UIActions.setLoading(true));
  try {
    const res = await trunkrs.Driver().findDriverByPhone(phoneNumber)
    if(!get(res, 'archived')) {
      return res
    }
    throw new Error('Driver is deleted/archived')
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return {
      isError: true
    }
  }
  finally {
    store.dispatch(UIActions.setLoading(false));
  }
}

export const updateDriverSuccess = (payload: any) => (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.UPDATE_USER_SUCCESS, payload })
}

export const toggleUserActive = (id: number, active: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.TOGGLE_USER_ACTIVE, payload: { id, active } })
}

