import React, {memo, useCallback, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import clsx from "clsx";
import get from 'lodash/get'
import defaultTo from 'lodash/defaultTo'
import {useTranslate} from "../../../utils";
import * as fromCombined from '../../../selectors/combined'
import {setLoading} from '../../../actions/ui'
import {reloadShipments} from '../../../actions/shipments'

import {
  Button, Icon,
  Tab, Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
} from "../../base";

import {FILTERS, IShipmentProfile} from "./@types";

import styles from './styles.module.scss'
import ShipmentStateBadge from "./components/ShipmentStateBadge";
import ShipmentStateDashboard from "./components/ShipmentStateDashboard";
import {environment} from "../../../constants";

import {createTestData} from "../../../utils/createTestData";
import {trunkrs} from "../../../index";
import ShipmentEmpty from "./components/ShipmentEmpty";

const ShipmentsOverview = () => {
  const {t} = useTranslate('SHIPMENTS_OVERVIEW')

  const dispatch = useDispatch()

  const [currentFilter, setCurrentFilter] = useState<FILTERS>(FILTERS.ALL_SHIPMENTS)

  const filterToAllShipments = useCallback(() => setCurrentFilter(FILTERS.ALL_SHIPMENTS), [])
  const filterToCentralSorted = useCallback(() => setCurrentFilter(FILTERS.CENTRAL_SORTED), [])
  const filterToCancelled = useCallback(() => setCurrentFilter(FILTERS.CANCELLED), [])

  const generateTestData = useCallback( () => {
    dispatch(setLoading(true))
    createTestData(trunkrs)
      .then(() => dispatch(reloadShipments()))
      .finally(() => dispatch(setLoading(false)))
  }, [trunkrs])

  const handleReloadShipments = useCallback(() => {
    dispatch(reloadShipments())
  }, [])

  const data = {
    [FILTERS.ALL_SHIPMENTS] : useSelector(fromCombined.getOverviewShipments),
    [FILTERS.CENTRAL_SORTED] : useSelector(fromCombined.getShipmentsCentralSorted),
    [FILTERS.CANCELLED] : useSelector(fromCombined.getShipmentsCancelled)
  } as {[key: string]: Array<IShipmentProfile>}

  const filteredData = data[currentFilter]

  return(
    <div>
      <div className={styles['header-container']}>
        <h1 className={styles.header}>{t('HEADER')}</h1>
        <div>
          {
            /staging|development/gi.test(environment) && <Button variant="success" onClick={generateTestData}>Create Test Data</Button>
          }
          <Button onClick={handleReloadShipments}><Icon name="retry" /> {t('REFRESH_RESULTS')}</Button>
        </div>
      </div>
      <ShipmentStateDashboard />
      <div className={styles["tab-container"]}>
        <div>
          <Tab active={currentFilter === FILTERS.ALL_SHIPMENTS} onClick={filterToAllShipments}>
            {t('FILTER_ALL_SHIPMENTS')}
          </Tab>
          <Tab active={currentFilter === FILTERS.CENTRAL_SORTED} onClick={filterToCentralSorted}>
            {t('FILTER_CENTRAL_SORTED')}
          </Tab>
          <Tab active={currentFilter === FILTERS.CANCELLED} onClick={filterToCancelled}>
            {t('FILTER_CANCELLED')}
          </Tab>
        </div>
        <div className={styles['result-count']}>
          {!!filteredData.length && t('RESULT_COUNT', {count: filteredData.length.toString()})}
        </div>
      </div>
      <Table className={styles['custom-table-style']}>
        {
          !!filteredData.length && (
            <TableRowHeader className="table-header-row">
              <TableHeader>
                {t('TABLE_HEADER_TRUNKRSNR')}
              </TableHeader>
              <TableHeader>
                {t('TABLE_HEADER_POSTAL_CODE')}
              </TableHeader>
              <TableHeader>
                {t('TABLE_HEADER_ADDRESS')}
              </TableHeader>
              <TableHeader>
                {t('TABLE_HEADER_CITY')}
              </TableHeader>
              <TableHeader>
                {t('TABLE_HEADER_TOUR')}
              </TableHeader>
              <TableHeader>
                {t('TABLE_HEADER_SHIPMENT_STATE')}
              </TableHeader>
            </TableRowHeader>
          )
        }
        <TableBody>
          {useMemo(() => filteredData.map((shipment: IShipmentProfile, id) =>  (
              <TableRow key={id}>
                <TableCell>
                  {defaultTo(get(shipment, 'TrunkrsNr'), '-')}
                </TableCell>
                <TableCell>
                  {defaultTo(get(shipment, 'Recipient.PostalCode'), '')}
                </TableCell>
                <TableCell>
                  {`${defaultTo(get(shipment, 'Recipient.Address'), '')} ${defaultTo(get(shipment, 'Recipient.HouseNumber'), '')}`}
                </TableCell>
                <TableCell>
                  {defaultTo(get(shipment, 'Recipient.City'), '')}
                </TableCell>
                <TableCell>
                  {defaultTo(get(shipment, 'Route'), '-')}
                </TableCell>
                <TableCell>
                  <ShipmentStateBadge shipment={shipment} />
                </TableCell>
              </TableRow>
            )
          ), [filteredData])}
        </TableBody>
      </Table>
      {
        !filteredData.length && (
          <ShipmentEmpty/>
        )
      }
    </div>
  )
}

export default memo(ShipmentsOverview)
