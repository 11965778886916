import React, { memo } from 'react'
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import clsx from 'clsx';
import get from 'lodash/get'
import { useTranslate } from 'utils'
import {ReactComponent as InfoIcon} from '../../../../images/small-info-icon.svg'
import Badge from 'components/base/Badge';

import { useIsMenuAccessible } from 'hooks/useIsMenuAccessible'
import { getShipmentsAtRegionalHub } from 'selectors/hubLogs';

import styles from '../../Sidebar.module.scss'

const SidebarListMenu = ({menuId, children, onClick, className, ...props}: React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> & {menuId: number}) => {
    const {t} = useTranslate()
    const atRegionalHub = useSelector(getShipmentsAtRegionalHub)
    const {isMenuAccessible} = useIsMenuAccessible(menuId)
    
    return (
        <li className={clsx(className, !isMenuAccessible && styles.menuDisabled)}{...(isMenuAccessible && {onClick})}>
            {children && (children as any).map((child: React.ReactNode, i: number) => {
                if(i === 1 && menuId === 10){
                    return [
                        !isMenuAccessible ?
                        <Tooltip title={t('SIDEBAR_RETURNS_SCAN_NOT_AVAILABLE_TOOLTIP')} classes={{tooltip: styles.tooltip, arrow: styles.tooltipArrow}} placement="bottom" arrow>
                            <span>
                                {get(child, 'props.children')}
                                <InfoIcon/>
                            </span> 
                        </Tooltip>: <span>{get(child, 'props.children')}</span>,
                        isMenuAccessible && <Badge>{atRegionalHub.length}</Badge>
                    ]
                }
                else {
                    return child
                }
            })}
        </li>
    )
}

export default memo(SidebarListMenu)