import React, { memo } from 'react'
import { useTranslate } from 'utils'
import { useSelector } from 'react-redux'
import { getHubInventory } from 'selectors/hubLogs'

import Page from '../Page'
import HubInventoryList from './components/HubInventoryList'
import EmptyLogs from './components/EmptyLogs'
import isEmpty from 'lodash/isEmpty'

const HubInventory = () => {
  const { t } = useTranslate('HUB_INVENTORY')
  const hubInventory = useSelector(getHubInventory)
  return (
    <Page title={t('TITLE')}>
      {isEmpty(hubInventory) ? <EmptyLogs/> : <HubInventoryList />}
    </Page>
  )
}

export default memo(HubInventory)
