import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import styles from './MuiTooltip.module.scss'


export const ToolTip = ({
  content,
  position,
  children,
}) => {
  return (
    <Tooltip classes={{ tooltip: styles.tooltip,
      arrow: styles.arrow,

    }} title={content} placement={position} arrow>
      {children}
    </Tooltip>
  )
}


ToolTip.propTypes = {
  content: PropTypes.any,
  children: PropTypes.node,
  position: PropTypes.string,
}

ToolTip.defaultProps = {
  position: 'bottom',
  content: null,
  children: null,
}

export default withStyles(styles)(ToolTip)