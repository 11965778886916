import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'

import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

import Tooltip from '../../planner/BoardList/components/Tooltip'
import classes from '../QuickTour.module.scss'

const getClassForShipment = (state) => {
  switch (state) {
  case SHIPMENT_STATES.DATA_PROCESSED:
    return classes.notScanned
  case SHIPMENT_STATES.SHIPMENT_DELIVERED:
    return classes.delivered
  case SHIPMENT_STATES.SHIPMENT_DELIVERED_TO_NEIGHBOR:
    return classes.deliveredToNeighbour
  case SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED:
    return classes.notDelivered
  default:
    return ''
  }
}

const QuickTour = ({
  tourOverview: { tour, collations, driver },
}) => (
  <div className={clsx(classes.boxShadow, classes.wrapper)}>
    <div className={classes.infoRow}>
      <div className={classes.driverInfo}>
        <h1>Tour {tour.id}</h1>
        <h2>{driver.name}</h2>
        <a className={classes.driverPhoneNumber} href={`tel:${driver.phoneNumber}`}>
          <FontAwesomeIcon icon={faPhoneAlt}/>
          {driver.phoneNumber}
        </a>
      </div>
      {!!driver.latitude && (
        <div className={classes.driverPosition}>
          <span>Location</span>
          <div className={classes.box}>
            <a target="_blank" rel="noopener noreferrer" href={driver.latestPosition}>
              Show on maps
            </a>
          </div>
        </div>
      )}
      <div className={classes.driverPosition}>
        <span>Speed</span>
        <div className={classes.box}>{driver.speed}km/h</div>
      </div>
    </div>
    <div className={classes.tourRow}>
      <div className={classes.time}>{tour.startTime}</div>
      <div className={classes.stopBar}>
        {React.useMemo(() => collations.map(collation => (
          <Tooltip key={collation.id} content={`ETA: ${moment(collation.eta).format('HH:mm')}`} position="bottom">
            <div className={clsx(classes.stop, getClassForShipment(collation.state))} key={collation.shipment_id}/>
          </Tooltip>
        )), [collations])}
      </div>
      <div className={classes.time}>{tour.endTime}</div>
    </div>
    <div className={classes.positionRow}>
      {collations.map((collation, index) => (
        <div className={classes.position} key={collation.shipment_id}>
          {index + 1}
        </div>
      ))}
    </div>
  </div>
)


QuickTour.propTypes = {
  tourOverview: PropTypes.shape({
    tour: PropTypes.object,
    collations: PropTypes.array,
    driver: PropTypes.object,
  }),
  legend: PropTypes.bool,
}

QuickTour.defaultProps = {
  tourOverview: null,
  legend: false,
}

export default React.memo(QuickTour)
