import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Grid from '@material-ui/core/Grid'

import { ToggleButton } from '../../../../ToggleButton'
import { translate } from '../../../../../utils/translate'
import { FUEL_TYPES } from '../../../../../constants/FuelTypes'
import Translate from '../../../../Translate'

import styles from './styles.module.scss'

const SelectFuelType = ({ vehicleType = 'fossil', id, changeVehicleType, locked, className }) => {
  return (
    <Grid container direction="row" className={clsx(styles['vehicle-type-container'], className)}>
      <Translate>VEHICLE_TYPE</Translate>
      <div>
        {
          useMemo(()=> FUEL_TYPES.map((fuelType, index) => (
            <Grid key={fuelType.name} className={styles[`grid${index}`]} item>
              <ToggleButton
                disabled={locked}
                key={fuelType.name}
                label={translate(fuelType.langKey.toUpperCase())}
                icon={fuelType.icon}
                color={fuelType.color}
                active={vehicleType === fuelType.name}
                onClick={() => changeVehicleType(id, fuelType.name)}
              />
            </Grid>
          )), [FUEL_TYPES, vehicleType, locked])
        }
      </div>
    </Grid>
  )
}

SelectFuelType.propTypes = {
  vehicleType: PropTypes.string,
  id: PropTypes.oneOfType(['string', 'number']),
  locked: PropTypes.bool,
  changeVehicleType: PropTypes.func,
}

SelectFuelType.defaultProps = {
  vehicleType: FUEL_TYPES[0].name,
  locked: false,
  id: null,
}

export default SelectFuelType
