import React, {memo, useMemo} from 'react'
import Grid from "@material-ui/core/Grid/Grid";
import styles from './styles.module.scss'
import {useSelector} from "react-redux";
import {getExpectedShipments} from "../../../../selectors/freezerManagement";
import {IFreezerLog} from "../../../../reducers/freezerManagement/@types";
import {translate} from "../../../../utils/translate";

const ExpectedShipmentsTable = () => {
  const shipments = useSelector((state: any) => getExpectedShipments(state))

  return (
    <Grid container item xs={4} className={styles.table}>
      <Grid container xs={12}>
        {translate('EXPECTED_SHIPMENTS')}
      </Grid>
      <Grid container xs={12}>
        <Grid container xs={12} className={styles.tableHeaderContainer}>
          <Grid item xs={6}>
            {translate('FROZEN_MANAGEMENT_ADDRESS')}
          </Grid>
          <Grid item xs={6}>
            {translate('FROZEN_MANAGEMENT_DRIVER')}
          </Grid>
        </Grid>
        {
          useMemo(()=>
            shipments.map(({location: {address, postalCode}, driverName} : IFreezerLog) => (
              <Grid container xs={12}  className={styles.tableRow}>
                <Grid item xs={6}>
                  <span>{address}</span>
                  <span>{postalCode}</span>
                </Grid>
                <Grid item xs={6}>
                  {driverName}
                </Grid>
              </Grid>
            ))
          , [shipments])
        }
        {
          !shipments.length &&
          <Grid container xs={12}  className={styles.tableRow}>
            <Grid alignItems="center" item xs>
              {translate('FROZEN_MANAGEMENT_NO_SHIPMENTS')}
            </Grid>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default memo(ExpectedShipmentsTable)
