import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import { browserHistory } from '../browserHistory'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import * as UIActions from '../actions/ui'
import * as profileActions from '../actions/profile'
import Sidebar from '../components/Sidebar'

// For Customization Options, edit  or use
// './src/material_ui_raw_theme_file.jsx' as a template.
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import theme from '../material_ui_raw_theme_file'

import * as fromCombined from '../selectors/combined'

import { invalidateAuth } from '../utils/invalidateAuth'
import DataGate from '../components/DataGate'
import ErrorBoundary from '../components/ErrorBoundary'
import { getSidebarCollapsed } from '../selectors/ui'
import { ReactComponent as ToggleIcon } from '../images/sidemenu/sidemenu-toggle.svg'
import { ReactComponent as ToggleIconCollapsed } from '../images/sidemenu/sidemenu-toggle-collapsed.svg'

import styles from './App.module.scss'
import OptimizeLoader from 'components/common/OptimizeLoader'

class Main extends Component {
  render() {
    return (
      <div id="main-container" className='container'>{this.props.children}</div>
    )
  }
}

class App extends Component {
  constructor(props) {
    super(props)
    this.checkBrowser()
  }

  checkBrowser() {
    const isChromium = window.chrome,
      winNav = window.navigator,
      vendorName = winNav.vendor,
      isOpera = winNav.userAgent.indexOf('OPR') > -1,
      isIEedge = winNav.userAgent.indexOf('Edge') > -1

    if((isChromium === null || isChromium === undefined) || vendorName !== 'Google Inc.' || isOpera === true || isIEedge === true) {
      alert('You need a modern browser to be able to use the Plannr app, please download Google Chrome.')
      window.location = 'https://www.google.nl/chrome/browser/desktop/index.html'
    }
  }

  countRoutes() {
    return (this.props.shipments) ? Object.values(this.props.routes).filter(item => item.Collations.length !== 0).length : 0
  }

  countShipments() {
    return (this.props.shipments) ? Object.values(this.props.shipments).filter(item => item.State !== 17).length : 0
  }

  countErrorShipments() {
    return this.props.shipments ? this.props.shipments.reduce((count, shipment) => {
      return !shipment.Recipient.Lat || !shipment.Recipient.Lng
        ? count + 1
        : count
    }, 0) : 0
  }

  isPlanned = () => {
    try {
      const tourIds = this.props.routes && Object.keys(this.props.routes)
      if (tourIds.length === 0) {
        return false
      }
      const planned = tourIds.filter(id => id !== 'undefined').map(id => {
        const tour = this.props.routes[id]
        return tour.isPlanned
      })

      return planned.every(item => item === true)
    } catch (error) {
      console.error(error)
      return false
    }
  }

  logoff = async() => {
    await invalidateAuth()
    await this.props.setLoggedInState()
    browserHistory.push('/login')
  };

  toggleSidebar = () => {
    const { uiActions: { setSidebarCollapsed }, sidebarCollapsed } = this.props
    setSidebarCollapsed && setSidebarCollapsed(!sidebarCollapsed)
  }

  isExport = () => this.props.location.pathname === '/export'
  render() {
    const { ui, children, isLoggedIn, sidebarCollapsed } = this.props

    return (
      <div style={{ height: this.isExport() ? 'auto' : '100%' }}>
        <ReduxToastr timeOut={4000}
          newestOnTop={false}
          preventDuplicates={true}
          position="top-left"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
        />

        <MuiThemeProvider muiTheme={theme}>
          <div style={{ height: '100%' }}>
            <div className="page--wrapper">
              {isLoggedIn && (
                <Sidebar logoff={this.logoff}>
                  <div
                    className={clsx(styles.sideBarToggler)}
                    onClick={this.toggleSidebar}
                  >
                    {sidebarCollapsed ? <ToggleIconCollapsed/> : <ToggleIcon/>}
                  </div>
                </Sidebar>
              )}
              <Main>
                <ErrorBoundary>
                  <OptimizeLoader visible={ui.loading} message={ui.message} showLoader={ui.optimizing}/>
                  <DataGate isLoggedIn={isLoggedIn}>
                    {children}
                  </DataGate>
                </ErrorBoundary>
              </Main>
            </div>
          </div>
        </MuiThemeProvider>
      </div>
    )
  }
}

App.contextTypes = {
  router: PropTypes.object,
  location: PropTypes.object,
}

App.propTypes = {
  uiActions: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
    routes: state.routes,
    shipments: fromCombined.getOverviewShipments(state),
    profile: state.profile,
    sidebarCollapsed: getSidebarCollapsed(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
    profileActions: bindActionCreators(profileActions, dispatch),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(App))
