import { createSelector } from 'reselect'

import { createFeatureSelector } from './core'

import * as fromShipments from '../reducers/shipments'

import { STATE } from '../constants'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'
import { resolveIndex } from '../utils/dataSets'

const omittedStates = [
  STATE.EXCEPTION_PICKUP_NOT_PICKED_UP,
  STATE.EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER,
  STATE.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS,
  STATE.EXCEPTION_SHIPMENT_NOT_ARRIVED,
]

const selectFeature = createFeatureSelector('shipments')

export const getIdentifiers = createSelector(
  selectFeature,
  fromShipments.selectIdentifiers,
)

export const getNonNegativeIdentifiers = createSelector(
  selectFeature,
  fromShipments.selectNonNegativeIdentifiers,
)

export const getData = createSelector(
  selectFeature,
  fromShipments.selectData,
)
export const hasFetched = createSelector(
  selectFeature,
  fromShipments.selectHasFetched,
)

export const getShipments = createSelector(
  getIdentifiers,
  getData,
  resolveIndex,
)

export const getNumberOfShipments = createSelector(
  getNonNegativeIdentifiers,
  identifiers => identifiers.length,
)

export const getVRPShipments = createSelector(
  getShipments,
  (shipments) => shipments
    .filter(({ state: { name }, id }) => !omittedStates.includes(name) && id > 0),
)

export const getNumberOfNonCancelledShipments = createSelector(
  getVRPShipments,
  shipments => shipments.length,
)

export const getHandoverShipments = createSelector(
  getShipments,
  (shipments) => shipments.reduce(
    (acc, shipment) =>
      Object.assign(acc, {
        [shipment.barcode]: shipment.id,
        [shipment.trunkrsNr]: shipment.id,
      }),
    {},
  ),
)

export const hasHandoverStarted = createSelector(
  getShipments,
  shipments => shipments.some(shipment =>
    shipment.state.name === SHIPMENT_STATES.SHIPMENT_ACCEPTED_BY_DRIVER
    || shipment.state.name === SHIPMENT_STATES.SHIPMENT_SORTED_AT_SUB_DEPOT,
  ),
)
