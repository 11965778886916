import React from 'react'
import get from 'lodash/get'
import Translate from '../Translate'
import styles from './styles.module.scss'
import clsx from 'clsx'

export const AddressSection = ({ recipient, label, className }) => {
  const location = get(recipient, 'location')
  const name = get(recipient, 'name')
  const phoneNumber = get(recipient, 'phoneNumber')
  return (
    <div className={clsx(styles['details-wrapper'], className)}>
      <Translate className={styles.label} capitalize>{label}</Translate>
      <span className={styles.value}>{name}</span>
      <span className={styles.value}>{`T. ${phoneNumber}`}</span>
      <span className={styles.value}>{get(location, 'address')}</span>
      <span className={styles.value}>{`${get(location, 'postalCode')} ${get(location, 'city')}`}</span>
    </div>
  )
}

export const DetailsWrapper = ({ value, label }) => {
  return (
    <div className={styles['details-wrapper']}>
      <Translate className={styles.label} capitalize>{label}</Translate>
      <span className={styles.value}>{value}</span>
    </div>
  )
}