import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import moment from 'moment'
// any additional languages should be imported here, else the translated dates won't work
import 'moment/locale/nl'
import 'moment/locale/fr'

import { useFormikContext } from 'formik'

import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import Progress from '@material-ui/core/CircularProgress'
import ScrollContainer from 'react-perfect-scrollbar'

import { InfoButton } from '../../common/Button/Button'
import Translate from '../../Translate'

import classes from './DateContainer.module.scss'

const DateContainer = ({
  disabled, date, isLoading, children, lang,
}) => {
  const { isValid } = useFormikContext()
  const [depth, setDepth] = React.useState(1)
  const [dayOfMonth, weekday] = React.useMemo(
    () => {
      const parsed = moment(date, 'YYYY-MM-DD')
      return [
        parsed.date(),
        parsed.locale(lang).format('dddd'),
      ]
    }, [date, lang],
  )

  const handleSetNormalDepth = React.useCallback(() => setDepth(3), [])
  const handleSetHoveredDepth = React.useCallback(() => setDepth(10), [])

  return (
    <Paper
      className={clsx(classes.container, isLoading && classes.blur)}
      elevation={depth}
      onMouseEnter={handleSetHoveredDepth}
      onMouseLeave={handleSetNormalDepth}
    >
      {isLoading && (
        <div className={classes.loading}>
          <Progress className={classes.loader} size={48} />
          <Translate component="p" className={classes.savingText}>
            SAVING
          </Translate>
        </div>
      )}

      <div className={classes.header}>
        <span>
          <p className={classes.date}>{dayOfMonth}</p>
          <p className={classes.day}>{weekday}</p>
        </span>
      </div>

      <div className={classes.flexWrapper}>
        <ScrollContainer className={classes.scrollContainer}>
          {children}
        </ScrollContainer>

        <Collapse in={isValid && !disabled}>
          <div className={classes.btnContainer}>
            <Translate fullWidth component={InfoButton}>
              SAVE
            </Translate>
          </div>
        </Collapse>
      </div>
    </Paper>
  )
}

DateContainer.propType = {
  disabled: PropTypes.bool,
  date: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  lang: PropTypes.string,
}

DateContainer.defaultProps = {
  disabled: false,
  isLoading: false,
  children: null,
  lang: 'nl',
}

export default DateContainer
