import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk'

import rootReducer from '../reducers'
import { environment } from '../constants'

const intervalTasksFilter = createFilter('intervalTasks', ['identifiers', 'tasks'])

const persistConfig = {
  key: 'Trunkrs Planner',
  storage,
  transforms: [
    intervalTasksFilter,
  ],
  whitelist: ['intervalTasks'],

}

export default function configureStore(initialState) {
  const composeWithDevToolsExtension = environment !== 'production'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  const composeEnhancers = (typeof composeWithDevToolsExtension === 'function')
    ? composeWithDevToolsExtension
    : compose

  const composedMiddleware = composeEnhancers(
    applyMiddleware(thunk),
  )

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(
    persistedReducer,
    initialState,
    composedMiddleware,
  )

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers')
      store.replaceReducer(nextReducer)
    })
  }

  const persistor = persistStore(store)
  return { store, persistor }
}
