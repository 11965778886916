import React, {memo, ReactElement, ReactNode, useMemo, useRef} from 'react'
import Select, {SelectProps} from "@material-ui/core/Select/Select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import clsx from 'clsx';
import PopupState, {bindTrigger, bindMenu, bindPopover} from 'material-ui-popup-state';

import styles from './styles.module.scss'
import Menu, {MenuProps} from "@material-ui/core/Menu/Menu";
import {usePopupState} from "material-ui-popup-state/hooks";
import uniqueId from 'lodash/uniqueId'
import Translate from "../../Translate";
import FlatButton from "material-ui/FlatButton";
import get from "lodash/get";

export interface ISimpleMenuProps {
  label: string
  children: ReactElement|ReactElement[]
  popOverClassName?: string
}

const SimpleMenu = ({label, children, popOverClassName, ...props} : ISimpleMenuProps) => {
  const {current: popupId} = useRef(uniqueId('dropdown-menu-'))
  const popupState = usePopupState({ variant: 'popover', popupId})

  const renderMenu = (child: ReactElement) => {
    const handleClick = () => {
      const {onClick} = child.props
      onClick && onClick()
      popupState.close()
    }

    return React.createElement(child.type, {
      ...child.props,
      onClick: handleClick
    })
  }

  return (
    <div className={styles["simple-menu-container"]}>
      <FlatButton className={clsx(styles["menu-button"], popupState.isOpen && styles["menu-button-open"])} icon={<FontAwesomeIcon icon={popupState.isOpen ? faChevronUp : faChevronDown} />} label={label} labelPosition="before"  {...bindTrigger(popupState)}/>
      <Menu
        {...bindMenu(popupState)}
        {...props}
        PopoverClasses={{paper: clsx(styles["menu-popover-container"], popOverClassName)}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}>
        {useMemo(() => Array.isArray(children) ? children.map(child => renderMenu(child)) : renderMenu(children), [children, popupState])}
      </Menu>
    </div>
  )
}

export default memo(SimpleMenu)
