import React, {memo, useCallback, useMemo, useState} from 'react'
import get from 'lodash/get'
import defaultTo from 'lodash/defaultTo'
import toPairs from 'lodash/toPairs'
import sortBy from 'lodash/sortBy'
import clsx from 'clsx'
import ClickAway from "../utils/ClickAway"
import {ReactComponent as CaretDownIcon} from '../../../images/caret-down.svg'

import styles from './styles.module.scss'

interface IDropdownLinkProps {
  data: {[key: string] : any}
  onChange: (value: any) => void
  value?: any
  textValue?: any
  ordered?: boolean
  className?: string
}

const DropdownLink = ({data, value, textValue, onChange, ordered, className}: IDropdownLinkProps) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const currentTextValue = defaultTo(textValue, get(data, value, ''))

  const showDropdown = useCallback(() => setIsDropdownVisible(true), [])
  const hideDropdown = useCallback(() => setIsDropdownVisible(false), [])

  const handleChange = useCallback((keyValue) => () => {
    hideDropdown()
    onChange(keyValue)
  }, [onChange])

  return(
    <div className={clsx(className, styles.container)}>
      <div onClick={showDropdown} className={clsx(styles.label, isDropdownVisible && styles.active)}>
        {currentTextValue}
        <CaretDownIcon />
      </div>
      <ClickAway callback={hideDropdown}>
        <ul className={clsx(styles.dropdownContainer, isDropdownVisible && styles.visible)}>
          {
            useMemo(() => {
              return(
                <>
                  {
                    ordered ? (
                      <>
                        {sortBy(toPairs(data), '[1]').map((pair, i) => {
                          const keyValue = get(pair, '[0]')
                          const textValue = get(pair, '[1]')
                          return (
                            <li key={`dropdown_link_${keyValue}_${i}`} onClick={handleChange(keyValue)} className={clsx(keyValue == value && 'active')}>{textValue}</li>
                          )
                        })}
                      </>
                    ) : (
                      Object.keys(data).map((keyValue, i) => {
                        const textValue = get(data, keyValue)
                        return (
                          <li key={`dropdown_link_${keyValue}_${i}`} onClick={handleChange(keyValue)} className={clsx(keyValue == value && 'active')}>{textValue}</li>
                        )
                      })
                    )
                  }
                </>
              )
            }, [data])
          }
        </ul>
      </ClickAway>
    </div>
  )
}

export default memo(DropdownLink)
