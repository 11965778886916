import React from 'react'

export const UploadButton = ({
  name,
  label,
  style,
  onChange,
}) => {
  return (<div>
    <input type="file" id={name} className="input--file-hidden" onChange={onChange} />
    <label htmlFor={name} className="label--upload-button">
      {label}
    </label>
  </div>)
}
