import React, { useState, useEffect } from 'react'



export interface WithDelayProps {
    show?: boolean
    delay: number
    children: React.ReactNode
}


export const WithDelay: React.FC<WithDelayProps> = ({
    show,
    delay,
    children,
}: any) => {
    const [hidden, setHidden] = useState<boolean>(true)


    useEffect(() => {
        setTimeout(() => {
            setHidden(show)
        }, delay)
    }, [show])
    
    if (hidden) return ''

    return children
}
  
export default WithDelay
