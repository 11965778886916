import React, { memo, useMemo } from 'react'

import { ICollation, ITour } from '../../../@models'
import { isValidCollation } from 'actions/tours/helpers'
import { useTranslate } from 'utils/translate'
import { getLastStopTime, isPastDeadLine } from '../../../../../utils'
import { Icon } from 'components/base'

import moment from 'moment'
import clsx from 'clsx'
import styles from './TourShipments.module.scss'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'


interface ITourShipmentsProps {
  tour: ITour,
  tourColor: any,
  locked: boolean,
  showList: boolean
}

interface ILabel { collations: Array<ICollation>, showLabel?: boolean, className?: string }

export const TourShipments = ({ tour: { collations, number: tourNo, totalDistance, hasBermudaStop }, tourColor, showList }: ITourShipmentsProps) => {
  const { t } = useTranslate()

  const totalShipments = useMemo(() => collations.filter(isValidCollation as any).reduce((a: number, b: ICollation) => a + b.shipmentIds.length || a, 0), [collations])
  const totalDistanceInKM = useMemo(() => (totalDistance / 1000).toFixed(1).concat(' km'), [totalDistance])

  return (
    <div>
      <div className={styles["tour-label"]}>
        <span className={styles["tour-color-indicator"]} style={{backgroundColor: tourColor}} /> {`Tour ${tourNo}`}
        {hasBermudaStop && !showList && <Icon name="danger" />}
      </div>
      <div className={styles.tourLabel}>
        <StartTimeLabel collations={collations}/>
        <EndTimeLabel collations={collations}/>
        <TextWrapper label={t('SHIPMENTS')} value={totalShipments}/>
        <TextWrapper label={t('TOTAL_DISTANCE')} value={totalDistanceInKM}/>
      </div>
    </div>
  )
}

export const TextWrapper = memo(({ value, label, className} : { value: string|number, label?: string, className?: string }) => 
  <span className={clsx(styles.labelWrapper, className)}>
    {label && <span>{label}</span>}
    <span className={clsx(styles.label)}>{value}</span>
  </span>
)

export const EndTimeLabel = memo(({ collations, showLabel, className }: ILabel) =>  {
  const { t } = useTranslate()

  const { endTime, pastDeadline } = useMemo(() => {
    const endTime = moment(getLastStopTime(collations)).format('HH:mm')
    const pastDeadline = isPastDeadLine(collations)
    return { endTime, pastDeadline }
  }, [collations, getLastStopTime, isPastDeadLine])
  
  return (
    <TextWrapper 
      label={showLabel || isUndefined(showLabel) ? t('LAST_STOP') : undefined} 
      value={endTime}
      className={clsx(pastDeadline && styles.pastDeadline, className)}/>
  )
})

export const StartTimeLabel = memo(({ collations, showLabel, ...props }: ILabel) =>  {
  const { t } = useTranslate()
  const startTime = useMemo(() => moment(get(collations, '[0].estimation')).format('HH:mm'), [collations])

  return (
    <TextWrapper 
      label={showLabel || isUndefined(showLabel) ? t('DEPART') : undefined} 
      value={startTime}
      {...props}/>
  )
})



export default memo(TourShipments)
