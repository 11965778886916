import { normalize } from 'normalizr'
import uuid from 'uuid'

import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'

import { collectionTourDateSchema } from '../../schema/schemas'

export const normalizeCollectionWeek = (week) => {
  const orderedStops = week.map(tour => ({
    ...tour,
    stops: orderBy(tour.stops, 'position'),
  }))

  const tourDateGroups = groupBy(orderedStops, 'date')
  const normalizable = Object.keys(tourDateGroups).map(tourDate => ({
    date: tourDate,
    instanceId: uuid(),
    tours: tourDateGroups[tourDate],
  }))

  const {
    result: tourDates,
    entities: { tourDate: tourDateData, tour },
  } = normalize(normalizable, [collectionTourDateSchema])

  return [tourDates, tourDateData, tour]
}
