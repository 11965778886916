import React, {memo, ReactNode, useEffect, useState} from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

export interface ISimpleCheckboxProps {
  checked?: boolean
  children?: ReactNode
  className?: string
  onChange?: (isChecked: boolean) => void
  mark?: 'check' | 'bar'
}

const SimpleCheckbox = ({children, onChange, className, mark, checked} : ISimpleCheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(()=>{
    setIsChecked(checked)
  }, [checked])

  const toggleCheck = () => {
    const nextValue = !isChecked
    setIsChecked(nextValue)
    if(typeof onChange === "function"){
      onChange(nextValue)
    }
  }

  return (
    <span className={clsx(styles.checkboxContainer, className)} onClick={toggleCheck}>
      {children}
      <input type="checkbox" checked={isChecked}/>
      <span className={clsx(styles.checkmark, mark === 'bar' && styles.checkBar)}/>
    </span>
  )
}

export default memo(SimpleCheckbox)
