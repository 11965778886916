import { connect } from 'react-redux'

import * as fromCombined from '../../../selectors/combined'
import * as fromTours from '../../../selectors/tour'
import * as fromWarehouse from '../../../selectors/warehouse'
import * as tourActions from '../../../actions/tours'

const mapStateToProps = (state) => ({
  areToursLocked: fromCombined.areToursLocked(state),
  areToursPlanned: fromCombined.areToursPlanned(state),
  areToursSorted: fromTours.getAreToursSorted(state),
  areDriversAssigned: fromCombined.allToursHaveDrivers(state),
  areToursSaved: fromCombined.allToursHaveIds(state),
  tours: fromTours.getData(state),
  isBusyDay: fromWarehouse.getIsBusyDay(state),
  shipmentToursNotSorted: fromCombined.getShipmentToursIfIncludesNotSorted(state),
})

const mapDispatchToProps = {
  lockTours: tourActions.lockTours,
  fetchTourSortingStatus: tourActions.fetchTourSortingStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)
