import React, { memo } from 'react'
import AddUserModal, { IAddDriverModalProps } from '../AddUserModal'
import { FORM_ACTION } from '../UsersList/@types'
import DeleteUserModal from '../DeleteUserModal'


export interface IModalProps extends IAddDriverModalProps {
    deleteFilter?: boolean
    mode: FORM_ACTION
}

export const Modal = ({
    onSucceed, mode, ...props
}: IModalProps) => {
    switch (mode) {
        case FORM_ACTION.CREATE:
        case FORM_ACTION.EDIT:
            return (
                <AddUserModal
                    onSucceed={onSucceed}
                    {...props}
                />
            )
        case FORM_ACTION.DELETE:
            return (
                <DeleteUserModal
                onSucceed={onSucceed}
                {...props}
                />
            )
        default: 
            return null
    }
}


export default memo(Modal)