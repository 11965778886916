import React, {memo, ReactNode, useEffect, useState} from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import {useFormContext} from "react-hook-form";

export interface ISimpleSwitchProps extends React.HTMLProps<HTMLInputElement>{
  onToggle?: (isActive: boolean) => void
  active?: boolean
}

const SimpleSwitch = ({ name, children, onToggle, className }: ISimpleSwitchProps & {name: string}) => {
  const { register, watch, setValue } = useFormContext()
  const refValue = watch(name)

  const toggleCheck = () => {
    const nextValue = !refValue
    setValue(name, nextValue)
    if(typeof onToggle === "function"){
      onToggle(nextValue)
    }
  }

  return (
    <span className={clsx(styles["switch-container"], className)} onClick={toggleCheck}>
      <div className={styles.switch}>
        <input name={name} type="checkbox" ref={register} />
        <div className={styles.slider} />
      </div>
      {children}
    </span>
  )
}

export const Switch = memo(({children, onToggle, className, active }: ISimpleSwitchProps) => {
  const [isActive, setIsActive] = useState(!!active)

  const toggleCheck = () => {
    const nextValue = !isActive
    setIsActive(nextValue)
    if(typeof onToggle === "function"){
      onToggle(nextValue)
    }
  }

  return (
    <span className={clsx(styles["switch-container"], className)} onClick={toggleCheck}>
      <div className={styles.switch}>
        <input checked={isActive} type="checkbox" />
        <div className={styles.slider} />
      </div>
      {children}
    </span>
  )
})

export default memo(SimpleSwitch)
