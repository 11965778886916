import React, {memo} from 'react'
import {ReactComponent as BarcodeIcon} from '../../../images/barcode-icon.svg'

import styles from './styles.module.scss'
import clsx from 'clsx'

const ScanInput = (props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
  return(
    <div className={clsx(styles.scanInputContainer, props.disabled && styles.disabled)}>
      <BarcodeIcon />
      <input type="text" {...props} />
    </div>
  )
}

export default memo(ScanInput)
