import React, {memo, useCallback, useMemo, useState} from 'react'
import get from 'lodash/get'
import defaultTo from "lodash/defaultTo";
import clsx from "clsx";
import styles from "./styles.module.scss";
import {ReactComponent as CaretDownIcon} from "../../../images/caret-down.svg";
import ClickAway from "../utils/ClickAway";

interface IDropdownMenuProps {
  data: {[key: string] : React.ReactNode}
  onChange: (value: any) => void
  value?: any
  className?: string
}

const DropdownMenu = ({data, onChange, value, className} : IDropdownMenuProps) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const selectedNode = useMemo(() => get(data, `${value}.props.children`), [data, value])

  const showDropdown = useCallback(() => setIsDropdownVisible(true), [])
  const hideDropdown = useCallback(() => setIsDropdownVisible(false), [])

  const handleChange = useCallback((keyValue) => () => {
    hideDropdown()
    onChange(keyValue)
  }, [onChange])

  return(
    <div className={clsx(className, styles.container)}>
      <div onClick={showDropdown} className={clsx(styles.label, isDropdownVisible && styles.active)}>
        <div>{selectedNode}</div>
        <CaretDownIcon />
      </div>
      <ClickAway callback={hideDropdown}>
        <ul className={clsx(styles.dropdownContainer, isDropdownVisible && styles.visible)}>
          {
            useMemo(() => Object.keys(data).map((keyValue, i) => {
              const node = get(data, keyValue)
              return (
                node && React.cloneElement((node as any), {
                  ...get(node, 'props'),
                  key:`dropdown_menu_${keyValue}_${i}`,
                  className: clsx(get(node, 'props.className'), value == keyValue && styles.selected),
                  onClick: handleChange(keyValue)
                })
              )
            }), [value, data])
          }
        </ul>
      </ClickAway>
    </div>
  )
}

export default memo(DropdownMenu)
