import React from 'react'
import PropTypes from 'prop-types'

import Spinner from 'material-ui/CircularProgress'

import TodoStatus from '../../TodoStatus'
import { variantNames } from '../index'

import NotDeliveredActions from './NotDeliverdActions'
import NoEndStateActions from './NoEndStateActions'

import classes from './TableActions.module.scss'
import MissortedActions from './MissortedActions'

const TableActions = ({
  variant, isLoading, status,
  onPostpone, onReturn, onComment,
}) => {
  if (isLoading) {
    return <Spinner className={classes.spinner} size={30} thickness={3} />
  }

  switch (variant && variant.toLowerCase()) {
  case variantNames.notDelivered:
    return <NotDeliveredActions onPostpone={onPostpone} onReturn={onReturn} />
  case variantNames.noEndState:
    return <NoEndStateActions onPostpone={onPostpone} onComment={onComment} />
  case variantNames.misSorted || variantNames.returnShipments:
    return <MissortedActions onReturn={onReturn} />
  default:
    return <TodoStatus status={status} />
  }
}

TableActions.propTypes = {
  variant: PropTypes.oneOf([
    'not-delivered',
    'no-end-state',
    'done',
    'returns',
  ]),
  isLoading: PropTypes.bool,
  status: PropTypes.string,
  onPostpone: PropTypes.func,
  onReturn: PropTypes.func,
  onComment: PropTypes.func,
}

TableActions.defaultProps = {

}

export default TableActions
