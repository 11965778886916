import React, {useEffect, useState, useCallback, useMemo, ReactNode, memo} from 'react'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Input from '@material-ui/core/Input'
import FlatButton from 'material-ui/FlatButton'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Box from '@material-ui/core/Box'

import styles from './AutoComplete.module.scss'
import OptionContainer from './components/OptionContainer'
import { IDriver } from '../../../../../models'
import { translate } from '../../../../../utils'
import Translate from "../../../../Translate";

interface IAutoCompleteProps {
  options: IDriver[],
  value: number,
  id?: string,
  className?: string,
  name?: string,
  disabled: boolean,
  onChange: (event: any, inputValue: string | null) => void,
  assignedDriverIds: number[],
  children: ReactNode | ReactNode[],
  fuelType: string
}

const AutoComplete: React.FunctionComponent<IAutoCompleteProps> = ({
  options,
  value,
  id,
  className,
  name,
  disabled,
  onChange,
  assignedDriverIds,
  children,
  fuelType = 'fossil'
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [list, setList] = useState<IDriver[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [focused, setFocus] = useState<boolean>(false)

  const toggleOpen = useCallback(() => setOpen((prevOpen: boolean) => !prevOpen), [])

  const clearInput = useCallback((event: any) => {
    if (!disabled) {
      setInputValue('')
      setList(options)
      onChange(event, null)
    }
  }, [])

  const onInputFocused = useCallback(() => {
    setOpen(true)
    setFocus(true)
  }, [])

  const handleClickAway = useCallback(() => {
    setOpen(false)
    setTimeout(() => setFocus(false), 250)
  }, [])

  const handleClick = (event: any) => {
    handleClickAway()
    if (onChange) {
      onChange(event, event.currentTarget.dataset)
    }
  }

  const setDriver = useCallback((id: number) => {
    const driver = options.find((driver: IDriver) => driver.id === id)
    if (driver) {
      setInputValue(driver.name)
      setList(options)
    }
  }, [id])

  const handleToggleDropdown = useCallback(() => {
    if(!disabled) {
      setFocus(focused => !focused)
      toggleOpen()
    }
  }, [])

  const search = (value: string) => setList(options.filter((driver: IDriver) =>
    driver.name.toLowerCase().indexOf(value.toLowerCase()) > -1
  ))

  const onInputChange = (event: any) => {
    const value = event.target.value
    setInputValue(value)
    search(value)
    setOpen(true)

    if(value.length <= 0) {
      onChange(event, null)
    }
  }

  const renderIcon = useCallback(() => {
    if (inputValue !== '' && !open) {
      return (
        <FlatButton
          className={styles.clearInput}
          disabled={disabled}
          onClick={clearInput}>
          <FontAwesomeIcon icon={faTimes} />
        </FlatButton>
      )
    }

    if (open) {
      return (
        <FlatButton
          className={styles.clearInput}
          onClick={handleToggleDropdown}
        >
          <FontAwesomeIcon icon={faChevronUp}/>
        </FlatButton>
      )
    }

    return (
      <FlatButton
        className={styles.clearInput}
        onClick={handleToggleDropdown}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </FlatButton>
    )
  }, [inputValue, open])

  useEffect(() => {
    setList(options)
    setDriver(value)
  }, [options, value, setDriver])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className={clsx(styles.form, styles.dropDown, focused && styles.overlay,  className)}>
        <List
          aria-label="dropdown"
          disablePadding
          classes={{
            root: clsx({
              [styles.disabled]: disabled,
              [styles.listRoot]: !disabled && !focused,
              [styles.focused]: !disabled && focused,
            })
          }}
        >
          <ListItem
            disabled={disabled}
            classes={{
              gutters: clsx({
                [styles.gutters]: value,
              }),
            }}
            className={clsx(styles.listItem)}
          >
            <Input
              disabled={disabled}
              classes={{
                root: styles.inputRoot,
              }}
              placeholder={`${translate('SELECT_DRIVER')}...`}
              aria-label="dropdownValue"
              disableUnderline
              autoComplete="off"
              onChange={onInputChange}
              onFocus={onInputFocused}
              value={inputValue
                ? inputValue
                : ''
              }
              id={id}
              name={name}
            />
            {
              !focused && <FuelType disabled={disabled} fuelType={fuelType} />
            }
            {renderIcon()}
          </ListItem>
          <Collapse in={open} timeout={200} unmountOnExit addEndListener={() => null}>
            {children}
            <PerfectScrollbar className={styles.collapseWrapper}>
              {useMemo(() => (
                <OptionContainer
                  list={list}
                  onClick={handleClick}
                  assignedDriverIds={assignedDriverIds}
                />
              ), [list, assignedDriverIds])}
            </PerfectScrollbar>
          </Collapse>
        </List>
      </Box>
    </ClickAwayListener>
  )
}

const FuelType = memo(({fuelType, disabled} : any) => {
  return (
    <span className={clsx(styles["fuel-type-badge"], styles[fuelType], disabled && styles["fuel-type-disabled"])}>
      <Translate>{fuelType.toUpperCase()}</Translate>
    </span>
  )
})

export default AutoComplete
