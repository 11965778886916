import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { FieldArray, Form, Formik } from 'formik'

import Translate from '../../components/Translate'
import DateContainer from '../../components/collection-tours/DateContainer'
import TourCard from '../../components/collection-tours/TourCard'
import TourDialog from '../../components/collection-tours/TourDialog'

import connect from './connect'
import { getDriversWUsage, isBeforeToday, validateTours } from './helpers'
import NoCollectionTours from './NoCollectionTours'
import Page from '../../components/Page'

import classes from './RouteCollectionTours.module.scss'

export const RouteCollectionTours = ({
  isSubcoReady, hasFetched, isLoading, toursOnDates, drivers, selectedTour,
  fetchTours, saveTours, setSelected, currentLocale,
}) => {
  React.useEffect(() => {
    if (!isLoading && !hasFetched && isSubcoReady) {
      fetchTours()
    }
  }, [isSubcoReady, isLoading, hasFetched, fetchTours])

  const handleCloseTour = React.useCallback(
    () => setSelected(null),
    [setSelected],
  )

  return (
    <Page
      title={<Translate>COLLECTIONS</Translate>}
    >
      {toursOnDates && !!toursOnDates.length && (
        <div className={classes.container}>
          {toursOnDates.map(({ instanceId, date, tours, isLoading }) => (
            <Formik
              key={instanceId}
              validateOnMount
              initialValues={{ date, tours }}
              validate={validateTours}
              onSubmit={saveTours}
            >
              {({ values: { tours } }) => {
                const markedDrivers = React.useMemo( /* eslint-disable-line */
                  () => getDriversWUsage(drivers, tours),
                  [drivers, tours],
                )
                const isBefore = useMemo( /* eslint-disable-line */
                  () => isBeforeToday(date),
                  [date],
                )

                return (
                  <Form style={{ flex: toursOnDates.length }}>
                    <DateContainer disabled={isBefore} date={date} isLoading={!!isLoading} lang={currentLocale}>
                      <FieldArray name="tours">
                        {() => tours.map((tour, index) => (
                          <TourCard
                            disabled={isBefore}
                            key={tour.id}
                            name={`tours.[${index}].driverId`}
                            remark={tour.remark}
                            tourNumber={index + 1}
                            driverId={tour.driverId}
                            drivers={markedDrivers}
                            onOpenTour={() => setSelected(tour.id)}
                          />
                        ))}
                      </FieldArray>
                    </DateContainer>
                  </Form>
                )
              }}
            </Formik>
          ))}
        </div>
      )}

      {(!toursOnDates || !toursOnDates.length) && !isLoading && (
        <div className={classes.noResultsContainer}>
          <NoCollectionTours />
        </div>
      )}

      {selectedTour && (
        <TourDialog
          tour={selectedTour}
          onClose={handleCloseTour}
        />
      )}
    </Page>
  )
}

RouteCollectionTours.propTypes = {
  isSubcoReady: PropTypes.number,
  hasFetched: PropTypes.bool,
  isLoading: PropTypes.bool,
  toursOnDates: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    tours: PropTypes.array,
  })),
  drivers: PropTypes.array,
  selectedTour: PropTypes.object,
  fetchTours: PropTypes.func,
  saveTours: PropTypes.func,
  setSelected: PropTypes.func,
  currentLocale: PropTypes.string,
}

RouteCollectionTours.defaultProps = {
  isSubcoReady: false,
  hasFetched: false,
  isLoading: false,
  toursOnDates: [],
  drivers: [],
  selectedTour: null,
  fetchTours: () => null,
  saveTours: () => null,
  setSelected: () => null,
  currentLocale: 'nl',
}

export default connect(RouteCollectionTours)
