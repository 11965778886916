import React from 'react'
import { InputField } from '../../InputField/InputField'
import Translate from '../../Translate'

const PhoneNumber = props => {
  const handleClear = () => {
    props.form.setFieldValue(props.name, '')
  }

  return (
    <InputField
      label={<Translate capitalize>PHONE_NUMBER</Translate>}
      valid={!props.error}
      placeholder={'+31'}
      {...props}
      resetField={handleClear}
    />
  )
}

export default PhoneNumber
