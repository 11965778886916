import React, {memo} from 'react'
import clsx from 'clsx'
import get from 'lodash/get'

import styles from './styles.module.scss'

enum BUTTON_TYPES {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  DEFAULT = 'default',
  LINK = 'link'
}

const Button = ({children, className, variant = BUTTON_TYPES.DEFAULT, ...props}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {variant?: BUTTON_TYPES|string}) => {
  const contents = React.Children.toArray(children);
  const isIcon = contents.length === 1 && get(contents, '[0].type.displayName', '').toLowerCase() === 'icon'

  return(
    <button {...props} className={clsx(styles.button, get(styles, variant), isIcon && styles.icon, className)}>
      {contents}
    </button>
  )
}

export default memo(Button)
