import React, {memo} from 'react'
import {ReactComponent as ShipmentsIcon} from '../../images/sidemenu/shipments-icon.svg'
import {ReactComponent as ToursIcon} from '../../images/sidemenu/tours-icon.svg'
import {ReactComponent as HandoverIcon} from '../../images/sidemenu/handover-icon.svg'
import {ReactComponent as TourProgressIcon} from '../../images/sidemenu/tour-overview-icon.svg'
import {ReactComponent as TodoListIcon} from '../../images/sidemenu/todo-list-icon.svg'
import {ReactComponent as HubInventoryIcon} from '../../images/sidemenu/hub-inventory-icon.svg'
import {ReactComponent as UserIcon} from '../../images/sidemenu/user-icon.svg'
import {ReactComponent as ReturnsScanIcon} from '../../images/sidemenu/returns-scan-icon.svg'
import {ReactComponent as LogOffIcon} from '../../images/sidemenu/log-off-icon.svg'

const iconDictionary = {
  'shipments' : <ShipmentsIcon/>,
  'tours' : <ToursIcon/>,
  'handover' : <HandoverIcon/>,
  'tour-progress' : <TourProgressIcon/>,
  'todo-list' : <TodoListIcon/>,
  'hub-inventory' : <HubInventoryIcon/>,
  'user' : <UserIcon/>,
  'returns-scan' : <ReturnsScanIcon/>,
  'log-off': <LogOffIcon/>
} as {[key: string] : React.ReactNode}

const SideMenuIcon = ({ icon }: { icon: string }) => {
  const svg = iconDictionary[icon]

  return(
    <>{svg}</>
  )
}

export default memo(SideMenuIcon)
