import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getSelectedCollations } from 'selectors/ui'
import { ITour } from '../../@models'
import { getShipmentTours } from 'selectors/combined'
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps'
import { getDepotCoordinates } from 'selectors/profile';
import { mapStyles } from './mapStyles';

import MapActions  from '../MapActions.tsx';
import GoogleMarker from '../Marker'
import isEmpty  from 'lodash/isEmpty';
import Directions from './Directions'

export interface IGoogleMapsProps {
    shown: number[]
    zoom: number
    onGoogleMapLoad: any
    enableScroll: boolean
    hiddenTours: string[]
}

export const GoogleMaps = ({
    shown, onGoogleMapLoad, zoom, enableScroll, hiddenTours
}: IGoogleMapsProps) => {
    const selectedMarkers = useSelector(getSelectedCollations)
    const [showActionContainer, setShowActionContainer] = React.useState(false)
    const tours = useSelector(getShipmentTours)
    const depotCoordinates = useSelector(getDepotCoordinates)

    useEffect(() => {
      if(isEmpty(selectedMarkers)) {
        setShowActionContainer(false)
      } else {
        setShowActionContainer(true)
      }
    }, [selectedMarkers])

    const isSingleTour = shown.length === 1

    return (
    <div>
      <GoogleMap
        ref={onGoogleMapLoad}
        zoom={zoom}
        defaultCenter={{ lat: 51.990276, lng: 5.103033 }}
        options={{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          scrollwheel: true,
          styles: mapStyles as any,
          disableDoubleClickZoom: true
        }}
      >
        <Marker
          position={depotCoordinates}
          defaultAnimation={0}
          icon="https://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=home|BDBDBD|ffffff"
        >
          {React.useMemo(() =>
          tours.map((tour: ITour) => (shown.includes(tour.id) && !hiddenTours.includes(tour.id.toString())) && (
            <React.Fragment key={tour.id}>
              <GoogleMarker isSingleTour={isSingleTour} tour={tour}/>
              <Directions tour={tour} />
            </React.Fragment>
            ))
          ,[shown, tours, hiddenTours])}
        </Marker>
      </GoogleMap>
      {showActionContainer &&  <MapActions/> }
    </div>
    )
}


export default withGoogleMap(GoogleMaps)
