import React, { Component } from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import styles from './Badge.module.scss'

export const BADGE_TYPES = {
  INFO: 'info',
  WARNING: 'warning',
}

export const Badge = ({
  label,
  style,
  type,
}) => (
  <div className={clsx(
    styles.badgeContainer,
    styles[type],
  )} style={style}>
    <span className={styles.badgeLabel}>{label}</span>
  </div>
)

Badge.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  type: PropTypes.string,
  style: PropTypes.any,
}

export default Badge
