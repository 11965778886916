import {FREEZER_LOG_NEXT_STEP} from "Trunkrs-SDK/dist/constants";

export interface IFreezerManagementState {
  isLoading: boolean
  hasFetched: boolean
  expectedShipments: INormalizedEntity<IFreezerLog>
  shipmentsInFreezer: INormalizedEntity<IFreezerLog>
}

export interface IFreezerLog {
  shipmentId: number
  location: {
    address: string
    city: string
    postalCode: string
  }
  driverName?: string
  merchantName?: string
  createdAt?: Date | string
  nextStep?: FREEZER_LOG_NEXT_STEP
}

export interface IFreezerLogPayload {
  expectedShipments: Array<IFreezerLog>
  shipmentsInFreezer: Array<IFreezerLog>
}

export enum FREEZER_STATE {
  FREEZER_IN = 'FREEZER_IN',
  FREEZER_OUT = 'FREEZER_OUT',
  FREEZER_RETURN = 'FREEZER_RETURN',
  FREEZER_NOT_ACCEPTED = 'FREEZER_NOT_ACCEPTED'
}

export interface INormalizedEntity<T> {
  identifiers: Array<number>
  data: {[key: string] : T}
}

export interface IReduxAction<T = any> {
  type: string
  payload: T
}

