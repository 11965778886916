export default [
  {
    text: 'English',
    value: 'en'
  },
  {
    text: 'Nederlands',
    value: 'nl'
  },
  {
    text: 'Francais',
    value: 'fr'
  },
] as Array<{text: string, value: string}>

export const EditModel = ['name', 'subcontractorId', 'picture', 'invoiceSubcontractorId', 'isPlanner', 'userPreferences' ]
export const UserModel = ['name', 'phoneNumber', 'isPlanner', 'userPreferences' ]

