import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment, { Moment } from 'moment'
import clsx from 'clsx'

import Typography from '@material-ui/core/Typography/Typography'
import OptimizeIcon from 'material-ui/svg-icons/action/update'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'

import { Button } from '../../common/Button/Button'
import { TourMessage } from './TourAlertMessage'
import Translate from '../../Translate'

import { useInterval } from '../../../hooks'
import * as fromTours from '../../../selectors/tour'
import * as fromWarehouse from '../../../selectors/warehouse'

import { isDevelopment, PLANNING_AVAILABILITY_TIME } from '../../../constants'
import { translate, useTranslate } from 'utils/translate'

import './TourToolbar.module.scss'
import styles from './TourToolbar.module.scss'
import { clearSelected } from 'actions/ui'
import { Callout } from '../../base'
import { BANNER_TYPES } from 'actions/warehouse'

interface ITourToolbarProps {
  isLocked: boolean;
  saveTours: (lock?: boolean) => Promise<void>;
  overflowed: boolean;
  handleClickRight: () => void;
  handleClickLeft: () => void;
  isPlanningLocked: boolean;
  openOptimizeModal: () => void;
  areToursSorted: boolean;
  areAllShipmentsInTours: boolean;
  optimizeTours: () => void;
  saveDrivers: () => void;
  tourPageState: { timeUserOnPage?: Moment };
  setTourPageState: (timeUserOnPage?: Moment) => void;
}

export const TourToolbar: React.FunctionComponent<ITourToolbarProps> = ({
  isLocked,
  saveTours,
  overflowed,
  handleClickRight,
  handleClickLeft,
  isPlanningLocked,
  openOptimizeModal,
  areToursSorted,
  areAllShipmentsInTours,
  optimizeTours,
  saveDrivers,
  setTourPageState,
}) => {
  const dispatch = useDispatch()

  const tourPageState = useSelector(fromTours.getTourPageState)
  const whatBannerShouldDisplayState: BANNER_TYPES = useSelector(fromWarehouse.getWhatBannerShouldDisplay)
  const [centralSortingDone, setCentralSortingDone] = useState(false)

  const forReloading = useRef(false)

  const checkPlanningAvailability = useCallback(() => {
    const isPastPlanningTime = moment().isAfter(
      moment(PLANNING_AVAILABILITY_TIME, 'HH:mm'),
    )
    if (isPastPlanningTime) {
      stopPolling()
    }
    setCentralSortingDone(isPastPlanningTime)
  }, [])

  const [startPolling, stopPolling] = useInterval(
    checkPlanningAvailability,
    1000,
  )

  useEffect(() => {
    const currentTimestamp = moment()

    if (!tourPageState.timeUserOnPage) {
      dispatch(setTourPageState(currentTimestamp))
    }

    if (
      currentTimestamp.isBefore(moment(PLANNING_AVAILABILITY_TIME, 'HH:mm'))
    ) {
      forReloading.current = true
      startPolling()
    } else {
      setCentralSortingDone(true)
    }

    return () => {
      stopPolling()
    }
  }, [])

  const handleSaveTours = useCallback(async() => {
    saveDrivers()
    saveTours()

    dispatch(clearSelected())
  }, [saveTours, saveDrivers, clearSelected, dispatch])

  const toggleSaveTours = useCallback(async() => {
    centralSortingDone ? startPolling() : stopPolling()
    setCentralSortingDone(!centralSortingDone)
  }, [centralSortingDone])

  const isDisabled = useMemo(() => !centralSortingDone, [centralSortingDone])

  const { t } = useTranslate('GLOBAL_NOTIFICATIONS')

  return (
    <div className={styles.wrapper}>
      <div className={styles['tour-toolbar-wrapper']}>
        <div>
          <Typography className={styles.title} variant="h1">
            <Translate>TOURS</Translate>
          </Typography>
        </div>
        <div
          className={clsx(
            'tour-actions-container',
            styles.tourActionsContainer,
          )}
        >
          <Button
            type="danger"
            label={<Translate capitalize={true}>CREATE_NEW_TOURS</Translate>}
            icon={<OptimizeIcon color="currentColor" />}
            onClick={openOptimizeModal}
            disabled={isLocked || isPlanningLocked}
            className={styles.danger}
          />
          {!isPlanningLocked && (
            <Button
              className={styles.success}
              type="success"
              label={<Translate capitalize={true}>SAVE_PLANNING</Translate>}
              onClick={handleSaveTours}
              icon={<FontAwesomeIcon icon={faSave} />}
              disabled={isDisabled}
            />
          )}

          {isDevelopment && (
            <Button
              type="info"
              label={translate(
                isDisabled ? 'ENABLE_PLANNING' : 'DISABLE_PLANNING',
              )}
              onClick={toggleSaveTours}
            />
          )}
        </div>
      </div>
      <div style={{ paddingRight: 16 }}>
        {
          whatBannerShouldDisplayState === BANNER_TYPES.BUSY_DAY && <Callout className={styles.callout} variant="primary">{t('BUSY_PEAK_DAYS')}</Callout>
        }
        {
          whatBannerShouldDisplayState === BANNER_TYPES.CENTRAL_SORTING && <Callout className={clsx(styles.callout, styles['danger-banner'])} variant="danger">{t('CENTRAL_SORTING_IS_DONE')}</Callout>
        }
        {!isPlanningLocked && (
          <TourMessage
            centralSortingDone={centralSortingDone}
            withReload={forReloading.current}
          />
        )}
      </div>
    </div>
  )
}

export default TourToolbar
