import * as actionTypes from '../constants/ActionTypes'
import uniq from 'lodash/uniq'

// 1.0.2 - 15/06/17
// 1.0.3 - 27/06/17
// 1.1.0 24/07/17
// currentVersion: 1.2.0 11/08/17
//// Major and minor updates trigger the update notification, patches do not (Major.Minor.patch)

const initialState = {
  appName: 'Plannr',
  appVersion: '1.2.0',
  showSidebar: false,
  showSidebarMargin: 256,
  sidebarMargin: 0,
  loading: false,
  loadMessage: null,
  optimizing: false,
  optimizeModal: false,
  speed: 'normal',
  traffic: 'normal',
  balance: false,
  open_end: false,
  min_vehicles: true,
  number_of_drivers: 5,
  min_visits_per_vehicle: 5,
  new_routific_version: false,
  setting_cluster: false,
  setting_zoom: false,
  showUpdate: false,
  selectedCollations: [],
  loginError: null,
  sidebarCollapsed: false,
  hasOptimizationError: false,
  powerMode: true,
  optimizeResult: undefined,
}

export default function ui(state = initialState, action) {
  switch (action.type) {
  case actionTypes.TOGGLE_SIDEBAR:
    return Object.assign({}, state, {
      showSidebar: !state.showSidebar,
      sidebarMargin: state.showSidebarMargin * !state.showSidebar,
    })

  case actionTypes.TOGGLE_LOADING:
    return Object.assign({}, state, {
      loading: !state.loading,
      loadMessage: action.payload,
    })

  case actionTypes.RESET_LOADING:
    return Object.assign({}, state, {
      loading: false,
    })

  case actionTypes.SET_OPTIMIZING:
    return Object.assign({}, state, {
      optimizing: action.payload,
    })

  case actionTypes.SET_SETTING:
    return Object.assign({}, state, {
      ...action.payload,
    })

  case actionTypes.SET_LOADING:
    const {
      payload: { loading, loadMessage },
    } = action

    return {
      ...state,
      loading,
      loadMessage,
    }

  case actionTypes.TOGGLE_SELECTED_COLLATION:
    const { selectedCollations } = state

    const newCollations = selectedCollations.includes(action.payload)
      ? selectedCollations.filter(id => id !== action.payload)
      : [...selectedCollations, action.payload]

    return {
      ...state,
      selectedCollations: newCollations,
    }

  case actionTypes.SELECT_MULTI_COLLATIONS:
    const uniqueSelectedCollations = uniq([...state.selectedCollations, ...action.payload])
    return {
      ...state,
      selectedCollations: uniqueSelectedCollations,
    }

  case actionTypes.CLEAR_SELECTED_COLLATIONS:
    return { ...state, selectedCollations: [] }

  case actionTypes.SET_LOGIN_ERROR:
    return { ...state, loginError: action.payload }

  case actionTypes.LOAD_COLLECTION_TOURS:
    return { ...state, loading: true, loadMessage: null }

  case actionTypes.LOAD_COLLECTION_TOURS_SUCCESS:
  case actionTypes.LOAD_COLLECTION_TOURS_FAILED:
    return { ...state, loading: false }

  case actionTypes.SET_SIDEBAR_COLLAPSED:
    return { ...state, sidebarCollapsed: action.payload }

  case actionTypes.SET_HAS_OPTIMIZATION_ERROR:
    return { ...state, hasOptimizationError: action.payload }

  case actionTypes.SET_POWER_MODE:
    return { ...state, powerMode: action.payload }

  case actionTypes.SET_OPTIMIZE_DONE:
    return { ...state, optimizeResult: action.payload }

  default:
    return state
  }
}

export const selectIsLoading = state => state.loading
export const selectPowerMode = state => state.powerMode
export const selectOptimizeResult = state => state.optimizeResult
export const selectLoadMessage = state => state.loadMessage
export const selectAppVersion = state => state.appVersion
export const selectSelectedCollations = state => state.selectedCollations
export const selectOptimizeModalShown = state => state.optimizeModal
export const selectSidebarCollapsed = state => state.sidebarCollapsed
export const selectHasOptimzationError = state => state.hasOptimizationError
