import React, { memo, useCallback } from 'react'
import { useTranslate } from 'utils'
import { useDispatch } from 'react-redux'
import {reloadShipments} from '../../../../../actions/shipments'

import styles from './styles.module.scss'
import {Button, Icon} from "../../../../base";


const ShipmentEmpty = () => {
  const { t } = useTranslate('SHIPMENTS_OVERVIEW')

  const dispatch = useDispatch()

  const handleReload = useCallback(() => {
      dispatch(reloadShipments())
  }, [])

  return (
    <div className={styles.container}>
      <img src={require('../../../../../images/empty-banner.svg')}/>
      <div>
        <span>{t('EMPTY_TITLE')}</span>
        <div>{t('EMPTY_DESC')}</div>
      </div>
      <Button
        variant='success' onClick={handleReload}>
        <Icon name="retry"/>
        {t('RELOAD_BUTTON')}
      </Button>
    </div>
  )
}

export default memo(ShipmentEmpty)
