import React, { memo, useMemo, useCallback, useEffect, useRef, useState } from 'react'

import * as fromCombined from '../../../selectors/combined'
import * as fromProfile from '../../../selectors/profile'
import * as fromUi from '../../../selectors/ui'

import { MAP } from 'react-google-maps/lib/constants'
import { MultiMoveCollations } from '../Map/MultiMoveCollations'
import { clearSelected, selectMultipleCollations } from '../../../actions/ui'
import { addTour, moveMultiple } from '../../../actions/tours'
import { useDispatch, useSelector } from 'react-redux'
import { ToolbarGroup } from 'material-ui/Toolbar'
import { ICollation } from '../@models'
import { ITour } from 'models'

import MenuItem from 'material-ui/MenuItem'
import FontIcon from 'material-ui/FontIcon'
import IconMenu from 'material-ui/IconMenu'
import FlatButton from 'material-ui/FlatButton'
import Checked from 'material-ui/svg-icons/toggle/check-box'
import Unchecked from 'material-ui/svg-icons/toggle/check-box-outline-blank'
import clsx from 'clsx'
import Toolbar from '../../toolbar/Toolbar'
import styles from './styles.module.scss'
import Translate from '../../Translate'
import TourList from '../Map/TourList'
import FilterIcon from '../../../images/filter.svg'
import EventListener from 'react-event-listener'
import lodashMap from 'lodash/map'
import get from 'lodash/get';
import GoogleMaps  from './Resources/Map'
import isEmpty from 'lodash/isEmpty';
import {usePlannerStore} from "../../../stores/planner";
import { isBermudaStop, isValidCollation } from 'actions/tours/helpers'
import {Callout, MiniToast} from "../../base";
import map from 'lodash/map'
import { useTranslate } from 'utils/translate'
import filter from 'lodash/filter'
import chunk from 'lodash/chunk'

export interface IMapProps {
  tourShown: number[]
  editMode?: boolean
  showToolbar?: boolean
}

export interface IMapStateProps {
  enableScroll: boolean
  zoom: number
  shown: number[]
}

export const Map = ({
  tourShown,
  editMode = false,
  showToolbar = false
 }: IMapProps) => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const mapRef = useRef<any>()

  const [mapState, setMapState] = useState<IMapStateProps>({
    enableScroll: false,
    zoom: 0,
    shown: []
  })

  const [selectedMarkers, setSelectedMarkers] = useState<Array<{collationId: string, tourId: string}>>([])

  const {hiddenTours} = usePlannerStore()
  const tours = useSelector(fromCombined.getShipmentTours)
  const depotCoordinates = useSelector(fromProfile.getDepotCoordinates)
  const selectedCollations = useSelector(fromUi.getSelectedCollations)

  const [showNotification, setShowNotification] = useState(false)
  const [text, setText] = useState('')

  const handleCloseNotification = useCallback(() => setShowNotification(false), [])

  const getValidMarkers = useMemo(() => {
    const markers = tours.reduce((acc: any, tour: any) => {
      if(tourShown.includes(tour.id)) {
        const filtered = tour.collations.filter(isValidCollation).map((collation: any) => ({...collation, tourId: tour.id}))
        acc = acc.concat(filtered)
      }
      else if(isEmpty(tourShown) && !hiddenTours.includes(tour.id.toString())) {
        const filtered = tour.collations.filter(isValidCollation).map((collation: any) => ({...collation, tourId: tour.id}))
        acc = acc.concat(filtered)
      }
      return acc
    }, [])
    return markers
  }, [hiddenTours, tours, tourShown])

  useEffect(()=>{
    const filteredCollations = selectedMarkers.filter(({tourId}) => !hiddenTours.includes(tourId.toString())).map(({collationId}) => collationId)
    handleSelectMultipleCollations(filteredCollations)
  }, [selectedMarkers])

  useEffect(() => {
    if (tourShown.length >= 1) {
      return setMapState({ ...mapState, shown: tourShown })
    }
    setMapState({ ...mapState, shown: lodashMap(tours, 'id') })
  }, [tourShown, tours])


  useEffect(() => {
    const tour = tours.filter(t => t.hasBermudaStop)
    if(!isEmpty(tour)) {
      const toursHasBermuda = filter(tours, (bermudaTours) => bermudaTours.hasBermudaStop).map(t => t.number)
      const string = toursHasBermuda.length >= 3 ? toursHasBermuda.join(', ') : toursHasBermuda.join(',')
      const length = string.length

      if(length >= 7) {
        setText(string.substring(0, length - 3).concat(' and ', string.substring(length - 1, length)))
      }else if(length >= 3) {
        setText(string.replace(',', ' and '))
      } else {
        setText(string)
      }
      setShowNotification(true)
    } else {
      setShowNotification(false)
    }
  } ,[tours, setShowNotification])


  const handleAddTour = useCallback(() => {
    dispatch(addTour(selectedCollations))
    dispatch(clearSelected())
  }, [addTour, clearSelected, selectedCollations])

  const handleSelectMultipleCollations = useCallback((collations) => {
    if(!isEmpty(collations)) {
      dispatch(selectMultipleCollations(collations))
    }
  }, [selectMultipleCollations, clearSelected])

  const handleMoveMultiple = useCallback((newTour) => {
    dispatch(moveMultiple(newTour.id, selectedCollations))
    dispatch(clearSelected())
  }, [moveMultiple, selectedCollations])

  const activateWindowListeners = useCallback(() => {
    if(mapRef.current && !mapRef.current.listenerActivated) {
      const mapInstance =  mapRef.current.context[MAP]


      let isMouseDown: boolean = false
      let isShiftPressed: boolean = false
      let gribBoundingBox: any
      let mouseDownPos: number = 0
      let selectedCollations: Array<{collationId: string, tourId: string}> = []

      window.addEventListener("keydown", event => {
        if(event.key === "Shift") {
          isShiftPressed = true
        }
      });
      window.addEventListener("keyup", event => {
        if(event.key === "Shift") {
          isShiftPressed = false
        }
      });

      window.google.maps.event.addListener(mapInstance, "mousemove", (e) => {
        if (isMouseDown && isShiftPressed) {
          if (!!gribBoundingBox) {
              // box exists
              const newbounds = new window.google.maps.LatLngBounds(mouseDownPos as any, undefined);
              newbounds.extend(new window.google.maps.LatLng(
                e.latLng.lat(),
                e.latLng.lng(),
              ));

              gribBoundingBox.setBounds(newbounds)
          } else {

            gribBoundingBox = new window.google.maps.Rectangle({
              map: mapInstance,
              strokeColor: '#201047',
              strokeWeight: 1.5,
              fillColor: '#201047',
              fillOpacity: 0.1,
              clickable: false,
              bounds: undefined,
            })
          }
        }
       });

       window.google.maps.event.addListener(mapInstance, "mousedown", (e) => {
        if (isShiftPressed) {
          isMouseDown = true
          mouseDownPos = e.latLng
          mapInstance.setOptions({
              draggable: false
          });
        }
       });

       window.google.maps.event.addListener(mapInstance, "mouseup", function() {
          isMouseDown = false

        if(!!gribBoundingBox) {
          getValidMarkers.forEach((stop: any) => {
            const latitude = get(stop, 'coord.lat', 0)
            const longiture = get(stop, 'coord.lng', 0)

            if (!!latitude && !!longiture &&
              !!gribBoundingBox!.getBounds() && gribBoundingBox!.getBounds()
              .contains(new window.google.maps.LatLng(latitude, longiture))) {
                selectedCollations.push({collationId: stop.id, tourId: stop.tourId})
            }
          })

          gribBoundingBox.setMap(null);
          gribBoundingBox = null

          setSelectedMarkers(selectedCollations)
          selectedCollations = []
        }
      mapInstance.setOptions({
          draggable: true
      });
    });
    mapRef.current.listenerActivated = true
    }
  }, [mapRef.current, getValidMarkers])

  const fitToActiveTours = useCallback((mapReference) => {
    const map = mapReference ? mapReference : mapRef.current

    if (tours && map) {
      const bounds = new window.google.maps.LatLngBounds()

      if (depotCoordinates.lat && depotCoordinates.lng) {
        bounds.extend(new window.google.maps.LatLng(
          depotCoordinates.lat,
          depotCoordinates.lng,
        ))
      }
      tours.filter(({ id } : any) => mapState.shown.includes(id))
        .forEach((tour: ITour) => {
          if (tour) {
            tour.collations.forEach(({ coord }: ICollation) => {
              !!coord.lat && !!coord.lng && bounds.extend(
                new window.google.maps.LatLng(coord.lat, coord.lng))
            })
          }
        })
      map.fitBounds(bounds)
    }
  }, [tours, mapState.shown])


  const handleMapMounted = useCallback((map) => {
    if (!map) return
    mapRef.current = map
    mapRef.current.isReady = true

    if (editMode) {
      setTimeout(() => fitToActiveTours(map))
    } else {
      fitToActiveTours(map)
    }
    activateWindowListeners()

  }, [tours, fitToActiveTours, editMode, mapRef])

  useEffect(() => {
    if(!!mapRef.current
      && !!mapRef.current.isReady) {
        mapRef.current.listenerActivated = false
        const mapInstance =  mapRef.current.context[MAP]
        window.google.maps.event.clearInstanceListeners(mapInstance)
      activateWindowListeners()
    }
  } , [getValidMarkers, mapRef.current])

  const setZoom = useCallback((e) => {
    const { current: map } = mapRef
    const currentZoom = map ? map!.getZoom() : 7

    if (!e.ctrlKey) {
      if (e.keyCode === 187 && currentZoom <= 18) {
        setMapState({ ...mapState, zoom: currentZoom + 1 })
      }
      if (e.keyCode === 189 && currentZoom > 0) {
        setMapState({ ...mapState, zoom: currentZoom - 1 })
      }
    }
  }, [mapRef.current])

  const fit = useCallback(() => {
    if (mapRef && mapRef.current.map) {
      fitToActiveTours(mapRef.current.map)
    }
  }, [mapRef.current])


  return (
    <>
      <EventListener
        target="window"
        onKeyDown={setZoom}
      />
      <div className={styles.notificationContainer}>
        <MiniToast variant="danger" visible={showNotification} onClose={handleCloseNotification}>
          <span>{t('BERMUDA_TEXT_NOTIFICATION', { text })}</span>
          <span>{t('BERMUDA_TEXT_MORE_INFO')}</span>
        </MiniToast>
      </div>
      {useMemo(() =>
        <GoogleMaps
          shown={mapState.shown}
          onGoogleMapLoad={handleMapMounted}
          zoom={mapState.zoom}
          enableScroll={mapState.enableScroll}
          mapElement={<div className={styles.map} />}
          hiddenTours={hiddenTours}
          containerElement={(
            <div
              className={clsx(
                styles.mapContainer,
                editMode && styles.editMode,
              )}
            />
          )}
        />
         ,[mapState, mapState.shown, hiddenTours])}

      {showToolbar && (
        <Toolbar
          color='#201047'
          className={clsx(
            styles.actionBar,
            editMode && styles.disabled,
          )}
        >
          <ToolbarGroup firstChild className={styles.toolbar}>
            <IconMenu
              iconButtonElement={
                <FlatButton
                  label={(
                    <>
                      <img src={FilterIcon} className={styles.filterIcon} />
                      <Translate capitalize={true}>VISIBLE_TOURS</Translate>
                    </>
                  )}
                  backgroundColor={'#ffffff'}
                  hoverColor={'#ededed'}
                  labelStyle={{
                    fontSize: 12,
                    textTransform: 'capitalize',
                  }}
                  className={styles.filterButton}
                />
              }
              // onChange={handleRouteChange}
              className={styles.filterMenu}
              menuStyle={{ width: 150 }}
            >
              {tours && tours.map((tour: ITour, index: number) => (
                <TourList
                  key={tour.id}
                  tour={tour}
                  index={index}
                  isShown={mapState.shown.includes(tour.id)}
                />
              ))}
            </IconMenu>

            {!!selectedCollations.length && (
              <MultiMoveCollations
                selected={selectedCollations}
                tours={tours}
                onMoveCollations={handleMoveMultiple}
                onNewTour={handleAddTour}
              />
            )}

            <IconMenu
              iconButtonElement={
                <FontIcon
                  className={clsx('material-icons', styles.settingsIcon)}
                >
                  settings
                  </FontIcon>}
              className={styles.settingsMenu}
              menuStyle={{ width: 200 }}
            >
              <MenuItem
                key={1}
                primaryText={<Translate capitalize={true}>SETTING_ZOOM</Translate>}
                rightIcon={mapState.enableScroll ? <Checked /> : <Unchecked />}
                value="zoom"
              />
            </IconMenu>
            <FontIcon
              className={clsx('material-icons', styles.centerMapButton)}
              onClick={fit}
            >
              settings_overscan
              </FontIcon>
          </ToolbarGroup>
        </Toolbar>
      )}
    </>

  )
}




export default memo(Map)
