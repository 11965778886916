import React, {memo} from 'react'
import {Controller, useFormContext} from "react-hook-form";
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'
import clsx from 'clsx'
import Select, {SelectProps} from "@material-ui/core/Select/Select";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import styles from './styles.module.scss'

const SimpleDropdownMenu = ({id = uniqueId('dropdown-menu-'), className, name, children, ...props} : React.HTMLProps<HTMLInputElement>) => {
  const {control, errors} = useFormContext()
  const errorMsg = get(errors, `${name}.message`)
  const hasError = !!errorMsg

  return (
    <div className={clsx(styles["dropdown-menu-container"], hasError && styles.error)}>
      <Controller
        {...props as any}
        name={name as string}
        control={control}
        as={(renderProps: any) => (
          <Select
            {...renderProps}
            variant="outlined"
            displayEmpty={true}
            MenuProps={{
              PopoverClasses: {
                paper: clsx('select-menu-popover-container', styles.popover, hasError && styles["popover-error"]),
                root: styles.popoverRoot
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 10,
                horizontal: 1,
              },
              getContentAnchorEl: null,
              marginThreshold: -999
            }}
            IconComponent={({className}) => <FontAwesomeIcon icon={className.includes('MuiSelect-iconOpen') ? faChevronUp : faChevronDown} />}
          >
            {children}
          </Select>
        )}
      />
      {
        hasError && <span className={styles["error-message"]}>{errorMsg}</span>
      }
    </div>
  )
}

export const DropdownMenu = ({children, maxListHeight, listClassName, ...props}: SelectProps & {listClassName?: string, maxListHeight?: number}) => (
  <div className={styles["dropdown-menu-container"]}>
    <Select
      {...props as any}
      variant="outlined"
      displayEmpty={true}
      MenuProps={{
        PopoverClasses: {
          paper: clsx('select-menu-popover-container', styles.popover, listClassName),
          root: styles.popoverRoot
        },
        PaperProps: {
          style: {
            maxHeight: maxListHeight
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 10,
          horizontal: 1,
        },
        getContentAnchorEl: null,
        marginThreshold: -999
      }}
      IconComponent={({className}) => <FontAwesomeIcon icon={className.includes('MuiSelect-iconOpen') ? faChevronUp : faChevronDown} />}
    >
      {children}
    </Select>
  </div>
)


export default memo(SimpleDropdownMenu)
