import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import Translate from '../../Translate'
import TourTable from '../TourTable'

import classes from './TourDialog.module.scss'

const TourDialog = ({
  tour, onClose,
}) => (
  <Dialog
    open
    className={classes.dialog}
    classes={{ paper: classes.paper }}>
    <div className={classes.title}>
      <Translate component="h1">
        COLLECTION_TOUR_TITLE
      </Translate>

      <IconButton className={classes.closeBtn} onClick={onClose}>
        <FontAwesomeIcon className={classes.icon} icon={faTimes} />
      </IconButton>
    </div>

    <DialogContent className={clsx(classes.content, !tour.remark && classes.noRemark)}>
      {tour.remark && (
        <p className={classes.remarkTitle}>
          <Translate component="span">TOUR_REMARK</Translate>
          {tour.remark}
        </p>
      )}

      <div className={classes.tableContainer}>
        <TourTable stops={tour.stops} />
      </div>
    </DialogContent>
  </Dialog>
)

TourDialog.propTypes = {
  tour: PropTypes.object.isRequired,
  onClose: PropTypes.func,
}

TourDialog.defaultProps = {
  onClose: () => null,
}

export default TourDialog
