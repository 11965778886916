import { connect } from 'react-redux'

import * as fromTourOverview from '../../selectors/tourOverview'
import * as fromTour from '../../selectors/tour'
import * as fromProfile from '../../selectors/profile'

import { fetchTourOverviews } from '../../actions/tourOverview'

const mapStateToProps = state => ({
  hasTours: fromTour.hasTours(state),
  subcoId: fromProfile.getCurrentSubcoId(state),
  isLoading: fromTourOverview.isLoading(state),
  tourOverviews: fromTourOverview.getTourOverviews(state),
})

const mapDispatchToProps = {
  fetchTourOverviews,
}

export default connect(mapStateToProps, mapDispatchToProps)
