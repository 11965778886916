import React, { useState, useEffect, useCallback } from 'react'

import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import Box from '@material-ui/core/Box'
import PerfectScrollbar from 'react-perfect-scrollbar'
import FlatButton from 'material-ui/FlatButton'
import clsx from 'clsx'
import styles from './styles.module.scss'

import { MapListActions } from './MapListActions'
import { Button } from 'components/common/Button/Button'
import { translate as t } from '../../../../utils'
import { clearSelected } from 'actions/ui'
import { addTour } from 'actions/tours'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedCollations } from 'selectors/ui'

export const MapActions = () => {
    const dispatch = useDispatch()
    const [showListTours, setshowListTours] = useState(false)
  
    const selectedMarkers = useSelector(getSelectedCollations)
    const handleAddTour = useCallback(() => {
        dispatch(addTour(selectedMarkers))
        dispatch(clearSelected())
    }, [addTour, clearSelected, selectedMarkers])

    const handleShowList = useCallback(() => setshowListTours(!showListTours), [setshowListTours])

    const handleDeselect = useCallback(() => dispatch(clearSelected()) , [clearSelected])
  
    return (
      <Paper className={styles.paperActions}>
        <div className={styles.actionsContainer}>
          <div className={styles.tourNo}>{selectedMarkers.length}</div>
            <Button type='info'
              className={styles.info}
              label={t('MOVE_TO_DIFFERENT_TOUR')}
              customLabelStyle={{ fontSize: 12 }}
              onClick={handleShowList}
            />
            <Button
              className={clsx(styles.root, styles.danger)}
              customLabelStyle={{ fontSize: 12 }}
              label={t('DE_SELECT_ALL')}
              onClick={handleDeselect}/>
        </div>
        <Fade in={showListTours}>
          <Box className={styles.listItems}>
            <PerfectScrollbar className={styles.collapseWrapper}>
              <MapListActions/>

              <div className={styles.addNewTourButton}>
                <FlatButton onClick={handleAddTour}>
                  {t('ADD_TO_NEW_TOUR')}
                </FlatButton>
              </div>
            </PerfectScrollbar>
          </Box>
        </Fade>
      </Paper>
    )
}

export default MapActions