import React, {memo} from 'react'
import clsx from 'clsx'
import get from 'lodash/get'

import styles from './styles.module.scss'

export enum PERCENTAGE_CARD_TYPES {
  CENTRAL_SORTED = 'sorted',
  ACCEPTED_BY_DRIVER = 'accepted',
  WITH_END_STATE = 'endState',
  NEIGHBOUR_VS_NON_DELIVERY = 'neighbourNonDelivery'
}

export interface IPercentageCardProps {
  caption: string,
  details: string
  percentage: number,
  variant: string | PERCENTAGE_CARD_TYPES
}

const PercentageCard = ({caption, details, percentage, variant}: IPercentageCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {caption}
      </div>
      <div className={styles.subHeader}>
        {details}
      </div>
      <div className={clsx(styles.percentageBar, get(styles, variant))}>
        {
          !isNaN(percentage) && (
            <div style={{width: `${percentage * 100}%`}}>
              <span>{`${Math.round(percentage * 100)}%`}</span>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default memo(PercentageCard)
