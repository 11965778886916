import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Translate from '../../../Translate'

import classes from './TextBlock.module.scss'

const TextBlock = ({
  variant, title, text,
}) => (
  <div className={classes.colContainer}>
    <Translate
      capitalize
      component="p"
      className={clsx(
        variant === 'default' && classes.title,
        variant === 'address' && classes.text,
        classes.limited,
      )}
    >
      {title}
    </Translate>
    <p className={clsx(
      classes.limited,
      variant === 'default' && classes.text,
      variant === 'address' && classes.subtitle,
    )}>
      {text || 'N/A'}
    </p>
  </div>
)

TextBlock.propTypes = {
  variant: PropTypes.oneOf([
    'default',
    'address',
  ]),
  title: PropTypes.string,
  text: PropTypes.string,
}

TextBlock.defaultProps = {
  variant: 'default',
  title: null,
  text: null,
}

export default React.memo(TextBlock)
