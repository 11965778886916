import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    grow: {
      flexGrow: 1,
    },
    toolbar: {
      padding: 0,
      minHeight: 0,
      marginBottom: '56px',
      alignItems: 'flex-start',
    },
    title: {
      fontFamily: 'ClanPro-WideBold',
      fontSize: '20px',
      textTransform: 'capitalize',
      color: theme.palette.primary.main
    },
  }),
)

export default useStyles
