import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from './checkmarkcard.module.scss'

/**
 * CheckmarkCard
 *
 * @param action: action to perform on CTA, if not provided CTA will be disabled
 * @param checked: whether or not the card is checked
 * @param cta: Call to action link, only enabled if action is added
 * @param description: Description of card, only shown when not checked
 * @param owner: who owns the card (owner shown at top of card)
 * @param title: title describing card
 * @returns {null}
 * @constructor
 */
export const CheckmarkCard = ({
  action,
  checked,
  checkedDescription,
  cta,
  description,
  owner,
  title,
}) => (
  <div className={styles.checkmarkcardWrapper}>
    <div className={clsx(styles.checkmarkcardCheckside, checked && styles.checkmarkcardChecksideChecked)}>
      <div className={clsx(styles.checkmarkcardCheck, checked && styles.checkmarkcardCheckChecked)} />
    </div>
    <div className={styles.checkmarkcardTextSide}>
      <span className={clsx({ [styles.opacity]: checked }, styles.checkmarkcardOwner)}>{owner}</span>
      <span className={clsx({ [styles.opacity]: checked }, styles.checkmarkcardTitle)}>{title}</span>
      <span className={clsx(styles.checkmarkcardDescripiton, checked && styles.checkmarkcardDescripitonChecked)}>{checked ? checkedDescription : description}</span>
      {!checked && (
        <span className={clsx(styles.checkmarkcardCta, action && styles.checkmarkcardCtaClickable)} onClick={action}>
          {cta}
        </span>
      )}
    </div>
  </div>
)

CheckmarkCard.propTypes = {
  checked: PropTypes.bool,
  owner: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  checkedDescription: PropTypes.string,
  action: PropTypes.func,
  cta: PropTypes.string,
}
