import { trunkrs } from '../index'

const CANCELLED_REASON = 'Cancelled in Planner'

export async function cancelShipment(shipmentId) {
  try {
    const shipment = await trunkrs.Shipment().fetch(shipmentId)
    if (!shipment) {
      return { shipmentId, status: 'ERROR ' }
    }
    await shipment.cancel('TRUNKRS', CANCELLED_REASON)
    return { shipmentId, status: 'OK' }
  } catch (error) {
    console.debug(error)
    return { shipmentId, status: 'ERROR' }
  }
}

