import React, {memo, useCallback, useEffect, useState} from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import get from "lodash/get";
import ModalBox from "../ModalBox";
import defaultTo from 'lodash/defaultTo';

enum SCAN_FEEDBACK_TYPES {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

export interface IScanFeedbackProps extends React.DetailedHTMLProps<any, any>{
  title: React.ReactNode|string
  visible?: boolean
  variant?: SCAN_FEEDBACK_TYPES|string
  children?: React.ReactNode
  onClose?: () => void
}

const ScanFeedback = ({title, variant = SCAN_FEEDBACK_TYPES.SUCCESS, className, children, onClose, visible = false, ...props}: IScanFeedbackProps) => {
  const [isVisible, setIsVisible] = useState(visible)

  useEffect(()=>{
    setIsVisible(visible)
  }, [visible])

  const hideFeedback = useCallback(() => {
    setIsVisible(false)
  }, [])

  return(
    <ModalBox visible={isVisible} onClose={defaultTo(onClose, hideFeedback)}>
      <div className={clsx(styles.scanFeedbackContainer, get(styles, variant), className)} {...props}>
        <div className={styles.title}>
          {title}
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </ModalBox>
  )
}

export default memo(ScanFeedback)
