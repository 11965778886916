import React, {HTMLAttributes} from "react";
import InputBase from "@material-ui/core/InputBase/InputBase";

interface IInputProps {
  register?: any;
  name: string;
}

export function Input({ register, name, ...props } : IInputProps) {
  return <InputBase name={name} inputRef={register} {...props}/>
}
