import { createFeatureSelector } from './core'
import { createSelector } from 'reselect'
import * as fromShipmentAndDriverEstimation from '../reducers/shipmentAndDriverEstimation'

const selectFeature = createFeatureSelector('shipmentAndDriverEstimation')

export const getShipments = createSelector(
  selectFeature,
  fromShipmentAndDriverEstimation.shipments,
)

export const getDrivers = createSelector(
  selectFeature,
  fromShipmentAndDriverEstimation.drivers,
)

export const hasFetched = createSelector(
  selectFeature,
  fromShipmentAndDriverEstimation.hasFetched,
)

export const isLoading = createSelector(
  selectFeature,
  fromShipmentAndDriverEstimation.isLoading,
)

export const getRemaining = createSelector(
  selectFeature,
  fromShipmentAndDriverEstimation.remaining,
)
export const getHours = createSelector(
  selectFeature,
  fromShipmentAndDriverEstimation.hours,
)
export const getMinutes = createSelector(
  selectFeature,
  fromShipmentAndDriverEstimation.minutes,
)
export const getSeconds = createSelector(
  selectFeature,
  fromShipmentAndDriverEstimation.seconds,
)
