import React, { memo, useCallback, useMemo, useState } from 'react'
import { useTranslate } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { getShipmentNextStep, batchHubOut } from 'actions/hubLogs'
import { getCurrentSubcoId } from 'selectors/profile'
import { ITYPES } from 'reducers/hublogs'
import { getHubInventoryScanList, getIsBatchHubOutDone, getShipmentsAtRegionalHub,
  getShipmentsBackToTrunkrs, getShipmentsToKeepOnHub, isShipmentReturnedExistOnInventory } from 'selectors/hubLogs'

import ScanInput from '../base/ScanInput'
import Cart from '../base/Cart'
import CartFooter from '../base/Cart/components/CartFooter'
import Button from '../base/Button'
import ScanFeedback from '../base/ScanFeedback'
import Page from '../Page'
import get from 'lodash/get'
import styles from './styles.module.scss'
import ModalBox from 'components/base/ModalBox'
import defaultTo from 'lodash/defaultTo'



const ReturnsScan = () => {
  const { t } = useTranslate('RETURNS_SCAN')
  const dispatch = useDispatch()

  const atRegionalHub = useSelector(getShipmentsAtRegionalHub)
  const keepOnHub = useSelector(getShipmentsToKeepOnHub)
  const backToTrunkrs = useSelector(getShipmentsBackToTrunkrs)
  const isNotAcknowledgedYet = useSelector(isShipmentReturnedExistOnInventory)

  const currentSubcoId = useSelector(getCurrentSubcoId)
  const scanList = useSelector(getHubInventoryScanList)
  const isBatchHubOutDone = useSelector(getIsBatchHubOutDone)

  const [barcodeOrTrunkrsNr, setBarcodeOrTrunkrsNr] = useState('')
  const [value, setValue] = useState('')
  const [acknowledgementVisible, setAcknowledgementVisible] = useState(false)
  const [scanFeedbackVisible, setScanFeedbackVisible] = useState(false)
  const [variant, setVariant] = useState('')

  const activeShipment = useMemo(() => get(scanList, barcodeOrTrunkrsNr), [barcodeOrTrunkrsNr, scanList])

  const showScanFeedback = useCallback(() => {
    setScanFeedbackVisible(true)
    setValue('')
  }, [])
  const hideScanFeedback = useCallback(() => setScanFeedbackVisible(false), [])

  const hideAcknowledgement = useCallback(() => setAcknowledgementVisible(false), [])
  const showAcknowledgement = useCallback(() => setAcknowledgementVisible(true), [])

  const handleBatchHubOut = useCallback(async() => {
    const shipmentsToReturn = backToTrunkrs.reduce((acc: any, shipment: any) => {
      acc.push({ shipmentId: shipment.shipmentId, subcoId: currentSubcoId })
      return acc
    }, [])
    batchHubOut(shipmentsToReturn).finally(() => hideAcknowledgement())

  }, [backToTrunkrs, batchHubOut, currentSubcoId])

  const handleScanInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setScanFeedbackVisible(false)
    setBarcodeOrTrunkrsNr(ev.currentTarget.value)
    setValue(ev.currentTarget.value)
  }, [])


  const handleScan = useCallback(async(ev: React.KeyboardEvent<HTMLInputElement>) => {
    if(ev.charCode === 13) {
      Promise.resolve()
        .then(async() => {
          if(activeShipment && !get(activeShipment, 'type')) {
            await getShipmentNextStep({
              subcoId: currentSubcoId,
              trunkrsNr: activeShipment.trunkrsNr,
              barcode: activeShipment.barcode,
            })
          }
        })
        .finally(() => {
          switch(activeShipment?.type) {
          case ITYPES.KEEP_IN_REGION:
            setVariant('success')
            break
          case ITYPES.BACK_TO_TRUNKRS:
            setVariant('warning')
            break
          case ITYPES.ERROR:
            setVariant('error')
            break
          default:
            setVariant('error')
          }
          showScanFeedback()
        })
    }
  }, [currentSubcoId, activeShipment, getShipmentNextStep, dispatch, setVariant])

  return (
    <Page title={t('TITLE')} className={styles.page}>
      <div className={styles.scanInputContainer}>
        <ScanInput onChange={handleScanInput} value={value}
          disabled={isBatchHubOutDone || !isNotAcknowledgedYet}
          onKeyPress={handleScan} placeholder={t('SCAN_INPUT_PLACEHOLDER')} />
      </div>
      <div className={styles.cartsContainer}>
        <Cart title={t('AT_REGIONAL_HUB')} placeholder={t('AT_REGIONAL_HUB_PLACEHOLDER')}>
          {
            useMemo(() => atRegionalHub.map(({ address, postalCode, shipmentId }) => (
              <div className={styles.address} key={shipmentId}>
                <div>{address}</div>
                <div>{postalCode}</div>
              </div>
            )), [atRegionalHub])
          }
        </Cart>
        <div>
          <Cart title={t('KEEP_IN_REGION')} placeholder={t('KEEP_ON_HUB_PLACEHOLDER')} className={styles.greenCart}>
            {
              useMemo(() => keepOnHub.map(({ address, postalCode, shipmentId }) => (
                <div className={styles.address} key={shipmentId}>
                  <div>{address}</div>
                  <div>{postalCode}</div>
                </div>
              )), [keepOnHub])
            }
          </Cart>
          <Cart title={t('BACK_TO_TRUNKRS')} placeholder={t('BACK_TO_TRUNKRS_PLACEHOLDER')} className={styles.orangeCart}>
            {
              useMemo(() => backToTrunkrs.map(({ address, postalCode, shipmentId }) => (
                <div className={styles.address} key={shipmentId}>
                  <div>{address}</div>
                  <div>{postalCode}</div>
                </div>
              )), [backToTrunkrs])
            }
            <CartFooter>
              {isBatchHubOutDone || !isNotAcknowledgedYet ?
                <div className={styles.returnedSuccess}>
                  <img src={require('../../images/circular_checkmark.svg')}/>
                  {t('RETURNED_SCAN_SUCCESS')}
                </div>
                :
                <Button disabled={backToTrunkrs.length <= 0} onClick={showAcknowledgement} variant='success'>{t('BUTTON_CONFIRM_RETURNS')}</Button>
              }
            </CartFooter>
          </Cart>
        </div>
      </div>
      <ScanFeedback variant={variant} onClose={hideScanFeedback}
        title={t(defaultTo(get(activeShipment, 'type'), 'ERROR'))} visible={scanFeedbackVisible}>
        {
          get(activeShipment, 'type') !== ITYPES.ERROR && activeShipment ?
            <div className={styles.scanFeedbackDetails}>
              <div>
                <div>{t('LAST_DRIVER')}</div>
                <div>{get(activeShipment, 'driverName') || '---'}</div>
              </div>
              <div>
                <div>{t('ADDRESS')}</div>
                <div>{`T: ${get(activeShipment, 'phoneNumber')}`}</div>
                <div>{get(activeShipment, 'address')}</div>
                <div>{`${get(activeShipment, 'postalCode')} ${get(activeShipment, 'city')}`}</div>
              </div>
              <div>
                <div>{t('SENDER')}</div>
                <div>{get(activeShipment, 'sender')}</div>
              </div>
            </div>
            :
            <div className={styles.scanErrorDetails}>
              <div>
                <div>{t('SCAN_ERROR_TITLE')}</div>
                <div>{t('SCAN_ERROR_DETAILS')}</div>
              </div>
            </div>
        }
      </ScanFeedback>
      <ModalBox onClose={hideAcknowledgement} visible={acknowledgementVisible}>
        <div className={styles.acknowledgement}>
          <div>
            <div>{t('ACKNOWLEDGEMENT.TITLE')}</div>
            <div>{t('ACKNOWLEDGEMENT.DESCRIPTION')}</div>
            {atRegionalHub.length > 0 && <div>
              <img src={require('../../images/warning.svg')}/>
              {t('ACKNOWLEDGEMENT.WARNING')}
            </div>}
          </div>

          <div>
            <Button onClick={handleBatchHubOut} variant='success'>{t('ACKNOWLEDGEMENT.CONFIRM')}</Button>
            <Button onClick={hideAcknowledgement} variant='link'>{t('ACKNOWLEDGEMENT.BACK')}</Button>
          </div>
        </div>
      </ModalBox>
    </Page>
  )
}

export default memo(ReturnsScan)
