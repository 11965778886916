import without from 'lodash/without'

import { mergeIndexes } from '../../utils/dataSets'

const removeEmptyTours = (ids, data) =>
  ids.filter(id => {
    const { [id]: tour } = data
    return tour.collations.length <= 2
  })

export const mergeUpdates = (identifiers, data, updatedIds, updates) => {
  const removedIds = removeEmptyTours(updatedIds, updates)
  const changedData = { ...data }
  const changedIdentifiers = without(
    mergeIndexes(identifiers, updatedIds),
    ...removedIds,
  )

  changedIdentifiers.forEach((id, index) => {
    const { [id]: tour } = changedData
    const { [id]: update } = updates

    Object.assign(changedData, {
      [id]: {
        ...tour,
        ...update,
        number: index + 1,
      },
    })
  })

  return [changedIdentifiers, changedData]
}
