import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { browserHistory } from '../../browserHistory'
import { Card } from 'material-ui/Card'
import CircularProgress from 'material-ui/CircularProgress'
import { ToolbarGroup } from 'material-ui/Toolbar'
import Toolbar from '../toolbar/Toolbar'
import logo from '../../images/plannr-logo.svg'
import Translate from '../Translate'
import * as profileActions from '../../actions/profile'
import * as driverActions from '../../actions/drivers'
import {store, trunkrs} from '../../index'
import * as UIActions from '../../actions/ui'
import Loading from '../loading/Loading'
import { translate } from '../../utils/translate'
import PhoneNumberForm from './PhoneNumberForm'
import VerifyCodeForm from './VerifyCodeForm'
import ErrorMessageBox from './ErrorMessageBox'
import * as Sentry from '@sentry/browser'
import OptimizeLoader from 'components/common/OptimizeLoader'
import {useResponseErrorHandler} from "../../hooks";
import get from "lodash/get";
import defaultTo from "lodash/defaultTo";

class Login extends Component {
  state = {
    email: '',
    password: '',
    restoreEmail: '',
    mailSent: false,
    login: true,
    disabled: false,
    codeRequested: false,
    magickLinkParams: undefined,
    errorBoxMessage: '',
    phoneNumber: '',
    digits: ['', '', '', '', '', ''],
  };
  digits = [null, null, null, null, null, null];

  constructor(props) {
    super(props)
  }

  handleChange = name => event => {
    this.setState(
      Object.assign({}, this.state, {
        [name]: event.target.value,
      }),
    )
  };

  handleChangeDigit = event => {
    const [name, index] = event.target.name.split('_')
    const value = event.target.value
    this.setState(
      state => {
        const { digits } = state
        const newDigits = [
          ...digits.slice(0, Number(index)),
          value,
          ...digits.slice(Number(index) + 1),
        ]
        return { ...state, digits: newDigits }
      },
      () => {
        if (value.length === 1 && Number(index) < 5) {
          this.digits[Number(index) + 1].focus()
        }
      },
    )
  };

  handleSendCode = phoneNumber => {
    const { setLoading, setLoginErrorMessage } = this.props.uiActions
    setLoading(true)

    trunkrs
      .Auth()
      .requestMagickLink(phoneNumber)
      .then(params => {
        this.setState({
          phoneNumber,
          magickLinkParams: params,
          codeRequested: true,
        })
      })
      .catch(err => {
        setLoginErrorMessage(translate('PHONE_NUMBER_NOT_REGISTERED'))
      }).finally(() =>  setLoading(false))
  };

  verifyRequest = async digits => {
    const { setLoading, setLoginErrorMessage } = this.props.uiActions

    setLoading(true)

    const { magickLinkParams, phoneNumber } = this.state
    const [, verificationResult] = await useResponseErrorHandler(trunkrs
      .Auth()
      .verifyMagickLink(magickLinkParams, phoneNumber, digits))

    if(verificationResult){
      let [, subcontractors] = await useResponseErrorHandler(trunkrs.Auth().fetchSubcoForUser())

      if(subcontractors) {
        subcontractors = !Array.isArray(subcontractors) ? [subcontractors] : subcontractors

        const [, driverInstances] = await useResponseErrorHandler(get(subcontractors, '[0].getDrivers'))

        if(driverInstances){
          const [, result] = await useResponseErrorHandler(Promise.all(driverInstances))

          const drivers = defaultTo(result, []).map(
            driver => ({
              id: get(driver, 'id'),
              name: get(driver, 'name'),
              email: get(driver, 'getEmailAddress'),
              phoneNumber: get(driver, 'getPhoneNumber'),
              active: get(driver, 'active'),
              isPlanner: get(driver, 'isPlanner'),
              isArchived: get(driver, 'isArchived'),
              picture: get(driver, 'picture'),
              Instance: driver,
            })
          )

          const [, location] = await useResponseErrorHandler(get(subcontractors, '[0].getLocation'))

          store.dispatch(profileActions.login({
            id: get(subcontractors, '[0].getId'),
            accesstoken : get(verificationResult, 'AccessToken'),
            subcos: subcontractors,
            selectedSubco: 0,
            drivers,
            location,
          }))

          store.dispatch(profileActions.setRoles())

          ///setLoading(false)
          await this.props.setLoggedInState()
          browserHistory.push('/shipments')
          return;
        }
      }
    }
    setLoading(false)
    setLoginErrorMessage(translate('WRONG_VERIFICATION_CODE'))
  };

  render() {
    const { ui, uiActions } = this.props
    const { codeRequested, disabled } = this.state

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Card
          style={{
            borderRadius: 5,
            overflow: 'hidden',
          }}
          containerStyle={{
            width: 440,
          }}
        >
          <Toolbar color="#201047">
            <ToolbarGroup>
              <img
                src={logo}
                style={{ width: 120, height: 40, marginLeft: 20 }}
              />
            </ToolbarGroup>
            <ToolbarGroup style={{ marginRight: 20 }}>
              {ui.loading ? (
                <CircularProgress size={20} color="white" thickness={2} />
              ) : null}
            </ToolbarGroup>
          </Toolbar>

          <div style={{ padding: 20 }}>
            <h2 style={{ fontWeight: 400, color: '#30322c' }}>
              <Translate capitalize={true}>LOGIN</Translate>
            </h2>

            {!codeRequested ? (
              <PhoneNumberForm
                disabled={disabled}
                onSubmit={this.handleSendCode}
                setErrorMessage={uiActions.setLoginErrorMessage}
              />
            ) : (
              <VerifyCodeForm
                disabled={disabled}
                onSubmit={this.verifyRequest}
                setErrorMessage={uiActions.setLoginErrorMessage}
              />
            )}
          </div>
        </Card>
        {ui.loginError && <ErrorMessageBox message={ui.loginError} />}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    profileActions: bindActionCreators(profileActions, dispatch),
    driverActions: bindActionCreators(driverActions, dispatch),
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login)
