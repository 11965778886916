import * as yup from 'yup'
import {translate as t} from "../../../utils";

export const defaultValues: IUserFormSchema = {
  name: '',
  isPlanner: false,
  userPreferences: {
    locale: ''
  },
  phoneNumber: ''
}

export default yup.object().shape({
  name: yup.string().required(t('ADD_USER_NAME_REQUIRED')),
  isPlanner: yup
    .bool()
    .default(false)
    .notRequired(),
  userPreferences: yup.object().shape({
    locale: yup.string().required(t('ADD_USER_LANGUAGE_REQUIRED'))
  }),
  phoneNumber: yup.string().required(t('ADD_USER_PHONE_REQUIRED'))
})

export interface IUserFormSchema {
  name: string
  isPlanner: boolean
  userPreferences: {
    locale: string
  },
  phoneNumber: string
}
