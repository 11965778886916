import React from 'react'
import PropTypes from 'prop-types'

import Dialog from 'material-ui/Dialog'
import TextField from 'material-ui/TextField'
import IconButton from 'material-ui/IconButton'
import Button from 'material-ui/FlatButton'

import { translate } from '../../../utils/translate'

import Translate from '../../Translate'
import Close from '../Close'
import TranslatedButton from '../TranslatedButton'

import classes from './CommentModel.module.scss'

const CommentModal = ({
  open, onClose, onSave,
}) => {
  const [value, setValue] = React.useState('')

  const handleOnChange = React.useCallback(
    event => setValue(event.target.value),
    [],
  )

  const handleOnSave = React.useCallback(
    () => onSave(value),
    [value, onSave],
  )

  React.useEffect(() => {
    if (open) setValue('')
  }, [open])

  return (
    <Dialog
      open={open}
      paperProps={{
        className: classes.dialog,
      }}
    >
      <div className={classes.headerContainer}>
        <Translate component="h3">SHIPMENT_COMMENT_TITLE</Translate>
        <IconButton onClick={onClose} className={classes.iconButton}>
          <Close className={classes.icon} />
        </IconButton>
      </div>

      <Translate component="p">
        SHIPMENT_COMMENT_TEXT
      </Translate>

      <TextField
        fullWidth
        multiLine
        autoFocus
        rows={5}
        placeHolder={translate('SHIPMENT_COMMENT_PLACEHOLDER')}
        className={classes.textField}
        value={value}
        onChange={handleOnChange}
      >
      </TextField>

      <div className={classes.actionContainer}>
        <Translate component={Button} className={classes.actionBtn} onClick={onClose}>
          CANCEL
        </Translate>
        <TranslatedButton variant="red" disabled={!value} onClick={handleOnSave}>
          CONFIRM
        </TranslatedButton>
      </div>
    </Dialog>
  )
}

CommentModal.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool,
}

CommentModal.defaultProps = {
  onClose: () => null,
  onSave: () => null,
  open: false,
}

export default CommentModal
