import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons'

import { Button } from '../common/Button/Button'
import { translate } from '../../utils/translate'

import styles from './ErrorPage.module.scss'

const ErrorPage = ({
  onReboot,
}) => (
  <div className={styles.container}>
    <div className={styles.box}>
      <FontAwesomeIcon
        icon={faThumbsDown}
        className={styles.icon}
      />
      <h1 className={styles.title}>
        {translate('ERROR_PAGE_TITLE')}
      </h1>
      <p className={styles.message}>
        {translate('ERROR_PAGE_MESSAGE')}
      </p>

      <Button
        type="success"
        label={translate('RELOAD_APP')}
        onClick={onReboot}
      />
    </div>
  </div>
)

ErrorPage.propTypes = {
  onReboot: PropTypes.func,
}

ErrorPage.defaultProps = {
  onReboot: () => null,
}

export default ErrorPage
