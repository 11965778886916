import React from 'react'
import PropTypes from 'prop-types'

import TranslatedButton from '../../TranslatedButton'

const MissortedActions = ({
  onReturn,
}) => (
  <>
    <TranslatedButton onClick={onReturn} variant="green">
      TODO_RETURNED
    </TranslatedButton>
  </>
)

MissortedActions.propTypes = {
  onReturn: PropTypes.func,
}

MissortedActions.defaultProps = {
  onReturn: () => null,
}

export default MissortedActions
