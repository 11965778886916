import React, { memo, useMemo } from 'react'
import { isValidCollation } from 'actions/tours/helpers'
import { ITour } from 'components/planner/@models'
import GoogleMarker from './GoogleMarker'
import { getSelectedCollations } from 'selectors/ui'
import { useSelector } from 'react-redux'



export interface IMarkerProps {
    tour: ITour
    isSingleTour?: boolean
}

export const Marker = ({
    tour,
    isSingleTour
}: IMarkerProps) => {
    const selectedMarkers = useSelector(getSelectedCollations)
    const stops = useMemo(
        () => tour.collations.filter(isValidCollation as any),
        [tour],
    )
    
    return <>
        {
            stops
            .map((stop: any) => (
            <GoogleMarker 
                tour={tour}
                tourColor={tour.color}
                stop={stop}
                isSelected={selectedMarkers.includes(stop.id)}
                isSingleTour={isSingleTour}
            />
            ))
        }
    </>
  
} 

export default memo(Marker)