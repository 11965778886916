import React from 'react'
import clsx from 'clsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'

import styles from './InputField.module.scss'

const validityClassName = (value, valid) => {
  if (!value.toString().length) {
    return null
  }

  return valid ? styles.valid : styles.invalid
}

export const InputField = ({
  label,
  value,
  form,
  valid,
  resetField,
  ...passed
}) => {
  const handleReset = React.useCallback(
    () => {
      if (!valid && resetField) resetField()
    },
    [valid],
  )

  return (
    <div className={styles.wrapper}>
      <legend className={styles.legend}>{label}</legend>
      <div className={clsx(styles.container, validityClassName(value, valid))}>
        <input
          type="text"
          className={styles.textField}
          value={value}
          {...passed}
        />

        {!!value.toString().length && (
          <FontAwesomeIcon
            className={styles.icon}
            icon={valid ? faCheckCircle : faTimesCircle}
            onClick={handleReset}
          />
        )}
      </div>
    </div>
  )
}
