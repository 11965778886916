import React from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'

import { COLORS } from '../../constants/COLORS'
import Translate from '../../components/Translate'

import styles from './toggle-button.module.css'

export const ToggleButton = ({
  active,
  color,
  icon,
  label,
  disabled,
  onClick,
}) => {

  const getActiveColorClass = (color) => {
    switch (color) {
    case 'orange':
      return styles.toggleButtonOrange
    case 'green':
      return styles.toggleButtonGreen
    default:
      return styles.toggleButtonGreen
    }
  }

  const getIconColor = (color) => {
    switch (color) {
    case 'orange':
      return COLORS.ORANGE
    case 'green':
      return COLORS.GREEN
    default:
      return COLORS.GRAY_FADED
    }
  }

  return (
    <div className={clsx(styles.toggleButton, active && getActiveColorClass(color),
      disabled && styles.disabled)}
         onClick={disabled ? null : onClick}>
      <Translate>{label}</Translate>
    </div>
  )
}

ToggleButton.propTypes = {
  color: PropTypes.oneOf(['green', 'orange']),
  icon: PropTypes.object,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
}
