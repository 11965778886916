
export const mapStyles = [
  { 'featureType': 'administrative', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#444444' }] },
  { 'featureType': 'landscape', 'elementType': 'all', 'stylers': [{ 'color': '#f2f2f2' }] },
  { 'featureType': 'landscape.man_made', 'elementType': 'geometry.fill', 'stylers': [{ 'visibility': 'on' }] },
  { 'featureType': 'poi', 'elementType': 'all', 'stylers': [{ 'visibility': 'off' }] },
  { 'featureType': 'poi.park', 'elementType': 'geometry.fill', 'stylers': [{ 'visibility': 'on' }, { 'color': '#c9e9ce' }] },
  { 'featureType': 'road', 'elementType': 'all', 'stylers': [{ 'saturation': -100 }, { 'lightness': 45 }] },
  { 'featureType': 'road', 'elementType': 'labels.text.stroke', 'stylers': [{ 'color': '#ffffff' }] },
  { 'featureType': 'road.highway', 'elementType': 'all', 'stylers': [{ 'visibility': 'simplified' }] },
  { 'featureType': 'road.arterial', 'elementType': 'all', 'stylers': [{ 'visibility': 'on' }] },
  { 'featureType': 'road.arterial', 'elementType': 'geometry.fill', 'stylers': [{ 'color': '#d8d2d2' }] },
  { 'featureType': 'road.arterial', 'elementType': 'labels.icon', 'stylers': [{ 'visibility': 'off' }] },
  { 'featureType': 'road.local', 'elementType': 'geometry.fill', 'stylers': [{ 'color': '#e0dede' }] },
  { 'featureType': 'transit', 'elementType': 'all', 'stylers': [{ 'visibility': 'off' }] },
  { 'featureType': 'water', 'elementType': 'all', 'stylers': [{ 'color': '#c4e3ef' }, { 'visibility': 'on' }] },
]