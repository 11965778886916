import { createSelector } from 'reselect'
import { createFeatureSelector } from './core'

import * as fromFreezerManagement from '../reducers/freezerManagement/index'
import {IFreezerLog, INormalizedEntity} from "../reducers/freezerManagement/@types";

const selectFeature = createFeatureSelector('freezerManagement')

const selectIdentifiers = (entity: INormalizedEntity<IFreezerLog>) => entity.identifiers
const selectData = (entity: INormalizedEntity<IFreezerLog>) => entity.data

export const getHasFetched = createSelector(
  selectFeature,
  fromFreezerManagement.selectHasFetched,
)

export const getIsLoading = createSelector(
  selectFeature,
  fromFreezerManagement.selectIsLoading,
)

export const getNormalizedExpectedShipments = createSelector(
  selectFeature,
  fromFreezerManagement.selectExpectedShipments,
)

export const getExpectedShipmentIdentifiers = createSelector(
  getNormalizedExpectedShipments,
  selectIdentifiers,
)

export const getExpectedShipmentData = createSelector(
  getNormalizedExpectedShipments,
  selectData,
)

export const getExpectedShipments = createSelector(
  getExpectedShipmentIdentifiers,
  getExpectedShipmentData,
  (ids, data) => ids.map(id => data[id])
)

export const getNormalizedShipmentsInFreezer = createSelector(
  selectFeature,
  fromFreezerManagement.selectShipmentsInFreezer,
)

export const getShipmentsInFreezerIdentifiers = createSelector(
  getNormalizedShipmentsInFreezer,
  selectIdentifiers,
)

export const getShipmentsInFreezerData = createSelector(
  getNormalizedShipmentsInFreezer,
  selectData,
)

export const getShipmentsInFreezer = createSelector(
  getShipmentsInFreezerIdentifiers,
  getShipmentsInFreezerData,
  (ids, data) => ids.map(id => data[id])
)
