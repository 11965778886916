import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'

import styles from './UploadLoader.module.scss'

export const UploadLoader = ({
  show, className,
}) => show && (
  <div className={clsx(styles.container, className)}>
    <div className={styles.spinner}><span /></div>
    <FontAwesomeIcon
      size="3x"
      className={styles.icon}
      icon={faArrowCircleUp}
    >
      cloud_upload
    </FontAwesomeIcon>
  </div>
)

UploadLoader.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
}

UploadLoader.defaultProps = {
  show: false,
  className: null,
}
