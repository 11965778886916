/* eslint-disable indent */
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import * as fromUI from '../../../selectors/ui'
import * as fromCombined from '../../../selectors/combined'

import { SimpleNotificationBox } from '../../common'
import { Button } from '../../common/Button/Button'

import { translate as t } from '../../../utils'

import styles from './TourToolbar.module.scss'

export const TourMessage = ({ centralSortingDone, withReload }) => {
  const allToursAreDoneAtOptimizedTime = useSelector(fromCombined.allToursAreDoneAtOptimizedTime)
  const hasOptimizationError = useSelector(fromUI.getHasOptimizationError)

  const handleReload = useCallback(() => window.location.reload(), [])

  if(centralSortingDone && withReload) {
        return(
          <SimpleNotificationBox className={styles["success-notification"]} icon={<FontAwesomeIcon icon={faCheckCircle} />}>
            {t('CENTRAL_SORTING_DONE_ALERT')}
            <Button
              type="info"
              label={t('RELOAD')}
              onClick={handleReload}
            />
          </SimpleNotificationBox>
        )
    }

    const showNotification = [!centralSortingDone, !allToursAreDoneAtOptimizedTime, hasOptimizationError].includes(true)

    return (
      <>
        {
          showNotification && (
            <SimpleNotificationBox icon={<FontAwesomeIcon icon={faExclamationTriangle} />}>
              <ul>
                {
                  !centralSortingDone && <li className={styles["sub-text"]}>{t('CENTRAL_SORTING_NOT_DONE_ALERT')}</li>
                }
                {
                  !allToursAreDoneAtOptimizedTime && <li className={styles["sub-text"]}>{t('LITE_NOTIFICATION_MESSAGE_NOT_DONE_BEFORE_TIME')}</li>
                }
                {
                  hasOptimizationError && <li className={styles["sub-text"]}>{t('LITE_NOTIFICATION_MESSAGE_OPTIMIZATION_FAILURE')}</li>
                }
              </ul>
            </SimpleNotificationBox>
          )
        }
      </>
    )
}

TourMessage.propTypes = {
  centralSortingDone: PropTypes.bool,
  withReload: PropTypes.bool,
}
