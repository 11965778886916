import React from 'react'
import PropTypes from 'prop-types'

import Translate from '../../../Translate'

import classes from './DoneCounter.module.scss'

const DoneCounter = ({
  done, total,
}) => (
  <div className={classes.container}>
    <Translate component="p" className={classes.title}>DONE_COUNTER_TITLE</Translate>
    <p className={classes.doneText}>{`${done} / ${total}`}</p>
  </div>
)

DoneCounter.propTypes = {
  done: PropTypes.number,
  total: PropTypes.number,
}

DoneCounter.defaultProps = {
  done: 0,
  total: 0,
}

export default DoneCounter
