import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import memoize from 'memoize-one'

import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel'

// Images: circular 265x256px + ring
import Tours from '../../images/onboardingTour.png'
import logo from '../../images/logo-inverted-lg.png'
import { translate } from '../../utils/translate'

class Release extends PureComponent {
  getTranslations = memoize(() =>
    translate([
      'ABT',
      'SL1T',
      'SL1ST',
      'SL2T',
      'SL2ST',
      'SL3T',
      'SL3ST',
      'SL4T',
      'SL4ST',
      'SL5T',
      'SL5ST',
    ]),
  )

  render() {
    const TrunkrsGreen = '#92D400'
    const darkGreen = '#83BE00'
    const subco = this.props.profile.subco

    const [
      actionBtnText,
      slideOneTitle,
      slideOneSubTitle,
      slideTwoTitle,
      slideTwoSubTitle,
      slideThreeTitle,
      slideThreeSubTitle,
      slideFourTitle,
      slideFourSubTitle,
      slideFiveTitle,
      slideFiveSubTitle,
    ] = this.getTranslations()

    return (
      <div>
        <AutoRotatingCarousel
          label={actionBtnText ? actionBtnText : 'New Update!'}
          autoplay={false}
          onRequestClose={this.props.close}
          onStart={this.props.close}
          open={this.props.open}>
          <Slide
            media={<img src={logo} />}
            mediaBackgroundStyle={{ backgroundColor: TrunkrsGreen }}
            contentStyle={{ backgroundColor: darkGreen }}
            title={slideOneTitle}
            subtitle={slideOneSubTitle}
          />
          <Slide
            media={<img src={Tours} />}
            mediaBackgroundStyle={{ backgroundColor: TrunkrsGreen }}
            contentStyle={{ backgroundColor: darkGreen }}
            title={slideTwoTitle}
            subtitle={slideTwoSubTitle}
          />
          {/* <Slide
            media={<img src={Zoom} />}
            mediaBackgroundStyle={{ backgroundColor: TrunkrsGreen }}
            contentStyle={{ backgroundColor: darkGreen }}
            title={slideThreeTitle}
            subtitle={slideThreeSubTitle}
          />
          <Slide
            media={<img src={Count} />}
            mediaBackgroundStyle={{ backgroundColor: TrunkrsGreen }}
            contentStyle={{ backgroundColor: darkGreen }}
            title={slideFourTitle}
            subtitle={slideFourSubTitle}
          />
          <Slide
            media={<img src={intercomImg} />}
            mediaBackgroundStyle={{ backgroundColor: TrunkrsGreen }}
            contentStyle={{ backgroundColor: darkGreen }}
            title={slideFiveTitle}
            subtitle={slideFiveSubTitle}
          /> */}
        </AutoRotatingCarousel>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
})

export default connect(mapStateToProps, null)(Release)
