import React, { memo } from 'react'

import styles from './styles.module.scss'
import clsx from 'clsx'
import { ITour, IDriver } from '../../models'

import BoardList from './BoardList'


export interface TourListProps {
    className?: string
    tours: ITour[]
    drivers: IDriver[]
    addRoute: () => void
    changeMade: () => void
    setEditMode: (tourId: number | null) => void
    onShowFullTour: (tourId: number, toggleState: boolean, tourNo: number) => void
    markTourAsPlanned: (tour: ITour, toUndo: boolean) => void
    isPlanningLocked: boolean
    areToursLocked: boolean
    markedTours: ITour[]
    activeTour?: number //activeTour must be tourId or unique identifier of each tour
  }


export const TourList: React.FC<TourListProps> = ({
    className,
    tours,
    drivers,
    addRoute,
    changeMade,
    setEditMode,
    onShowFullTour,
    markTourAsPlanned,
    isPlanningLocked,
    areToursLocked,
    activeTour,
    markedTours
 
  }: TourListProps) => {
        if(activeTour) {
            return (
                <>
                {
                    tours && tours.map((tour: ITour, index: number) => 
                        tour.number === activeTour &&
                            <BoardList
                                locked={areToursLocked}
                                tours={tours}
                                key={tour.id}
                                tour={tour}
                                index={index}
                                color={tour.color}
                                drivers={drivers}
                                addRoute={addRoute}
                                changeMade={changeMade}
                                setEditMode={setEditMode}
                                onShowFullTour={onShowFullTour}
                                markTourAsPlanned={markTourAsPlanned}
                                isPlanningLocked={isPlanningLocked}
                                isMarkAsPlanned={markedTours.some(markedTour => markedTour.id === tour.id)}
                            />
                        
                    )
                }
       </>
            )
        }

    return (
       <>
        {
            tours && tours.map((tour: ITour, index: number) => {
                
                return (
                    <BoardList
                        locked={areToursLocked}
                        tours={tours}
                        key={tour.id}
                        tour={tour}
                        index={index}
                        color={tour.color}
                        drivers={drivers}
                        addRoute={addRoute}
                        changeMade={changeMade}
                        setEditMode={setEditMode}
                        onShowFullTour={onShowFullTour}
                        markTourAsPlanned={markTourAsPlanned}
                        isPlanningLocked={isPlanningLocked}
                        isMarkAsPlanned={markedTours.some(markedTour => markedTour.id === tour.id)}
                    />
                )
            })
        }
       </>
    )
  }
  
export default memo(TourList)