import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import { translate } from '../utils'

const addCapital = translation =>
  translation && translation.charAt(0).toUpperCase() + translation.slice(1)

const makeUpper = translation => translation && translation.toUpperCase()

const applyInterpolations = (text, interpolations) => {
  let interpolated = text
  interpolations.forEach((interpolation, index) => {
    const interpolationIndex = `\${${index}}`

    while (interpolated.indexOf(interpolationIndex) !== -1) {
      interpolated = interpolated.replace(interpolationIndex, interpolation)
    }
  })

  return interpolated
}

class Translate extends Component {
  renderTranslation = (key, interpolations, capitalize, uppercase) => {
    let translation = applyInterpolations(translate(key), interpolations)

    if (capitalize && typeof translation === 'string') {
      translation = addCapital(translation)
    }
    if (uppercase && typeof translation === 'string') {
      translation = makeUpper(translation)
    }

    return translation
  }

  memoizedInterpolations = memoize((...interpolations) => interpolations)

  render() {
    const {
      interpolations,
      children: key,
      capitalize,
      uppercase,
      component: Component,
      ...passedProps
    } = this.props

    return (
      <Component {...passedProps}>
        {this.renderTranslation(
          key,
          this.memoizedInterpolations(...(interpolations || [])),
          capitalize,
          uppercase,
        )}
      </Component>
    )
  }
}

Translate.propTypes = {
  children: PropTypes.string.isRequired,
  capitalize: PropTypes.bool,
  uppercase: PropTypes.bool,
  interpolations: PropTypes.array,
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]),
}

Translate.defaultProps = {
  interpolations: null,
  capitalize: false,
  uppercase: false,
  component: 'span',
}

export default Translate
