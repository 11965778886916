import React, {ReactElement, SyntheticEvent, useMemo} from "react";
import {UnpackNestedValue, useForm, UseFormOptions} from "react-hook-form";
import get from "lodash/get"

interface IFormProps<T> extends UseFormOptions<T> {
  onSubmit: (formValues: UnpackNestedValue<T>) => void
  children: ReactElement | ReactElement[]
}

export default function Form<T>({ children, onSubmit, ...options } : IFormProps<T>) {
  const {handleSubmit, control, register, errors, reset} = useForm<T>(options);

  const render = (child: ReactElement) => React.createElement(child.type, {
    ...{
      ...child.props,
      register,
      control,
      key: get(child, 'props.name'),
      error: get(errors, `${get(child, 'props.name')}.message`)
    }
  })

  const handleSubmitWithReset = (ev?: SyntheticEvent) => {
    handleSubmit(onSubmit)(ev)
    reset()
  }

  return (
    <form onSubmit={handleSubmitWithReset}>
      {
        useMemo(() => Array.isArray(children) ? children.map(child => {
          return child.props.name
            ? render(child)
            : child;
        }) : render(children), [children])
      }
    </form>
  );
}
