import React, {useCallback} from 'react'
import {createDispatchHook, createSelectorHook, Provider} from "react-redux";
import {createStore} from "redux";

import PlannerContext from './context'
import reducer from './reducer'
import {ACTION_TYPES} from "./actionTypes";
import {getHiddenTours, getTourVisibility} from "./selectors";

const store = createStore(reducer)

const useDispatch = createDispatchHook(PlannerContext)
const useSelector = createSelectorHook(PlannerContext)

export const usePlannerStore = () => {
  const dispatch = useDispatch()

  const toggleTourVisibility = useCallback((tourNo: any) => dispatch(({ type: ACTION_TYPES.TOGGLE_TOUR_VISIBILITY, payload: tourNo })), [dispatch])

  const tourVisibility = useSelector(getTourVisibility)

  const hiddenTours = useSelector(getHiddenTours)

  //add here

  return {
    toggleTourVisibility,
    tourVisibility,
    hiddenTours
  }
}

export default ({ children }: { children: React.ReactNode }) => {
  return (
    <Provider context={PlannerContext} store={store}>
      {children}
    </Provider>
  )
}
