import React, {memo, useMemo} from 'react'
import {useSelector} from "react-redux";
import moment from "moment"
import Grid from "@material-ui/core/Grid/Grid";
import {getShipmentsInFreezer} from "../../../../selectors/freezerManagement";
import {IFreezerLog} from "../../../../reducers/freezerManagement/@types";

import styles from './styles.module.scss'
import {translate} from "../../../../utils/translate";
import {FREEZER_LOG_NEXT_STEP} from "Trunkrs-SDK/dist/constants";

const FrozenShipmentsTable = () => {
  const shipments = useSelector((state: any) => getShipmentsInFreezer(state))

  const translateNextStep = (nextStep: FREEZER_LOG_NEXT_STEP) => translate(nextStep === FREEZER_LOG_NEXT_STEP.BACK_INTO_DELIVERY ? 'BACK_INTO_DELIVERY' : 'BACK_TO_TRUNKRS')

  return (
    <Grid container item xs className={styles.table}>
      <Grid container xs={12}>
        {translate('CURRENTLY_IN_THE_FREEZER')}
      </Grid>
      <Grid container xs={12}>
        <Grid container xs={12} className={styles.tableHeaderContainer}>
          <Grid item xs={4}>
            {translate('FROZEN_MANAGEMENT_ADDRESS')}
          </Grid>
          <Grid item xs={3}>
            {translate('MERCHANT')}
          </Grid>
          <Grid item xs={3}>
            {translate('IN_SINCE')}
          </Grid>
          <Grid item xs={2}>
            {translate('NEXT_STEP')}
          </Grid>
        </Grid>
        {
          useMemo(() =>
              shipments.map(({location: {address, postalCode}, merchantName, createdAt, nextStep}: IFreezerLog) => (
                <Grid container xs={12}  className={styles.tableRow}>
                  <Grid item xs={4}>
                    <span>{address}</span>
                    <span>{postalCode}</span>
                  </Grid>
                  <Grid item xs={3}>
                    {merchantName}
                  </Grid>
                  <Grid item xs={3}>
                    {moment(createdAt).format("MM-DD-YYYY HH:mm")}
                  </Grid>
                  <Grid item xs={2} className={(nextStep === FREEZER_LOG_NEXT_STEP.BACK_TO_TRUNKRS && styles.highlighted) as string}>
                    {nextStep && translateNextStep(nextStep)}
                  </Grid>
                </Grid>
                )
              )
            , [shipments])
        }
        {
          !shipments.length &&
          <Grid container xs={12}  className={styles.tableRow}>
            <Grid alignItems="center" item xs>
              {translate('FROZEN_MANAGEMENT_NO_SHIPMENTS')}
            </Grid>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default memo(FrozenShipmentsTable)
