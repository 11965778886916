import React, {memo, ReactNode} from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

interface ISimpleNotificationBoxProps extends React.HTMLAttributes<any>{
  icon?: ReactNode
}

const SimpleNotificationBox = ({icon, children, className, ...props}: ISimpleNotificationBoxProps) => {
  return (
    <div className={clsx(styles["notification-box"], className)} {...props}>
      <div>
        {icon}
      </div>
      <div>
        {
          children
        }
      </div>
    </div>
  )
}

export default memo(SimpleNotificationBox)
