import * as actionTypes from '../../constants/ActionTypes'

import { normalizeOverviewTours } from './helpers'

const initialState = {
  hasFetched: false,
  isLoading: false,
  identifiers: [],
  data: {},
}

export default function tourOverviews(state = initialState, { type, payload }) {
  switch (type) {
  case actionTypes.UPDATE_TOUR_OVERVIEW_LOADING: {
    return { ...state, isLoading: true }
  }

  case actionTypes.UPDATE_TOUR_OVERVIEW_SUCCESS: {
    const [identifiers, data] = normalizeOverviewTours(payload)

    return {
      ...state,
      hasFetched: !!identifiers.length,
      isLoading: false,
      identifiers,
      data,
    }
  }

  case actionTypes.CHANGE_SUBCO: {
    return initialState
  }

  default:
    return state
  }
}

export const hasFetched = state => state.hasFetched
export const isLoading = state => state.isLoading
export const getData = state => state.data
export const getIdentifiers = state => state.identifiers
