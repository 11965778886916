/* eslint-disable no-extra-boolean-cast */
import React, { memo } from 'react'
import { translate } from '../../utils/translate'
import { SimpleModalBox } from 'components/common'
import { AddressSection, DetailsWrapper } from './TextWrapper'
import styles from './styles.module.scss'
import get from 'lodash/get'
import timesIcon from '../../images/times-icon.svg'
import setAsideIcon from '../../images/set-aside-icon.svg'

const ShipmentModal = ({ shipment, error, ...props }) => {
  return (
    <SimpleModalBox {...props}>
      {!!error ?
        <ScanError/>
        :
        <ScanSuccess shipment={shipment}/>
      }
    </SimpleModalBox>
  )
}

const ScanSuccess = ({ shipment }) => (

  <div className={styles.wrapper}>
    <div className={styles.body}>
      <div style={{
        background: get(shipment, 'tour.color'),
      }}> {/** left column */}
        <div className={styles.cart}>{translate('CART')}</div>
        <div className={styles.cartNumber}>{get(shipment, 'tour.number')}</div>
        <div className={styles.footer}>
          <div>
            <span>{`Tour: ${get(shipment, 'tour.id')}`}</span>
          </div>
          <div>
            <span>{`Position: ${get(shipment, 'position')}`}</span>
          </div>
        </div>
      </div>
      <div className={styles['details-container']}> {/**right column */}
        <div className={styles.details}>
          <DetailsWrapper label="TOUR" value={get(shipment, 'tour.id')}/>
          <DetailsWrapper label="DRIVER" value={get(shipment, 'driver.name')}/>
          <AddressSection
            recipient={get(shipment, 'originalRecipient') || get(shipment, 'recipient')}
            label={get(shipment, 'originalRecipient') ? 'OLD_ADDRESS' : 'ADDRESS'}
          />
        </div>
        {get(shipment, 'recipient') && get(shipment, 'originalRecipient') &&
          <div className={styles['new-address']}>
            <div>
              <img src={require('../../images/new-address-caution.svg')}/>
              <span>{translate('SHIPMENT_ADDRESS_CHANGED')}</span>
            </div>
            <div>
              <AddressSection
                className={styles.address}
                recipient={get(shipment, 'recipient')}
                label={'NEW_ADDRESS'}
              />
            </div>
          </div>
        }
      </div>
    </div>
  </div>
)
// eslint-disable-next-line no-unused-vars
const ScanError = ({ ...props }) => (
  <div className={styles.wrapper}>
    <div className={styles.body}>
      <div className={styles['error-icon-container']}>
        <div>
          <img src={timesIcon}/>
          <span className={styles.description}>{translate('SET_ASIDE')}</span>
        </div>
      </div>
      <div>
        <div className={styles['error-details']}>
          <span className={styles.description}>{translate('ATTENTION_SET_ASIDE_MSG')}</span>
          <img src={setAsideIcon}/>
        </div>
      </div>
    </div>
  </div>

)

export default memo(ShipmentModal)