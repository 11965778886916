import {ICollation} from "../../components/planner/@models";
import moment from "moment";
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import get from 'lodash/get'
import {getTotalDuration} from "../getTotalDuration";

export const getEndTime = (collations: Array<ICollation>, unitOfTime: 'm' | 'h' = 'm') => {
  const start = minBy(collations, 'position')
  if(start){
    const totalDuration = getTotalDuration(collations)
    return moment(start.estimation).add(totalDuration, unitOfTime)
  }
}

export const getLastStopTime = (collations: Array<ICollation>) => {
  const end = maxBy(collations, 'position')
  if(end){
    const durationValue = get(end, 'durationValue', 0)
    return moment(end.estimation).subtract(durationValue + 120, 's')
  }
}


export const parseTime = (timeString: string, timeFormat: string) => moment(timeString, timeFormat)
export const isAfter = (time: string, format: string, isAfter: string) =>  parseTime(time, format).isAfter(parseTime(isAfter, format))
