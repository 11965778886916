import { setLoading } from '../ui'
import { trunkrs, store } from '../../index'
import * as Sentry from '@sentry/browser'
import get from 'lodash/get'
import defaultTo from 'lodash/defaultTo'
import { IReturnedShipments, ITYPES } from 'reducers/hublogs'

export enum ACTION_TYPES {
  SET_HUB_INVENTORY = 'SET_HUB_INVENTORY',
  SET_SHIPMENT_NEXT_STEP = 'SET_SHIPMENT_NEXT_STEP',
  SET_BATCH_HUB_OUT_DONE = 'SET_BATCH_HUB_OUT_DONE',
  SET_RETURNED_SHIPMENTS = 'SET_RETURNED_SHIPMENTS'
}

export interface INextStep {
  subcoId: number,
  trunkrsNr: string,
  barcode: string
}

const getDriverName = (driverId: number, drivers: Array<any>) => get(drivers.find(driver => driver.id === driverId), 'name', '')

const getScannedState = (shipmentId: number, returnedShipments: Array<IReturnedShipments>) =>
  get(returnedShipments.find((s) => s.shipmentId === shipmentId), 'type')

export const getHubInventoryList = (subcoId: number, drivers: Array<{id: number, name: string}>) => async(dispatch: any) => {
  try {
    dispatch(setLoading(true))

    const hubInventory = await trunkrs.Shipment().fetchHubLogsBySubCoId(subcoId)
    const returnedShipments = await trunkrs.Shipment().fetchReturnedShipments(subcoId)

    const hubInventoryWithDriverName = defaultTo(hubInventory, []).map((log: any) => ({
      ...log,
      driverName: getDriverName(get(log, 'lastDriver'), drivers),
      type: getScannedState(get(log, 'shipmentId'), returnedShipments),
    }))

    dispatch({ type: ACTION_TYPES.SET_HUB_INVENTORY, payload: hubInventoryWithDriverName })
    dispatch({ type: ACTION_TYPES.SET_RETURNED_SHIPMENTS, payload: returnedShipments })
  }
  catch (err) {
    Sentry.captureException(err)
    dispatch({ type: ACTION_TYPES.SET_HUB_INVENTORY, payload: [] })
  }
  finally {
    dispatch(setLoading(false))
  }
}

export const getShipmentNextStep = async(data: INextStep) => {
  try {
    store.dispatch(setLoading(true))

    const shipmentNextStep = await trunkrs.Shipment().getShipmentNextStep(data)
    store.dispatch({ type: ACTION_TYPES.SET_SHIPMENT_NEXT_STEP, payload: {
      ...data,
      type: get(shipmentNextStep, 'type') || ITYPES.ERROR,
    } })
  }
  catch (err) {
    Sentry.captureException(err)
    store.dispatch({ type: ACTION_TYPES.SET_SHIPMENT_NEXT_STEP, payload: {
      ...data,
      type: ITYPES.ERROR,
    } })
  }
  finally {
    store.dispatch(setLoading(false))
  }
}

export const batchHubOut = async(payload: Array<{
  shipmentId: number;
  subcoId: number;
}>) => {
  try {
    store.dispatch(setLoading(true))
    await trunkrs.Shipment().batchHubOut(payload)
    store.dispatch({ type: ACTION_TYPES.SET_BATCH_HUB_OUT_DONE })
  }
  catch (err) {
    Sentry.captureException(err)
    console.warn(err)
  }
  finally {
    store.dispatch(setLoading(false))
  }
}


