import { createSelector } from 'reselect'
import { createFeatureSelector } from './core'
import {IHubInventory, IReturnedShipments, ITYPES} from "../reducers/hublogs"
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export const selectFeature = createFeatureSelector('hubLogs')

export const getReturnedShipments = createSelector(
  selectFeature,
  state => state.returnedShipments
)

export const getHubInventory = createSelector(
  selectFeature,
  state => state.inventory as Array<IHubInventory>
)

export const getIsBatchHubOutDone = createSelector(
  selectFeature,
  state => state.isBatchHubOutDone
)

export const getShipmentsAtRegionalHub = createSelector(
  getHubInventory,
  (inventory: Array<IHubInventory>) => inventory.filter((shipment) => !shipment.type),
)

export const getHubInventoryScanList = createSelector(
  getHubInventory,
  (inventory: Array<IHubInventory>) => inventory.reduce((acc, shipment) => {
    Object.assign(acc, {
      [shipment.barcode.trim()]: shipment,
      [shipment.trunkrsNr.trim()]: shipment
    })
    return acc
  }, {} as any),
)

export const isShipmentReturnedExistOnInventory = createSelector(
  getHubInventory,
  getReturnedShipments,
  (inventory: Array<IHubInventory>, returnedShipments: Array<IReturnedShipments>) => {
    const shipmentsShippedBack = returnedShipments.filter((shipment) => shipment.type === ITYPES.BACK_TO_TRUNKRS)
    if(isEmpty(shipmentsShippedBack)) {
      return true
    }
     return !!inventory.find(s => s.shipmentId === get(shipmentsShippedBack, '[0].shipmentId', undefined))
  }
)

export const getShipmentsToKeepOnHub = createSelector(
  getHubInventory,
  getReturnedShipments,
  getHubInventoryScanList,
  isShipmentReturnedExistOnInventory,
  (inventory: Array<IHubInventory>, returnedShipments: Array<IReturnedShipments>, getHubInventoryScanList, isShipmentReturnedExistOnInventory) => {
    if(isShipmentReturnedExistOnInventory) {
      return inventory.filter((shipment) => shipment.type === ITYPES.KEEP_IN_REGION)
    }
    return returnedShipments.filter((shipment) => shipment.type === ITYPES.KEEP_IN_REGION && !getHubInventoryScanList[shipment.shipmentId]) as IHubInventory[] 
  }
)

export const getShipmentsBackToTrunkrs = createSelector(
  getHubInventory,
  getReturnedShipments,
  getHubInventoryScanList,
  isShipmentReturnedExistOnInventory,
  (inventory: Array<IHubInventory>, returnedShipments: Array<IReturnedShipments>, getHubInventoryScanList, isShipmentReturnedExistOnInventory) => {
    if(isShipmentReturnedExistOnInventory) {
      return inventory.filter((shipment) => shipment.type === ITYPES.BACK_TO_TRUNKRS)
    }
    return returnedShipments.filter((shipment) => shipment.type === ITYPES.BACK_TO_TRUNKRS && !getHubInventoryScanList[shipment.shipmentId]) as IHubInventory[] 
  }
)

