import React, { Component } from 'react'
import PropTypes from 'prop-types'

function contrastColor(color) {
  const c = color.substr(1)
  const rgb = parseInt(c, 16)
  const r = (rgb >> 16) & 0xff
  const g = (rgb >> 8) & 0xff
  const b = (rgb >> 0) & 0xff
  const luma = .2126 * r + .7152 * g + .0722 * b
  if (luma > 200) {
    return '#201047'
  }
  return '#ffffff'
}

function getStyles(props, context) {
  const { noGutter } = props

  const {
    baseTheme,
    toolbar,
  } = context.muiTheme

  return {
    root: {
      boxSizing: 'border-box',
      WebkitTapHighlightColor: 'rgba(0,0,0,0)', // Remove mobile color flashing (deprecated)
      backgroundColor: (props.color) ? props.color : toolbar.backgroundColor,
      color: contrastColor((props.color) ? props.color : toolbar.backgroundColor),
      height: toolbar.height,
      // padding: noGutter ? 0 : `0px ${baseTheme.spacing.desktopGutter}px`,
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      fontFamily: 'ClanPro-WideBold',
    },
  }
}

class Toolbar extends Component {
  static propTypes = {
    /**
     * Can be a `ToolbarGroup` to render a group of related items.
     */
    children: PropTypes.node,
    /**
     * The css class name of the root element.
     */
    className: PropTypes.string,
    /**
     * Do not apply `desktopGutter` to the `Toolbar`.
     */
    noGutter: PropTypes.bool,
    /**
     * Override the inline-styles of the root element.
     */
    style: PropTypes.object,
  };

  static defaultProps = {
    noGutter: false,
  };

  static contextTypes = {
    muiTheme: PropTypes.object.isRequired,
  };

  render() {
    const {
      children,
      className,
      noGutter, // eslint-disable-line no-unused-vars
      style,
      crooked,
      ...other
    } = this.props

    const { prepareStyles } = this.context.muiTheme
    const styles = getStyles(this.props, this.context)
    return (
      <div {...other} className={className} style={prepareStyles(Object.assign({}, styles.root, style))}>
        {children}
      </div>
    )
  }
}

export default Toolbar
