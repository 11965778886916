import {ICollation} from "../../components/planner/@models";
import moment from "moment";
import {minBy, maxBy} from 'lodash'

export const getTotalDuration = (collations: Array<ICollation>, unitOfTime: 'm' | 'h' = 'm') => {
  const start = minBy(collations, 'position')
  const end = maxBy(collations, 'position')

  if(start && end){
    return moment(end.estimation).diff(moment(start.estimation), unitOfTime)
  }

  return 0
}
