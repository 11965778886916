import React, { memo } from 'react'
import ReactDOM from 'react-dom'
import { Provider, useSelector } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { syncHistoryWithStore } from 'react-router-redux'
import * as fetch from 'isomorphic-fetch'
import Axios from 'axios'
import * as Sentry from '@sentry/browser'
import { PersistGate } from 'redux-persist/integration/react'

import App from './containers/App'
import configureStore from './store/configureStore'

import * as fromUI from './selectors/ui'
import * as UIActions from './actions/ui'
import * as shipmentsAction from './actions/shipments'
import * as routesAction from './actions/routes'
import * as locationActions from './actions/locations'
import * as driversActions from './actions/drivers'
import * as profileActions from './actions/profile'
import * as warehouseActions from 'actions/warehouse'

import Login from './components/login/Login'
import Popup from './components/popup/Popup'
import Planner from './components/planner/Planner'
import Board from './components/planner/Board'
import Handover from './components/handover/connect'
import Export from './components/export/Export'
import Profile from './components/user/Profile'
import HubInventory from './components/hubInventory'
import EditProfile from './components/user/EditProfile'
import ChangePassword from './components/user/ChangePassword'
import UsersList from './components/user/UsersList'
import AddDriver from './components/driver/AddDriver'
import Driver from './components/driver/Driver'
import Update from './components/info/Update'
import Instructions from './components/info/Instructions'
import SDKProvider from './components/SDK/Provider'
import ToursOverview from './components/toursOverview'
import ToDoList from './components/todoList'
import RouteCollectionTours from './containers/RouteCollectionTours'
import FreezerManagement from './components/freezerManagement'
import ReturnsScan from './components/returnsScan'

import { TrunkrsSDK } from 'Trunkrs-SDK/dist'
import { AuditLog } from 'Trunkrs-SDK/dist/models'

import { cancelShipment } from './api/cancelShipment'
import { createTestData } from './utils/createTestData'


import { environment } from './constants'
import { sentryDSN } from './constants/external'

import 'react-perfect-scrollbar/dist/css/styles.css'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import './main.scss'
import './components/index.css'
import './components/planner/planner.css'
import ThemeProvider from './components/ThemeProvider'
import TaskTickerManager from './components/TaskTickerStarter'
import { browserHistory } from './browserHistory'
import PlannerLite from './components/planner/PlannerLite'
import get from 'lodash/get'
import defaultTo from 'lodash/defaultTo'
import { useResponseErrorHandler } from './hooks'
import ShipmentsOverview from './components/planner/ShipmentsOverview'

function getMode() {
  switch(process.env.REACT_APP_ENV) {
  case 'development':
    return 'DEVELOPMENT'
  case 'qa':
    return 'QA'
  case 'production':
    return 'PRODUCTION'
  default:
    return 'DEVELOPMENT'
  }
}

// Initialize TrunkrsSDK
export const trunkrs = new TrunkrsSDK({
  cache: false,
  cacheInvalidateAfter: 15,
  maxRetryAttempts: 2,
  mode: getMode(),
  store: localStorage, // or AsyncStorage for React-Native!
  enableBatching: true,
})

//Needed for React Developer Tools
window.React = React

function getUrl(type = environment) {
  switch(type) {
  case 'local':
    return 'http://localhost:3000/api/'
  case 'staging':
  case 'development':
    return 'https://staging-api.trunkrs.nl/api/'
  case 'production' :
    return 'https://api.trunkrs.nl/api/'
  default:
    return 'https://staging-api.trunkrs.nl/api/'
  }
}

const enableErrorTracking = (environment === 'staging'
  || environment === 'production')
  && sentryDSN

if (enableErrorTracking) {
  Sentry.init({ dsn: sentryDSN })
}

export const { store, persistor } = configureStore({})
const history = syncHistoryWithStore(browserHistory, store)
const url = getUrl('development')

function getProfile() {
  return store.getState().profile
}

function getRoutes() {
  return store.getState().routes
}

async function loggedIn() {
  const loggedIn = await trunkrs.Auth().checkAccess()
  return loggedIn
}

async function authenticationRequired() {
  if (await loggedIn()) {
    return true
  }

  browserHistory.push('/login')
}

export async function loadShipments() {
  try {
    const profile = getProfile()
    if (profile.subco) {
      const subco = await trunkrs.Subco().fetch(profile.subco.id)
      const { shipments } = await subco.fetchDailyShipmentOverview()

      return shipments
    }

    return []
  }catch(e) {
    console.log(`Error at loadShipments(): ${e}`)
  }

}

const loadTours = async() => {
  const profile = getProfile()
  if(profile) {
    const tours = await trunkrs.Tour().fetchBySubcoId(profile.subco.id)

    return tours.map(async tour => {
      const collations = await tour.getCollations
      return {
        Id: tour.getId,
        Collations: Array.isArray(collations) ? collations.map(collation => collation.tourId) : [],
        DriverId: tour.getDriverId,
        Date: tour.date.toISOString(),
        Instance: tour,
      }
    })
  }
  return []
}

const discardTours = async() => {
  const routes = getRoutes()
  const tours = await loadTours()
  const nextRoutes = tours.reduce((acc, tour) => {
    const { Id, Collations, DriverId, Date, Instance } = tour
    return {
      ...acc,
      [tour.Id]: {
        ...routes[tour.Id],
        Id,
        Collations,
        DriverId,
        Date,
        Instance,
      },
    }
  }, {})
  await Promise.all(tours.map(tour => tour.Instance.discard)).then(store.dispatch(routesAction.update(nextRoutes)))
}

async function storeTour(tour) {
  const profile = getProfile()
  return await trunkrs.Tour().store(
    tour.Date,
    profile.subco.subcoId,
    tour.Collations,
    tour.DriverId,
  )
}

async function getLatestCollation(shipmentId, timeSlotId) {
  const profile = getProfile()
  const result = await Axios.get(`${url}Collations/findOne`, {
    params: {
      filter: {
        where: {
          shipmentId,
          timeSlotId,
        },
      },
    },
    headers: {
      'Content-Type': 'application/json',
      'Authorization': profile.accesstoken,
    },
  })
  if (result.status >= 400) {
    return result.statusText
  }
  return result.data
}

async function storeCollation(shipment, routeId, driverId, position, deliveryDate) {
  const profile = getProfile()
  const collation = await getLatestCollation(shipment.Id, shipment.Timeslot.Id)
  if (!collation || !collation.id) {
    throw new Error(`Collation not found! ${shipment.Id}`)
  }
  const result = await Axios.patch(`${url}Collations/${collation.id}`, {
    driverId,
    routeId,
    position,
    deliveryDate,
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': profile.accesstoken,
    },
  })
  if (result.status >= 400) {
    return result.statusText
  }
  return result.data
}

async function handoverShipments(ids) {
  const profile = getProfile()
  store.dispatch(UIActions.setLoading(true))

  const resolved = await Promise.all(await ids.map(async id =>
    await store.getState().shipments[id.toString()].instance.sortToCart(profile.subco.id),
  ))
  store.dispatch(UIActions.setLoading(false))
  return resolved
}

function storeLocation(id, latitude, longitude) {
  const profile = getProfile()
  return fetch.default(`${url}Recipients/${id}/location`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': profile.accesstoken,
    },
    body: JSON.stringify({
      lat: latitude,
      lng: longitude,
    }),
  })
    .then((response) => {
      if (response.status >= 400) {
        return Promise.reject(response.status)
      }
      return response.json()
    })
}

function addRoute() {
  const profile = getProfile()
  return fetch.default(`${url}Routes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': profile.accesstoken,
    },
    body: JSON.stringify({
      subcoId: profile.subco.id,
      driverId: null,
      index: Object.keys(store.getState().routes).length,
      deliveryDate: new Date().toISOString().substr(0, 10),
    }),
  })
    .then((response) => {
      if (response.status >= 400) {
        return Promise.reject(response.status)
      }
      return response.json()
    })
}

function editPassword(oldPassword, newPassword) {
  const profile = getProfile()
  store.dispatch(UIActions.setLoading(true))

  return fetch.default(`${url}Profiles/change-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': profile.accesstoken,
    },
    body: JSON.stringify({
      oldPassword: oldPassword,
      newPassword: newPassword,
    }),
  })
    .then((response) => {
      if (response.status >= 400) {
        return Promise.reject(response.status)
      }
      return response
    })
    .finally(store.dispatch(UIActions.setLoading(false)))
}

function editProfile(data) {

  const { address, number, postalCode, city, lat, lng, country, phone } = data

  const profile = getProfile()
  store.dispatch(UIActions.setLoading(true))

  return fetch.default(`${url}Subcos/${profile.subco.id}/location`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': profile.accesstoken,
    },
    body: JSON.stringify({
      address: address,
      number: number,
      postalCode: postalCode,
      city: city,
      latitude: lat,
      longitude: lng,
      country: country,
    }),
  })
    .then((response) => {

      store.dispatch(UIActions.setLoading(false))
      if (response.status >= 400) {
        return Promise.reject(response.status)
      }
      // Check if phonenumber has been altered
      if(phone && phone !== profile.subco.phoneNumber) {
        fetch.default(`${url}Subcos/${profile.subco.id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': profile.accesstoken,
          },
          body: JSON.stringify({
            phoneNumber: phone,
          }),
        })
          .then((response) => {
            if (response.status >= 400) {
              return Promise.reject(response.status)
            }
          })
      }

      // update store
      store.dispatch(locationActions.editLocation({
        address: address,
        number: number,
        postalCode: postalCode,
        city: city,
        lat: lat,
        lng: lng,
        country: country,
        phone: phone,
      }))

      return response
    })
}

function addDriver(data) {
  const { name, email, newPassword, remarks, phoneNumber } = data

  const profile = getProfile()
  store.dispatch(UIActions.setLoading(true))

  return fetch.default(`${url}Drivers/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': profile.accesstoken,
    },
    body: JSON.stringify({
      subcoId: profile.subco.subcoId,
      name: name,
      email: email,
      password: newPassword,
      phonenumber: phoneNumber,
      remarks: remarks,
    }),
  })
    .then((response) => {

      store.dispatch(UIActions.setLoading(false))
      if (response.status >= 400) {
        return Promise.reject(response.status)
      }
      response.json().then((res) => {
        store.dispatch(driversActions.add({
          Id: res.driver.id,
          Name: res.driver.name,
        }))
        store.dispatch(driversActions.addNew({
          driverId: res.driver.id,
          locationId: res.driver.locationId,
          driverType: res.driver.driverType,
          Edefault: res.driver.default,
          defaultLinehaul: res.driver.defaultLinehaul,
          name: res.driver.name,
          email: res.driver.email,
          phoneNumber: res.driver.phoneNumber,
          remarks: res.driver.remarks,
          created_at: res.driver.created_at,
          updated_at: res.driver.updated_at,
        }))
      })

      return response

    })
}

function geocode(address) {
  const encodedAddress = encodeURI(address)
  return fetch.default(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=AIzaSyBzWD9a4l8NYzXz7eakuWJpLNBmRnxYEc4`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  })
    .then((response) => {
      if (response.status >= 400) {
        return Promise.reject(response.status)
      }
      return response.json()
    })
}

function resetHandOver(routes) {
  const profile = getProfile()
  store.dispatch(UIActions.setLoading(true))
  Promise.all(Object.keys(routes).map(key => routes[key]).map(route => {
    return fetch.default(`${url}Routes/removeHandover/${route.Id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': profile.accesstoken,
      },
    })
  })).then(responses => Promise.all(responses.map(res => {
    if (res.status >= 400) {
      return Promise.reject(res.status)
    }
  })))
}

function changeDriver(routes) {
  const profile = getProfile()
  const routeChanges = Object.values(routes).map(tour => {
    return {
      routeId: tour.Id,
      driverId: tour.DriverId,
    }
  })

  return fetch.default(`${url}Routes/changeDriver`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': profile.accesstoken,
    },
    body: JSON.stringify({ routeChanges }),
  })
    .then((response) => {
      if (response.status >= 400) {
        return Promise.reject(response.status)
      }
      return response.json()
    })
}

function checkSubCoArea(barcode) {
  const profile = getProfile()
  const area = (profile && profile.subco ? profile.subco.area : '')

  return fetch.default(`${url}Shipments/check/${barcode}/at/${area}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': profile.accesstoken,
    },
  })
    .then((response) => {
      if (response.status >= 400) {
        return Promise.reject(response.status)
      }
      return response.json()
    })
}


const disableMarkTourConfirmation = () => {
  store.dispatch(profileActions.disableMarkTourConfirmation())
}

const checkRegion = async(barcode) => {
  try {
    const profile = getProfile()
    const region = get(profile, 'subco.tag', '')
    const subcoId = get(profile, 'selectedSubco')
    const shipments = store.getState().shipments
    const shipment = Object.values(shipments).find(item => item.OrderReference === barcode || item.TrunkrsNr === barcode)
    let isBelong = { correct: true }
    if (!shipment) {
      isBelong = await shipment.belongsToRegion(region, subcoId)
    }
    return {
      ...isBelong,
      shipment,
    }
  }
  catch (e) {
    console.error(e)
    return null
  }
}

/**
 * getCart
 * Promise that takes shipmentId and returns cartNumber
 *
 * @param shipmentId
 * @returns {Promise<number>}
 */
export async function getCart(shipmentId) {
  const profile = getProfile()
  store.dispatch(UIActions.setLoading(true));
  (await trunkrs.Shipment().fetch(shipmentId)).sortToCart(profile.subco.id)
  store.dispatch(shipmentsAction.isSorted({
    shipmentId,
  }))
  store.dispatch(UIActions.setLoading(false))
}

export async function addMissorted(barcode, region, subcoId) {
  store.dispatch(UIActions.setLoading(true))
  trunkrs.Shipment().fetchByBarcode(barcode)
    .then(shipmentInstance => {
      shipmentInstance.isMissorted(region, subcoId)
    })
    .catch((error) => {
      Sentry.captureException(error)
      AuditLog.logShipmentByBarcode(barcode)
    })
    .finally(store.dispatch(UIActions.setLoading(false)))
}

/**
 * setTourToPlanned
 * accepts tourId and sets its state to TOUR_PLANNED
 *
 * @param tourId
 * @returns {Promise<void>}
 */
async function setTourToPlanned(tourId) {
  store.dispatch(UIActions.setLoading(true))
  const tour = await trunkrs.Tour().fetch(tourId)
  await tour.planned()
  store.dispatch(routesAction.isPlanned({
    tourId,
    stateName: 'TOUR_PLANNED',
  }))
  store.dispatch(UIActions.setLoading(false))
}

/**
 * handleHandoverDone
 * calls assignDriver
 * calls setTourInTransit
 *
 * @param driverId
 * @param tourId
 */
export async function handleHandoverDone(tour) {
  // TODO: discuss what to do here, do we want to
  // await assignDriver(tour.driverId);
  await setTourToPlanned(tour.id)
}

class RootComponent extends React.Component {
  state = {
    isLoggedIn: false,
  };

  async componentDidMount() {
    await this.setLoggedInState()
    if (this.state.isLoggedIn) {
      await this.rehydrateApplication()
    }
  }

  async rehydrateApplication() {
    if (!this.props.subco) {
      await this.rehydrateSubcontractor()
    }
  }

  rehydrateSubcontractor = async() => {
    store.dispatch(UIActions.setLoading(true))
    let [, subcontractors] = await useResponseErrorHandler(trunkrs.Auth().fetchSubcoForUser())

    if(subcontractors) {
      subcontractors = !Array.isArray(subcontractors) ? [subcontractors] : subcontractors

      store.dispatch(UIActions.setLoading(true))
      const [, driverInstances] = await useResponseErrorHandler(get(subcontractors, '[0].getDrivers'))

      if(driverInstances) {
        store.dispatch(UIActions.setLoading(true))
        const [, result] = await useResponseErrorHandler(Promise.all(driverInstances))

        const drivers = defaultTo(result, []).map(
          driver => ({
            id: get(driver, 'id'),
            name: get(driver, 'name'),
            email: get(driver, 'getEmailAddress'),
            phoneNumber: get(driver, 'getPhoneNumber'),
            active: get(driver, 'active'),
            isPlanner: get(driver, 'isPlanner'),
            isArchived: get(driver, 'isArchived'),
            picture: get(driver, 'picture'),
            Instance: driver,
          }),
        )
        store.dispatch(UIActions.setLoading(true))
        const [, location] = await useResponseErrorHandler(get(subcontractors, '[0].getLocation'))
        const [, accesstoken] = await useResponseErrorHandler(trunkrs.Auth().getValue('accessToken'))

        store.dispatch(profileActions.login({
          id: get(subcontractors, '[0].getId'),
          accesstoken,
          subcos: subcontractors,
          selectedSubco: 0,
          drivers,
          location,
        }))
        store.dispatch(profileActions.setRoles())
      }
    }
    else {
      this.setState({ isLoggedIn: false })
      await useResponseErrorHandler(trunkrs.Auth().invalidate())
    }

    store.dispatch(UIActions.setLoading(false))
  };

  setLoggedInState = async() => {
    this.setState({ isLoggedIn: await loggedIn() })
  };

  generatePlannerWithProps = () => (<Planner geocode={geocode}
    storeLocation={storeLocation}
    cancelShipment={cancelShipment}
    createTestData={RootComponent.handleCreateTestData}
  />)

  static async handleCreateTestData() {
    store.dispatch(UIActions.setLoading(true))
    await createTestData(trunkrs)
    store.dispatch(UIActions.setLoading(false))

    await store.dispatch(shipmentsAction.reloadShipments())
  }

  render() {
    const isAuthRequired = authenticationRequired()

    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <TaskTickerManager />
          <ThemeProvider>
            <SDKProvider sdk={trunkrs}>
              <Router history={history}>
                <App isLoggedIn={this.state.isLoggedIn} setLoggedInState={this.setLoggedInState}>
                  <Switch>
                    {!this.state.isLoggedIn && (
                      <Route path="/login" component={() => <Login setLoggedInState={this.setLoggedInState} />} />
                    )}

                    {
                      !!isAuthRequired &&
                      <>
                        <Route exact path="/shipments" component={ShipmentsOverview} />
                        <Route path="/tours">
                          <PlannerModeSwitch/>
                        </Route>
                        <Route path="/handover">
                          <Handover updateShipments={loadShipments} doHandover={handoverShipments}
                            checkRegion={checkRegion} checkSubCoArea={checkSubCoArea} url={url} getCart={getCart}
                            handoverDone={handleHandoverDone}/>
                        </Route>
                        <Route path="/export" component={Export} />
                        <Route path="popup" component={Popup} />
                        <Route path="/profile" component={Profile} />
                        <Route path="/profile/:status" component={Profile} />
                        <Route path="/edit-profile" render={() => <EditProfile editProfile={editProfile} />} />
                        <Route path="/change-password" render={() => <ChangePassword editPassword={editPassword} />} />
                        <Route path="/drivers" exact render={() => <UsersList />} />
                        <Route path="/hub-inventory" component={HubInventory}/>
                        <Route path="/returns-scan" component={ReturnsScan}/>
                        <Route path="/drivers/add" render={() => <AddDriver addDriver={addDriver} />} />
                        <Route path="/driver/:id" component={Driver} />
                        <Route path="/instructions" component={Instructions} />
                        <Route path="/update" component={Update} />
                        <Route path="/quicktours" component={ToursOverview} />
                        <Route path="/todo-list" component={ToDoList} />
                        <Route path="/collections" component={RouteCollectionTours} />
                        <Route path="/freezer-management" component={FreezerManagement} />
                        <Route render={() => <Redirect to={{ pathname: '/shipments' }} />} />
                      </>
                    }
                  </Switch>
                </App>
              </Router>
            </SDKProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    )
  }
}

const PlannerModeSwitch = memo(() => {
  const isPowerMode = useSelector(fromUI.getMode)
  return (
    isPowerMode ?
      <Board changeDriver={changeDriver}
        resetHandOver={resetHandOver}
        addRoute={addRoute}
        updateShipments={loadShipments}
        storeTour={storeTour}
        discardTours={discardTours}
        storeCollation={storeCollation}
        onEnter={authenticationRequired}
        disableMarkTourConfirmation={disableMarkTourConfirmation}
      /> :
      <PlannerLite/>
  )
})

PlannerModeSwitch.displayName = 'PlannerModeSwitch'


ReactDOM.render(
  <RootComponent/>,
  document.getElementById('root'),
)
