import React, { memo, useCallback } from 'react'
import { Button } from 'components/common/Button/Button'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslate } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { getHubInventoryList } from 'actions/hubLogs'
import { getDrivers } from 'selectors/driver'
import { getCurrentSubcoId } from 'selectors/profile'
import { ReactComponent as RefreshIcon } from '../../../../images/refresh-icon.svg'

import isEmpty from 'lodash/isEmpty'
import styles from './styles.module.scss'


const EmptyLogs = () => {
  const dispatch = useDispatch()
  const { t } = useTranslate(['HUB_INVENTORY'])

  const currentSubcoId = useSelector(getCurrentSubcoId)
  const drivers = useSelector(getDrivers)

  const handleReload = useCallback(() => {
    if(currentSubcoId && !isEmpty(drivers)) {
      dispatch(getHubInventoryList(currentSubcoId, drivers))
    }
  }, [currentSubcoId, drivers, getHubInventoryList])

  return (
    <div className={styles.container}>
      <img src={require('../../../../images/hub-inventory-empty.svg')}/>
      <div>
        <span>{t('HUB_INVENTORY:EMPTY_TITLE')}</span>
        <div>{t('HUB_INVENTORY:EMPTY_DESC')}</div>
      </div>
      <Button
        type='success'
        className={styles.button}
        label={t('HUB_INVENTORY:RELOAD_BUTTON')}
        icon={<RefreshIcon/>} onClick={handleReload}/>
    </div>
  )
}

export default memo(EmptyLogs)
