import React, {memo} from 'react'
import clsx from 'clsx'
import get from 'lodash/get'

import styles from './styles.module.scss'

enum CHIP_TYPES {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning'
}

const Chip = ({children, className, variant = CHIP_TYPES.DEFAULT, ...props}: React.DetailedHTMLProps<any, any> & {variant?: CHIP_TYPES|string}) => {
  return(
    <div {...props} className={clsx(styles.chip, get(styles, variant))}>
      {children}
    </div>
  )
}

export default memo(Chip)
