import React, {memo} from 'react'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Grid from "@material-ui/core/Grid/Grid";
import FontIcon from "material-ui/FontIcon";
import clsx from "clsx"

import styles from './styles.module.scss'
import {translate} from "../../../../../../utils/translate";

const FreezerReturnFeedback = ({isOpen} : {isOpen: boolean}) => (
  <Tooltip
    leaveTouchDelay={0}
    disableHoverListener={true}
    disableFocusListener={true}
    disableTouchListener={true}
    arrow title={<Grid container className={styles.tooltipBody}>
    <Grid item>
      <FontIcon className={clsx(styles.fontIcon, 'material-icons')}>keyboard_return</FontIcon>
    </Grid>
    <Grid item>
      {translate('RETURN_SHIPMENT')}
    </Grid>
  </Grid>}
    classes={styles}
    open={isOpen}
    placement="right">
    <div />
  </Tooltip>
)

export default memo(FreezerReturnFeedback)
