import * as Sentry from '@sentry/browser'

import { CollectionTour } from 'Trunkrs-SDK/dist/models'

import { translate } from '../../utils/translate'

import * as actionTypes from '../../constants/ActionTypes'
import * as fromProfile from '../../selectors/profile'

import { normalizeCollectionWeek } from './helpers'

export function loadCollectionTours() {
  return async(dispatch, getState) => {
    const subcoId = fromProfile.getCurrentSubcoId(getState())

    dispatch({ type: actionTypes.LOAD_COLLECTION_TOURS })

    const collectionWeek = await CollectionTour.getSubcoWeek(subcoId)
    const [tourDates, toursOnDate, tours] = normalizeCollectionWeek(collectionWeek)

    dispatch({
      type: actionTypes.LOAD_COLLECTION_TOURS_SUCCESS,
      payload: { tourDates, toursOnDate, tours },
    })
  }
}

export const selectTour = tourId => ({
  type: actionTypes.SELECT_COLLECTION_TOUR,
  payload: tourId,
})

export function saveTours(tourDate) {
  const { date, tours: tourUpdates } = tourDate

  return async(dispatch, getState) => {
    dispatch({
      type: actionTypes.SAVE_TOURS,
      payload: { date },
    })
    const { id: subcoId, name, tag } = fromProfile.getSubco(getState())

    try {
      const changedTours = await CollectionTour.saveCollectionTours({
        subcoId,
        date,
        tours: tourUpdates.map(tour => ({
          id: tour.id,
          driverId: tour.driverId,
        })),
      })

      const [tourDates, toursOnDate, tours] = normalizeCollectionWeek(changedTours)

      dispatch({
        type: actionTypes.SAVE_TOURS_SUCCESS,
        payload: {
          tourDates,
          toursOnDate,
          tours,
        },
      })
    } catch (error) {
      console.error(error)
      dispatch({
        type: actionTypes.SAVE_TOURS_FAILED,
        payload: { date },
      })

      const eventId = Sentry.captureException(error)
      Sentry.showReportDialog({
        eventId,
        user: {
          name,
          email: `${tag.toLowerCase()}@trunkrs.nl`,
        },
        title: translate('FEEDBACK_TITLE'),
        subtitle: translate('FEEDBACK_SUBTITLE'),
        subtitle2: translate('FEEDBACK_SUBTITLE_2'),
        successMessage: translate('FEEDBACK_LABEL_SUCCESS'),
        labelComments: translate('FEEDBACK_LABEL_COMMENTS'),
      })
    }
  }
}
