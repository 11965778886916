import React, { PureComponent } from 'react'
import memoize from 'memoize-one'
import * as PropTypes from 'prop-types'

import MapsPlace from 'material-ui/svg-icons/maps/place'
import { IconButton, IconMenu, MenuItem } from 'material-ui'

import Translate from '../../Translate'
import Badge, { BADGE_TYPES } from '../../badge/Badge'

import moveCollationsStyle from './MultiMoveCollations.module.scss'
import { isValidCollation } from '../../../actions/tours/helpers'

export class TourBadges extends PureComponent {
  getBadgeContent = memoize(collations =>
    collations.filter(isValidCollation).length)

  render() {
    const { tour, move } = this.props

    return (
      <MenuItem
        key={tour.id}
        onClick={() => move(tour)}
      >
        <Translate capitalize={true}>TOUR</Translate>&nbsp;{tour && tour.number}
        <Badge
          label={this.getBadgeContent(tour.collations)}
          type={BADGE_TYPES.INFO}
          style={{
            backgroundColor: tour.color,
            float: 'right',
            marginTop: 13,
          }}
        />
      </MenuItem>
    )
  }
}

TourBadges.propTypes = {
  tour: PropTypes.any,
  index: PropTypes.any,
  move: PropTypes.any,
}

export class MultiMoveCollations extends PureComponent {
  renderBadges = memoize(tours =>
    tours.map((tour, index) =>
      (<TourBadges
        key={tour.id}
        tour={tour}
        index={index}
        move={this.props.onMoveCollations}
      />),
    ),
  )

  render() {
    const {
      selected, tours, onNewTour,
    } = this.props

    return (
      <span className={moveCollationsStyle.container}>
        <IconMenu
          iconStyle={{ marginTop: '-13px', marginLeft: '-5px', paddingRight: 50 }}
          iconButtonElement={<IconButton><MapsPlace/></IconButton>}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          maxHeight={272}
          menuStyle={{ width: 150 }}
        >
          {this.renderBadges(tours)}

          <MenuItem onClick={onNewTour}>
            <Translate>ADD_TOUR</Translate>
          </MenuItem>
        </IconMenu>
        <span className={moveCollationsStyle.selectedBadge}>
          {selected.length}
        </span>
      </span>
    )
  }
}

MultiMoveCollations.propTypes = {
  selected: PropTypes.array,
  tours: PropTypes.array,
  onMoveCollations: PropTypes.func,
  onNewTour: PropTypes.func,
}
