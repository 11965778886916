import * as React from 'react'
import get from 'lodash/get'
import moment from 'moment'
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table'

import Translate from '../Translate'

export default class TableData extends React.Component {
  scannedStates = [
    'SHIPMENT_SORTED_AT_SUB_DEPOT',
    'SHIPMENT_ACCEPTED_BY_DRIVER',
    'SHIPMENT_DELIVERED',
    'SHIPMENT_DELIVERED_TO_NEIGHBOR',
    'SHIPMENT_NOT_DELIVERED',
  ]

  displayTime = (minutes) => {
    const hours = String(Math.floor(minutes / 60))
    const mins = String(Math.floor(minutes % 60))
    let time = ''
    if (hours.length < 2) {
      time += '0'
    }
    time += hours
    time += ':'
    if (mins.length < 2) {
      time += '0'
    }
    time += mins
    return time
  }

  isStart = (ids) => {
    return String([...ids].pop()).substr(0, 2) === '-1'
  }

  isEnd = (ids) => {
    return String([...ids].pop()).substr(0, 2) === '-2'
  }

  isScanned = (collation) => {
    const stateNames = get(collation, 'states', []).map(state => state.name)
    return stateNames.some(name => this.scannedStates.includes(name))
  }

  calculateTime = (collations) => {
    return collations.reduce((acc, collation) => {
      if (
        this.isStart(collation.shipmentIds) ||
        this.isEnd(collation.shipmentIds)
      ) {
        acc += (collation.durationValue / 60)
      }
      if (this.isScanned(collation)) {
        acc += (collation.durationValue / 60) + 3
      }
      return acc
    }, 0)
  }

  getEndTime = (collations, totalDuration) => {
    const start = collations.find(collation => this.isStart(collation.shipmentIds))
    return moment(start.estimation).add(totalDuration, 'minute')
  }

  getNoCollo = (collation) => {
    const stateNames = get(collation, 'states', []).map(state => state.name)
    return stateNames.filter(name => this.scannedStates.includes(name)).length
  }

  getTotalNoCollo = (collations) => collations
    .reduce((acc, collation) => acc += this.getNoCollo(collation), 0)

  render() {
    const { tour, driver, spanStyle } = this.props
    const total = this.calculateTime(tour.collations)
    const endtime = this.getEndTime(tour.collations, total)
    const totalCollations = this.getTotalNoCollo(tour.collations)

    return (
      <div className="page-break-always">
        <h1><Translate capitalize={true}>TOUR</Translate> {tour.id}: {driver.name}</h1>
        <div><span style={spanStyle}><Translate capitalize={true}>DURATION</Translate>:</span> {this.displayTime(total) } hr</div>
        <div style={{ height: 15 }}></div>
        <Table selectable={false}>
          <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
            <TableRow>
              <TableHeaderColumn style={{ width: 25, paddingLeft: 5, paddingRight: 5 }}>
                #
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: 60 }}>
                <Translate capitalize={true}>POSTALCODE</Translate>
              </TableHeaderColumn>
              <TableHeaderColumn>
                <Translate capitalize={true}>ADDRESS</Translate>
              </TableHeaderColumn>
              <TableHeaderColumn>
                <Translate capitalize={true}>CITY</Translate>
              </TableHeaderColumn>
              <TableHeaderColumn>
                <Translate capitalize={true}>ETA</Translate>
              </TableHeaderColumn>
              <TableHeaderColumn>
                <Translate capitalize={true}># Collo</Translate>
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false} showRowHover={true}>
            { tour.collations.map((collation) => {
              if (this.isStart(collation.shipmentIds)) {
                return (
                  <TableRow key={collation.id}>
                    <TableRowColumn style={{ width: 25, paddingLeft: 5, paddingRight: 5 }}>
                    </TableRowColumn>
                    <TableRowColumn style={{ width: 60 }}>
                    </TableRowColumn>
                    <TableRowColumn>
                      Vertrek bij HUB
                    </TableRowColumn>
                    <TableRowColumn>
                    </TableRowColumn>
                    <TableRowColumn>
                      {moment(collation.estimation).format('HH:mm')}
                    </TableRowColumn>
                    <TableRowColumn>
                    </TableRowColumn>
                  </TableRow>
                )
              }
              if (this.isEnd(collation.shipmentIds)) {
                return (
                  <TableRow key={collation.id}>
                    <TableRowColumn style={{ width: 25, paddingLeft: 5, paddingRight: 5 }}>
                    </TableRowColumn>
                    <TableRowColumn style={{ width: 60 }}>
                    </TableRowColumn>
                    <TableRowColumn>
                      Terug bij HUB
                    </TableRowColumn>
                    <TableRowColumn>
                    </TableRowColumn>
                    <TableRowColumn>
                      {endtime.format('HH:mm')}
                    </TableRowColumn>
                    <TableRowColumn>
                      {totalCollations}
                    </TableRowColumn>
                  </TableRow>
                )
              }
              if (!this.isScanned(collation)) {
                return (<TableRow key={collation.id}>
                  <TableRowColumn style={{ width: 25, paddingLeft: 5, paddingRight: 5 }}>
                    <strike>{collation.position}</strike>
                  </TableRowColumn>
                  <TableRowColumn style={{ width: 60 }}>
                    <strike>{collation.recipient.location.postalCode}</strike>
                  </TableRowColumn>
                  <TableRowColumn>
                    <strike>{collation.recipient.location.address}</strike>
                  </TableRowColumn>
                  <TableRowColumn>
                    <strike>{collation.recipient.location.city}</strike>
                  </TableRowColumn>
                  <TableRowColumn>
                    Niet aanwezig
                  </TableRowColumn>
                  <TableRowColumn>
                    0
                  </TableRowColumn>
                </TableRow>)
              }
              return (
                <TableRow key={collation.id}>
                  <TableRowColumn style={{ width: 25, paddingLeft: 5, paddingRight: 5 }}>
                    {collation.position}
                  </TableRowColumn>
                  <TableRowColumn style={{ width: 60 }}>
                    {collation.recipient.location.postalCode}
                  </TableRowColumn>
                  <TableRowColumn>
                    {collation.recipient.location.address}
                  </TableRowColumn>
                  <TableRowColumn>
                    {collation.recipient.location.city}
                  </TableRowColumn>
                  <TableRowColumn>
                    {moment(collation.estimation).format('HH:mm')}
                  </TableRowColumn>
                  <TableRowColumn>
                    {this.getNoCollo(collation)}
                  </TableRowColumn>
                </TableRow>
              )})}
          </TableBody>
        </Table>
      </div>
    )
  }
}
