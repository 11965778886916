import { trunkrs } from '../'
import * as actionTypes from '../constants/ActionTypes'
import {Dispatch} from "redux";
import {IFreezerLogPayload} from "../reducers/freezerManagement/@types";

export const setLoading = (isLoading: boolean) => (dispatch: Dispatch) => {
  dispatch({type: actionTypes.FREEZER_MANAGEMENT_LOADING, payload: {loading: isLoading}})
}

export const fetchFreezerLogs = (subcoId: number) => (dispatch: Dispatch) => {
  dispatch({type: actionTypes.FREEZER_MANAGEMENT_LOADING, payload: {loading: true}})
  getFreezerLogs(subcoId).then((payload: any) => {
    dispatch({type: actionTypes.FREEZER_MANAGEMENT_SET_FREEZER_LOGS, payload})
  }).finally(()=> {
    dispatch({type: actionTypes.FREEZER_MANAGEMENT_LOADING, payload: {loading: false}})
  })
}

export const setFreezerLogs = (payload: IFreezerLogPayload) => (dispatch: Dispatch) => {
  dispatch({type: actionTypes.FREEZER_MANAGEMENT_SET_FREEZER_LOGS, payload})
}

export const getFreezerLogs = (subcoId: number) => trunkrs.FreezerLog(subcoId).fetchFreezerLogs()

export const addFreezerState =  (subcoId: number, trunkrsNrOrBarcode: string) => trunkrs.FreezerLog(subcoId).addFreezerState(trunkrsNrOrBarcode)


