import React, { useState } from 'react'
import { ToolbarGroup } from 'material-ui/Toolbar'
import { FlatButton, FontIcon } from 'material-ui'
import ToolbarTitle from '../toolbar/ToolbarTitle'
import Translate from '../Translate'
import Toolbar from '../toolbar/Toolbar'
import { browserHistory } from '../../browserHistory'

import styles from './handoverToolbar.module.scss'
import { COLORS } from '../../constants/COLORS'

import ShippingDocumentPrintingModal from './ShippingDocumentPrintingModal'

export const HandoverToolbar = () => {
  const [isShippingDocPrintModalVisible, setIsShippingDocPrintModalVisible] = useState(false)

  const openShippingDocPrinting = () => {
    setIsShippingDocPrintModalVisible(true)
  }

  const closeShippingDocPrinting = () => setIsShippingDocPrintModalVisible(false)

  const exportList = () => browserHistory.push('/export')

  return (
    <>
      <Toolbar color={COLORS.GRAY_LIGHT} className={styles.toolbar}>
        <ToolbarTitle text={<Translate>HANDOVER</Translate>} style={{
          fontFamily: 'ClanPro-WideBold',
          fontSize: 24,
          textTransform: 'capitalize',
        }}/>
        <ToolbarGroup>
          <FlatButton
            labelStyle={{
              fontSize: 12,
              textTransform: 'capitalize',
            }}
            icon={<FontIcon className="material-icons">print</FontIcon>}
            style={{
              marginRight: 0,
              color: COLORS.WHITE,
              borderRadius: 5,
            }}
            label={<Translate capitalize={true}>EXPORT</Translate>}
            onClick={exportList}
            backgroundColor={COLORS.BLUE}
            hoverColor={COLORS.BLUE_HOVER}
          />
        </ToolbarGroup>
      </Toolbar>
      <ShippingDocumentPrintingModal visible={isShippingDocPrintModalVisible} onClose={closeShippingDocPrinting} />
    </>
  )
}
