import React from 'react'
import memoize from 'memoize-one'
import moment from 'moment'
import clsx from 'clsx'

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import PerfectScrollBar from 'react-perfect-scrollbar'
import { ListItem } from 'material-ui/List'
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert'
import List from '@material-ui/core/List'
import Fade from '@material-ui/core/Fade'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Translate from '../../Translate'
import Chip from './components/Chip'
import { StartTimeLabel, EndTimeLabel } from '../BoardList/components/TourShipments'

import { isValidCollation } from '../../../actions/tours/helpers'
import { translate } from '../../../utils'

import styles from './SortableItem.module.scss'

const Handle = SortableHandle(({ className }) => (
  <div className={clsx(styles.dragHandle, className)}>
    <FontAwesomeIcon icon={faArrowsAltV}/>
  </div>
))


const SortableItemElement = SortableElement(({
  collation,
  tour,
  tours,
  index,
  moveTour,
  locked,
}) => {
  const {
    estimation, position, recipient: { location },
    isMultiColli, shipmentIds,
  } = collation

  const [active, setActive] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const getMenuItems = memoize((tours, orgTour) => {
    return(
      tours.map((item)=>{
        return item.id !== tour.id && (
          <ListItem
            key={item.id}
            onClick={() => moveTour(orgTour, collation, item)}
            disabled={item.isWorking}
            innerDivStyle={{ padding: 0, textAlign: 'center', fontSize: 12 }}
            className={styles.itemRoot}
            leftIcon={<span className={styles.icon} style={{ background: item.color }}>{item.number}</span>}
          >
            <Grid container direction="row" className={styles.listItemContainer}>
              <Grid item xs={7} className={styles.gridMenuItem} >
                <div className={styles.shipmentTime}>
                  <StartTimeLabel showLabel={false} className={styles.eta} collations={item.collations}/> -
                  <EndTimeLabel showLabel={false} className={styles.eta} collations={item.collations}/>
                </div>
              </Grid>
              <Grid item xs={5}>
                <span>{item.collations.filter(isValidCollation).reduce((a, b) =>
                  a + b.shipmentIds.length || a, 0)}</span>
              </Grid>
            </Grid>
          </ListItem>
        )
      },
      )
    )
  })


  let menuItems
  if(!locked) {
    menuItems = getMenuItems(tours, tour)
  }

  const mouseEnter = () => {
    setActive(true)
  }

  const mouseLeave = () => {
    setActive(false)
  }

  const handleClick = ()=> {
    setOpen(!open)
  }

  return (
    <Box className={clsx(styles.menu, collation.isBermudaStop && styles.bermudaIndicator)}>
      <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <Grid container>
          <Grid container item>
            <Grid item xs={10} lg={11}>
              {active ?
                <Handle index={index} className={clsx({ [styles.hover]: active }, collation.isBermudaStop && styles.dragHandleButton)}/>
                :
                <div className={clsx(styles.dragHandle, collation.isBermudaStop && styles.dragHandleButton)}>{position}</div>}
              <div className={styles.customerInfo}>
                <span className={styles.address}>{location.address}</span>
                <span>{location.postalCode} {location.city}</span>
              </div>
            </Grid>
            <Grid item xs={2} lg={1} className={styles['pull-right']}>
              <span className={styles.estimation}>{!!estimation && moment(estimation).format('HH:mm')}</span>
              <span className={styles['more-icon-container']}>{(!locked && tours.length >= 2) && <MoreVertIcon className={styles.moreIcon} onClick={handleClick}/>}</span>
            </Grid>
          </Grid>
          {isMultiColli && (
            <div className={styles.barPositioning}>
              <Chip label={`${shipmentIds.length} ${translate('AMOUNT_OF_PACKAGES')}`}/>
            </div>
          )}
        </Grid>
      </div>
      <Fade style={{ display: open ? 'block' : 'none' }} in={open}>
        <List classes={{
          root: styles.listRoot,
        }}>
          <span className={styles.listTitle}><Translate>MOVE_TOUR</Translate></span>
          <PerfectScrollBar className={styles.boxScroll}>
            <Box classes={{ root: styles.boxRoot }}>
              {menuItems}
            </Box>
          </PerfectScrollBar>
        </List>
      </Fade>
    </Box>
  )
})

const SortableList = (({
  tour, tours, moveTour, addRoute, locked,
}) => tour.collations.filter(isValidCollation).map((stop, index) => (
  <SortableItemElement
    disabled={locked}
    locked={locked}
    key={stop.id}
    tour={tour}
    index={index}
    collation={stop}
    tours={tours}
    moveTour={moveTour}
    addRoute={addRoute}
  />
)))

export const SortableItem = SortableContainer(SortableList)
