import React, { memo, useMemo } from 'react'
import {  useSelector } from 'react-redux'
import moment from 'moment'
import {getHubInventory, getIsBatchHubOutDone, isShipmentReturnedExistOnInventory} from "../../../../selectors/hubLogs";
import styles from './styles.module.scss'
import {useTranslate} from "../../../../utils";
import { ITYPES } from 'reducers/hublogs';

const HubInventoryList = () => {
  const hubInventory = useSelector(getHubInventory)
  const isNotAcknowledgedYet = useSelector(isShipmentReturnedExistOnInventory)
  const isHubOutDone = useSelector(getIsBatchHubOutDone)

  const shipments = useMemo(() => !isNotAcknowledgedYet || isHubOutDone ?
    hubInventory.filter((shipment) => shipment.type === ITYPES.KEEP_IN_REGION || !shipment.type)
    : hubInventory, [hubInventory, isNotAcknowledgedYet, isHubOutDone])

  const {t} = useTranslate(['SHIPMENT_STATES', 'HUB_INVENTORY'])

  return(
    <section className={styles.table}>
      <header className={styles.resized}>
        <div>{t('HUB_INVENTORY:TRUNKRSNR')}</div>
        <div>{t('HUB_INVENTORY:ADDRESS')}</div>
        <div>{t('HUB_INVENTORY:LAST_DRIVER')}</div>
        <div>
          <span>
            {t('HUB_INVENTORY:INTENDED_DELIVERY_DATE')}
          </span>
        </div>
        <div>{t('HUB_INVENTORY:IN_SINCE')}</div>
        <div>{t('HUB_INVENTORY:STATUS')}</div>
      </header>
      <div className={styles['table-body']}>
        {
          useMemo(()=> shipments.map(({trunkrsNr, country, city, postalCode, address, driverName, intendedDeliveryDate, inSince, shipmentState}) => {
            return (
              <div className={styles.resized}>
                <div>
                  {trunkrsNr}
                </div>
                <div>
                  <div className={styles.address}>
                    <div>
                      <span>{country}</span>
                    </div>
                    <div>
                      <div>{city}</div>
                      <div>{`${postalCode} ${address}`}</div>
                    </div>
                  </div>
                </div>
                <div>
                  {driverName}
                </div>
                <div>
                  {intendedDeliveryDate}
                </div>
                <div>
                  {moment(inSince).format('DD-MM-YYYY HH:mm:ss')}
                </div>
                <div className={styles.state}><span>{t(`SHIPMENT_STATES:${shipmentState}`)}</span></div>
              </div>
            )
          }), [shipments])
        }
      </div>
    </section>
  )
}

export default memo(HubInventoryList)
