import { useInterval } from 'hooks';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useIsMenuAccessible = (id: number) =>{
    const timeFormat = 'HH:mm:ss';

    const [isMenuAccessible, setIsMenuAccessible] = useState(false)

    const timeStamp = useRef<number>()

    const verify = useCallback(() => {
        switch(id){
            case 10: {
                timeStamp.current = moment.now()
                const result = moment(moment.now()).isBetween(moment('03:00:00', timeFormat), moment('13:59:59', timeFormat))
                setIsMenuAccessible(result)
                break;
            }
            default:
                setIsMenuAccessible(true)
        }
    }, [id, timeStamp.current])
  
    const [startPolling, stopPolling] = useInterval(verify, 10000)
  
    useEffect(() => {
        verify()
        startPolling()
        return () => stopPolling()
    }, [])

    return {isMenuAccessible}
}