import { environment } from '../constants'

const CREATE_TEST_DATA_KEY_EXPIRY = 'CREATE_TEST_DATA_KEY_EXPIRY'
const API_KEY = 'VfdFGzOWY7aCJHcM8lt7Axz8NEYYYxN15F61iwGf'

export const createTestData = async sdk => {
  if(/^(staging|development)/.test(environment)) {
    console.log('creating test data')
    try {
      await sdk.createTestData(API_KEY)
      localStorage.setItem(CREATE_TEST_DATA_KEY_EXPIRY, JSON.stringify({
        timestamp: new Date(),
      }))
    }
    catch (e) {
      console.error(e)
    }
  }
}
