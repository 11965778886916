import { useRef, useEffect, useMemo } from 'react'

export const useInterval = (
  callback: (...args: any[]) => void,
  delay: number = 120000
) => {
  const interval = useRef<any>()

  const start = () => {
    interval.current = setInterval(callback, delay)
  }
  const stop = () => {
    if (interval.current) {
      clearInterval(interval.current)
    }
  }

  useEffect(() => {
    return () => stop()
  }, [])

  return useMemo(() => [start, stop], [])
}
