import { createSelector } from 'reselect'
import { createFeatureSelector } from './core'

import * as fromTodoList from '../reducers/todoList'

const selectFeature = createFeatureSelector('todoList')

const selectIdentifiers = state => state.identifiers
const selectData = state => state.data

export const getHasFetched = createSelector(
  selectFeature,
  fromTodoList.selectHasFetched,
)

export const getIsLoading = createSelector(
  selectFeature,
  fromTodoList.selectIsLoading,
)

export const getNotDelivered = createSelector(
  selectFeature,
  fromTodoList.selectNotDelivered,
)

export const getNoEndState = createSelector(
  selectFeature,
  fromTodoList.selectNoEndState,
)


export const getReturnShipments = createSelector(
  selectFeature,
  fromTodoList.selectReturnShipments,
)

export const getNotDeliveredIdentifiers = createSelector(
  getNotDelivered,
  selectIdentifiers,
)

export const getNotDeliveredData = createSelector(
  getNotDelivered,
  selectData,
)

export const getNoEndStateIdentifiers = createSelector(
  getNoEndState,
  selectIdentifiers,
)

export const getNoEndStateData = createSelector(
  getNoEndState,
  selectData,
)

export const getReturnIdentifiers = createSelector(
  getReturnShipments,
  selectIdentifiers,
)

export const getReturnsData = createSelector(
  getReturnShipments,
  selectData,
)

export const getScanLookup = createSelector(
  selectFeature,
  fromTodoList.selectScanLookup,
)

export const getNotDeliveredShipments = createSelector(
  getNotDeliveredIdentifiers,
  getNotDeliveredData,
  (ids, data) => ids.map(id => data[id]),
)

export const getTodoNotDeliveredShipments = createSelector(
  getNotDeliveredShipments,
  shipments => shipments.filter(shipment => !shipment.todoStatus),
)

export const getReturnShipmentsData = createSelector(
  getReturnIdentifiers,
  getReturnsData,
  (ids, data) => ids.map(id => data[id]),
)

export const getTodoReturns = createSelector(
  getReturnShipmentsData,
  shipments => shipments.filter(shipment => !shipment.todoStatus),
)

export const getNoEndStateShipments = createSelector(
  getNoEndStateIdentifiers,
  getNoEndStateData,
  (ids, data) => ids.map(id => data[id]),
)

export const getTodoNoEndStateShipments = createSelector(
  getNoEndStateShipments,
  shipments => shipments.filter(shipment => !shipment.todoStatus),
)

export const getDoneTodoShipments = createSelector(
  getNoEndStateShipments,
  getNotDeliveredShipments,
  getReturnShipmentsData,
  (noEndStates, notDelivered, returns) => [].concat(...[noEndStates, notDelivered, returns]).filter(shipment => shipment.todoStatus),
)
