import * as React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import useStyles from "./styles"
import clsx from 'clsx'

interface IPageProps {
  className?: string
  children: React.ReactNode
  title: string | React.ReactNode
  left?: React.ReactNode
  right?: React.ReactNode
}

const Page: React.FunctionComponent<IPageProps> = ({
  className,
  children,
  title,
  left,
  right,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.page}>
      <Toolbar className={clsx(classes.toolbar, className)}>
        <Typography className={classes.title} variant="h1">
          {title}
        </Typography>
        {!!left && left}
        <div className={classes.grow} />
        {!!right && right}
      </Toolbar>
      {children}
    </Box>
  )
}

export default Page
