import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Spinner from 'material-ui/CircularProgress'

import Translate from '../../Translate'

import classes from './PageHeader.module.scss'
import { translate } from '../../../utils/translate'

const PageHeader = ({
  isLoading,
}) => {
  const [updated, setUpdated] = React.useState(null)

  React.useEffect(() => {
    if (!isLoading) setUpdated(moment())
  }, [isLoading])

  return (
    <div className={classes.header}>
      <Translate component="h1" className={classes.pageTitle}>
        TOUR_OVERVIEW_PAGE_TITLE
      </Translate>

      {isLoading
        ? (
          <div className={classes.loadingContainer}>
            <Spinner size={18} thickness={2} className={classes.spinner} />
            <Translate component="p" className={classes.updated}>
                UPDATING_OVERVIEW
            </Translate>
          </div>
        )
        : updated && (
          <p className={classes.updated}>
            {`${translate('LAST_UPDATED')}: ${updated.format('YYYY-MM-DD HH:mm:ss')}`}
          </p>
        )
      }
    </div>
  )
}

PageHeader.propTypes = {
  isLoading: PropTypes.bool,
}

PageHeader.defaultProps = {
  isLoading: false,
}

export default PageHeader
