import React from 'react'
import clsx from 'clsx'

import withStyles from '@material-ui/core/styles/withStyles'
import Chip from '@material-ui/core/Chip'

import styles from './Chip.module.scss'

export const Label = ({
  className,
  ...muiChipProps
}) => {
  return (
    <Chip
      aria-label="chip"
      classes={{
        root: styles.root,
      }}
      size="small"
      className={clsx(styles.root, className)}
      {...muiChipProps}
    />
  )
}

export default withStyles(styles)(Label)