import React, { PropTypes, Component } from 'react'
import Modal from './Modal'
import LinearProgress from '../LinearProgress'
import { connect } from 'react-redux'

import logo from '../../images/logo.png'

class Popup extends Component {
  constructor(props) {
    super(props)
    this.loopObj = null
    this.loopProgressObj = null
    this.state = {
      position: 'Down',
      overlayStyle: {
        'animation': 'MoveOverlayDown 0.5s ease-in-out 1 forwards',
      },
      textStyle: {
        'animation': 'MoveTextDown 0.5s ease-in-out 1 forwards',
      },
      contentIdx: 0,
    }
  }

  componentDidUpdate() {
    if (this.props.ui.optimizing) {
      if (this.loopObj === null) {
        this.loopObj = this.loop()
      }
    } else {
      if (this.loopObj !== null) {
        this.loopObj = null
        clearInterval(this.loopObj)
      }
    }
  }

  loop() {
    return setInterval(() => {
      const position = this.state.position === 'Down' ? 'Up' : 'Down'
      const idx = this.state.contentIdx === (this.props.children.length - 1) ? 0 : ++this.state.contentIdx
      this.setState({
        position: position,
        overlayStyle: {
          'animation': 'MoveOverlay' + position + ' 0.5s ease-in-out 1 forwards',
        },
        textStyle: {
          'animation': 'MoveText' + position + ' 0.5s ease-in-out 1 forwards',
        },
        contentIdx: idx,
      })
    }, this.props.timeout)
  }

  render() {
    return (
      <div>
        {(this.props.ui.optimizing) ? <div className="backdrop" /> : null }
        <Modal transitionName="modal-anim" isOpen={this.props.ui.optimizing} >
          <div className="green-overlay" style={this.state.overlayStyle}></div>
          <div className="modal-content">
            <img src={logo} className="logo1"/>
            <p className="popup-text" style={this.state.textStyle}>{
              this.props.children[this.state.contentIdx]
            }</p>
            <img src={logo} className="logo2"/>
          </div>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
  }
}

export default connect(mapStateToProps, null)(Popup)
