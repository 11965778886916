import React, {memo, useCallback} from 'react'
import get from 'lodash/get'
import {StatusBadge} from "../../../../base";
import {isCancelled, isDataProcessed} from "../../../../../utils/state-checker";
import {STATUS_BADGE_TYPES} from "../../../../base/StatusBadge";
import {IShipmentProfile} from "../../@types";
import {useTranslate} from "../../../../../utils";

const ShipmentStateBadge = ({shipment} : {shipment: IShipmentProfile}) => {
  const {t} = useTranslate('SHIPMENTS_OVERVIEW')

  const getBadgeType = useCallback((state, hasTour) => {
    switch (true) {
      case isCancelled(state):
        return {
          label: t('STATE_CANCELED'),
          variant: STATUS_BADGE_TYPES.DANGER,
        }
      case isDataProcessed(state):
        return {
          label: t('STATE_EXPECTED'),
          variant: STATUS_BADGE_TYPES.DARK,
        }
      case hasTour:
        return {
          label: t('STATE_HAS_TOUR'),
          variant: STATUS_BADGE_TYPES.PRIMARY_PURPLE,
        }
      default:
        return {
          label: t('STATE_NO_TOUR'),
          variant: STATUS_BADGE_TYPES.PRIMARY,
        }
    }
  }, [shipment])

  const {label, variant} = getBadgeType(get(shipment, 'State'), get(shipment, 'HasTourAssigned', false))

  return (
    <StatusBadge variant={variant}>
      {label}
    </StatusBadge>
  )
}

export default memo(ShipmentStateBadge)
