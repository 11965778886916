import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { useField } from 'formik'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Collapse from '@material-ui/core/Collapse'
import Button from '@material-ui/core/Button'

import { translate } from '../../../utils/translate'
import Translate from '../../Translate'

import classes from './TourCard.module.scss'

const TourCard = ({
  disabled, name, tourNumber, drivers, remark,
  onOpenTour,
}) => {
  const [{ value, ...field }] = useField({ name })

  return (
    <div className={clsx(classes.container, value && classes.driverSet)}>
      <div className={classes.header}>
        <Translate component="p" interpolations={[tourNumber]}>
          COLLECTION_TOUR
        </Translate>
        <Translate
          component={Button}
          color="primary"
          className={classes.tourDetailsBtn}
          onClick={onOpenTour}
        >
          STOPS
        </Translate>
      </div>

      {remark && (
        <div className={classes.remarkContainer}>
          <Translate component="p" className={classes.remarkTitle}>
            TOUR_REMARK
          </Translate>
          <p>{remark}</p>
        </div>
      )}

      <div className={classes.selectContainer}>
        <Select displayEmpty disabled={disabled} className={classes.select} value={value || ''} {...field}>
          <MenuItem disabled value="">
            {translate('COLLECTION_DRIVER_PLACEHOLDER')}
          </MenuItem>
          {React.useMemo(() => drivers.map(driver => (
            <MenuItem key={driver.id} value={driver.id} disabled={driver.inUse}>
              {driver.name}
            </MenuItem>
          )), [drivers])}
        </Select>
      </div>

      <Collapse in={!value}>
        <div className={classes.noDriverFooter}>
          <FontAwesomeIcon className={classes.icon} icon={faExclamationCircle} />
          <Translate component="p">NO_DRIVER_ASSIGNED</Translate>
        </div>
      </Collapse>
    </div>
  )
}

TourCard.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  tourNumber: PropTypes.number,
  drivers: PropTypes.array,
  remark: PropTypes.string,
  onOpenTour: PropTypes.func,
}

TourCard.defaultProps = {
  disabled: false,
  tourNumber: 0,
  driverId: null,
  drivers: [],
  remark: null,
  onOpenTour: () => null,
}

export default TourCard
