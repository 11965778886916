import React, { memo } from 'react'
import clsx from 'clsx'
import SimpleModalBox from "../../../common/SimpleModalBox";
import { translate as t } from "../../../../utils";
import FlatButton from "material-ui/FlatButton";
import Box from "@material-ui/core/Box/Box";

import styles from './styles.module.scss'
import { useCallback } from 'react';
import { setOptimizeResponse } from 'actions/ui';
import { useDispatch } from 'react-redux';

interface IOptimizationResultDialogProps {
  visible: boolean
  reOptimize: () => void
  optimizationResultData: {
    isOptimizationError?: boolean
    isHttpError?: boolean
    data?: {
      elapsedTime: any
      driverCount: number
      overflowCount?: number
    }
  }
}

const OptimizationResultDialog = memo(({ optimizationResultData, reOptimize, ...props }: IOptimizationResultDialogProps) => {


  const dispatch = useDispatch()
  const handleClose = useCallback(() => dispatch(setOptimizeResponse(undefined)), [dispatch])

  const { isOptimizationError, isHttpError, data } = optimizationResultData
  const isError = (isOptimizationError || isHttpError)
  return (
    <SimpleModalBox onClose={handleClose} {...props}>
      <Box className={clsx(styles.container, isError ? styles["error-container"] : styles["success-container"])}>
        {
          isError ? (
            <OptimizeFailed reOptimize={reOptimize} handleClose={handleClose} isOptimizationError={isOptimizationError} />
          ) :
            data && <OptimizeSuccess reOptimize={reOptimize} handleClose={handleClose} data={data} />
        }
      </Box>
    </SimpleModalBox>
  )
})

const OptimizeSuccess = memo((
  {
    data: {
      elapsedTime,
      driverCount,
      overflowCount
    },
    handleClose,
    reOptimize
  }:
    {
      data: {
        elapsedTime: any
        driverCount: number
        overflowCount?: number
      },
      handleClose: () => void
      reOptimize: () => void
    }
) => (
  <>
    <div className={styles.title}>
      {t('OPTIMIZATION_RESULT_SUCCESS_TITLE')}
    </div>
    <div className={styles.body}>
      <div>
        <span>{t('OPTIMIZATION_RESULT_SUCCESS_DESC_1')} <b>{elapsedTime}</b></span>
        <span>{t('OPTIMIZATION_RESULT_SUCCESS_DESC_2')} <b>{driverCount}</b></span>
        {
          overflowCount && <span>{t('OPTIMIZATION_RESULT_SUCCESS_DESC_3')} <b>{overflowCount}</b></span>
        }
      </div>
        {
          overflowCount && <span>{t('OPTIMIZATION_RESULT_OVERFLOW_DESC')}</span>
        }
    </div>
    <div>
      <FlatButton
        label={t('OPTIMIZATION_RESULT_BUTTON_ACCEPT')}
        className={styles.accept}
        onClick={handleClose}
      />
      <FlatButton
        label={t('OPTIMIZATION_RESULT_BUTTON_RE_OPTIMIZE')}
        className={styles.submit}
        onClick={reOptimize}
      />
    </div>
  </>
))

const OptimizeFailed = memo((
  { isOptimizationError, handleClose, reOptimize }:
    { isOptimizationError?: boolean, handleClose: () => void, reOptimize: () => void }) => (
  <>
    <div className={styles.title}>
      {t('OPTIMIZATION_RESULT_ERROR_TITLE')}
    </div>
    <div className={styles.body}>
      {
        isOptimizationError ? (
          <>
            <span>{t('OPTIMIZATION_RESULT_ERROR_DESC_1')}</span>
            <span>{t('OPTIMIZATION_RESULT_ERROR_DESC_2')}</span>
          </>
        ) :
          <>
            <span>{t('OPTIMIZATION_RESULT_ERROR_DESC_3')}</span>
            <span>{t('OPTIMIZATION_RESULT_ERROR_DESC_4')} <a href="tel:+31850601410">{t('OPTIMIZATION_RESULT_ERROR_SUPPORT_TEL')}</a>.</span>
          </>
      }
    </div>
    {
      isOptimizationError && (
        <div>
          <FlatButton
            label={t('OPTIMIZATION_RESULT_BUTTON_RUN_AGAIN')}
            className={styles.submit}
            onClick={reOptimize}
          />
          <FlatButton
            label={t('CANCEL')}
            className={styles.cancel}
            onClick={handleClose}
          />
        </div>
      )
    }
  </>
))

export default OptimizationResultDialog


