import React, {memo} from 'react'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Grid from "@material-ui/core/Grid/Grid";
import {faSnowflake} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from './styles.module.scss'
import {translate} from "../../../../../../utils/translate";

const FreezerInFeedback = ({isOpen}: {isOpen: boolean}) =>  (
  <Tooltip
    leaveTouchDelay={0}
    disableHoverListener={true}
    disableFocusListener={true}
    disableTouchListener={true}
    arrow title={<Grid container className={styles.tooltipBody}>
    <Grid item>
      <FontAwesomeIcon icon={faSnowflake} />
    </Grid>
    <Grid item>
      {translate('SHIPMENT_ADDED_TO_THE_FREEZER')}
    </Grid>
  </Grid>}
    classes={styles}
    open={isOpen}
    placement="right">
    <div />
  </Tooltip>
)

export default memo(FreezerInFeedback)
