import { schema, normalize } from 'normalizr'
import {
  ADD_SHIPMENT,
  EDIT_SHIPMENT,
  DELETE_SHIPMENT,
  CLEAR_SHIPMENTS,
  SHIPMENT_SORTED,
  CHANGE_SUBCO, COMPLETE_TASK, SHIPMENT_IN_TRANSACTION,
} from '../constants/ActionTypes'

const shipmentSchema = new schema.Entity('shipments', {}, { idAttribute: 'id' })

const normalizeData = (data) => {
  const {
    result: keys,
    entities: { shipments: normalizedData },
  } = normalize(data, [shipmentSchema])

  return [
    keys || [],
    normalizedData || {},
  ]
}

const initialState = {
  hasFetched: false,
  identifiers: [],
  data: {},
}

export default function shipments(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
  case ADD_SHIPMENT:
    const [identifiers, data] = normalizeData(payload)

    return {
      ...state,
      hasFetched: true,
      identifiers,
      data,
    }

  case EDIT_SHIPMENT:
    return {
      ...state,
      data: { [payload.id]: payload },
    }

  case SHIPMENT_SORTED:
    const { shipmentId } = payload
    const { data: { [shipmentId]: shipment } } = state
    return {
      ...state,
      data: {
        ...state.data,
        [shipment.id]: {
          ...shipment,
          state: {
            name: 'SHIPMENT_SORTED_AT_SUB_DEPOT',
            reasonCode: null,
          },
        },
      },
    }

  case CLEAR_SHIPMENTS:
  case CHANGE_SUBCO:
    return initialState

  case DELETE_SHIPMENT:
    const deleteIds = state.identifiers
      .filter(id => id !== payload)
    const deleteData = { ...state.data }
    delete data[payload]

    return { ...state, deleteIds, deleteData }

  case SHIPMENT_IN_TRANSACTION: {
    const { id } = payload

    const { data: { [id]: shipment } } = state
    return {
      ...state,
      data: {
        ...state.data,
        [id]: {
          ...shipment,
          isWorking: true,
        },
      },
    }
  }

  case COMPLETE_TASK: {
    const { id } = payload
    if (!id.startsWith('handover-')) {
      return state
    }

    const [, shipmentId] = id.split('handover-')

    const { data: { [shipmentId]: shipment } } = state
    if (!shipment) {
      return { ...state }
    }
    return {
      ...state,
      data: {
        ...state.data,
        [shipment.id]: {
          ...shipment,
          isWorking: false,
          state: {
            name: 'SHIPMENT_SORTED_AT_SUB_DEPOT',
            reasonCode: null,
          },
        },
      },
    }
  }

  default:
    return state
  }
}

export const selectIdentifiers = state => state.identifiers
export const selectNonNegativeIdentifiers = state => state.identifiers.filter(id => id > 0)
export const selectData = state => state.data
export const selectHasFetched = state => state.hasFetched
