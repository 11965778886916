import React, {memo} from 'react'
import clsx from 'clsx'
import get from 'lodash/get'

import styles from './styles.module.scss'

const Avatar = memo(({children, className, ...props} : React.HTMLAttributes<any>) => (
  <div className={clsx(styles.avatarContainer, className)} {...props}>
    {
      typeof children === 'string' ? `${get(children.split(' '), '[0][0]') + get(children.split(' '), '[1][0]', '')}` : children
    }
  </div>
))

export default Avatar
