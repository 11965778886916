import React, {memo} from 'react'
import clsx from 'clsx'
import closeIcon from '../../../images/close.svg'
import styles from './styles.module.scss'

export interface IModalBoxProps extends React.HTMLAttributes<any>{
  visible: boolean,
  onClose: () => void
}

const ModalBox = ({ visible, onClose, children, ...props } : IModalBoxProps) => {
  return (
    <div className={clsx(styles.modalBox, !visible && styles.hidden)} {...props}>
      <div className={styles.modalBody}>
        <span className="close-button" onClick={onClose}>
          <img src={closeIcon}/>
        </span>
        <div className="content">
          {
            children
          }
        </div>
      </div>
    </div>
  )
}

export default memo(ModalBox)

