import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import memoize from 'memoize-one'

import * as fromUI from '../../selectors/ui'

import Translate from '../Translate'
import { translate } from '../../utils/translate'

import multicolli from '../../images/multicolli.png'

const styles = {
  p: {
    maxWidth: 500,
  },
  img: {
    maxWidth: 500,
    // boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px",
    borderRadius: '2px',
  },
}

class Update extends PureComponent {
  getTranslations = memoize(() =>
    translate([
      'ABT',
      'SL1T',
      'SL1ST',
      'SL2T',
      'SL2ST',
      'SL3T',
      'SL3ST',
      'SL4T',
      'SL4ST',
      'SL5T',
      'SL5ST',
    ]),
  )

  render() {
    const { appVersion } = this.props
    const [
      actionBtnText,
      slideOneTitle,
      slideOneSubTitle,
      slideTwoTitle,
      slideTwoSubTitle,
      slideThreeTitle,
      slideThreeSubTitle,
      slideFourTitle,
      slideFourSubTitle,
      slideFiveTitle,
      slideFiveSubTitle,
    ] = this.getTranslations()

    return (
      <div>
        <h1><Translate uppercase>UPDATE</Translate> - v{appVersion}</h1>
        <h2>{slideTwoTitle}</h2>
        <p style={styles.p}>{slideTwoSubTitle}</p>
        <img src={multicolli} style={styles.img}/>
        {/* <h2>{slideThreeTitle}</h2>
        <p style={styles.p}>{slideThreeSubTitle}</p>
        <h2>{slideFourTitle}</h2>
        <p style={styles.p}>{slideFourSubTitle}</p>
        <img src={settings} style={styles.img}/> */}
        <h2>{slideFiveTitle}</h2>
        <p style={styles.p}>{slideFiveSubTitle}</p>
        <div style={{ height: 60 }}></div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    appVersion: fromUI.getAppVersion(state),
  }
}

export default connect(mapStateToProps, null)(Update)
