import React from 'react'

import Dialog from 'material-ui/Dialog'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'

import Error from './Error'
import Translate from '../Translate'

const OptimizeButtons = ({
  onContinue, onClose,
}) => [
  <FlatButton
    style={{ marginRight: 10 }}
    label={<Translate>CANCEL</Translate>}
    onClick={onClose}
  />,
  <RaisedButton
    label={<Translate>OK</Translate>}
    primary={true}
    onClick={onContinue}
  />,
]

const OptimizeWarning = ({
  show, close: handleClose, continue: handleContinue,
}) => (
  <Dialog
    modal
    open={show}
    actions={(
      <OptimizeButtons
        onClose={handleClose}
        onContinue={handleContinue}
      />
    )}
  >
    <Error show={true} style={{ margin: '20px 0' }}>
      <Translate>OPTIMIZE_WARNING</Translate>
    </Error>
  </Dialog>
)

export default OptimizeWarning
