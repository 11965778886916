import React from 'react'
import moment from 'moment'
import clsx from 'clsx'

import Translate from '../../../Translate'

import classes from './TimeRemaining.module.scss'

const endTime = moment()
  .hour(13).startOf('hour')

const getTimeLeft = () => {
  const diff = Math.max(endTime.diff(moment()), 0)
  const duration = moment.duration(diff)

  return [
    diff,
    `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`,
  ]
}

const TimeRemaining = () => {
  const [[timeDiff, timeLeft], setTimeLeft] = React.useState(getTimeLeft())

  React.useEffect(() => {
    // Specifically check it on first run
    if (!timeDiff) return

    const interval = setInterval(() => {
      setTimeLeft(getTimeLeft())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const timerClasses = clsx(
    classes.time,
    timeDiff === 0 && classes.noTimeFlash,
    timeDiff > 0 && timeDiff < (2 * 3600 * 1000) && classes.almostNoTimeFlash, // Two hours
  )

  return (
    <div className={classes.container}>
      <Translate component="p" className={classes.title}>
        TIME_REMAINING
      </Translate>
      <p className={timerClasses}>
        {timeLeft}
      </p>
    </div>
  )
}

export default TimeRemaining
