import React from 'react'
import clsx from 'clsx'

import Translate from '../Translate'

import classes from './QuickTour.module.scss'

const TourOverviewLegend = () => (
  <div className={classes.legend}>
    <div className={clsx(classes.stop, classes.notScanned)}/>
    =&nbsp;
    <Translate>NOT_SCANNED_AT_HUB</Translate>
    <div className={classes.stop}/>
    =&nbsp;
    <Translate>ON_ITS_WAY</Translate>
    <div className={clsx(classes.stop, classes.delivered)}/>
    =&nbsp;
    <Translate>DELIVERED</Translate>
    <div className={clsx(classes.stop, classes.deliveredToNeighbour)}/>
    =&nbsp;
    <Translate>DELIVERED_TO_NEIGHBOUR</Translate>
    <div className={clsx(classes.stop, classes.notDelivered)}/>
    =&nbsp;
    <Translate>NOT_DELIVERED</Translate>
  </div>
)

export default TourOverviewLegend
