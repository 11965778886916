import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from '../../browserHistory'
import Translate from '../Translate'

import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton'
import FontIcon from 'material-ui/FontIcon'

let driverName

class Driver extends PureComponent {
  constructor(props) {
    super(props)
    const drivers = this.props.profile.subco.drivers
    const driverId = this.props.routeParams.id
    {Object.keys(drivers).map((key) => {
      const driver = drivers[key]
      if (driver.driverId === parseInt(driverId)) {
        this.state = {
          name: driver.name,
          phoneNumber: driver.phoneNumber,
        }
        driverName = driver.name
      }
    })}
  }

  componentDidMount() {
    if(!this.state) {
      browserHistory.push('/')
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit(e) {
    const { name, phoneNumber } = this.state
  }


  render() {

    return (
      <div>
        <h1><Translate capitalize={true}>EDIT_DRIVER</Translate>: {driverName}</h1>
        <div style={{ width: '100%', maxWidth: 450, minHeight: '85vh' }}>

          <TextField
            style={{ width: '100%' }}
            hintText={<Translate capitalize={true}>NAME</Translate>}
            floatingLabelText={<Translate capitalize={true}>NAME</Translate>}
            defaultValue={this.state.name}
            name="name"
            onChange={this.handleChange.bind(this)}
          />
          <TextField
            style={{ width: '100%' }}
            hintText={<Translate capitalize={true}>PHONE_NUMBER</Translate>}
            floatingLabelText={<Translate capitalize={true}>PHONE_NUMBER</Translate>}
            defaultValue={this.state.phoneNumber}
            name="name"
            onChange={this.handleChange.bind(this)}
          /><br/><br/>
          <RaisedButton
            primary={true}
            disabled={this.state.active}
            label={<Translate uppercase={true}>SAVE</Translate>}
            icon={<FontIcon className="material-icons">save</FontIcon>}
            onClick={this.handleSubmit.bind(this)}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
})

export default connect(mapStateToProps, null)(Driver)
