import * as actions from '../../constants/ActionTypes'
import { mergeUpdates } from './helpers'
import get  from 'lodash/get'


const initialState = {
  hasTours: false,
  isWorking: false,
  identifiers: [],
  data: {},
  editTourId: null,
  productivityGoal: 0,
  isPlanningLocked: false,
  areToursSorted: false,
  tourPageState: {
    timeUserOnPage: undefined
  },
}

export default function tours(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
  case actions.SET_DRIVER: {

    const { tourId, driverId } = payload
    const { data: { [tourId]: tour } } = state
    return {
      ...state,
      data: {
        ...state.data,
        [tourId]: { ...tour, driverId },
        isSaved: false,
      },
    }
  }

  case actions.SAVE_DRIVER: {
    return {
      ...state,
      data: {
        ...state.data,
      },
    }
  }

  case actions.SET_VEHICLE_TYPE: {
    const { tourId, vehicleType } = payload
    const { data: { [tourId]: tour } } = state

    return {
      ...state,
      data: {
        ...state.data,
        [tourId]: { ...tour, vehicleType },
      },
    }
  }

  case actions.TOUR_PLANNED: {
    const { tourId, stateName } = payload
    const { data: { [tourId]: tour } } = state

    return {
      ...state,
      data: {
        ...state.data,
        [tourId]: { ...tour, state: stateName },
      },
    }
  }

  case actions.SET_TOURS: {
    const { tours: [identifiers, data] } = payload

    return {
      ...state,
      hasTours: !!identifiers.length,
      identifiers,
      data,
    }
  }

  case actions.UPDATE_TOURS: {
    const { tours: [identifiers, data] } = payload

    const [
      changedIdentifiers,
      changedData,
    ] = mergeUpdates(
      state.identifiers,
      state.data,
      identifiers,
      data,
    )

    return {
      ...state,
      data: changedData,
      identifiers: changedIdentifiers,
    }
  }

  case actions.SET_EDIT_MODE: {
    return { ...state, editTourId: payload }
  }

  case actions.CHANGE_SUBCO: {
    return {
      ...initialState
    }
  }

  case actions.UPDATE_TOUR: {
    const { tour } = payload
    const { data: { [tour.id]: currentTour } } = state
    return {
      ...state,
      data: {
        ...state.data,
        [tour.id]: { ...currentTour, ...tour },
      },
    }
  }

  case actions.SET_REGION_PRODUCTIVITY_GOAL: {
    const { productivity } = payload
    return {
      ...state,
      productivityGoal: productivity,
    }
  }

  case actions.SET_IS_TOURS_PLANNING_LOCKED: {
    const { isPlanningLocked } = payload
    return {
      ...state,
      isPlanningLocked,
    }
  }

  case actions.SET_ARE_TOURS_SORTED: {
    const { areToursSorted } = payload
    return {
      ...state,
      areToursSorted,
    }
  }

  case actions.SET_IS_PAGERELOADED:
    return {
      ...state,
      tourPageState: {
        isPageReloaded: get(action, 'payload.isPageReloaded'),
        timeUserOnPage: get(action, 'payload.timeUserOnPage', state.tourPageState.timeUserOnPage)
      }
    }

  default:
    return state
  }
}

export const selectIdentifiers = state => state.identifiers
export const selectData = state => state.data
export const selectHasTours = state => state.hasTours
export const selectEditTourId = state => state.editTourId
export const selectProductivityGoal = state => state.productivityGoal
export const selectIsPlanningLocked = state => state.isPlanningLocked
export const selectAreToursSorted = state => state.areToursSorted
export const selectTourPageState = state => state.tourPageState
