import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import uniqueId from 'lodash/uniqueId'
import get from 'lodash/get'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import TourShipments from '../TourShipments'
import styles from '../../../BoardList.module.scss'
import Callout from '../../../../base/Callout'

import { SortableItem } from '../../SortableItem'
import { isPastDeadLine } from '../../../../../utils'
import { usePlannerStore } from '../../../../../stores/planner'
import { useTranslate } from '../../../../../utils/translate'
import { BoardActions } from '../BoardActions/index'



const SortableListComponent = (props) => {

  const id = uniqueId()
  const { t } = useTranslate()

  const [state, setState] = useState({
    displayColorPicker: false,
    showList: false,
    showItemList: false,
    grow: false,
  })

  const { toggleTourVisibility, tourVisibility } = usePlannerStore()

  const handleListChange = (tourId, toggleState, tourNo) => {
    props.onShowFullTour(tourId, toggleState, tourNo)
    setState(state => ({...state, grow: toggleState }))

    if(!toggleState) {
      setTimeout(() => setState(state => ({ ...state, showList: false })), 700)
      return
    }
    setState(state => ({ ...state, showList: true }))
  }


  const handleDriverChanged = (event, selected) => {
    const { tour: { id }, onChangeDriver } = props
    if(selected) {
      return onChangeDriver(id, parseInt(selected.value))
    }
    onChangeDriver(id, selected)
  }

  const showColorPicker = () => setState(state => ({ ...state, displayColorPicker: true }))
  const clearColorPicker = () => setTimeout(() => setState(state => ({ ...state, displayColorPicker: false })), 100)

  const saveTourColor = (selectedColor) => {
    const { tour: { color, id }, saveTourColor } = props
    if(color !== selectedColor) {
      saveTourColor(id, selectedColor)
    }
  }

  const changeTourColor = async({ hex }) => {
    if(hex) {
      saveTourColor(hex)
    }
  }

  const getContainer = (index) => ()=> document.getElementById(`collapse-container-${index}`)

  const {
    onMoveCollations,
    tours,
    tour,
    index,
    onNewTour,
    onReverseTour,
    onOptimizeTour,
    locked,
    isPlanningLocked,
    onChangePosition,
  } = props

  const { displayColorPicker, currentColor } = state

  const tourColor = currentColor || tour.color

  const pastDeadLine = isPastDeadLine(tour.collations)

  const isVisible = get(tourVisibility, tour.id, true)

  const toggleTour = useCallback(() => {
    toggleTourVisibility(tour.id)
  }, [tour.id])

  return (
    <Paper className={clsx(styles.paperContainer, pastDeadLine && styles.warningBorder)}>
      <Grid container direction="row">
        <Grid item lg={7}>
          <TourShipments locked={locked} showList={state.showList} tour={tour} tourColor={tourColor}/>
        </Grid >
        <Grid item lg={5}>
          <BoardActions
            drivers={props.drivers}
            tour={tour}
            showList={state.showList}
            isVisible={isVisible}
            onChangeVehicleType={props.onChangeVehicleType}
            tourColor={tourColor}
            tours={tours}
            locked={locked}
            displayColorPicker={displayColorPicker}
            onReverseTour={onReverseTour}
            onOptimizeTour={onOptimizeTour}
            handleDriverChanged={handleDriverChanged}
            toggleTour={toggleTour}
            showColorPicker={showColorPicker}
            changeTourColor={changeTourColor}
            clearColorPicker={clearColorPicker}
            handleListChange={handleListChange}
          />
        </Grid>
      </Grid>
      <Collapse timeout={500} in={state.grow}>
        { state.showList && (
          <div id={`collapse-container-${index}`} className={styles.showList}>
            {tour.hasBermudaStop &&
              <Callout variant="danger" className={styles.bermudaNotification}>
                {t('BERMUDA_TEXT_CALLOUT')}
              </Callout>
            }
            <SortableItem
              locked={locked || isPlanningLocked}
              useDragHandle
              key={tour.id}
              index={index}
              no={index + 1}
              tour={tour}
              tours={tours}
              moveTour={onMoveCollations}
              addRoute={onNewTour}
              onSortEnd={onChangePosition}
              helperClass="drag-helper"
              lockAxis="y"
              useWindowAsScrollContainer={false}
              getContainer={getContainer(index)}
              lockToContainerEdges={true}
            />
          </div>
        )}
      </Collapse>
    </Paper>
  )
}

SortableListComponent.propTypes = {
  drivers: PropTypes.array,
  tours: PropTypes.array,
  tour: PropTypes.object,
  index: PropTypes.number,
  editMode: PropTypes.bool,
  isMarkAsPlanned: PropTypes.bool,
  isPlanningLocked: PropTypes.bool,
  container: PropTypes.bool,
  locked: PropTypes.bool,
  onChangeDriver: PropTypes.func,
  onChangePosition: PropTypes.func,
  onChangeVehicleType: PropTypes.func,
  onMoveCollations: PropTypes.func,
  onSetEditMode: PropTypes.func,
  onNewTour: PropTypes.func,
  onReverseTour: PropTypes.func,
  onOptimizeTour: PropTypes.func,
  saveTourColor: PropTypes.func,
  onShowFullTour: PropTypes.func,
}

export default SortableListComponent
