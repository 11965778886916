import moment from 'moment'
import get from 'lodash/get'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'

import { leftFindJoin } from '../../utils/dataSets'
import { availableForHandover } from '../../utils/state-checker'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/models/delivery/ShipmentLog'

export const mapOverviewShipment = (shipment, tourData) => {
  const {
    id, timeSlot, tour: { id: tourId }, isReturn, barcode,
    recipient: { name, location }, state, trunkrsNr,
  } = shipment
  const { [tourId]: tour } = tourData

  return {
    CollationId: 0,
    DeliveryDate: moment(get(timeSlot, 'from')).toDate(),
    DriverId: get(tour, 'driverId'),
    Id: id,
    Log: {
      DeliveryHubId: null,
      DestinationId: null,
      PickupHubId: null,
      ServiceHubId: null,
    },
    MultiColli: false,
    OrderReference: barcode,
    Pos: null,
    Recipient: {
      Address: location.address,
      City: location.city,
      Country: location.country,
      HouseNumber: null,
      Id: location.id,
      Lat: location.latitude,
      Lng: location.longitude,
      Name: name,
      PhoneNumber: '',
      PostalCode: location.postalCode,
      Remarks: null,
    },
    Return: isReturn,
    Route: get(tour, 'number'),
    SenderId: null,
    State: get(state, 'name'),
    Timeslot: {
      Close: get(timeSlot, 'to'),
      CutOff: null,
      Date: null,
      Id: get(timeSlot, 'id'),
      Open: get(timeSlot, 'from'),
    },
    TrunkrsNr: trunkrsNr,
    instance: null,
    HasTourAssigned: !isEmpty(tour),
  }
}

const combineCollationShipment = (collation, shipment) => ({
  ...collation,
  recipient: get(shipment, 'recipient', { location: {} }),
  timeSlot: get(shipment, 'timeSlot', {}),
  state: get(shipment, 'state'),
})

const combineCollationWithShipments = (collation, shipments) => {
  const shipment = first(shipments)
  return {
    ...collation,
    recipient: get(shipment, 'recipient', { location: {} }),
    timeSlot: get(shipment, 'timeSlot', {}),
    state: get(shipment, 'state'),
    states: shipments.map(item => get(item, 'state')),
  }
}

export const mapTourForOverview = (shipments, tour) => ({
  ...tour,
  collations: tour.collations
    .map(
      collation => combineCollationWithShipments(
        collation,
        shipments.filter(shipment => collation && collation.shipmentIds.includes(shipment.id)),
      ),
    ),
})

export const mapToToursForOverview = (tours, shipments) =>
  tours.map(mapTourForOverview.bind(null, shipments))

export const mapTourForHandover = (shipments, tour) => {
  const collationsInTour = leftFindJoin(
    tour.collations,
    shipments,
    (data) => {
      if (!data || !data.shipmentIds) {
        return
      }
      const { shipmentIds: [shipmentId] } = data
      return shipmentId
    },
    shipment => shipment.id,
    combineCollationShipment,
  )
  const shipmentsInTour = collationsInTour.reduce((acc, collation) => {
    return acc.concat(
      get(collation, 'shipmentIds', []).map(shipmentId => shipments.find(shipment => shipment.id === shipmentId)),
    )
  }, [])
    .filter((shipment) => availableForHandover(get(shipment, 'state.name')))
  return {
    ...tour,
    collations: shipmentsInTour,
  }
}

export const mapToToursForHandover = (tours, shipments) =>
  tours.map(mapTourForHandover.bind(null, shipments))

export const mapDriverToTour = (tour, drivers) => ({
  ...tour,
  driver: {
    ...get(drivers, tour.driverId, {}),
  },
})