import * as Yup from 'yup'

const phoneNumberRegex = /\+316[0-9]{8}/
const emailRegex = /\S+@\S+\.\S+/

export const validationSchema = Yup.object().shape({
  name: Yup.string()
  .required(('REQUIRED_FIELD')),
  phoneNumber: Yup.string()
    .required(('REQUIRED_FIELD')),
  language: Yup.string()
  .required(('REQUIRED_FIELD')),
})


export const subcoValidationSchema = Yup.object().shape({
  name: Yup.string()
  .required(('REQUIRED_FIELD')),
  phoneNumber: Yup.string()
    .required(('REQUIRED_FIELD')),
  language: Yup.string()
  .required(('REQUIRED_FIELD')),
  invoiceSubcoId: Yup.string()
  .required(('REQUIRED_FIELD')),
})