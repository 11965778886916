import React, {memo, useCallback, useEffect, useRef, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faCheckCircle, definition} from "@fortawesome/free-solid-svg-icons/faCheckCircle";

import styles from './styles.module.scss'

interface ISimpleNotificationProps extends React.HTMLProps<HTMLDivElement>{
  icon?: typeof definition
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  headerText?: string,
  subText?: string
  onExit?: () => void
}

const SimpleNotification = ({icon = faCheckCircle, position = 'bottom-right', className, children, headerText, subText, onExit, ...props}: ISimpleNotificationProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const timer = useRef<any>()

  const clearTimer = useCallback(() => {
    clearTimeout(timer.current)
  }, [timer.current])

  useEffect(() => {
    clearTimer()
    if (isVisible) {
      timer.current = setTimeout(() => {
        setIsVisible(false)
        if(typeof onExit === 'function'){
          onExit()
        }
      }, 5000)
    }
    return clearTimer
  }, [isVisible])

  useEffect(()=>{
    setIsVisible(true)
  }, [])

  return isVisible ? (
    <div
      className={clsx(styles["notification-container"], styles[position], className)}
      {...props}
    >
      <div className={styles.icon}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={styles["message-container"]}>
        <span className={styles["close-button"]} onClick={onExit} ><FontAwesomeIcon icon={faTimes} /></span>
        {
          children ? children : (
            <>
              <span className={styles["header-text"]}>
                {headerText}
              </span>
              <span className={styles["sub-text"]}>
                {subText}
              </span>
            </>
          )
        }
      </div>
    </div>
  ) : null
}

export default memo(SimpleNotification)
