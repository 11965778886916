import React, { PureComponent } from 'react'
import memoize from 'memoize-one'

import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel'

import { translate } from '../../utils/translate'

// Images: circular 265x256px + ring
import shipmentsImg from '../../images/onboardingShipments.png'
import toursImg from '../../images/onboardingTours.png'
import handoverImg from '../../images/onboardingHandover.png'
import carImg from '../../images/onboardingCar.png'
import intercomImg from '../../images/onboardingIntercom.png'

const TrunkrsGreen = '#92D400'
const darkGreen = '#83BE00'

class NewUser extends PureComponent {
  getTranslations = memoize(() =>
    translate([
      'ABT',
      'NU1T',
      'NU1ST',
      'NU2T',
      'NU2ST',
      'NU3T',
      'NU3ST',
      'NU4T',
      'NU4ST',
      'NU5T',
      'NU5ST',
    ]),
  )

  render() {
    const [
      actionBtnText,
      slideOneTitle,
      slideOneSubTitle,
      slideTwoTitle,
      slideTwoSubTitle,
      slideThreeTitle,
      slideThreeSubTitle,
      slideFourTitle,
      slideFourSubTitle,
      slideFiveTitle,
      slideFiveSubTitle,
    ] = this.getTranslations()

    return (
      <div>
        <AutoRotatingCarousel
          label={actionBtnText ? actionBtnText : 'New Update!'}
          autoplay={false}
          onRequestClose={this.props.close}
          onStart={this.props.close}
          open={this.props.open}>
          <Slide
            media={<img src={carImg} />}
            mediaBackgroundStyle={{ backgroundColor: TrunkrsGreen }}
            contentStyle={{ backgroundColor: darkGreen }}
            title={slideOneTitle}
            subtitle={slideOneSubTitle}
          />
          <Slide
            media={<img src={shipmentsImg} />}
            mediaBackgroundStyle={{ backgroundColor: TrunkrsGreen }}
            contentStyle={{ backgroundColor: darkGreen }}
            title={slideTwoTitle}
            subtitle={slideTwoSubTitle}
          />
          <Slide
            media={<img src={toursImg} />}
            mediaBackgroundStyle={{ backgroundColor: TrunkrsGreen }}
            contentStyle={{ backgroundColor: darkGreen }}
            title={slideThreeTitle}
            subtitle={slideThreeSubTitle}
          />
          <Slide
            media={<img src={handoverImg} />}
            mediaBackgroundStyle={{ backgroundColor: TrunkrsGreen }}
            contentStyle={{ backgroundColor: darkGreen }}
            title={slideFourTitle}
            subtitle={slideFourSubTitle}
          />
          <Slide
            media={<img src={intercomImg} />}
            mediaBackgroundStyle={{ backgroundColor: TrunkrsGreen }}
            contentStyle={{ backgroundColor: darkGreen }}
            title={slideFiveTitle}
            subtitle={slideFiveSubTitle}
          />
        </AutoRotatingCarousel>
      </div>
    )
  }
}

export default NewUser
