import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import get from 'lodash/get'

import TextField from 'material-ui/TextField'

import styles from './DigitField.module.scss'

const DIGIT_COUNT = 6
const digitFields = Array(DIGIT_COUNT).fill(null)

const mergeWithChange = (digits, newDigit, changeIndex) =>
  digitFields.reduce(
    (string, _, iterIndex) =>
      string + (iterIndex === changeIndex ? newDigit : digits[iterIndex] || ''),
    '',
  )

const DigitField = ({
  className, placeholder, value, onChange, errorText, ...passed
}) => {
  const { current: fieldRefs } = React.useRef(Array(DIGIT_COUNT))

  const handleChangeHop = (currentIndex, forward = true) => {
    if (forward && currentIndex === fieldRefs.length - 1) {
      return
    }
    if (!forward && currentIndex === 0) {
      return
    }

    fieldRefs[currentIndex + (forward ? 1 : -1)].focus()
  }

  return (
    <div className={clsx(styles.container, className)}>
      {digitFields.map((_, index) => (
        <TextField
          key={index}
          autoFocus={index === 0}
          autocomplete="off"
          autofill="off"
          value={get(value, index, '')}
          placeholder={get(placeholder, index, '')}
          className={styles.field}
          maxLength={1}
          errorText={index === 0 && errorText}
          onChange={({ target: { value: digit } }) => {
            onChange(mergeWithChange(value, digit, index))
            handleChangeHop(index, !!digit)
          }}
          {...passed}
          ref={ref => fieldRefs[index] = ref}
        />
      ))}
    </div>
  )
}

DigitField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

DigitField.defaultProps = {
  className: null,
  disabled: false,
  errorText: null,
  value: '',
  onChange: () => null,
}

export default DigitField
