import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import groupBy from 'lodash/groupBy'
import Box from '@material-ui/core/Box'

import { IDriver } from 'models'
import styles from '../AutoComplete.module.scss'
import DropdownItem from '../../Dropdown/item'

interface IOptionContainerProps {
  list: IDriver[]
  onClick: (event: any) => void
  assignedDriverIds: number[]
}

const OptionContainer: React.FunctionComponent<IOptionContainerProps> = ({
  list,
  onClick,
  assignedDriverIds,
}) => {
  const [groups, setGroups] = useState<{ [key: string]: IDriver[] }>({})

  const groupedList = (list: IDriver[]): { [key: string]: IDriver[] } =>
    groupBy(list, (driver) => driver.name.charAt(0).toUpperCase())

  useEffect(() => {
    setGroups(groupedList(list))
  }, [list])

  return (
    <Box>
      {Object.keys(groups).map((letter: string) => (
        <div key={letter}>
          <span className={styles.groupLetter}>
            {letter}
          </span>
          {groups[letter].map((driver: IDriver) => (
            <DropdownItem
            onClick={onClick}
            value={driver.id}
            label={driver.name}
            key={driver.id}
            disabled={assignedDriverIds.includes(driver.id)}
            className={clsx(styles.menuHover)}
            />
          ))}
        </div>
      ))}
    </Box>
  )
}

export default OptionContainer
