import { translate } from '../translate'
import { isDevelopment } from '../../constants'

export const validatePhoneNumber = (
  errorTranslation = 'PHONE_INCORRECT_MSG',
) => value => {
  if (value === '') {
    return null
  }

  if (isDevelopment) {
    return !(value && value.match(/\+(316|639|62|34)\d{8,9}$/)) && translate(errorTranslation) /*NL, PH*/
  }

  return !(value && value.match(/\+(316|34)\d{8}$/)) && translate(errorTranslation)
}
