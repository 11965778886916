import React from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import ScanBar from '../ScanBar'
import Orderlist from './OrderList/OrderList'
import { color } from '../../utils/getColor'

import styles from './Handover.module.scss'
import LockScreen from './LockScreen/LockScreen'
import { ChangeDriverModal } from './ChangeDriverModal'
import { HandoverToolbar } from './HandoverToolbar'

export class Handover extends React.PureComponent {
  state = {
    isChangeDriverModalVisible: false,
    activeTour: null,
    selectedDriver: null,
  }

  setChangeDriverModalVisibility = (visible, tour) => () => {
    this.setState({ isChangeDriverModalVisible: visible, activeTour: tour, selectedDriver: null })
  };

  handleDriverChanged = (driverId) => this.setState({ selectedDriver: driverId })

  onSaveDriverChange = async() => {
    const { selectedDriver, activeTour } = this.state
    if(selectedDriver && activeTour) {
      this.setChangeDriverModalVisibility(false)()
      await this.props.saveDriverToTour(activeTour.id, selectedDriver).catch(err => console.error(err))
    }
  }

  renderTours = memoize((
    tours, drivers,
    { handoverShipment } = this.props,
  ) => tours.map((tour, index) =>
    tour.driverId && drivers && (
      <Orderlist
        key={tour.id}
        color={tour.color || color(index)}
        tour={tour}
        driver={drivers[tour.driverId]}
        onModalOpen={this.setChangeDriverModalVisibility(true, tour)}
        onHandOver={handoverShipment}
      />
    )))

  render() {
    const {
      props: {
        tours,
        drivers,
        areToursLocked,
        allToursHaveDrivers,
        availableDrivers,
        handoverShipment,
      },
      state: { activeTour, isChangeDriverModalVisible, selectedDriver },
    } = this

    return (
      <React.Fragment>
        <LockScreen
          toursLocked={areToursLocked}
          allToursHaveDrivers={allToursHaveDrivers}
        >
          <HandoverToolbar />
          <ScanBar onHandOver={handoverShipment} />
          <div className={styles.container}>
            {this.renderTours(tours, drivers)}
          </div>
        </LockScreen>

        {activeTour && (
          <ChangeDriverModal
            show={isChangeDriverModalVisible}
            onClose={this.setChangeDriverModalVisibility(false)}
            onDriverChanged={this.handleDriverChanged}
            onSaveDriverChange={this.onSaveDriverChange}
            tour={activeTour}
            drivers={availableDrivers}
            selectedDriver={selectedDriver}
          />
        )}
      </React.Fragment>
    )
  }
}

Handover.propTypes = {
  tours: PropTypes.array,
  drivers: PropTypes.object,
  areToursLocked: PropTypes.bool,
  allToursHaveDrivers: PropTypes.bool,
  availableDrivers: PropTypes.array,
  saveDriverToTour: PropTypes.func,
  handoverShipment: PropTypes.func.isRequired,
}

Handover.defaultProps = {
  tours: [],
  drivers: [],
  areToursLocked: false,
  allToursHaveDrivers: false,
  availableDrivers: [],
}
