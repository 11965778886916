import * as actionTypes from '../../constants/ActionTypes'
import { normalizePayload } from './helpers'
import {IFreezerLogPayload, IFreezerManagementState, IReduxAction} from "./@types";

const initialState = {
  isLoading: false,
  hasFetched: false,
  expectedShipments: {
    identifiers: [],
    data: {},
  },
  shipmentsInFreezer: {
    identifiers: [],
    data: {},
  }
} as IFreezerManagementState

function freezerManagementReducer(state = initialState, { type, payload }: IReduxAction<IFreezerLogPayload & {loading?: boolean}>) {
  switch (type) {

  case actionTypes.FREEZER_MANAGEMENT_LOADING: {
    return { ...state, isLoading: payload.loading, hasFetched: false }
  }

  case actionTypes.FREEZER_MANAGEMENT_SET_FREEZER_LOGS: {
    const expectedShipments = normalizePayload(payload.expectedShipments)
    const shipmentsInFreezer = normalizePayload(payload.shipmentsInFreezer)

    return {
      ...state,
      hasFetched: true,
      expectedShipments,
      shipmentsInFreezer
    }
  }

  case actionTypes.CHANGE_SUBCO: {
    return initialState
  }

  default:
    return state
  }
}

export const selectHasFetched = (state: IFreezerManagementState) => state.hasFetched
export const selectIsLoading = (state: IFreezerManagementState) => state.isLoading
export const selectExpectedShipments = (state: IFreezerManagementState) => state.expectedShipments
export const selectShipmentsInFreezer = (state: IFreezerManagementState) => state.shipmentsInFreezer

export default freezerManagementReducer
