import React, { PureComponent } from 'react'
import memoize from 'memoize-one'
import { connect } from 'react-redux'

import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow } from 'material-ui'

import ShipmentRow from './ShipmentRow'
import Translate from '../Translate'

import * as UIActions from '../../actions/ui'

class ShipmentTable extends PureComponent {
  renderRows = memoize(shipments => {
    const { cancelShipment, updateShipment } = this.props

    return shipments.map((shipment, index) => (
      <ShipmentRow
        error={this.props.error}
        index={index}
        shipment={shipment}
        key={shipment.Id}
        cancelShipment={cancelShipment}
        updateShipment={updateShipment}
      />
    ))
  })

  render() {
    const {
      props: { shipments, error },
    } = this

    return (
      <div>
        <Table
          className="table"
          selectable={false}>
          <TableHeader adjustForCheckbox={false} displaySelectAll={false} className="table-header">
            <TableRow className="table-header-row">
              <TableHeaderColumn className="table-header-column" style={{ width: 90, paddingRight: 5 }}>
                #Trunkrs Nr
              </TableHeaderColumn>
              <TableHeaderColumn className="table-header-column" style={{ width: 90, paddingRight: 5 }}>
                <Translate capitalize={true}>POSTALCODE</Translate>
              </TableHeaderColumn>
              <TableHeaderColumn className="table-header-column">
                <Translate capitalize={true}>ADDRESS</Translate>
              </TableHeaderColumn>
              <TableHeaderColumn className="table-header-column" style={{ maxWidth: 150 }}>
                <Translate capitalize={true}>CITY</Translate>
              </TableHeaderColumn>
              <TableHeaderColumn className="table-header-column" style={{ maxWidth: 150 }}>
                <Translate capitalize={true}>TOUR</Translate>
              </TableHeaderColumn>
              <TableHeaderColumn className="table-header-column" style={{ width: error ? 140 : 80 }}>
                <Translate capitalize={true}>STATE</Translate>
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false} showRowHover={true} style={{ border: 0 }}>
            {this.renderRows(shipments)}
          </TableBody>
        </Table>
        <br/>
      </div>
    )
  }
}

const mapDispatchToProps = {
  setLoading: UIActions.setLoading,
}

export default connect(null, mapDispatchToProps)(ShipmentTable)
