import * as React from 'react'

import NoTourIcon from '../../../images/no-tours-icon.svg'
import Translate from '../../../components/Translate'

import classes from './NoCollectionTours.module.scss'

const NoCollectionTours = () => (
  <div className={classes.container}>
    <img alt="No results" src={NoTourIcon} />
    <Translate component="h1">NO_COLLECTION_TOURS</Translate>
    <Translate component="p">NO_COLLECTION_TOURS_DESC</Translate>
  </div>
)

export default NoCollectionTours
