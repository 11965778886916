import React, { memo, useCallback, useEffect, useState } from 'react'

import Box from '@material-ui/core/Box'
import styles from './styles.module.scss'
import SimpleModalBox from 'components/common/SimpleModalBox'
import FlatButton from 'material-ui/FlatButton'
import TextBox from 'components/common/Textfield'
import * as uiAction from '../../../../actions/ui'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { translate } from 'utils/translate'
import SimpleCheckbox from '../../../common/SimpleCheckbox'


export interface IOptimizationModalProps {
    visible: boolean
    shipmentCount: number
    onClose: () => void
    onOptimize: () => void
}

export const OptimizationModal = ({
    onOptimize, shipmentCount = 5, ...props
}: IOptimizationModalProps) => {
    const [value, setValue] = useState(0)
    const [usePreviousOptimization, setUsePreviousOptimization] = useState(false)
    const [newRoutificVersion, useNewRoutificVersion] = useState(shipmentCount > 500)
    const dispatch = useDispatch()

    useEffect(() => {
      setValue(Math.ceil(shipmentCount/20))
    } ,[shipmentCount, props.visible])
    useEffect(() => {
        dispatch(uiAction.set({number_of_drivers: value }))
    }, [value])
    useEffect(() => {
      dispatch(uiAction.set({use_previous_optimization: usePreviousOptimization}))
    }, [usePreviousOptimization])
    useEffect(() => {
      dispatch(uiAction.set({ new_routific_version: newRoutificVersion }))
    }, [newRoutificVersion])

    const increment = useCallback(() => setValue(prevValue => prevValue + 1), [])
    const decrement = useCallback(() => {
        setValue(prevValue => {
            if(prevValue <= 0) {
                return 0
            } else {
                return prevValue - 1
            }
        })
    }, [])

    const handleChange = useCallback((event) => {
        setValue(event.target.value)
    }, [value])

    const handleOptimize = useCallback(() => {
        if(value > 0 ) {
            dispatch(uiAction.set({ optimizeModal: false }))
            onOptimize()
        }
    }, [value])

    return (
        <SimpleModalBox {...props}>
            <Box className={styles.container}>
                <div className={styles.title}>
                    {translate('HOW_MANY_DRIVERS')}
                </div>
                <div className={styles.body}>
                    <FlatButton
                        icon={<FontAwesomeIcon icon={faMinus}/>}
                        onClick={decrement}
                        className={styles.buttons}
                    />
                    <TextBox value={value} onChange={handleChange} type='number' className={styles.textboxContainer}/>
                    <FlatButton
                        icon={<FontAwesomeIcon icon={faPlus}/>}
                        onClick={increment}
                        className={styles.buttons}
                    />
                </div>
                <SimpleCheckbox
                  checked={usePreviousOptimization}
                  onChange={setUsePreviousOptimization}
                >
                  {translate('USE_PREVIOUS_OPTIMIZATION')}
                </SimpleCheckbox>
                { shipmentCount > 500 && (
                  <SimpleCheckbox
                    checked={newRoutificVersion}
                    onChange={useNewRoutificVersion}
                  >
                    {translate('USE_ROUTIFIC_V2')}
                  </SimpleCheckbox>
                )}
                <FlatButton
                    label={translate('CREATE_TOURS')}
                    className={styles.submit}
                    onClick={handleOptimize}
                />
            </Box>
        </SimpleModalBox>
    )
}


export default memo(OptimizationModal)
