import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import styles from './MapActions.module.scss'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { MapListActions } from './MapListActions'

import { Button } from 'components/common/Button/Button'
import FlatButton from 'material-ui/FlatButton'
import { translate as t } from '../../../utils'

export const MapActions = ({
  moveSelectedCollations,
  deselect,
  tours,
  selectedMarkers,
  addToNewTour,
}) => {

  const [showListTours, setshowListTours] = useState(false)
  const [collationList, setCollationList] = useState([])
  const [disabledParentTour, setdisabledParentTour] = useState([])

  useEffect(() => {
    const tourList = tours.reduce((acc, tour) => {
      const { id, collations } = tour
      collations.forEach((item) => {
        acc.push({ parentTour: id, child: item.id })
      })
      return acc
    }, [])
    setCollationList(tourList)
  }, [tours])

  useEffect(() => {
    setdisabledParentTour([])
    selectedMarkers.map((item) => {
      collationList.some(e => {
        if(e.child === item) {
          setdisabledParentTour(prevState => [...prevState, e.parentTour])
        }
      })
    })
  }, [selectedMarkers, collationList])

  return (
    <Paper className={styles.paperActions}>
      <div className={styles.actionsContainer}>
        <div item className={styles.tourNo}>{selectedMarkers.length}</div>
          <Button type='info'
            className={styles.info}
            label={t('MOVE_TO_DIFFERENT_TOUR')}
            customLabelStyle={{ fontSize: 12 }}
            onClick={()=> setshowListTours(!showListTours)}
          />
          <Button
            className={clsx(styles.root, styles.danger)}
            customLabelStyle={{ fontSize: 12 }}
            label={t('DE_SELECT_ALL')}
            onClick={deselect}/>
      </div>
      <Fade in={showListTours}>
        <Box className={styles.listItems}>
          <PerfectScrollbar className={styles.collapseWrapper}>
            <MapListActions
              activeTours={tours}
              disabledParentTour={disabledParentTour}
              moveSelectedCollations={moveSelectedCollations}
              selectedMarkers={selectedMarkers}
            />
            <div className={styles.addNewTourButton}>
              <FlatButton onClick={addToNewTour}>
                {t('ADD_TO_NEW_TOUR')}
              </FlatButton>
            </div>
          </PerfectScrollbar>
        </Box>
      </Fade>
    </Paper>
  )
}

MapActions.propTypes = {
  moveSelectedCollations: PropTypes.func,
  deselect: PropTypes.func,
  tours: PropTypes.array,
  selectedMarkers: PropTypes.array,
  addToNewTour: PropTypes.func,
}

export default MapActions
