import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser'
import TextField from 'material-ui/TextField'
import ShipmentModal from './shipmentModal/ShipmentModal'
import Translate from './Translate'
import get from 'lodash/get'

import { addMissorted } from '..'
import { getHandoverShipments, getData as getShipmentData } from '../selectors/shipments'
import { getData as getTourData } from '../selectors/tour'
import { getCurrentSubcoId, getSubcoRegion } from '../selectors/profile'

class ScanBar extends PureComponent {
  state = {
    barcode: '',
    visible: false,
    lastShipment: null,
  };

  handleChange = (e) => this.setState({ barcode: e.target.value })

  playSortingCartAudio = (cartNumber) => {
    const route = (this.state.dialogShipment && this.props.routes) ?
      Object.values(this.props.routes).find(item => item.Collations.indexOf(this.state.dialogShipment.Id) !== -1) :
      null
    const driver = (route) ?
      this.props.drivers[route.DriverId] : null

    /* play audio if it a shipment is handed over to a driver */
    if (driver) {
      const audio = new Audio(`https://s3-eu-west-1.amazonaws.com/trunkrs-audio-sort/${cartNumber}.mp3`)
      audio.play().catch(Sentry.captureException)
    }
  };

  handleScan = async(shipment) => {
    const { routes, onHandOver } = this.props
    const tour = Object.values(routes)
      .find(tour => tour.Collations.indexOf(shipment.Id) > -1)
    const cartNumber = Object.keys(routes)
      .indexOf(tour.Id.toString()) + 1

    this.setState({ opacity: 0 }, () => {
      this.setState({
        showDialog: true,
        dialogShipment: shipment,
        barcode: '',
        opacity: 1,
        cartNumber,
      })
    })

    this.playSortingCartAudio(cartNumber)
    onHandOver(shipment.Id)
  };

  handleSubmit = async(e) => {
    e.preventDefault()
    const { barcode } = this.state
    const { barcodes, shipments, tours, drivers, subcoCurrentRegion, onHandOver, subcoId } = this.props

    const shipmentId = barcodes[barcode]

    if (!shipmentId) {
      await addMissorted(barcode, subcoCurrentRegion, subcoId)
      this.setState({
        barcode: '',
        lastShipment: null,
        visible: true,
        hasError: true,
      })
    }
    else {
      const shipment = shipments[shipmentId]
      const tour = tours[shipment.tour.id]
      const driver = drivers[tour.driverId]
      const position = get(shipments, `${shipmentId}.tour.position`)

      this.setState({
        visible: true,
        barcode: '',
        hasError: false,
        lastShipment: {
          ...shipment,
          position,
          tour,
          driver,
        },
      })
      onHandOver(shipmentId)
    }
  };

  handleClose = () => this.setState({ visible: false, hasError: false })

  render() {
    return (
      <>
        <div style={{
          background: '#ffffff',
          padding: '10px 18px 8px',
          marginBottom: 48,
          transition: 'all 1s',
          borderRadius: 5,
          boxShadow: '0px 2px 4px rgba(0,0,0,0.08)',
        }} >
          <form onSubmit={this.handleSubmit}>
            <TextField autoFocus
              fullWidth
              id="barcode-controlled"
              value={this.state.barcode}
              underlineShow={false}
              hintText={<Translate>BARCODE</Translate>}
              onChange={this.handleChange}
            />
          </form>
        </div>
        <ShipmentModal
          shipment={this.state.lastShipment}
          onClose={this.handleClose}
          visible={this.state.visible}
          error={this.state.hasError}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  barcodes: getHandoverShipments(state),
  shipments: getShipmentData(state),
  tours: getTourData(state),
  drivers: state.drivers,
  subcoCurrentRegion: getSubcoRegion(state),
  subcoId: getCurrentSubcoId(state),
})

export default connect(mapStateToProps)(ScanBar)
