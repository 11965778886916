import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

export const Separator = ({ className, ...passed }) => (
  <hr
    {...passed}
    className={clsx('separator', className)}
  />
)

Separator.propTypes = {
  className: PropTypes.string,
}

Separator.defaultProps = {
  className: null,
}
