import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { browserHistory } from '../../browserHistory'

import { ToolbarGroup } from 'material-ui/Toolbar'

import Toolbar from '../toolbar/Toolbar'
import ToolbarTitle from '../toolbar/ToolbarTitle'
import * as shipmentsAction from '../../actions/shipments'
import * as uiAction from '../../actions/ui'
import ShipmentTable from './ShipmentTable'
import Translate from '../Translate'
import ReleaseUpdate from '../onboarding/Release'
import Onboarding from '../onboarding/NewUser'
import ShipmentOverview from '../ShipmentOverview'
import ShipmentTableTitle from './ShipmentTableTitle'
import Page from '../../components/Page'

import FlatButton from 'material-ui/FlatButton'

import * as fromCombined from '../../selectors/combined'

import { COLORS } from '../../constants/COLORS'
import NoShipments from '../../images/no-shipment-icon.png'
import { NoResults } from '../noResults/noResults'
import { environment } from '../../constants'
import { translate } from '../../utils/translate'

import './planner.css'

class Planner extends Component {
  state = {
    showOnboarding: false,
    showReleaseUpdate: false,
    requestTimeStamp: null,
    dataSetIndex: null,
    subDataSetIndex: 0,
  }

  componentDidMount() {
    this.verifyVersion()
  }

  verifyVersion = () => {
    const currentVersion = this.props.ui.appVersion.match(/(\d\.\d+)?/g)
    const userVersion = localStorage.getItem('appVersion') ? localStorage.getItem('appVersion').match(/(\d\.\d+)?/g) : 0
    if(currentVersion[0] !== userVersion[0]) {
      // this.setState({showReleaseUpdate: true});
      localStorage.setItem('appVersion', currentVersion[0])
      this.props.uiAction.set({ showUpdate: true })
    }
  }

  closeOnboarding = () => {
    this.setState({
      showOnboarding: false,
    })
  }

  closeReleaseUpdate = () => {
    this.setState({
      showReleaseUpdate: false,
    })
  }

  doLoadShipments = () => {
    this.props.loadShipments()
  }

  reloadShipments = () => {
    this.props.shipmentsAction.reloadShipments()
  }

  doCancelShipment = () => {
    this.props.cancelShipment()
  }

  allShipmentsComplete = () => {
    return Object.keys(this.props.shipments).reduce((completed, id) => {
      if (
        !this.props.shipments[id].Recipient ||
        !this.props.shipments[id].Recipient.Lat ||
        !this.props.shipments[id].Recipient.Lng
      ) {
        return false
      }
      return completed
    }, true)
  }

  goToBoard() {
    browserHistory.push('/board')
  }

  checkLatLng() {
    for (let i = 0; i < Object.keys(this.props.shipments).length; i += 1) {
      const shipment = this.props.shipments[Object.keys(this.props.shipments)[i]]
      if(!shipment.Recipient.Lat || !shipment.Recipient.Lng) {
        this.props.geocode(`${shipment.Recipient.Address} ${shipment.Recipient.HouseNumber}, ${shipment.Recipient.PostalCode}, ${shipment.Recipient.City}, ${shipment.Recipient.Country}`)
          .then((res) => {
            if(res.results.length > 0) {
              const location = res.results[0].geometry.location
              if(location) {
                const geocodedShipment = Object.assign({}, shipment, {
                  Recipient: {
                    ...shipment.Recipient,
                    Lat: parseFloat(location.lat),
                    Lng: parseFloat(location.lng),
                  },
                })
                this.updateLocation(geocodedShipment)
              }
            }
          })
      }
    }
  }

  errorShipments = memoize(shipments =>
    shipments.filter(shipment => !shipment.Recipient.Lat || !shipment.Recipient.Lng),
  )


  correctShipments = memoize(shipments =>
    shipments.filter(shipment => shipment.Recipient.Lat && shipment.Recipient.Lng),
  )

  updateLocation(shipment) {
    this.props.storeLocation(shipment.Recipient.Id, shipment.Recipient.Lat, shipment.Recipient.Lng)
      .then(() => {
        this.props.shipmentsAction.add(shipment)
      }, (err) => {
        console.log(err)
      })
  }

  handleSetFilter = (dataSetIndex, subDataSetIndex) => {
    this.setState({ dataSetIndex, subDataSetIndex })
  }

  handleResetFilter = () => this.setState({
    dataSetIndex: null,
    subDataSetIndex: null,
  })

  render() {
    const {
      props: {
        createTestData,
        cancelShipment,
        shipmentsSortedAtRegionalHub,
        shipmentsNotSortedAtRegionalHub,
        shipmentsAcceptedByDrivers,
        shipmentsNotAcceptedByDrivers,
        shipmentsWithEndState,
        shipmentsWithoutEndState,
        shipmentsDeliveredToNeighbour,
        shipmentsNotDelivered,
        correctShipments,
        errorShipments,
      },
      state: {
        showReleaseUpdate,
        showOnboarding,
        dataSetIndex,
        subDataSetIndex,
      },
    } = this
    const dataSets = [
      {
        first: shipmentsSortedAtRegionalHub,
        second: shipmentsNotSortedAtRegionalHub,
        title: translate('SHIPMENTS_SORTED_AT_REGIONAL_HUB'),
        firstTitle: translate('WHICH_SHIPMENTS_HAVE_BEEN_SORTED_AT_REGIONAL_HUB'),
        secondTitle: translate('WHICH_SHIPMENTS_HAVE_NOT_BEEN_SORTED_AT_REGIONAL_HUB'),
        total: shipmentsSortedAtRegionalHub.length + shipmentsNotSortedAtRegionalHub.length,
      },
      {
        first: shipmentsAcceptedByDrivers,
        second: shipmentsNotAcceptedByDrivers,
        title: translate('SHIPMENTS_ACCEPTED_BY_DRIVERS'),
        firstTitle: translate('WHICH_SHIPMENTS_HAVE_BEEN_ACCEPTED_BY_THEIR_DRIVERS'),
        secondTitle: translate('WHICH_SHIPMENTS_HAVE_NOT_BEEN_ACCEPTED_BY_THEIR_DRIVERS'),
        total: shipmentsAcceptedByDrivers.length + shipmentsNotAcceptedByDrivers.length,
      },
      {
        first: shipmentsWithEndState,
        second: shipmentsWithoutEndState,
        title: translate('SHIPMENTS_WITH_END_STATE'),
        firstTitle: translate('WHICH_SHIPMENTS_HAVE_AN_END_STATE'),
        secondTitle: translate('WHICH_SHIPMENTS_DO_NOT_HAVE_AN_END_STATE'),
        total: shipmentsWithEndState.length + shipmentsWithoutEndState.length,
      },
      {
        first: shipmentsDeliveredToNeighbour,
        second: shipmentsNotDelivered,
        title: translate('NEIGHBOUR_DELIVERY_VS_NON_DELIVERY'),
        isRatio: true,
        firstTitle: translate('WHICH_SHIPMENTS_ARE_DELIVERED_TO_NEIGHBOURS'),
        secondTitle: translate('WHICH_SHIPMENTS_HAVE_NOT_BEEN_DELIVERED'),
        total: shipmentsDeliveredToNeighbour.length + shipmentsNotDelivered.length,
      },
    ]
    const primaryFilter = dataSets[dataSetIndex]
    const secondaryFilter = subDataSetIndex === 1
      ? get(primaryFilter, 'second', null)
      : get(primaryFilter, 'first', null)

    let shipmentsFiltered = correctShipments
    let shipmentTableTitle = translate('ALL_SHIPMENTS')
    if (secondaryFilter) {
      shipmentsFiltered = secondaryFilter
      shipmentTableTitle = subDataSetIndex === 1
        ? get(primaryFilter, 'secondTitle', '')
        : get(primaryFilter, 'firstTitle', '')
    }

    return (
      <Page
        className='page-special'
        title={<Translate>SHIPMENTS</Translate>}
        right={
          <ToolbarGroup>
            {environment === 'staging' && (
              <FlatButton
                label={<Translate>Create Test Data</Translate>}
                labelStyle={{
                  fontSize: 12,
                  textTransform: 'capitalize',
                }}
                style={{
                  marginRight: 0,
                  color: COLORS.WHITE,
                  borderRadius: 5,
                }}
                backgroundColor={COLORS.RED}
                hoverColor={COLORS.RED_HOVER}
                onClick={createTestData}
              />
            )}

            <FlatButton
              label={<Translate>RELOAD</Translate>}
              onClick={this.reloadShipments}
              labelStyle={{
                fontSize: 12, textTransform: 'capitalize',
              }}
              style={{ marginRight: 0, color: COLORS.WHITE, borderRadius: 5 }}
              backgroundColor={COLORS.BLUE}
              hoverColor={COLORS.BLUE_HOVER}
            />
          </ToolbarGroup>
        }
      >
        {!!correctShipments.length &&
          <ShipmentOverview
            setFilter={this.handleSetFilter}
            dataSets={dataSets}
          />
        }

        {!!shipmentsFiltered.length && (
          <ShipmentTableTitle
            resetFilter={this.handleResetFilter}
            title={shipmentTableTitle}
            results={shipmentsFiltered.length}
            showClear={secondaryFilter}
          />
        )}

        {shipmentsFiltered.length ? (
          <ShipmentTable
            cancelShipment={cancelShipment}
            shipments={shipmentsFiltered}
            updateShipment={this.updateLocation}
          />
        ) : (
          <NoResults
            icon={NoShipments}
            title={(
              <Translate capitalize={true}>NO_SHIPMENTS</Translate>
            )}
          />
        )}
      </Page>
    )
  }
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
    shipments: fromCombined.getOverviewShipments(state),
    shipmentsSortedAtRegionalHub: fromCombined.getShipmentsSortedAtRegionalHub(state),
    shipmentsNotSortedAtRegionalHub: fromCombined.getShipmentsNotSortedAtRegionalHub(state),
    shipmentsAcceptedByDrivers: fromCombined.getShipmentsAcceptedByDrivers(state),
    shipmentsNotAcceptedByDrivers: fromCombined.getShipmentsNotAcceptedByDrivers(state),
    shipmentsWithEndState: fromCombined.getShipmentsWithEndState(state),
    shipmentsWithoutEndState: fromCombined.getShipmentsWithoutEndState(state),
    shipmentsDeliveredToNeighbour: fromCombined.getShipmentsDeliveredToNeighbour(state),
    shipmentsNotDelivered: fromCombined.getShipmentsNotDelivered(state),
    correctShipments: fromCombined.getCorrectShipments(state),
    errorShipments: fromCombined.getErrorShipments(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    shipmentsAction: bindActionCreators(shipmentsAction, dispatch),
    uiAction: bindActionCreators(uiAction, dispatch),
  }
}

Planner.propTypes = {
  createTestData: PropTypes.func,
  shipments: PropTypes.array,
  cancelShipment: PropTypes.func,
  shipmentsSortedAtRegionalHub: PropTypes.array,
  shipmentsNotSortedAtRegionalHub: PropTypes.array,
  shipmentsAcceptedByDrivers: PropTypes.array,
  shipmentsNotAcceptedByDrivers: PropTypes.array,
  shipmentsWithEndState: PropTypes.array,
  shipmentsWithoutEndState: PropTypes.array,
  shipmentsDeliveredToNeighbour: PropTypes.array,
  shipmentsNotDelivered: PropTypes.array,
  correctShipments: PropTypes.array,
  errorShipments: PropTypes.array,
  ui: PropTypes.object,
  uiAction: PropTypes.object,
  loadShipments: PropTypes.func,
  shipmentsAction: PropTypes.object,
  geocode: PropTypes.func,
  storeLocation: PropTypes.func,
}

Planner.defaultProps = {
  createTestData: () => null,
  shipments: [],
  cancelShipment: () => null,
  shipmentsSortedAtRegionalHub: [],
  shipmentsNotSortedAtRegionalHub: [],
  shipmentsAcceptedByDrivers: [],
  shipmentsNotAcceptedByDrivers: [],
  shipmentsWithEndState: [],
  shipmentsWithoutEndState: [],
  shipmentsDeliveredToNeighbour: [],
  shipmentsNotDelivered: [],
  correctShipments: [],
  errorShipments: [],
  ui: {},
  uiAction: {},
  loadShipments: () => null,
  shipmentsAction: {},
  geocode: () => null,
  storeLocation: () => null,
}

export default connect(mapStateToProps, mapDispatchToProps)(Planner)
