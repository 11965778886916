import React, { memo } from 'react'

import {translate as t} from '../../../../utils'

import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import styles from './styles.module.scss'
import SimpleModalBox from 'components/common/SimpleModalBox'
import FlatButton from 'material-ui/FlatButton'


export interface ISwitchModalProps {
    onClose: () => void
    onSwitch: () => void
    visible: boolean
}

export const SwitchModal = ({
    onSwitch, ...props
}: ISwitchModalProps) => {
    return (
        <SimpleModalBox {...props}>
          <Box className={styles.container}>
                <Box className={styles.body}>
                    <div className={styles.title}>
                        {t('SWITCH_POWER_MODE_TITLE')}
                    </div>
                    <div className={styles.subtitle}>
                        {t('SWITCH_POWER_MODE_BODY')}
                    </div>

                    <div className={clsx(styles.footer, styles.marginTop)}>
                        <FlatButton
                            className={styles.cancel}
                            label={t('ADD_USER_CANCEL_BUTTON')}
                            onClick={props.onClose}
                        />
                        <FlatButton 
                            className={clsx(styles.confirm)}
                            label={t('SWITCH_POWER_MODE_BTN')}
                            onClick={onSwitch}
                        />
                    </div>
                </Box>
            </Box>
        </SimpleModalBox>
    )
}


export default memo(SwitchModal)