import React from 'react'
import PropTypes from 'prop-types'

import TranslatedButton from '../../TranslatedButton'

const NoEndStateActions = ({
  onComment, onPostpone,
}) => (
  <>
    <TranslatedButton onClick={onComment} variant="red">
      TODO_NO_PARCEL
    </TranslatedButton>
    <TranslatedButton onClick={onPostpone} variant="blue">
      TODO_PARCEL_POSTPONE
    </TranslatedButton>
  </>
)

NoEndStateActions.propTypes = {
  onComment: PropTypes.func,
  onPostpone: PropTypes.func,
}

NoEndStateActions.defaultProps = {
  onComment: () => null,
  onPostpone: () => null,
}

export default NoEndStateActions
