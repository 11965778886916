import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Tabs from 'material-ui/Tabs/Tabs'
import Tab from 'material-ui/Tabs/Tab'
import SwipeableViews from 'react-swipeable-views'

import { languages, translate } from '../../utils'

import TodoHeader from './TodoHeader'
import ScannedParcelModal from './ScannedParcelModal'
import ShipmentTable from './ShipmentTable'

import classes from './todoList.module.scss'
import connect from './connect'

import Page from '../Page'

const tabNames = {
  notDelivered: 0,
  noEndState: 1,
  returnShipments: 2,
  done: 3,
}

export const ToDoList = ({
  loadShipments, markPostponed, markLost, markReturned, returnShipments,
  notDelivered, noEndState, done, isLoadNeeded, scanLookup, currentLocale,
}) => {
  const [currentTab, setTab] = React.useState(tabNames.notDelivered)
  const [scannedShipment, setScannedShipment] = React.useState(null)
  const [tabTranslations, setTabTranslations] = React.useState({})

  React.useEffect(() => setTabTranslations({
    [tabNames.notDelivered]: translate('TAB_LABEL_NOT_DELIVERED'),
    [tabNames.noEndState]: translate('TAB_LABEL_NO_END_STATE'),
    [tabNames.returnShipments]: translate('TAB_LABEL_RETURNS'),
    [tabNames.done]: translate('TAB_LABEL_DONE'),
  }), [currentLocale])

  React.useEffect(() => {
    if (isLoadNeeded) loadShipments()
  }, [isLoadNeeded])

  const handleScan = React.useCallback(
    (barcode) => {
      const { [barcode]: shipment } = scanLookup
      if (shipment) {
        setScannedShipment(shipment)
      }
    },
    [scanLookup],
  )

  const handleCloseScanModal = React.useCallback(
    () => setScannedShipment(null),
    [],
  )
  const shipmentsTotal = noEndState.length + notDelivered.length + returnShipments.length + done.length

  return (
    <Page title={translate('TITLE_TODO_LIST')}>
      <div className={classes.container}>

        <TodoHeader
          shipmentsDone={done.length}
          shipmentsTotal={shipmentsTotal}
          onScan={handleScan}
        />

        <ScannedParcelModal
          shipment={scannedShipment}
          onClose={handleCloseScanModal}
          onPostpone={markPostponed}
          onReturn={markReturned}
        />

        <Tabs
          className={classes.tabsContainer}
          value={currentTab}
          onChange={(selectedTab) => setTab(selectedTab)}
        >

          <Tab
            disabled={!notDelivered.length}
            label={`${tabTranslations[tabNames.notDelivered]} (${notDelivered.length})`}
            value={tabNames.notDelivered}
            className={clsx(classes.tab, currentTab === tabNames.notDelivered && classes.active)}
          />

          <Tab
            disabled={!noEndState.length}
            label={`${tabTranslations[tabNames.noEndState]} (${noEndState.length})`}
            value={tabNames.noEndState}
            className={clsx(classes.tab, currentTab === tabNames.noEndState && classes.active)}
          />

          <Tab
            disabled={!returnShipments.length}
            label={`${tabTranslations[tabNames.returnShipments]} (${returnShipments.length})`}
            value={tabNames.returnShipments}
            className={clsx(classes.tab, currentTab === tabNames.returnShipments && classes.active)}
          />

          <Tab
            disabled={!done.length}
            label={`${tabTranslations[tabNames.done]} (${done.length})`}
            value={tabNames.done}
            className={clsx(classes.tab, currentTab === tabNames.done && classes.active)}
          />
        </Tabs>

        <SwipeableViews index={currentTab}>
          <span>
            <ShipmentTable
              variant="not-delivered"
              shipments={notDelivered}
              onPostpone={markPostponed}
              onReturn={markReturned}
            />
          </span>
          <span>
            <ShipmentTable
              variant="no-end-state"
              shipments={noEndState}
              onPostpone={markPostponed}
              onComment={markLost}
            />
          </span>
          <span>
            <ShipmentTable
              variant="returns"
              shipments={returnShipments}
              onReturn={markReturned}
            />
          </span>
          <span>
            <ShipmentTable
              variant="done"
              shipments={done}
            />
          </span>
        </SwipeableViews>
      </div>
    </Page>
  )
}

ToDoList.propTypes = {
  loadShipments: PropTypes.func.isRequired,
  markPostponed: PropTypes.func.isRequired,
  markLost: PropTypes.func.isRequired,
  markReturned: PropTypes.func.isRequired,
  notDelivered: PropTypes.array,
  noEndState: PropTypes.array,
  done: PropTypes.array,
  isLoadNeeded: PropTypes.bool,
  scanLookup: PropTypes.object,
  currentLocale: PropTypes.string,
}

ToDoList.defaultProps = {
  notDelivered: [],
  noEndState: [],
  done: [],
  scanLookup: {},
  currentLocale: languages.nl
}

export default connect(ToDoList)
