import React, {memo} from 'react'
import clsx from "clsx";
import styles from "../styles.module.scss";

const CartFooter = memo(({children, className, ...props}: any) => {
  return (
    <div className={clsx(styles.footer, className)} {...props}>
      {children}
    </div>
  )
})

CartFooter.displayName = 'CartFooter'

export default CartFooter
