import {ReactComponent as PlusIcon} from "../../../images/icon-library/plus-icon.svg";
import {ReactComponent as SwapIcon} from "../../../images/icon-library/swap-icon.svg";
import {ReactComponent as EditIcon} from "../../../images/icon-library/edit-icon.svg";
import {ReactComponent as TrashIcon} from "../../../images/icon-library/trash-icon.svg";
import {ReactComponent as CloseIcon} from "../../../images/icon-library/close-icon.svg"
import {ReactComponent as CheckCircleIcon} from "../../../images/icon-library/check-circle-icon.svg"
import {ReactComponent as InfoCircleIcon} from "../../../images/icon-library/info-circle-icon.svg"
import {ReactComponent as WarningIcon} from "../../../images/icon-library/warning-icon.svg"
import {ReactComponent as DangerIcon} from "../../../images/icon-library/danger-icon.svg"
import {ReactComponent as SearchIcon} from "../../../images/icon-library/search-icon.svg"
import {ReactComponent as PhoneIcon} from "../../../images/icon-library/phone-icon.svg"
import {ReactComponent as HouseIcon} from "../../../images/icon-library/house-icon.svg"
import {ReactComponent as ChevronDownIcon} from "../../../images/icon-library/chevron-down-icon.svg"
import {ReactComponent as RetryIcon} from "../../../images/icon-library/retry-icon.svg"

export const IconLibrary = {
  'plus' : PlusIcon,
  'edit' : EditIcon,
  'trash' : TrashIcon,
  'swap' : SwapIcon,
  'close' : CloseIcon,
  'check-circle' : CheckCircleIcon,
  'info-circle' : InfoCircleIcon,
  'warning' : WarningIcon,
  'danger' : DangerIcon,
  'search' : SearchIcon,
  'phone' : PhoneIcon,
  'house' : HouseIcon,
  'chevron-down' : ChevronDownIcon,
  'retry' : RetryIcon
}
