import { createFeatureSelector } from './core'
import { createSelector } from 'reselect'
import * as fromRouting from '../reducers/routes'

const selectFeature = createFeatureSelector('routing')

export const getCurrentPath = createSelector(
  selectFeature,
  fromRouting.selectCurrentRoute,
)
