import React, { PropTypes, Component } from 'react'
import { connect } from 'react-redux'

import FontIcon from 'material-ui/FontIcon'

class Error extends Component {

  render() {
    const styles = {
      error: {
        margin: '20px 50px',
        borderRadius: '2px',
        padding: '10px 15px 10px 55px',
        border: '1px solid #be3504',
        color: '#be3504',
        backgroundColor: 'rgba(190, 53, 4, .1)',
        lineHeight: '1em',
        fontSize: '1.1em',
        display: this.props.show ? 'block' : 'none',
        position: 'relative',
      },
      icon: {
        color: '#be3504',
        fontSize: '1.5em',
        position: 'absolute',
        left: 12,
      },
      text: {
        color: 'rgba(0,0,0,.65)',
        verticalAlign: 'super',
      },
    }
    return (
      <div>
        <div style={{ ...styles.error, ...this.props.style }}>
          <FontIcon style={styles.icon} className="material-icons">warning</FontIcon>
          <span style={styles.text}>{this.props.children}</span>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
  }
}

export default connect(mapStateToProps, null)(Error)
