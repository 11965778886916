import { getHandoverShipments } from '../../selectors/shipments'
import * as fromCombined from '../../selectors/combined'
import { getAvailableDrivers } from '../../selectors/driver'
import * as tourActions from '../../actions/tours'
import * as shipmentsAction from '../../actions/shipments'
import { connect } from 'react-redux'
import { Handover } from './Handover'

function mapStateToProps(state) {
  return {
    profile: state.profile,
    barcodes: getHandoverShipments(state),
    tours: fromCombined.getShipmentToursHandovered(state),
    drivers: state.drivers,
    availableDrivers: getAvailableDrivers(state),
    areToursLocked: fromCombined.areToursLocked(state),
    allToursHaveDrivers: fromCombined.allToursHaveDrivers(state),
  }
}

const dispatchToProps = {
  saveTours: tourActions.saveTours,
  saveDriverToTour: tourActions.saveDriverToTour,
  setDriver: tourActions.setDriver,
  reloadShipments: shipmentsAction.reloadShipments,
  handoverShipment: shipmentsAction.handOverShipment,
}

export default connect(mapStateToProps, dispatchToProps)(Handover)
