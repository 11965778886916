import { faBolt, faFire } from '@fortawesome/free-solid-svg-icons'

export enum FUEL_TYPES {
  FOSSIL = 'FOSSIL',
  ELECTRIC = 'ELECTRIC'
}

export default [
  {
    name: FUEL_TYPES.FOSSIL,
    icon: faFire
  },
  {
    name: FUEL_TYPES.ELECTRIC,
    icon: faBolt
  }
] as Array<{name: FUEL_TYPES|string, icon: any}>
