import React from 'react'
import PropTypes from 'prop-types'

import QuickTour from './quickTour'
import connect from './connect'

import Translate from '../Translate'
import PageHeader from './PageHeader'
import TourOverviewLegend from './TourOverviewLegend'

import classes from './QuickTour.module.scss'

const isValidOverviewTour = overviewTour =>
  overviewTour.tour && overviewTour.collations.length > 0 && overviewTour.driver

const ToursOverview = ({
  hasTours, subcoId, isLoading, tourOverviews, fetchTourOverviews,
}) => {
  React.useEffect(() => {
    if (!hasTours) {
      return
    }

    fetchTourOverviews()

    const interval = setInterval(() => {
      fetchTourOverviews()
    }, 60000)

    return () => clearInterval(interval)
  }, [hasTours, subcoId])

  return (
    <>
      <PageHeader isLoading={isLoading} />
      <TourOverviewLegend/>

      {!tourOverviews.length
        ? <Translate className={classes.noTours} component="h3">QUICK_TOURS_NO_TOURS_YET</Translate>
        : tourOverviews.map((tourOverview, index) => isValidOverviewTour(tourOverview) && (
          <QuickTour
            key={tourOverview.id}
            tourOverview={tourOverview}
            legend={!index}
          />
        ))
      }
    </>
  )
}

ToursOverview.propTypes = {
  fetchTourOverviews: PropTypes.func,
  hasTours: PropTypes.bool,
  subcoId: PropTypes.number,
  isLoading: PropTypes.bool,
  tourOverviews: PropTypes.array,
}

ToursOverview.defaultProps = {
  fetchTourOverviews: () => null,
  subcoId: -1,
  hasTours: false,
  isLoading: false,
  tourOverviews: [],
}

export default connect(ToursOverview)
