import * as React from 'react'
import { UploadLoader } from './UploadLoader'

export const ProfilePicture = ({
  uploading,
  picture,
}) => (<div className="profile-picture--wrapper">
  <label className="profile-picture--container">
    { picture && <img src={picture} className="profile-picture--picture" /> }
    <UploadLoader show={uploading} />
  </label>
</div>)
