import React, {memo} from 'react'
import { useFormContext } from "react-hook-form";
import get from 'lodash/get'
import clsx from 'clsx'

import styles from './styles.module.scss'

const SimpleTextbox = ({className, name, ...props} : React.HTMLProps<HTMLInputElement>) => {
  const {register, errors} = useFormContext()
  const errorMsg = get(errors, `${name}.message`)
  const hasError = !!errorMsg

  return (
    <div className={styles["textbox-container"]}>
      <input type="text" name={name} className={clsx(styles.textbox, className, hasError && styles.error)} {...props} ref={register} />
      {
        hasError && <span className={styles["error-message"]}>{errorMsg}</span>
      }
    </div>
  )
}

export default memo(SimpleTextbox)
