import * as React from 'react'
import PropTypes from 'prop-types'

import Translate from '../../Translate'

const StopPosition = ({
  position, first, last, ...passed
}) => {
  if (first) {
    return <Translate capitalize {...passed} component="b">START</Translate>
  }
  if (last) {
    return <Translate capitalize {...passed} component="b">END</Translate>
  }

  return <p>{position}</p>
}

StopPosition.propTypes = {
  position: PropTypes.number,
  first: PropTypes.bool,
  last: PropTypes.bool,
}

StopPosition.defaultProps = {
  position: 0,
  first: false,
  last: false,
}

export default StopPosition
