import React, { useCallback, useMemo } from 'react'

import styles from './styles.module.scss'
import Grid from '@material-ui/core/Grid'
import ListItem from 'material-ui/List/ListItem'

import { isValidCollation } from '../../../../actions/tours/helpers'
import { StartTimeLabel, EndTimeLabel } from '../../BoardList/components/TourShipments'
import { useDispatch, useSelector } from 'react-redux'
import { clearSelected } from 'actions/ui'
import { moveMultiple } from 'actions/tours'
import { getSelectedCollations } from 'selectors/ui'
import { getShipmentTours } from 'selectors/combined'
import { ITour } from 'components/planner/@models'

export const MapListActions = () => {
    const dispatch = useDispatch()
    const selectedCollations = useSelector(getSelectedCollations)
    const tours = useSelector(getShipmentTours)
    
    const handleMoveMultiple = useCallback((newTour) => ()=> {
        dispatch(moveMultiple(newTour.id, selectedCollations))
        dispatch(clearSelected())
    }, [moveMultiple, clearSelected, selectedCollations])

  return (
    <>
      {
        useMemo(()=> 
        tours.map((item: ITour) =>(
            <ListItem
             key={item.id}
             onClick={handleMoveMultiple(item)}
             className={styles.itemRoot}
             leftIcon={<span className={styles.icon} style={{ background: item.color }}>{item.number}</span>}
           >
             <Grid container direction="row" className={styles.listItemContainer}>
               <Grid item lg={9} xs={12} >
                 <div className={styles.time}>
                   <StartTimeLabel showLabel={false}
                     className={styles.eta} collations={item.collations}/> -
                   <EndTimeLabel showLabel={false}
                     className={styles.eta} collations={item.collations}/>
                 </div>
               </Grid>
               <Grid item lg={3} xs={12}>
                 <div>
                   {item.collations.filter(isValidCollation as any).reduce((a, b) =>
                     a + b.shipmentIds.length || a, 0)}
                 </div>
               </Grid>
             </Grid>
           </ListItem>),
         )
       , [handleMoveMultiple, tours])
      }
    </>
  )
}

export default MapListActions