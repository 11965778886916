import React, {memo, useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import Typography from "@material-ui/core/Typography/Typography";
import {faExclamationTriangle, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as fromUI from '../../../selectors/ui'
import * as fromTours from '../../../selectors/tour'
import * as fromCombined from '../../../selectors/combined'
import * as fromShipments from '../../../selectors/shipments'

import {assignToTours} from '../../../actions/shipments'
import {toggleOptimizeModal} from '../../../actions/ui'

import {Button} from "../../common/Button/Button";
import {SimpleNotificationBox} from "../../common";
import Page from "../../Page";
import Toolbar from './components/ToolBar'
import ToursTable from "./components/ToursTable";

import icon from "../../../images/planner-lite-icon.svg"

import {getLocale, translate as t} from "../../../utils";

import styles from './styles.module.scss'
import OptimizationModal from '../Board/OptimizationModal';
import {useInterval} from "../../../hooks";
import {PLANNING_AVAILABILITY_TIME} from "../../../constants";
import {useHistory} from "react-router";
import { setTourPageState } from 'actions/tours';
import OptimizeLoader from 'components/common/OptimizeLoader';

const PlannerLite = () => {
  const [isSavingDisabled, setIsSavingDisabled] = useState(true)
  const [centralSortingDone, setCentralSortingDone] = useState(false)
  const [enableSaveTours, setEnableSaveTours] = useState(false)
  const forReloading = useRef(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const date = moment()
    .locale(getLocale())
    .format('DD MMMM YYYY, dddd')

  const hasTours = useSelector(fromTours.hasTours)
  const hasOptimizationError = useSelector(fromUI.getHasOptimizationError)
  const allToursAreDoneAtOptimizedTime = useSelector(fromCombined.allToursAreDoneAtOptimizedTime)
  const optimizeModalVisible = useSelector(fromUI.isOptimizeModalShown)
  const numberOfShipments = useSelector(fromShipments.getNumberOfNonCancelledShipments)
  const tourPageState = useSelector(fromTours.getTourPageState)

  const runOptimization = useCallback(() => {
    dispatch(assignToTours())
  }, [assignToTours])

  const isPageReloaded = useCallback(() => {
    if(moment(tourPageState.timeUserOnPage).isBefore(moment(PLANNING_AVAILABILITY_TIME, 'HH:mm'))) {
      forReloading.current = true
    }
    setIsSavingDisabled(false)
    setCentralSortingDone(true)
  }, [forReloading.current])

  const toggleOptimizationModal = useCallback(() => {
    dispatch(toggleOptimizeModal())
  }, [toggleOptimizeModal])

  const checkPlanningAvailability = useCallback(() => {
    setIsSavingDisabled(moment().isBefore(moment(PLANNING_AVAILABILITY_TIME, 'HH:mm')))
    setCentralSortingDone(!moment().isBefore(moment(PLANNING_AVAILABILITY_TIME, 'HH:mm')))
  }, [])

  const [startPolling, stopPolling] = useInterval(checkPlanningAvailability, 1000)

  const reloadPage = useCallback(() => history.go(0), [])



  useEffect(() => {
    if(!tourPageState.timeUserOnPage) {
      dispatch(setTourPageState(moment()))
    }

    if(moment().isBefore(moment(PLANNING_AVAILABILITY_TIME, 'HH:mm'))){
      forReloading.current = true
      startPolling()
    }else {
      isPageReloaded()
    }
    return () => stopPolling()
  }, [])

  useEffect(() => setEnableSaveTours(moment(tourPageState.timeUserOnPage).isBefore(moment(PLANNING_AVAILABILITY_TIME, 'HH:mm')))
  ,[tourPageState.timeUserOnPage])

  return (
    <Page
      className={styles.container}
      title={t('TOURS')}
      right={(
        <Typography className={styles.pageDate}>
          {date}
        </Typography>
      )}
    >
      {
        hasTours ? (
          <>
            <Toolbar savingDisabled={enableSaveTours}/>
            <div className={styles["notification-container"]}>
              {
                isSavingDisabled && !centralSortingDone &&
                <SimpleNotificationBox icon={<FontAwesomeIcon icon={faExclamationTriangle} />}>
                  {t('LITE_NOTIFICATION_MESSAGE_CENTRAL_SORTING_NOT_STARTED')}
                </SimpleNotificationBox>
              }
              {
                (forReloading.current && !isSavingDisabled) &&
                <SimpleNotificationBox className={styles["success-notification"]} icon={<FontAwesomeIcon icon={faCheckCircle} />}>
                  {t('LITE_NOTIFICATION_MESSAGE_CENTRAL_SORTING_IS_DONE')}
                  <Button
                    type="info"
                    label={t('LITE_RELOAD')}
                    onClick={reloadPage}
                  />
                </SimpleNotificationBox>
              }
              {
                !allToursAreDoneAtOptimizedTime &&
                <SimpleNotificationBox icon={<FontAwesomeIcon icon={faExclamationTriangle} />}>
                  {t('LITE_NOTIFICATION_MESSAGE_NOT_DONE_BEFORE_TIME')}
                </SimpleNotificationBox>
              }
              {
                hasOptimizationError &&
                <SimpleNotificationBox icon={<FontAwesomeIcon icon={faExclamationTriangle} />}>
                  {t('LITE_NOTIFICATION_MESSAGE_OPTIMIZATION_FAILURE')}
                </SimpleNotificationBox>
              }
            </div>
            <ToursTable/>
          </>
        ) :
        (
          <div className={styles["default-message-container"]}>
            <img src={icon}/>
            <h1>{t('LITE_DEFAULT_HEADER')}</h1>
            <span className={styles["sub-details"]}>{t('LITE_DEFAULT_SUB_DETAILS')}</span>
            <Button
              className={styles.button}
              type="info"
              label={t('LITE_CREATE_TOURS')}
              onClick={toggleOptimizationModal}
            />
          </div>
        )
      }

      <OptimizationModal
        visible={optimizeModalVisible}
        onClose={toggleOptimizationModal}
        onOptimize={runOptimization}
        shipmentCount={numberOfShipments}
      />
    </Page>
  )
}

export default memo(PlannerLite)
