import React, { PropTypes, Component } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from '../../browserHistory'
import FontIcon from 'material-ui/FontIcon'

import Translate from '../Translate'

class Instructions extends Component {

  render() {

    return (
      <div>
        <h1><Translate uppercase={true}>INSTRUCTIONS</Translate></h1>
        <p><Translate capitalize={true}>INSTRUCTIONS_TXT</Translate></p>
        <a style={{ cursor: 'pointer', color: '#048dbe' }} onClick={() => browserHistory.push('/update')}>
          <Translate capitalize={true}>UPDATE_VIDEO</Translate> (v{this.props.ui.appVersion})
        </a>
        <div style={{ height: 60 }}></div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
  }
}

export default connect(mapStateToProps, null)(Instructions)
