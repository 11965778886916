import React from 'react'
import PropTypes from 'prop-types'

import Dialog from 'material-ui/Dialog'
import IconButton from 'material-ui/IconButton'

import Translate from '../../Translate'
import Close from '../Close'

import classes from './ScannedDialog.module.scss'
import TranslatedButton from '../TranslatedButton'

const ScannedParcelModal = ({
  shipment, onPostpone, onReturn, onClose,
}) => {
  const handleOnPostPone = React.useCallback(
    () => {
      onPostpone(shipment.shipmentId)
      onClose()
    },
    [shipment, onPostpone, onClose],
  )

  const handleOnReturn = React.useCallback(
    () => {
      onReturn(shipment.shipmentId)
      onClose()
    },
    [shipment, onReturn, onClose],
  )

  return (
    <Dialog
      open={!!shipment}
      paperProps={{
        className: classes.dialog,
      }}
    >
      {shipment && (
        <>
          <div className={classes.headerContainer}>
            <Translate component="h3">SHIPMENT_SCANNED_TITLE</Translate>
            <IconButton onClick={onClose} className={classes.iconButton}>
              <Close className={classes.icon} />
            </IconButton>
          </div>

          <div className={classes.detailsContainer}>
            <span className={classes.detail}>
              <Translate className={classes.title} component="p" capitalize>
                ADDRESS
              </Translate>
              <p className={classes.text}>
                {shipment.recipient.address}
              </p>
              <p className={classes.subtitle}>
                {`${shipment.recipient.postalCode} ${shipment.recipient.city}`}
              </p>
            </span>

            <span className={classes.detail}>
              <Translate component="p" className={classes.title} capitalize>
                DRIVER
              </Translate>
              <p className={classes.text}>
                {shipment.driverName || 'N/A'}
              </p>
            </span>

            <span className={classes.detail}>
              <Translate component="p" className={classes.title} capitalize>
                TOUR
              </Translate>
              <p className={classes.text}>
                {shipment.tourId}
              </p>
            </span>

            <span className={classes.detail}>
              <Translate component="p" className={classes.title}>
                NON_DELIVERY_REASON
              </Translate>
              <Translate component="p" className={classes.text}>
                {shipment.reasonCode || 'N/A'}
              </Translate>
            </span>
          </div>

          <div className={classes.actionContainer}>
            <TranslatedButton onClick={handleOnPostPone} variant="blue" className={classes.actionBtn}>
              TODO_NOT_RETURNED
            </TranslatedButton>
            <TranslatedButton onClick={handleOnReturn} variant="green">
              TODO_RETURNED
            </TranslatedButton>
          </div>
        </>
      )}
    </Dialog>
  )
}

ScannedParcelModal.propTypes = {
  onClose: PropTypes.func,
  onPostpone: PropTypes.func,
  onReturn: PropTypes.func,
  shipment: PropTypes.object,
}

ScannedParcelModal.defaultProps = {
  onPostpone: () => null,
  onReturn: () => null,
  shipment: null,
}

export default ScannedParcelModal
