import React, { useMemo } from 'react'
import { isValidCollation } from '../../../actions/tours/helpers'

import styles from './MapActions.module.scss'

import Grid from '@material-ui/core/Grid'
import ListItem from 'material-ui/List/ListItem'

import { StartTimeLabel, EndTimeLabel } from '../BoardList/components/TourShipments'

export const MapListActions = ({
  moveSelectedCollations,
  activeTours,
  disabledParentTour,
}) => {
  return (
    useMemo(()=> {
      return activeTours.map((item) =>
        (<ListItem
          key={item.id}
          onClick={()=> moveSelectedCollations(item)}
          disabled={item.isWorking}
          className={styles.itemRoot}
          leftIcon={<span className={styles.icon} style={{ background: item.color }}>{item.number}</span>}
        >
          <Grid container direction="row" className={styles.listItemContainer}>
            <Grid item lg={9} xs={12} >
              <div className={styles.time}>
                <StartTimeLabel showLabel={false}
                  className={styles.eta} collations={item.collations}/> -
                <EndTimeLabel showLabel={false}
                  className={styles.eta} collations={item.collations}/>
              </div>
            </Grid>
            <Grid item lg={3} xs={12}>
              <div>
                {item.collations.filter(isValidCollation).reduce((a, b) =>
                  a + b.shipmentIds.length || a, 0)}
              </div>
            </Grid>
          </Grid>
        </ListItem>),
      )
    }, [moveSelectedCollations, activeTours, disabledParentTour])
  )}