import * as React from 'react'
import PropTypes from 'prop-types'

import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper'

import Translate from '../../Translate'

import StopPosition from './StopPosition'
import classes from './TourTable.module.scss'

const TourTable = ({
  stops,
}) => (
  <TableContainer component={Paper} elevation={0} className={classes.container}>
    <Table>
      <TableHead>
        <TableRow>
          <Translate capitalize component={TableCell} align="left" width={96}>
            ORDER_STOPS
          </Translate>
          <Translate capitalize component={TableCell} align="left" width={96}>
            CLIENT_TITLE
          </Translate>
          <Translate capitalize component={TableCell} align="left" width={180}>
            ADDRESS
          </Translate>
          <Translate capitalize component={TableCell} align="left" width={96}>POSTAL</Translate>
          <Translate capitalize component={TableCell} align="left" width={96}>CITY</Translate>
          <Translate capitalize component={TableCell} align="left" width={96}>PICKUP_TIME</Translate>
          <Translate capitalize component={TableCell} align="left">STOP_REMARK</Translate>
        </TableRow>
      </TableHead>
      <TableBody>
        {React.useMemo(() => stops.map((stop, index) => (
          <TableRow key={stop.id}>
            <Translate component={TableCell} align="left" width={96}>
              <StopPosition
                position={index}
                first={index === 0}
                last={index === stops.length - 1}
              />
            </Translate>
            <Translate component={TableCell} align="left" width={96}>{stop.name}</Translate>
            <Translate component={TableCell} align="left" width={180}>{stop.addressLine}</Translate>
            <Translate component={TableCell} align="left" width={96}>{stop.postal}</Translate>
            <Translate component={TableCell} align="left" width={96}>{stop.city}</Translate>
            <Translate component={TableCell} align="left" width={96}>{stop.time}</Translate>
            <Translate component={TableCell} align="left">{stop.remark}</Translate>
          </TableRow>
        )), [stops])}
      </TableBody>
    </Table>
  </TableContainer>
)

TourTable.propTypes = {
  stops: PropTypes.array,
}

TourTable.defaultProps = {
  stop: [],
}

export default TourTable
