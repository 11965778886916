import React from 'react'
import PropTypes from 'prop-types'
import FlatButton from 'material-ui/FlatButton'
import { COLORS } from '../../../constants'

const labelStyle = {
  color: COLORS.WHITE,
  textTransform: 'None',
}

const labelStyleInverted = {
  color: COLORS.BLACK,
  textTransform: 'None',
}

const buttonStyle = {
  borderRadius: 5,
}

export const Button = ({
  type='submit', label, onClick, disabled = false, style = {}, customLabelStyle = {}, ...passed
}) => {
  switch (type) {
  case 'success':
    return (<FlatButton
      type="submit"
      backgroundColor={COLORS.GREEN_1}
      labelStyle={Object.assign({}, labelStyle, customLabelStyle)}
      hoverColor={COLORS.GREEN_2}
      disableTouchRipple={true}
      label={label}
      disabled={disabled}
      onClick={onClick}
      style={{ ...buttonStyle, ...style }}
      {...passed}
    />)
  case 'error':
    return (<FlatButton
      type="submit"
      backgroundColor={COLORS.RED_3}
      labelStyle={Object.assign({}, labelStyle, customLabelStyle)}
      hoverColor={COLORS.RED_4}
      disableTouchRipple={true}
      label={label}
      disabled={disabled}
      onClick={onClick}
      style={{ ...buttonStyle, ...style }}
      {...passed}
    />)
  case 'invert':
    return (<FlatButton
      backgroundColor={COLORS.WHITE}
      labelStyle={labelStyleInverted}
      hoverColor={COLORS.GRAY_LIGHT_3}
      disableTouchRipple={true}
      label={label}
      disabled={disabled}
      onClick={onClick}
      style={{ ...buttonStyle, ...style }}
    />)
  case 'danger' :
    return (<FlatButton
      type="submit"
      backgroundColor={COLORS.RED_DANGER}
      labelStyle={Object.assign({}, labelStyle, customLabelStyle)}
      hoverColor={COLORS.RED_DANGER_HOVER}
      disableTouchRipple={true}
      label={label}
      disabled={disabled}
      onClick={onClick}
      style={{ ...buttonStyle, ...style }}
      {...passed}
    />)
  case 'info':
  default:
    return (<FlatButton
      type="submit"
      backgroundColor={COLORS.BLUE}
      labelStyle={Object.assign({}, labelStyle, customLabelStyle)}
      hoverColor={COLORS.BLUE_HOVER}
      disableTouchRipple={true}
      label={label}
      disabled={disabled}
      onClick={onClick}
      style={{ ...buttonStyle, ...style }}
      {...passed}
    />)
  }
}

export const SuccessButton = (props) => {
  return (<Button
    {...props}
    type="success"
  />)
}

export const ErrorButton = (props) => {
  return (<Button
    {...props}
    type="error"
  />)
}

export const InfoButton = (props) => {
  return (<Button
    {...props}
    type="info"
  />)
}

export const DangerButton = (props) => {
  return (<Button
    {...props}
    type="danger"
  />)
}

Button.propTypes = {
  type: PropTypes.string,
  label: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  customLabelStyle: PropTypes.object,
}
