import React from 'react'
import { Card } from 'material-ui'

const ErrorMessageBox = ({ message }) => {
  return (
    <Card
      style={{
        maxWidth: 440,
        width: '100%',
        marginTop: 12,
        height: 35,
        lineHeight: '35px',
        borderRadius: 5,
        backgroundColor: '#FF5351',
        color: '#FFFFFF',
        textAlign: 'center',
      }}
    >
      <div>{message}</div>
    </Card>
  )
}

export default ErrorMessageBox
