import {ICollation, IDriver} from "./@types";
import maxBy from "lodash/maxBy";
import get from "lodash/get";
import moment from "moment";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import groupBy from "lodash/groupBy";

const isValidCollation = ({ shipmentIds: [shipmentId] }: ICollation) => shipmentId > 0

export const getTotalShipment = (collations: Array<ICollation>) => collations.filter(isValidCollation).reduce((a: number, b: ICollation) => a + b.shipmentIds.length || a, 0)

export const getDepartureTime = ([{ estimation: startTime }]: Array<ICollation>) => startTime

export const getLastStopTime = (collations: Array<ICollation>) => {
  const end = maxBy(collations, 'position')
  if(end){
    const durationValue = get(end, 'durationValue', 0)
    return moment(end.estimation).subtract(durationValue + 120, 's')
  }
}

export const getDriversGroupByName = (drivers: Array<IDriver>) => {
  const driversSortedByName = sortBy(uniqBy(drivers, 'name'), ({name}) => name.toLowerCase())
  return groupBy(driversSortedByName, ({name}) => name.slice(0, 1).toLowerCase()) as {[key: string] : Array<IDriver>}
}
