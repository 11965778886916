import React from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import has from 'lodash/has'

import Translate from '../Translate'
import { trunkrs } from '../../index'
import { Card } from 'material-ui'
import { ProfilePicture } from './components/ProfilePicture'
import { Separator } from '../Separator/Separator'
import { UploadButton } from './components/UploadButton'
import * as UIActions from '../../actions/ui'
import * as driversActions from '../../actions/drivers'
import { fetchInvoiceSubco, reloadDrivers } from '../../actions/profile'
import { translate } from '../../utils/translate'
import { getInvoiceSubcos, getSubco, getSubcoRegion } from '../../selectors/profile'
import { changeSubco } from '../../actions/profile'

import { browserHistory } from '../../browserHistory'
import { Form, Formik } from 'formik'
import FormikInput from '../FormComponents/FormikInput'

import { validationSchema, subcoValidationSchema } from './Validation'
import Button from '@material-ui/core/Button'

import styles from './styles.module.scss'
import { INVOICE_SUBCO_REGIONS } from '../../constants'
import FormikDropdown from '../../components/FormComponents/FormikDropdown'
import Item from 'components/planner/BoardList/components/Dropdown/item'
import clsx from 'clsx'


const cardStyle = {
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.12)',
  width: 650,
  borderRadius: 5,
  marginTop: 28,
  padding: 28,
  textAlign: 'center',
}

const languages = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Netherlands',
    value: 'nl',
  },
  {
    label: 'France',
    value: 'fr',
  },
]

class AddDriver extends React.Component {
  subco = this.props.subco;
  state = {
    active: false,
    file: {},
    imagePreviewUrl: '',
    image: null,
    uploading: false,
  };

  showToast = (title, msg) => {
    const [transTitle, transMessage] = translate([title, msg])
    toastr.error(transTitle, transMessage)
  };

  saveImage = async() => {
    try {
      this.setState({ uploading: true })
      const image = await trunkrs.Image.upload(`driver_${new Date().getTime()}.png`, this.state.imagePreviewUrl)
      this.setState({ uploading: false, image, imagePreviewUrl: image.getBase64string })
    } catch (error) {
      this.showToast('IMAGE_ERROR', 'IMAGE_ERROR_MSG')
      this.setState({
        file: {},
        imagePreviewUrl: '',
        uploading: false,
      })
    }
  }

  isInvoiceSubcoRequired = () => has(INVOICE_SUBCO_REGIONS, this.props.subcoCurrentRegion)

  handleSelectedFile = (event) => {
    const reader = new FileReader()
    const file = event.target.files[0]

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      }, this.saveImage)
    }
    reader.readAsDataURL(file)
  };

  componentDidMount() {
    const { fetchInvoiceSubco } = this.props
    fetchInvoiceSubco()
  }

  onSubmit = async(values) => {
    const { image } = this.state
    const { name, phoneNumber, invoiceSubcoId, language } = values

    try {
      this.props.setLoading(true)
      const driver = new (trunkrs.Driver())(
        undefined,
        name,
        undefined,
        phoneNumber,
        image ? image.getLocation : '',
        get(this.props, 'subco.getId'),
        false,
        invoiceSubcoId,
      )

      const newDriver = await driver.save()
      await trunkrs.Driver().setUserPreferencesById(newDriver.id, 'locale', language)
      await this.props.reloadDrivers(newDriver)
      this.props.setLoading(false)
      browserHistory.push('/drivers')
    } catch (error) {
      console.error(error)
      this.showToast('IMAGE_ERROR', 'IMAGE_ERROR_MSG')
    }
    finally {
      this.props.setLoading(false)
    }
  }

  render() {
    const { invoiceSubcos } = this.props

    return (
      <div className="add-driver--wrapper">
        <h1 className="add-driver--title">
          <Translate capitalize={true}>ADD_DRIVER</Translate>
        </h1>
        <Card style={cardStyle}>
          <ProfilePicture
            picture={this.state.imagePreviewUrl}
            uploading={this.state.uploading}
          />
          <UploadButton
            name="profile-picture-driver"
            onChange={this.handleSelectedFile}
            label={'Upload driver picture'}
          />
          <Separator style={{ marginTop: 28, marginBottom: 28 }}/>

          <Formik
            validateOnChange
            validateOnBlur={false}
            validateOnMount={false}
            initialValues={{
              name: '',
              phoneNumber: '',
              invoiceSubcoId: undefined,
              language: ''
            }}
            validationSchema={this.isInvoiceSubcoRequired() ? subcoValidationSchema : validationSchema}
            onSubmit={this.onSubmit}
          >
            <Form className="form">
              <FormikInput
                fullWidth
                className="input"
                rootClass="rootClass"
                name="name"
                label="Name"
              />
              <div className={styles.selectInvoiceSubcoContainer}>
                <span className={styles.label}>
                  <Translate>DRIVER_PREF_LANG</Translate>
                </span>
                <FormikDropdown name="language" button={false}
                  containerRoot={styles.inputStyle}
                  rootClass={clsx(styles.dropdown, styles.zIndex)}>
                  {languages.map(item => (
                    <Item
                      overrideSelectColor
                      value={item.value}
                      key={item.value}
                      label={item.label}/>),
                  )}
                </FormikDropdown>
              </div>

              { this.isInvoiceSubcoRequired() &&
                <div className={styles.selectInvoiceSubcoContainer}>
                  <div className={styles.selectInvoiceSubcoLabel}>
                    <Translate capitalize={true}>SUBCONTRACTOR</Translate>
                  </div>
                  <FormikDropdown
                    button={false}
                    containerRoot={styles.inputStyle}
                    name="invoiceSubcoId"
                    rootClass={styles.dropdown}>
                    {invoiceSubcos.map(({ id, name }) => (<Item
                      overrideSelectColor
                      value={id}
                      key={id}
                      label={name}/>))}
                  </FormikDropdown>
                </div>
              }

              <div>
                <FormikInput
                  fullWidth
                  className="input"
                  rootClass="rootClass"
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="+316"
                />
              </div>

              <div className={styles.btnContainer}>
                <Button
                  type="submit"
                  className={styles.save}
                >
                  <Translate>SAVE_DRIVER</Translate>
                </Button>
              </div>
            </Form>
          </Formik>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  subco: getSubco(state),
  location: state.profile.location,
  invoiceSubcos: getInvoiceSubcos(state),
  subcoCurrentRegion: getSubcoRegion(state),
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: () => dispatch(UIActions.setLoading()),
  addDriver: (payload) => dispatch(driversActions.add(payload)),
  changeSubco: (payload) => dispatch(changeSubco(payload)),
  fetchInvoiceSubco: () => dispatch(fetchInvoiceSubco()),
  reloadDrivers: (payload) => dispatch(reloadDrivers(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddDriver)
