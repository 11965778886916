import moment from "moment";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

export enum FILTERS {
  ALL_SHIPMENTS,
  CENTRAL_SORTED,
  CANCELLED
}

export interface IShipmentProfile {
  CollationId: number,
  DeliveryDate: Date,
  DriverId: any,
  Id: number,
  Log: {
    DeliveryHubId: any,
    DestinationId: any,
    PickupHubId: any,
    ServiceHubId: any,
  },
  MultiColli: boolean,
  OrderReference: string,
  Pos: any,
  Recipient: {
    Address: string,
    City: string,
    Country: string,
    HouseNumber: string,
    Id: number,
    Lat: number,
    Lng: number,
    Name: string,
    PhoneNumber: string,
    PostalCode: string,
    Remarks: any,
  },
  Return: boolean,
  Route: string,
  SenderId: number,
  State: string,
  Timeslot: {
    Close: string,
    CutOff: any,
    Date: string,
    Id: any,
    Open: string,
  },
  TrunkrsNr: string,
  instance: any,
  HasTourAssigned: boolean,
}
