import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import connect from './connect'
import { Button } from '../../common/Button/Button'
import { translate } from '../../../utils/translate'
import { CheckmarkCard } from '../../CheckmarkCard'
import { Callout } from 'components/base'
import Translate from '../../Translate'
import { useTranslate } from 'utils'
import clsx from 'clsx'
import { browserHistory } from '../../../browserHistory'

import styles from './lockscreen.module.scss'

export const LockScreen = ({
  areToursLocked,
  areToursPlanned,
  areDriversAssigned,
  areToursSaved,
  lockTours,
  fetchTourSortingStatus,
  children,
  tours,
  isBusyDay,
  shipmentToursNotSorted,
}) => {
  const { t } = useTranslate('GLOBAL_NOTIFICATIONS')
  const handleLockButton = () => lockTours()
  const navigateToTours = () => browserHistory.push('/tours')

  useEffect(() => {
    fetchTourSortingStatus()
  }, [])

  const shipmentHasNotSorted = React.useMemo(() => isBusyDay && shipmentToursNotSorted, [isBusyDay, shipmentToursNotSorted])

  if (!areToursPlanned) {
    const disabled = !areToursSaved || !(areDriversAssigned && tours.isSaved === undefined) || shipmentHasNotSorted
    return (
      <div className={styles.lockscreenWrapper}>
        <Translate className={styles.indigo} component="h1">LOCK_TITLE</Translate>
        <Translate className={styles.indigo} component="p">LOCK_DESCRIPTION</Translate>

        {shipmentHasNotSorted && (
          <Callout variant="danger" className={styles.banner}>
            {t('HANDOVER_CENTRAL_SORTING_DONE')}
          </Callout>
        )}

        <CheckmarkCard
          owner={translate('LOCK_PLANNING_OWNER')}
          title={translate('LOCK_PLANNING_TITLE')}
          description={translate('LOCK_PLANNING_DESCRIPTION')}
          checkedDescription={translate('LOCK_PLANNING_SAVED')}
          checked={areToursSaved}
        />

        <CheckmarkCard
          owner={translate('LOCK_DRIVERS_OWNER')}
          title={translate('LOCK_DRIVERS_TITLE')}
          description={translate('LOCK_DRIVER_DESCRIPTION')}
          checkedDescription={translate('LOCK_DRIVER_DONE')}
          cta={translate('LOCK_DRIVER_CTA')}
          action={navigateToTours}
          checked={(areDriversAssigned && tours.isSaved === undefined)}
        />

        <div className={styles.buttonRoot}>
          <Button
            label={translate('LOCK_BUTTON')}
            onClick={handleLockButton}
            className={clsx({ [styles.disabled]: disabled }, styles.lockscreenButton)}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }
  return children
}

LockScreen.propTypes = {
  tours: PropTypes.any,
  toursLocked: PropTypes.any,
  allToursHaveDriver: PropTypes.any,
  lockTours: PropTypes.func,
  locking: PropTypes.bool,
  areToursSorted: PropTypes.bool,
}

export default connect(LockScreen)
