import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

const theme = createMuiTheme({
  overrides: {
    MuiSelect: {
      icon: {
        fontSize: '18px',
      },
      select: {
        background: 'none !important',
      },
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 5,
  },
  palette: {
    primary: { main: '#201047' },
    secondary: { main: '#2CE27E' },
    error: { main: '#f04141' },
    warning: { main: '#ffce00' },
    success: { main: '#2CE27E' },
  },
  typography: {
    fontSize: '12px',
    fontFamily: [
      'ClanPro-WideBook',
      'sans-serif',
    ].join(', '),
  },
})

export default theme
