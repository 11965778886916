import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBoxes,
  faClipboardList,
  faCompass,
  faGift,
  faSnowflake,
  faTruck, faUserAstronaut
} from "@fortawesome/free-solid-svg-icons";
import {isFeatureEnabled} from "../../utils";
import HUB_INVENTORY_ICON from '../../images/hub-inventory.svg'
import SideMenuIcon from "../SideMenuIcon";


export default [
  {
    id: 1,
    path: '/shipments',
    label: 'SHIPMENTS',
    icon: <SideMenuIcon icon="shipments" />,
    disabled: !isFeatureEnabled('SHIPMENTS')
  },
  {
    id: 2,
    path: '/tours',
    label: 'TOURS',
    icon: <SideMenuIcon icon="tours" />,
    disabled: !isFeatureEnabled('TOURS')
  },
  {
    id: 3,
    path: '/handover',
    label: 'HANDOVER',
    icon: <SideMenuIcon icon="handover" />,
    disabled: !isFeatureEnabled('HANDOVER')
  },
  {
    id: 4,
    path: '/quicktours',
    label: 'DASHBOARD',
    icon: <SideMenuIcon icon="tour-progress" />,
    disabled: !isFeatureEnabled('DASHBOARD')
  },
  {
    id: 5,
    path: '/todo-list',
    label: 'TODO_LIST',
    icon: <SideMenuIcon icon="todo-list" />,
    disabled: !isFeatureEnabled('TODO_LIST')
  },
  {
    id: 6,
    path: '/collections',
    label: 'COLLECTIONS',
    icon: <FontAwesomeIcon icon={faBoxes} />,
    disabled: !isFeatureEnabled('COLLECTIONS')
  },
  {
    id: 7,
    path: '/freezer-management',
    label: 'FREEZER_MANAGEMENT',
    icon: <FontAwesomeIcon icon={faSnowflake} />,
    disabled: !isFeatureEnabled('FREEZER_MANAGEMENT')
  },
  {
    id: 8,
    path: '/drivers',
    label: 'EMPLOYEES',
    icon: <SideMenuIcon icon="user" />,
    disabled: !isFeatureEnabled('EMPLOYEES')
  },
  {
    id: 9,
    path: '/hub-inventory',
    label: 'SIDEBAR_HUB_INVENTORY',
    icon: <SideMenuIcon icon="hub-inventory" />,
    disabled: !isFeatureEnabled('HUB_INVENTORY'),
  },
  {
    id: 10,
    path: '/returns-scan',
    label: 'SIDEBAR_RETURNS_SCAN',
    icon: <SideMenuIcon icon="returns-scan" />,
    disabled: !isFeatureEnabled('RETURNS_SCAN')
  },
]
