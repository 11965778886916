import React from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import Dialog from 'material-ui/Dialog'
import RaisedButton from 'material-ui/RaisedButton'

import BoardList from '../BoardList'
import Map from '../Map'
import Translate from '../../Translate'

import styles from './index.module.css'
import { Button, DangerButton } from '../../common/Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { translate } from '../../../utils/translate'

class EditModal extends React.PureComponent {
  getTourIndex = memoize((tours, tour) =>
    tours.findIndex(overviewTour => overviewTour.id === tour.id),
  )

  render() {
    const {
      tour, tours, drivers, handoverStarted,
      onSetDriver, onNewRoute, onClose,
    } = this.props
    const tourIndex = this.getTourIndex(tours, tour)

    return (
      <Dialog
        open
        modal
        className={styles.modal}
        contentClassName={styles.modalContent}
        bodyClassName={styles.modalBody}
        paperClassName={styles.modalPaper}
      >
        <DangerButton
          label={<>
            <FontAwesomeIcon icon={faTimes}/> {translate('CLOSE')}
          </>}
          className={styles.closeButton}
          onClick={onClose}
        />

        <div className={styles.container}>
          <div className={styles.listBox}>
            <BoardList
              editMode
              scroll
              index={tourIndex}
              tours={tours}
              tour={tour}
              color={tour.color}
              updateEstimation={this.updateEstimation}
              updateTours={this.updateTours}
              drivers={drivers}
              addRoute={onNewRoute}
              changeMade={this.changeMade}
              reverseTour={this.reverseTour}
              optimizeTour={this.optimizeTour}
              markTourAsPlanned={this.handleMarkTourAsPlanned}
              assignDriverToATour={onSetDriver}
            />
          </div>
          <div className={styles.mapBox}>
            <Map
              editMode
              editTour={tour}
              tours={tours}
              mapNr={1}
              addRoute={onNewRoute}
              handoverStarted={handoverStarted}
              toggleRoute={this.toggleRoute}
              updateEstimation={this.updateEstimation}
              updateTours={this.updateTours}
              changeMade={this.changeMade}
            />
          </div>
        </div>
      </Dialog>
    )
  }
}

EditModal.propTypes = {
  tour: PropTypes.object.isRequired,
  tours: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  handoverStarted: PropTypes.bool.isRequired,
  onSetDriver: PropTypes.func.isRequired,
  onNewRoute: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

EditModal.defaultProps = {
}

export default EditModal
