import { connect } from 'react-redux'

import * as todoListActions from '../../actions/todoList'
import * as fromTodoList from '../../selectors/todoList'
import * as fromProfile from '../../selectors/profile'

const mapStateToProps = state => ({
  isLoadNeeded: !fromTodoList.getHasFetched(state)
    && !fromTodoList.getIsLoading(state)
    && fromProfile.getCurrentSubcoId(state) > 0,
  isLoading: fromTodoList.getIsLoading(state),
  notDelivered: fromTodoList.getTodoNotDeliveredShipments(state),
  noEndState: fromTodoList.getTodoNoEndStateShipments(state),
  done: fromTodoList.getDoneTodoShipments(state),
  scanLookup: fromTodoList.getScanLookup(state),
  returnShipments: fromTodoList.getTodoReturns(state),
  currentLocale: fromProfile.getCurrentLocale(state),
})

const mapDispatchToProps = {
  loadShipments: todoListActions.loadShipments,
  markPostponed: todoListActions.markPostponed,
  markLost: todoListActions.markLost,
  markReturned: todoListActions.markReturned,
}

export default connect(mapStateToProps, mapDispatchToProps)
