import React, {memo, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import {getIsLoading} from "../../selectors/freezerManagement";
import {getCurrentSubcoId} from '../../selectors/profile'
import {
  addFreezerState,
  fetchFreezerLogs,
  getFreezerLogs,
  setFreezerLogs,
  setLoading
} from "../../actions/freezerManagement";
import {FREEZER_STATE, IFreezerLogPayload} from "../../reducers/freezerManagement/@types";
import Loading from "../loading/Loading";
import Form from "../common/reactHookComponents/form";
import {ExpectedShipmentsTable, FrozenShipmentsTable, ScanBar, ScanFeedback} from "./components"
import {translate} from "../../utils/translate";
import Page from '../Page'
import styles from './styles.module.scss'
import OptimizeLoader from "components/common/OptimizeLoader";

const FreezerManagement = () => {
  const dispatch = useDispatch()
  const subcoId = useSelector((state: any) => getCurrentSubcoId(state))
  const isLoading = useSelector((state) => getIsLoading(state))

  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false)
  const [currentFreezerState, setCurrentFreezerState] = useState<FREEZER_STATE>(FREEZER_STATE.FREEZER_IN)

  const timeoutId = useRef<any>()

  const handleScan = ({trunkrsNrOrBarcode}: {
    trunkrsNrOrBarcode: string
  }) => {
    clearTimeout(timeoutId.current)
    setIsFeedbackVisible(false)
    dispatch(setLoading(true))
    addFreezerState(subcoId, trunkrsNrOrBarcode).then(({freezerState, freezerLogResponse,}: {freezerState: FREEZER_STATE, freezerLogResponse: FREEZER_STATE, })=> {
      setCurrentFreezerState(freezerState ? freezerState: freezerLogResponse)
      getFreezerLogs(subcoId)
        .then((payload: any) => {
          dispatch(setFreezerLogs(payload))
          setIsFeedbackVisible(true)
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }).catch(() =>   {
      dispatch(setLoading(false))
    })
  }

  useEffect(()=>{
    if(subcoId){
      dispatch(fetchFreezerLogs(subcoId))
    }
  }, [subcoId])

  useEffect(()=>{
    if(isFeedbackVisible){
      timeoutId.current = setTimeout(()=>{
        setIsFeedbackVisible(false)
      }, 5000)
    }
  }, [isFeedbackVisible, timeoutId.current])


  return (
   <Page title={ translate('FREEZER_MANAGEMENT')}>
      <Container className={styles.container} maxWidth={false}>
      <Grid container>
        <Grid item xs={4} className={styles.scanBarContainer}>
          <Form<any> defaultValues={{
            trunkrsNrOrBarcode: ''
          }} onSubmit={handleScan}>
            <ScanBar name="trunkrsNrOrBarcode" placeholder={translate('SCAN_FROZEN_SHIPMENT')} />
          </Form>
          <ScanFeedback isOpen={isFeedbackVisible} freezerState={currentFreezerState} />
        </Grid>
      </Grid>
      <Grid container className={styles.tableContainer}>
        <ExpectedShipmentsTable />
        <FrozenShipmentsTable />
      </Grid>
      <OptimizeLoader visible={isLoading}/>
    </Container>
   </Page>
  )
}

export default memo(FreezerManagement)
