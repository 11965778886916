import React, {memo} from 'react'
import {FREEZER_STATE} from "../../../../reducers/freezerManagement/@types";
import {FreezerInFeedback, FreezerOutFeedback, FreezerReturnFeedback, FreezerNotAcceptedFeedback} from "./components";

const ScanFeedback = ({isOpen, freezerState}: {isOpen: boolean, freezerState: FREEZER_STATE  }) => {
  switch (freezerState) {
    case FREEZER_STATE.FREEZER_IN:
      return <FreezerInFeedback isOpen={isOpen} key="freezer-in" />;
    case FREEZER_STATE.FREEZER_OUT:
      return <FreezerOutFeedback isOpen={isOpen}  key="freezer-out" />;
    case FREEZER_STATE.FREEZER_RETURN:
      return <FreezerReturnFeedback isOpen={isOpen}  key="freezer-return" />;
    default:
      return <FreezerNotAcceptedFeedback isOpen={isOpen} key="freezer-not-accepted" />
  }
}

export default memo(ScanFeedback)
