import * as React from 'react'
import PropTypes from 'prop-types'

import { MuiThemeProvider as ProvideTheme } from '@material-ui/core/styles'

import theme from './theme'

const ThemeProvider = ({
  children,
}) => (
  <ProvideTheme theme={theme}>
    {children}
  </ProvideTheme>
)

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ThemeProvider
