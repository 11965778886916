import { mergeIndexes } from '../../utils/dataSets'

export const mergeUpdates = (identifiers, data, updatedIds, updatedData) => {
  const changedData = { ...data, ... updatedData }

  return [
    mergeIndexes(identifiers, updatedIds),
    changedData,
  ]
}
