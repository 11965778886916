import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Button from 'material-ui/FlatButton'

import Translate from '../../Translate'

import classes from './TranslatedButton.module.scss'

const variantClasses = {
  blue: classes.blue,
  green: classes.green,
  red: classes.red,
  yellow: classes.yellow,
}

const TranslatedButton = ({
  variant, className, children, onClick, ...passed
}) => (
  <Translate
    component={Button}
    onClick={onClick}
    className={clsx(classes.button, variantClasses[variant], className)}
    {...passed}
  >
    {children}
  </Translate>
)

TranslatedButton.propTypes = {
  variant: PropTypes.oneOf([
    'purple',
    'blue',
    'green',
    'red',
    'yellow',
  ]),
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

TranslatedButton.defaultProps = {
  variant: 'purple',
  className: null,
  children: null,
  onClick: () => null,
}
export default TranslatedButton
