import React from 'react'
import { DangerButton } from '../Button/Button'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { translate } from '../../../utils/translate'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'

const ModalBox = (props) => {
  const { children, onClose, hidden } = props
  const label = (<React.Fragment>
    <FontAwesomeIcon icon={faTimes}/>
    {translate('CLOSE')}
  </React.Fragment>)

  return (
    <div className={clsx('modal-box', { hidden })}>
      <div className="modal-box-content">
        <span className="modal-box-close-button">
          <DangerButton label={label} onClick={onClose}/>
        </span>
        {children}
      </div>
    </div>
  )
}

ModalBox.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  hidden: PropTypes.bool,
}

export default ModalBox
