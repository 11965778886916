import React from 'react'
import PropTypes from 'prop-types'
import classes from '../ShipmentOverview.module.scss'
import clsx from 'clsx'
import memoizeOne from 'memoize-one'
import Box from './Box'

const LineGraph = (props) => {
  const { first, second, title, setFilter, isRatio, index, total } = props
  const percentageFirst = total > 0
    ? Math.round(first.length / total * 100)
    : 0
  const percentageSecond = total > 0
    ? 100 - percentageFirst
    : 0
  const primaryIndex = index
  const generateBoxes = memoizeOne(() => Array(10).fill(0).map((_, index) => {
    const isFirstSet = index * 10 < percentageFirst
    const secondaryIndex = isFirstSet ? 0 : 1
    return (
      <Box
        key={index}
        index={index}
        classes={clsx(
          classes.item,
          index * 10 < percentageFirst && classes.greenItem,
          index * 10 >= percentageFirst && isRatio && classes.redItem,
          index * 10 >= percentageFirst && !isRatio && classes.defaultItem,
        )}
        primaryIndex={primaryIndex}
        secondaryIndex={secondaryIndex}
        setFilter={setFilter}
      />
    )
  }))
  return (
    <div className={classes.filter}>
      <div className={classes.title}>{title}</div>
      <div className={classes.items}>{generateBoxes()}</div>
      <div className={classes.summary}>
        {total > 0
          ? isRatio
            ? <div>{first.length} : {second.length}</div>
            : <div>{first.length} / {total}</div>
          : <div>---</div>
        }
        {isRatio
          ? <div>{percentageFirst}% : {percentageSecond}%</div>
          : <div>{percentageFirst}%</div>
        }
      </div>
    </div>
  )
}

LineGraph.propTypes = {
  first: PropTypes.array,
  second: PropTypes.array,
  title: PropTypes.string,
  setFilter: PropTypes.func,
  isRatio: PropTypes.bool,
  index: PropTypes.number,
  total: PropTypes.number,
}

LineGraph.defaultProps = {
  first: [],
  second: [],
  title: 'title not set',
  setFilter: () => null,
  isRatio: false,
  index: null,
  total: 0,
}

export default LineGraph
