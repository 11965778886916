import React, {memo, useCallback, useMemo, useState} from 'react'
import {useSelector} from "react-redux";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {addItem, downloadPDF, mergePDFBinaryFiles, removeItem, translate as t} from "../../../utils";

import styles from './styles.module.scss'

import SimpleModalBox from "../../common/SimpleModalBox";
import SimpleCheckbox from "../../common/SimpleCheckbox";

import {getToursWithDriver} from '../../../selectors/combined'
import {FlatButton} from "material-ui";
import {COLORS} from "../../../constants";
import { printShippingDocument } from '../../../actions/tours'
import moment from "moment";
import {getSubcoRegion} from "../../../selectors/profile";

export interface IShippingDocumentPrintingModalProps {
  visible: boolean
  onClose: () => void
}

const ShippingDocumentPrintingModal = (props: IShippingDocumentPrintingModalProps) => {
  const [loading, setLoading] = useState(false)

  const tours = useSelector(getToursWithDriver)
  const region = useSelector(getSubcoRegion)

  const [selectedTourIds, setSelectedTourIds] = useState<number[]>([])

  const handleTourSelection = (tourId: number) => (isChecked: boolean) => {
    const nextValue = isChecked ? addItem(selectedTourIds, tourId) : removeItem(selectedTourIds, tourId);
    setSelectedTourIds(nextValue)
  }

  const toggleSelectAll = (isChecked: boolean) => setSelectedTourIds(isChecked ? tours.map(({id}: any) => id) : [])

  const handleExport = useCallback(async () => {
    setLoading(true)
    const binaryFiles = await Promise.all(selectedTourIds.map(printShippingDocument))
    const pdfBytes = await mergePDFBinaryFiles(binaryFiles)
    const filename = `SHIPPING_TOUR_${region}_${moment().format("YYYYMMDDHHmmss")}.pdf`
    downloadPDF(pdfBytes, filename)
    setLoading(false)
  }, [selectedTourIds])

  const hasSelectedTours = !!selectedTourIds.length

  return (
    <SimpleModalBox {...props}>
      <div className={styles.wrapper}>
        <div className={styles["header-container"]}>
          <span className={styles.header}>{t('SELECT_TOUR_TO_PRINT')}</span>
          <span className={styles["sub-header"]}>{t('SELECT_TOUR_TO_PRINT_SUB_DESCRIPTION')}</span>
        </div>
        <div className={styles["main-content"]}>
          <SimpleCheckbox mark="bar" className={styles.grey} checked={hasSelectedTours} onChange={toggleSelectAll}>
            {hasSelectedTours ? `${selectedTourIds.length} ${t('SELECTED_PRINT')}` : t('SELECT_PRINT_ALL')}
          </SimpleCheckbox>
          <div className={styles["list-container"]}>
            {
              useMemo(()=> tours.map(({id:tourId, driver: {name:driverName}}: any) => (
                <SimpleCheckbox onChange={handleTourSelection(tourId)} checked={selectedTourIds.includes(tourId)}>{`Tour ${tourId}: ${driverName}`}</SimpleCheckbox>
              )), [tours, selectedTourIds])
            }
          </div>
        </div>
        <div className={styles["footer"]}>
          <FlatButton
            labelStyle={{
              fontSize: 12,
              textTransform: 'capitalize',
            }}
            icon={loading ? <span className={styles.loading} /> : <FontAwesomeIcon icon={faFileExport} />}
            style={{
              marginRight: 0,
              color: COLORS.WHITE,
              borderRadius: 8,
              height: 45,
              width: 147
            }}
            label={t(loading ? 'PRINT_EXPORT_LOADING' : 'PRINT_EXPORT')}
            backgroundColor={COLORS.BLUE}
            hoverColor={COLORS.BLUE_HOVER}
            onClick={handleExport}
          />
        </div>
      </div>
    </SimpleModalBox>
  )
};

export default memo(ShippingDocumentPrintingModal)
