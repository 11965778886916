import React, {memo, useCallback} from 'react'
import {useDispatch, useSelector} from "react-redux";

import {ToolbarGroup} from "material-ui/Toolbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

import * as fromTours from '../../../../../selectors/tour'
import * as fromCombined from '../../../../../selectors/combined'

import {toggleOptimizeModal} from "../../../../../actions/ui";
import {saveTours, saveDrivers} from "../../../../../actions/tours";

import {COLORS} from "../../../../../constants";

import {Button} from "../../../../common/Button/Button";
import Toolbar from "../../../../toolbar/Toolbar";

import {translate as t} from '../../../../../utils'

import styles from './styles.module.scss'

const ToolBar = ({savingDisabled}: {savingDisabled?: boolean}) => {
  const dispatch = useDispatch()

  const isPlanningLocked = useSelector(fromTours.getIsPlanningLocked)
  const isLocked = useSelector(fromCombined.areToursLocked)
  const tourCount = useSelector(fromCombined.getShipmentToursCount)

  const openOptimizationModal = useCallback(() => {
    dispatch(toggleOptimizeModal())
  }, [toggleOptimizeModal])

  const handeSaveTours = useCallback(() => {
    dispatch(saveDrivers())
    dispatch(saveTours())
  }, [saveTours, saveDrivers])

  return (
    <Toolbar crooked={false} color={COLORS.GRAY_LIGHT} className={styles.tourActionsContainer}>
      <ToolbarGroup>
        <div>
          {!!tourCount && `${tourCount} ${t('LITE_NUMBER_OF_TOURS_CREATED')}`}
        </div>
        <div>
          {!isPlanningLocked &&
            <Button
              className={styles.success}
              type="success"
              label={t('LITE_SAVE_TOURS')}
              icon={<FontAwesomeIcon icon={faSave} />}
              onClick={handeSaveTours}
              disabled={savingDisabled}
            />
          }
          <Button
            type="danger"
            label={t('LITE_RE_OPTIMIZE')}
            onClick={openOptimizationModal}
            disabled={isLocked || isPlanningLocked}
          />
        </div>
      </ToolbarGroup>
    </Toolbar>
  )
}

export default memo(ToolBar)
