import { connect } from 'react-redux'

import * as actions from '../../actions/collection'

import * as fromDrivers from '../../selectors/driver'
import * as fromProfile from '../../selectors/profile'
import * as fromCollection from '../../selectors/collection'

const mapStateToProps = state => ({
  isSubcoReady: !!fromProfile.getCurrentSubcoId(state),
  hasFetched: fromCollection.hasFetchedTours(state),
  isLoading: fromCollection.getIsLoading(state),
  toursOnDates: fromCollection.getTourDatesOverview(state),
  drivers: fromDrivers.getDrivers(state),
  selectedTour: fromCollection.getSelectedTour(state),
  currentLocale: fromProfile.getCurrentLocale(state)
})

const mapDispatchToProps = {
  fetchTours: actions.loadCollectionTours,
  setSelected: actions.selectTour,
  saveTours: actions.saveTours,
}

export default connect(mapStateToProps, mapDispatchToProps)
