import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import isEmpty from 'lodash/isEmpty'

import AutoComplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";

import styles from './styles.module.scss'

export interface ISimpleAutoCompleteProps {
  options: Array<{value: any, text: string}>
  onChange?: (ev: React.ChangeEvent<{}>, value: any) => void
  noOptionsText?: string
  maxListHeight?: number
  textValue?: string
  value?: any
  placeholder?: string
  disabled?: boolean
}

const SimpleAutoComplete = ({options, textValue, placeholder = '', ...props}: ISimpleAutoCompleteProps) => {
  const [inputValue , setInputValue] = useState(textValue)

  useEffect(()=>{
    setInputValue(textValue)
  }, [textValue])

  const handleInputChange = useCallback((ev) => {
    setInputValue( ev.currentTarget.value)
  }, [inputValue])

  const handleInputBlur = useCallback((ev) => {
    const value = ev.currentTarget.value
    setInputValue(isEmpty(value) ? textValue : value)
  }, [inputValue])

  const groupOptions = options.map(option => {
    const firstLetter = option.text[0].toUpperCase()
    return {
      firstLetter,
      ...option
    }
  })

  const renderOptions = useMemo(() => groupOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)), [groupOptions])
  const renderGroupBy = useCallback( (option) => option.firstLetter, [groupOptions])
  const renderOptionLabel = useCallback((option) => option.text, [groupOptions])
  const renderInput = useCallback((params) => <TextField {...{...params, inputProps: {...params.inputProps, value: inputValue}}} onChange={handleInputChange} onBlur={handleInputBlur} placeholder={placeholder} variant="outlined" />, [groupOptions, inputValue])

  return (
    <div className={styles["auto-complete-container"]}>
      <AutoComplete
        {...props}
        options={renderOptions}
        groupBy={renderGroupBy}
        getOptionLabel={renderOptionLabel}
        renderInput={renderInput}
        classes={{
          popper: styles.popper,
          paper: styles.paper,
          listbox: styles.listbox,
          inputRoot: styles.inputRoot
        }}
        popupIcon={<FontAwesomeIcon icon={faChevronDown}/>}
        filterSelectedOptions
        fullWidth
        forcePopupIcon
      />
    </div>
  )
}

export default memo(SimpleAutoComplete)
